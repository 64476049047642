"use strict"

import {observable, action, ObservableMap} from "mobx"
import api from "../actions/api"
import {getUserCompanyInfo, getUserPersonnelOrgUnits, getSelectedOrgUnit} from "../../../global/helpers/actions"
import {getFilters} from "../../../global/helpers/api"

import GlobalStore from "../../../global/store/GlobalStore"
import UIStore from "./UIStore"

class InvoiceSettingsStore {
	@observable isDetailOpen = false
	@observable isDetailLoading = false
	@observable isDetailSaving = false
	@observable isOpenInvoiceSettingDialog = false

	detail_id = ""
	orgUnitId = ""
	@observable invoiceSettingsDataStore = []
	@observable detail_name = ""
	@observable detail_customer = ""
	@observable detail_constant_symbol = ""
	@observable detail_specific_symbol = ""
	@observable detail_description = ""

	customers = []

	@observable insurer = 0

	invoiceConfigId = ""
	@observable invoicesJSON = new ObservableMap()

	invoiceFormRef = null
	@observable insurerConfigId = null
	@observable orgunitConfigId = null
	@observable disabledFields = false

	@observable disabledCap = true
	@observable disabledBezd = true
	@observable disabledCudz = true
	@observable disabledIne = true
	@observable disabledEu = true
	@observable disabledVzp = true

	invoiceArr = []

	customerJSON = {}
	insurerJSON = {}
	orgunitJSON = {}

	@action loadInvoice() {
		for (let i = 1; i <= 50; i++) {
			this.invoiceArr.push({_id: i, name_ext: `Faktúra ${i}`})
		}
	}

	@action loadCustomers() {
		const searchRequest = getFilters([`object_owner=${getUserCompanyInfo().object_owner}`])
		api
			.loadPartners(searchRequest)
			.call()
			.then((response) => {
				if (response.rows) {
					this.customers = response.rows.map((p) => p.org_unit)

					this.customers.forEach((cust) => {
						this.customerJSON[cust._id] = cust.name_ext
					})
				}
			})
	}

	@action loadOrgunit() {
		getUserPersonnelOrgUnits().forEach((orgunit) => {
			this.orgunitJSON[orgunit._id] = orgunit.name_ext
		})
	}

	@action changeInvoice(invoiceNumber) {
		this.clearCheckboxes()

		if (
			isSafe(this.invoiceFormRef) &&
			this.invoicesJSON.has(`${invoiceNumber}`) &&
			this.invoicesJSON.get(`${invoiceNumber}`).length > 0
		) {
			let invoices = this.invoicesJSON.get(`${invoiceNumber}`)

			this.invoiceFormRef.$("insurer").value = invoices[0].insurer
			this.invoiceFormRef.$("partner").value = invoices[0].partner

			this.disabledFields = true
		} else {
			this.invoiceFormRef.$("insurer").value = ""
			this.invoiceFormRef.$("partner").value = ""

			this.disabledFields = false
		}

		this.invoiceFormRef.$("orgunit").value = ""
		this.disabledCap = true
		this.disabledBezd = true
		this.disabledCudz = true
		this.disabledIne = true
		this.disabledEu = true
		this.disabledVzp = true

		this.insurerConfigId = null
	}

	@action clearCheckboxes() {
		this.disabledCap = false
		this.disabledBezd = false
		this.disabledCudz = false
		this.disabledIne = false
		this.disabledEu = false
		this.disabledVzp = false

		this.invoiceFormRef.$("capitation").value = false
		this.invoiceFormRef.$("bezd").value = false
		this.invoiceFormRef.$("eu").value = false
		this.invoiceFormRef.$("ine").value = false
		this.invoiceFormRef.$("cudz").value = false
		this.invoiceFormRef.$("vzp").value = false
	}

	@action validation() {
		if (
			isSafe(this.invoiceFormRef) &&
			isNotEmpty(this.invoiceFormRef.$("orgunit").value) &&
			isNotEmpty(this.invoiceFormRef.$("insurer").value) &&
			isNotEmpty(this.invoiceFormRef.$("partner").value)
		) {
			this.clearCheckboxes()
			let selectedOrgunit = this.invoiceFormRef.$("orgunit").value
			let selectedInsurer = this.invoiceFormRef.$("insurer").value
			let selectedPartner = this.invoiceFormRef.$("partner").value

			this.invoicesJSON.forEach((value, key) => {
				//eslint-disable-line
				let invoiceArr = this.invoicesJSON.get(key)
				if (isSafe(invoiceArr)) {
					invoiceArr.forEach((inv) => {
						if (inv.insurer == selectedInsurer && inv.partner == selectedPartner && inv.orgunit == selectedOrgunit) {
							if (isSafe(inv.capitation) && inv.capitation) {
								this.disabledCap = true
							}
							if (isSafe(inv.eu) && inv.eu) {
								this.disabledEu = true
							}
							if (isSafe(inv.vzp) && inv.vzp) {
								this.disabledVzp = true
							}
							if (isSafe(inv.bezd) && inv.bezd) {
								this.disabledBezd = true
							}
							if (isSafe(inv.cudz) && inv.cudz) {
								this.disabledCudz = true
							}
							if (isSafe(inv.ine) && inv.ine) {
								this.disabledIne = true
							}
						}
					})
				}
			})
		}
	}

	@action selectInvoiceItem(invoice) {
		this.insurerConfigId = invoice.insurer_config_id
		this.orgunitConfigId = invoice.orgunits_config_id
		this.disabledFields = true

		this.clearCheckboxes()
		let selectedOrgunit = invoice.orgunit
		let selectedInsurer = invoice.insurer
		let selectedPartner = invoice.partner

		this.invoicesJSON.forEach((value, key) => {
			//eslint-disable-line
			let invoiceArr = this.invoicesJSON.get(key)
			if (isSafe(invoiceArr)) {
				invoiceArr.forEach((inv) => {
					if (
						inv.insurer == selectedInsurer &&
						inv.partner == selectedPartner &&
						inv.orgunit == selectedOrgunit &&
						inv.insurer_config_id != this.insurerConfigId
					) {
						if (isSafe(inv.capitation) && inv.capitation) {
							this.disabledCap = true
						}
						if (isSafe(inv.eu) && inv.eu) {
							this.disabledEu = true
						}
						if (isSafe(inv.vzp) && inv.vzp) {
							this.disabledVzp = true
						}
						if (isSafe(inv.bezd) && inv.bezd) {
							this.disabledBezd = true
						}
						if (isSafe(inv.cudz) && inv.cudz) {
							this.disabledCudz = true
						}
						if (isSafe(inv.ine) && inv.ine) {
							this.disabledIne = true
						}
					}
				})
			}
		})

		this.invoiceFormRef.$("invoice").value = invoice.invoice
		this.invoiceFormRef.$("insurer").value = invoice.insurer
		this.invoiceFormRef.$("orgunit").value = invoice.orgunit
		this.invoiceFormRef.$("partner").value = invoice.partner
		this.invoiceFormRef.$("capitation").value = isSafe(invoice.capitation) ? invoice.capitation : false
		this.invoiceFormRef.$("bezd").value = isSafe(invoice.bezd) ? invoice.bezd : false
		this.invoiceFormRef.$("eu").value = isSafe(invoice.eu) ? invoice.eu : false
		this.invoiceFormRef.$("ine").value = isSafe(invoice.ine) ? invoice.ine : false
		this.invoiceFormRef.$("cudz").value = isSafe(invoice.cudz) ? invoice.cudz : false
		this.invoiceFormRef.$("vzp").value = isSafe(invoice.vzp) ? invoice.vzp : false
	}

	@action addInvoice(form) {
		if (!this.invoicesJSON.has(`${form.$("invoice").value}`)) {
			this.invoicesJSON.set(`${form.$("invoice").value}`, [])
		}

		let invoiceCount = 0

		this.invoicesJSON.forEach((value, idx) => {
			//eslint-disable-line
			invoiceCount += this.invoicesJSON.get(idx).length
		})

		let newInvoice = form.values()

		newInvoice["insurer_config_id"] = -(invoiceCount + 1)
		newInvoice["orgunits_config_id"] = -(invoiceCount + 1)

		this.invoicesJSON.get(`${form.$("invoice").value}`).push(newInvoice)

		this.disabledFields = true

		this.invoiceFormRef.$("orgunit").value = ""
		this.invoiceFormRef.$("capitation").value = false
		this.invoiceFormRef.$("bezd").value = false
		this.invoiceFormRef.$("eu").value = false
		this.invoiceFormRef.$("ine").value = false
		this.invoiceFormRef.$("cudz").value = false
		this.invoiceFormRef.$("vzp").value = false

		this.disabledCap = true
		this.disabledBezd = true
		this.disabledCudz = true
		this.disabledIne = true
		this.disabledEu = true
		this.disabledVzp = true
	}

	@action updateInvoice(form) {
		// let updatedInsurance = this.invoicesJSON
		// 	.get(`${form.$("invoice").value}`)
		// 	.filter((ins) => ins.insurer_config_id == insurerId)[0]

		let updatedInsurance = this.invoicesJSON
			.get(`${form.$("invoice").value}`)
			.filter((ins) => ins.orgunits_config_id == this.orgunitConfigId)[0]

		let newArr = this.invoicesJSON
			.get(`${form.$("invoice").value}`)
			.filter((ins) => ins.orgunits_config_id != this.orgunitConfigId)
		newArr.push(
			Object.assign(form.values(), {
				orgunits_config_id: updatedInsurance.orgunits_config_id,
				insurer_config_id: updatedInsurance.insurer_config_id
			})
		)

		this.invoicesJSON.set(`${form.$("invoice").value}`, newArr)

		this.invoiceFormRef.$("orgunit").value = ""
		this.invoiceFormRef.$("capitation").value = false
		this.invoiceFormRef.$("bezd").value = false
		this.invoiceFormRef.$("eu").value = false
		this.invoiceFormRef.$("ine").value = false
		this.invoiceFormRef.$("cudz").value = false
		this.invoiceFormRef.$("vzp").value = false

		this.disabledCap = true
		this.disabledBezd = true
		this.disabledCudz = true
		this.disabledIne = true
		this.disabledEu = true
		this.disabledVzp = true

		this.disabledFields = false
		this.insurerConfigId = null
	}

	@action removeInvoice(invoice, orgunitsConfigId) {
		this.invoicesJSON.set(
			`${invoice}`,
			this.invoicesJSON.get(`${invoice}`).filter((ins) => ins.orgunits_config_id != orgunitsConfigId)
		)

		this.invoiceFormRef.reset()

		this.disabledCap = true
		this.disabledBezd = true
		this.disabledCudz = true
		this.disabledIne = true
		this.disabledEu = true
		this.disabledVzp = true

		this.disabledFields = false
		this.insurerConfigId = null
	}

	@action saveInvoices(saveAndClose) {
		let request = {
			_id: this.invoiceConfigId, //new
			_ref: false,
			_type: "EHR.Data.Fin.InvoiceConfig",
			active: true,
			company_id: getUserCompanyInfo().id,
			insurers: []
		}

		this.invoicesJSON.forEach((value, idx) => {
			//eslint-disable-line
			let invoiceArr = this.invoicesJSON.get(idx)

			if (isSafe(invoiceArr) && invoiceArr.length > 0) {
				let orgUnits = []

				invoiceArr.forEach((invoice) => {
					let insuranceTypes = []

					if (invoice.eu) {
						insuranceTypes.push({
							_type: "EHR.CL.Patient.InsuranceType",
							_id: "EU"
						})
					}

					if (invoice.cudz) {
						insuranceTypes.push({
							_type: "EHR.CL.Patient.InsuranceType",
							_id: "FRGN"
						})
					}

					if (invoice.bezd) {
						insuranceTypes.push({
							_type: "EHR.CL.Patient.InsuranceType",
							_id: "HMLS"
						})
					}

					if (invoice.ine) {
						insuranceTypes.push({
							_type: "EHR.CL.Patient.InsuranceType",
							_id: "OTH"
						})
					}

					if (invoice.vzp) {
						insuranceTypes.push({
							_type: "EHR.CL.Patient.InsuranceType",
							_id: "GH"
						})
					}

					orgUnits.push({
						_id:
							isFinite(invoice.orgunits_config_id) && invoice.orgunits_config_id < 0 ? "" : invoice.orgunits_config_id, //take nieco....1||2||2
						_ref: false,
						_type: "EHR.Data.Fin.InvoiceConfig.OrgUnit",
						active: true,
						include_capitations: invoice.capitation, //kapitacia checkbox iba K dat nad checkbox, tooltip kapitacia
						insurance_types: insuranceTypes,
						org_unit: {
							_type: "Entity.Data.OrgUnit",
							_id: invoice.orgunit //id orgunity napr. EUROMED
						}
					})
				})

				request.insurers.push({
					_id:
						isFinite(invoiceArr[0].insurer_config_id) && invoiceArr[0].insurer_config_id < 0
							? ""
							: invoiceArr[0].insurer_config_id, //ak novy prazdny, inak take 1||2 kkt..
					_ref: false,
					_type: "EHR.Data.Fin.InvoiceConfig.Insurer",
					active: true,
					insurer: invoiceArr[0].insurer, //id poistovne
					item_order: invoiceArr[0].invoice, //cislo faktury / poradie
					org_units: orgUnits,
					related_insurer_ou_id: invoiceArr[0].partner //id odberatela, pridat na tretie miesto dropdownlist...api je v ulohe....
				})
			}
		})

		api
			.saveInvoiceConfig(getUserCompanyInfo().id, request)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res._id)) {
					GlobalStore.setNotificationMessage("Common.label.invoicesSettings")
					this.invoiceConfigId = res._id
					if (saveAndClose) {
						this.closeInvoiceSettings()
					}
				}
			})
	}

	@action openInvoiceSettings() {
		api
			.getInvoiceConfig(getUserCompanyInfo().id)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.insurers)) {
					this.invoiceConfigId = res._id

					let tempInvoicesJSON = {}

					res.insurers.forEach((insurer) => {
						let numberInvoice = isSafe(insurer.item_order) ? insurer.item_order : 0

						if (isSafe(insurer.org_units) && insurer.org_units.length > 0) {
							insurer.org_units.forEach((org) => {
								let invoice = {
									vzp: false,
									eu: false,
									bezd: false,
									cudz: false,
									ine: false
								}

								invoice["invoice"] = numberInvoice
								invoice["insurer"] = insurer.insurer._id
								invoice["insurer_config_id"] = insurer._id
								invoice["partner"] = insurer.related_insurer_ou_id

								invoice["orgunit"] = org.org_unit._id
								invoice["orgunits_config_id"] = org._id
								invoice["capitation"] = org.include_capitations ? true : false

								if (isSafe(org.insurance_types) && org.insurance_types.length > 0) {
									org.insurance_types.forEach((type) => {
										if (type._id == "GH") {
											invoice["vzp"] = true
										}
										if (type._id == "EU") {
											invoice["eu"] = true
										}
										if (type._id == "HMLS") {
											invoice["bezd"] = true
										}
										if (type._id == "FRGN") {
											invoice["cudz"] = true
										}
										if (type._id == "OTH") {
											invoice["ine"] = true
										}
									})
								}

								if (!tempInvoicesJSON.hasOwnProperty(numberInvoice)) {
									tempInvoicesJSON[numberInvoice] = []
								}

								tempInvoicesJSON[numberInvoice].push(invoice)
							})
						}
					})

					this.invoicesJSON.merge(tempInvoicesJSON)

					this.invoiceFormRef.$("invoice").value = 1
					this.changeInvoice(1)

					this.isOpenInvoiceSettingDialog = true
				} else {
					this.isOpenInvoiceSettingDialog = true
				}
			})
	}

	@action closeInvoiceSettings() {
		this.invoiceConfigId = ""

		this.isOpenInvoiceSettingDialog = false
	}

	@action loadInvoiceSettings() {
		UIStore.isFormSaving = true

		const orgUnitId = getSelectedOrgUnit()._id
		const params = {
			filters: [
				{
					associated_column: "org_unit",
					values: [{id_value: orgUnitId}]
				}
			]
		}

		api
			.getInvoicesTemplate(params)
			.call()
			.then((data) => {
				if (data.rows && data.rows.length > 0) {
					this.invoiceSettingsDataStore = data.rows
				} else {
					this.invoiceSettingsDataStore = []
				}
				UIStore.isFormSaving = false
			})
			.catch(() => {
				this.invoiceSettingsDataStore = []
				UIStore.isFormSaving = false
			})
	}

	@action deleteInvoiceSetting(id) {
		UIStore.isFormSaving = true

		api
			.deleteInvoiceTemplate(id)
			.call()
			.then(() => {
				this.loadInvoiceSettings()
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action openEditDetail(id) {
		this.isDetailOpen = true
		this.isDetailLoading = true

		api
			.getSelectedInvoicesTemplate(id)
			.call()
			.then((resp) => {
				this.detail_id = id
				this.orgUnitId = resp.org_unit
				this.detail_name = isSafe(resp.name) ? resp.name : ""
				this.detail_customer =
					isSafe(resp.customer) && isSafe(this.customers.find((x) => x._id == resp.customer))
						? this.customers.find((x) => x._id == resp.customer)._id
						: ""
				this.detail_constant_symbol = isSafe(resp.constant_symbol) ? resp.constant_symbol : ""
				this.detail_specific_symbol = isSafe(resp.specific_symbol) ? resp.specific_symbol : ""
				this.detail_description = isSafe(resp.description) ? resp.description : ""

				this.isDetailLoading = false
			})
			.catch((err) => {
				this.isDetailLoading = false
				logger("error rowclick:", err)
			})
	}

	@action saveDetail(formValues, form) {
		const data = {
			_id: isSafe(this.detail_id) ? this.detail_id : undefined,
			_type: "Fin.API.JSON.Proxy.Template.Invoice",
			_ref: false,
			active: true,
			constant_symbol: isSafe(formValues.detail_constant_symbol) ? formValues.detail_constant_symbol : "",
			specific_symbol: isSafe(formValues.detail_specific_symbol) ? formValues.detail_specific_symbol : "",
			description: isSafe(formValues.detail_description) ? formValues.detail_description : "",
			name: isSafe(formValues.detail_name) ? formValues.detail_name : "",
			org_unit:
				isSafe(this.orgUnitId) && isSafe(this.orgUnitId._id)
					? this.orgUnitId
					: isSafe(this.orgUnitId)
					? {_id: this.orgUnitId, _type: "Entity.Data.OrgUnit"}
					: "",
			customer: isSafe(formValues.detail_customer) ? formValues.detail_customer : ""
		}

		api
			.saveInvoicesTemplate(data)
			.call()
			.then(() => {
				this.isDetailSaving = false
				this.close(form)
				this.loadInvoiceSettings()
			})
			.catch((err) => {
				this.isDetailSaving = false
				logger("chyba ukladania sablony", err)
			})
	}

	@action openCreateDetail(orgUnit) {
		this.isDetailOpen = true
		this.orgUnitId = orgUnit
	}

	@action close(form) {
		this.isDetailOpen = false

		this.detail_id = ""
		this.orgUnitId = ""
		this.detail_name = ""
		this.detail_customer = ""
		this.detail_constant_symbol = ""
		this.detail_specific_symbol = ""
		this.detail_description = ""

		form.reset()
	}
}

var singleton = new InvoiceSettingsStore()
export default singleton
