import React from "react"
import {observer} from "mobx-react"

import RoutesList from "./routesList"
import CodelistContainer from "./codelists/codelistContainer"
import UsersContainer from "./users/usersContainer"
import TranslationList from "./translationList"
import EHGWContainer from "./EHGWContainer"
import NotifyContainer from "./notify/notifyContainer"

@observer
export default class AdminContainer extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		let content = ""
		switch (this.props.view) {
			case "routes":
				content = <RoutesList />
				break
			case "translation":
				content = <TranslationList />
				break
			case "codelist":
				content = <CodelistContainer />
				break
			case "users":
				content = <UsersContainer />
				break
			case "ehgw":
				content = <EHGWContainer />
				break
			case "notify":
				content = <NotifyContainer />
				break
		}

		return <React.Fragment>{content}</React.Fragment>
	}
}
