import React, {Component} from "react"
import {Switch, Route, withRouter} from "react-router-dom"
import LoginForm from "./authentification/LoginForm"

import {inject, observer} from "mobx-react"
import {getUserAccessRights} from "../global/helpers/actions"

import PatientListContainer from "../modules/ambulance/components/patientList/patientListContainer"
import PatientDetailContainer from "../modules/ambulance/components/patientDetail/patientDetailContainer"
import DeliveriesContainer from "../modules/ambulance/components/deliveries/deliveriesContainer"
import ReportContainer from "../modules/ambulance/components/report/reportContainer"
import AdminContainer from "../modules/ambulance/components/admin/adminContainer"
import CalendarContainer from "../modules/ambulance/components/calendar/calendarContainer"
import EmployeesListContainer from "../modules/attendance/components/employeesList/employeesListContainer"
import EmployeesDetail from "../modules/attendance/components/employeesDetail/employeesDetail"
import OrgunitsDetailContainer from "../modules/attendance/components/orgunitsDetail/orgunitsDetailContainer"
import UserContainer from "../modules/ambulance/components/admin/users/userContainer"
import AgreementsContainer from "../modules/ambulance/components/agreements/agreementsContainer"
import AgreementDetail from "../modules/ambulance/components/agreements/agreementDetail/agreementDetail"
import AgreementAttributeList from "../modules/ambulance/components/agreements/attributeList/agreementAttributeList"

import "../global/styles/global.less"
import "../modules/attendance/styles/global.less"
import XsHeader from "../global/ui/xsHeader/xsHeader"
import XsFooter from "../global/ui/xsFooter/xsFooter"

import XsSnackbar from "../global/ui/xsSnackbar/xsSnackbar"
import XsErrorDialog from "../global/ui/xsDialog/xsErrorDialog"
import XsWarningDialog from "../global/ui/xsDialog/xsWarningDialog"
import XsInfoDialog from "../global/ui/xsDialog/xsInfoDialog"
import XsInformationDialog from "../global/ui/xsDialog/xsInformationDialog"
import EHealthProgressDialog from "../modules/ambulance/eHealthProgressDialog"
import GWUpdatingDialog from "../modules/ambulance/gwUpdatingDialog"
import GWWaitingDialog from "../modules/ambulance/gwWaitingDialog"
import CommMsgQueueDialog from "../modules/ambulance/CommMsgQueueDialog"
// import Container from "../modules/ambulance/components/poc/container"
// import C from "../modules/ambulance/components/poc/C"
import NotificationCenter from "../modules/ambulance/components/notificationCenter/notificationCenter"

import GlobalStore from "../global/store/GlobalStore"
import StorageManagementDialog from "../modules/ambulance/StorageManagementDialog"
import GWRegisterZprWaitingDialog from "../modules/ambulance/gwRegisterZprWaitingDialog"
import NotificationCenterStore from "../modules/ambulance/components/notificationCenter/NotificationCenterStore"

import RegistersCacheStore from "../global/store/RegistersCacheStore"
import NotificationDialog from "../modules/ambulance/components/notificationCenter/notificationDialog"
import XsLoading from "../global/ui/xsLoading/xsLoading"

import {getUser} from "../global/helpers/actions"

import {StorageBase} from "../global/storage/storageEx"
import {LANGUAGE} from "../global/config/constants"
import {FormattedMessage} from "react-intl"

@inject("routing")
@withRouter
@observer
export default class App extends Component {
	componentDidMount() {
		GlobalStore.GetBLConfiguration()
		GlobalStore.checkVersion()

		const user = getUser()

		if (
			isSafe(user) &&
			user.chat_enabled == "1" &&
			NotificationCenterStore.connection.state == "Disconnected" &&
			this.props.routing.location.pathname != "/login" &&
			this.props.routing.location.pathname != "/"
		) {
			NotificationCenterStore.startConnection()
		}
	}

	render() {
		document.documentElement.lang = isNotEmpty(StorageBase.getByKey(LANGUAGE)) ? StorageBase.getByKey(LANGUAGE) : "sk"
		const id = new URLSearchParams(this.props.routing.location.search).get("id")
		const personId = new URLSearchParams(this.props.routing.location.search).get("personId")

		RegistersCacheStore.checkRegisters(this.props.routing.location.pathname)

		const user = getUser()

		return (
			<React.Fragment>
				{isSafe(user) ? (
					<React.Fragment>
						{RegistersCacheStore.registersLoaded ? (
							<Switch>
								<Route exact path="/" component={() => <LoginForm />} />
								<Route path="/login" component={() => <LoginForm />} />
								<Route
									path="/patients"
									component={() => (
										<div id="xsContainer" className="pb-4">
											<XsHeader />
											<CalendarContainer />
											<PatientListContainer id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/patient"
									component={() => (
										<React.Fragment>
											<XsHeader isPatientDetail={true} />
											<PatientDetailContainer id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</React.Fragment>
									)}
								/>
								<Route
									path="/deliveries"
									component={() => (
										<div className="report-cover">
											<XsHeader />
											<DeliveriesContainer />
											{user.chat_enabled == "1" && <NotificationCenter />}
											{/* <XsFooter /> */}
										</div>
									)}
								/>
								<Route
									path="/agreements"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AgreementsContainer />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/agreement"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AgreementDetail id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/part"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AgreementAttributeList id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/reports"
									component={() => (
										<div className="report-cover">
											<XsHeader />
											<ReportContainer />
											{/* <XsFooter /> */}
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/admin-cl"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AdminContainer view={"codelist"} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/routes"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AdminContainer view={"routes"} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								{isSafe(getUserAccessRights("RESTT.AMBEEDEV.SRVZO.API.REST.MAIN./session/sec-resource")) && (
									<Route
										path="/users"
										component={() => (
											<div className="report-cover">
												<XsHeader />
												<AdminContainer view={"users"} />
												<XsFooter />
												{user.chat_enabled == "1" && <NotificationCenter />}
											</div>
										)}
									/>
								)}
								<Route
									path="/user"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<UserContainer id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/employees"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<EmployeesListContainer />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/employee"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<EmployeesDetail personId={personId} id={id} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/orgunit"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<OrgunitsDetailContainer
												id={id}
												companyId={new URLSearchParams(this.props.routing.location.search).get("companyId")}
											/>
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/translation"
									component={() => (
										<div id="xsContainer">
											<XsHeader />
											<AdminContainer view={"translation"} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/ehgw"
									component={() => (
										<div className="report-cover">
											<XsHeader />
											<AdminContainer view={"ehgw"} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								<Route
									path="/notify"
									component={() => (
										<div className="report-cover">
											<XsHeader />
											<AdminContainer view={"notify"} />
											<XsFooter />
											{user.chat_enabled == "1" && <NotificationCenter />}
										</div>
									)}
								/>
								{/* <Route
									path="/poc"
									component={() => (
										<div id="xsContainer">
											<Container />
										</div>
									)}
								/>
								<Route
									path="/poc2"
									component={() => (
										<div id="xsContainer">
											<C />
										</div>
									)}
								/> */}
							</Switch>
						) : (
							<React.Fragment>
								<XsLoading message={<FormattedMessage id="Common.label.loadingCodeLists" />} darkText />
							</React.Fragment>
						)}
					</React.Fragment>
				) : (
					<Switch>
						<Route path="/" component={() => <LoginForm />} />
						<Route path="/login" component={() => <LoginForm />} />
					</Switch>
				)}

				<XsSnackbar />
				<XsErrorDialog />
				<XsWarningDialog />
				<XsInfoDialog />
				<XsInformationDialog />
				<EHealthProgressDialog />
				<GWUpdatingDialog />
				<GWWaitingDialog />
				<GWRegisterZprWaitingDialog />
				<CommMsgQueueDialog />
				<StorageManagementDialog />
				<NotificationDialog />
			</React.Fragment>
		)
	}
}
