"use strict"

import {observable, action} from "mobx"
import api from "../../modules/ambulance/actions/api"

class InstallGuideStore {
	@observable isOpen = false
	@observable donwloading = false

	@action open() {
		this.isOpen = true
		this.donwloading = true

		api
			.downloadEHGWInstallatorNais()
			.call()
			.then((response) => {
				const fileName = response.headers.get("content-disposition").split("filename=")[1]
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
					this.donwloading = false
				})
			})
			.catch(() => {
				this.donwloading = false
			})
	}

	@action close() {
		this.isOpen = false
	}
}

var singleton = new InstallGuideStore()
export default singleton
