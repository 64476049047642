import React from "react"
import {observer} from "mobx-react"
import {injectIntl} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import moment from "moment"

import Grid from "@material-ui/core/Grid"
import AddIcon from "@material-ui/icons/Add"

import "./printTemplatesStyle.less"

import fields from "./prazdnyDekurzTemplateFields"
import bindings from "../../../../../../global/ui/globalUISchemeBindings"
import PrintTemplatesStore from "../../../../stores/PrintTemplatesStore"
import {handleInsertHelpersIntoTextArea} from "../../../../../../global/helpers/actions"
import XsTextArea from "../../../../../../global/ui/xsTextArea/xsTextArea"
import XsDropDownAction from "../../../../../../global/ui/xsDropDownAction/xsDropDownAction"
import XsIconButton from "../../../../../../global/ui/xsButton/xsIconButton"
import DataStore from "../../../../stores/DataStore"
import TemplateForm from "../../patientRecordForm/templateForm"
import UIStore from "../../../../stores/UIStore"
import XsInput from "../../../../../../global/ui/xsInput/xsInput"
import {XsDateTimePicker} from "../../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

@injectIntl
@observer
export default class prazdnyDekurzTemplate extends React.Component {
	constructor(props) {
		super(props)

		DataStore.loadTemplatesSync(["AMB", "OF", "Recomm", "DC"], "RECORD")

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		PrintTemplatesStore.templateForm = this.form
		PrintTemplatesStore.templateFields = fields

		if (isSafe(props.values)) {
			Object.keys(props.values).forEach((key) => {
				if (this.form.has(key)) {
					this.form.$(key).value = props.values[key]
				}
			})
		}

		this.form.$("dateTime").value = moment(this.form.$("date").value).format("DD.MM.YYYY HH:mm")
	}

	componentDidMount() {
		UIStore.isOverviewTemplateLoading = false
		PrintTemplatesStore.isHeaderTemplateLoaded = true
	}

	componentWillUnmount() {
		PrintTemplatesStore.isHeaderTemplateLoaded = false
	}

	render() {
		let dropdownRecordTemplates =
			isSafe(DataStore.templatesAll) &&
			isSafe(DataStore.templatesAll["RECORD"]) &&
			isSafe(DataStore.templatesAll["RECORD"].records) &&
			DataStore.templatesAll["RECORD"].records.length > 0
				? DataStore.templatesAll["RECORD"].records.map((r) => {
						return {
							label: r.name,
							itemData: r.template_stream.replace(/\\\"/g, '"'), // eslint-disable-line
							action: (e) => DataStore.editTemplate(e.currentTarget, r)
						}
				  })
				: []

		return (
			<div style={{padding: "20px"}}>
				<Grid container direction="column" align="top">
					<Grid container direction="row" align="left" spacing={8}>
						<Grid item xs={4}>
							<XsInput field={this.form.$("customTemplateName")} />
						</Grid>
						<Grid item xs={3}>
							<XsDateTimePicker
								field={this.form.$("date")}
								showTimeSelect={true}
								onChange={(val) => {
									this.form.$("dateTime").value = val.format("DD.MM.YYYY HH:mm")
								}}
							/>
						</Grid>
					</Grid>
					<Grid container direction="row" align="left">
						<Grid item xs={12}>
							<XsTextArea
								field={this.form.$("dekurz")}
								maxLength={6000} // zatial, po pridani loadingu dat na 85000
								// hideToolbar={true}
								style={{width: "800px"}}
								rows={10}
								rowsId={"emptyrecordDekurz"}
								customToolbarHelpers={
									<div className="xs-textarea-helpers">
										<XsDropDownAction
											items={dropdownRecordTemplates}
											actionIconClassName="fal fa-pencil-alt"
											className="xs-textarea-dropdown"
											onSelect={(data) => {
												if (isNotEmpty(this.form.$("dekurz").value) && PrintTemplatesStore.textAreaCursors.dekurz) {
													handleInsertHelpersIntoTextArea(
														this.form,
														"dekurz",
														PrintTemplatesStore.textAreaCursors.dekurz.cursor,
														data
													)
												} else {
													this.form.$("dekurz").value += data
												}
												delete PrintTemplatesStore.textAreaCursors.dekurz
											}}
										/>
									</div>
								}
								customToolbar={
									<div className="xs-textarea-helpers-other">
										<XsIconButton
											className="xs-default xs-outline xs-quil-helper-btn"
											icon={<AddIcon />}
											onClick={(e) => {
												DataStore.templateAnchorEl = e.currentTarget
												DataStore.openTemplateForm()
											}}
											disabled={
												//https://github.com/quilljs/quill/issues/2287
												PrintTemplatesStore.isHeaderTemplateLoaded == false || DataStore.templateIsMounted == false
											}
										/>
									</div>
								}
								onFocus={(data) => {
									if (
										data &&
										(isNotSafe(PrintTemplatesStore.textAreaCursors.dekurz) ||
											PrintTemplatesStore.textAreaCursors.dekurz.cursor != data.cursor)
									) {
										PrintTemplatesStore.textAreaCursors.dekurz = {
											cursor: data.cursor
										}
									}
								}}
							/>
						</Grid>
						<TemplateForm type="AMB" specialLoadType={["AMB", "OF", "Recomm", "DC"]} specialName="RECORD" />
					</Grid>
				</Grid>
			</div>
		)
	}
}
