export default {
	load() {
		return {
			fields: {
				templateId: {
					value: "corona" // TODO: realny nazov sablony
				},

				first_name: {},
				last_name: {},
				identifier: {},
				sex: {},
				phone: {},
				phoneForm: {
					label: "Telefónne číslo",
					rules: "required"
				},
				email: {},
				emailForm: {
					label: "Emailová adresa"
				},
				insurerCode: {},

				vNationality: {
					label: "Národnosť",
					rules: "required"
				},
				citizenshipCode: {},

				vSex: {},
				sex_default: {},

				dDateOfBirth: {},
				birthdateISO: {},

				vIDCardNumber: {
					label: "Číslo OP"
				},

				vOrderingHealthProfessionalCode: {},
				doctorcode: {},
				vOrderingCareProviderCode: {},
				orgunitcode: {},
				vOrderingHealthProfessionalGivenName: {},
				doctor_first_name: {},
				vOrderingHealthProfessionalSurname: {},
				doctor_last_name: {},
				vOrderingHealthProfessionalPhone: {
					label: "Mobil",
					rules: "required"
				},
				orgunitmobile: {},
				vOrderingHealthProfessionalEmail: {
					label: "Email",
					rules: "required"
				},
				orgunitemail: {},
				vOrderingHealthProfessionalAddress: {
					label: "Adresa ambulancie",
					rules: "required"
				},
				orgunitaddress: {},
				vOrderingCareProviderName: {},
				companyName: {},
				vOrderingCareProviderCIN: {},
				companyIdentifier: {},

				vOrderDiagnose: {
					label: "Diagnóza",
					rules: "required"
				},

				iceFirstName: {
					label: "Meno"
				},
				iceLastName: {
					label: "Priezvisko"
				},
				iceEmail: {
					label: "Emailová adresa"
				},
				icePhone: {
					label: "Telefónne číslo"
				},

				city: {},
				cityForm: {
					label: "Mesto / Obec"
				},
				street: {},
				streetForm: {
					label: "Názov ulice"
				},
				house_number: {},
				house_numberForm: {
					label: "Číslo"
				},
				postcode: {},
				postcodeForm: {
					label: "PSČ"
				},

				//
				homePlaced: {
					label: "Zdržiavam sa v mieste trvalého pobytu",
					type: "checkbox",
					value: true
				},
				other_city: {
					label: "Mesto / Obec"
				},
				other_street: {
					label: "Názov ulice"
				},
				other_house_number: {
					label: "Číslo"
				},
				other_postcode: {
					label: "PSČ"
				},
				//

				housePerson: {
					label: "Počet osôb v domácnosti"
				},
				houseRoom: {
					label: "Počet izieb v domácnosti"
				},
				startQuarantine: {
					label: "Začiatok karantény",
					type: "date"
				},
				endQuarantine: {
					label: "Koniec karantény",
					type: "date"
				},
				reasonQuarantine: {
					label: "Dôvod karantény",
					placeholder: "Napíšte dôvod karantény (dôvod, odkiaľ a kedy ste sa vrátili)"
				},

				hasTemperature: {
					label: "Teplota nad 38°C",
					type: "checkbox",
					value: false
				},
				dFeverStartedAt: {
					label: "Začiatok teploty",
					type: "date"
				},
				dFeverEndedAt: {
					label: "Koniec teploty",
					type: "date"
				},

				hasSuchyKasel: {
					label: "Suchý kašeľ",
					type: "checkbox",
					value: false
				},
				hasMalatnost: {
					label: "Malátnosť",
					type: "checkbox",
					value: false
				},
				hasBolestHlavy: {
					label: "Bolesť hlavy",
					type: "checkbox",
					value: false
				},
				hasBolestKlbovASvalov: {
					label: "Bolesť kĺbov a svalov",
					type: "checkbox",
					value: false
				},
				hasNadcha: {
					label: "Nádcha",
					type: "checkbox",
					value: false
				},
				hasZvracanie: {
					label: "Zvracanie",
					type: "checkbox",
					value: false
				},
				hasBusenieSrdca: {
					label: "Búšenie srdca",
					type: "checkbox",
					value: false
				},
				hasHnacka: {
					label: "Hnačka",
					type: "checkbox",
					value: false
				},
				hasTriaska: {
					label: "Triaška",
					type: "checkbox",
					value: false
				},
				hasVykasliavamHlien: {
					label: "Vykašliavam hlien",
					type: "checkbox",
					value: false
				},
				hasStazeneDychanie: {
					label: "Sťažené dýchanie",
					type: "checkbox",
					value: false
				},
				hasStrataCuchu: {
					label: "Strata čuchu",
					type: "checkbox",
					value: false
				},
				hasStrataChuti: {
					label: "Strata chuti",
					type: "checkbox",
					value: false
				},
				hasPrisielZoZahranicia: {
					label: "Prišiel som zo zahraničia",
					type: "checkbox",
					value: false
				},
				hasFajciar: {
					label: "Fajčiar",
					type: "checkbox",
					value: false
				},
				hasOckovanie: {
					label: "Očkovaný proti chrípke",
					type: "checkbox",
					value: false
				},
				dInfluenzaVaccineDate: {
					label: "Dátum očkovania",
					type: "date"
				},
				vInfluenzaVaccineName: {
					label: "Názov vakcíny"
				},

				vDrugsUsed: {
					label: "Užívané lieky",
					placeholder: "Napíšte názvy liekov, ktoré užívate."
				},
				vOtherSymptoms: {
					label: "Iné informácie",
					placeholder:
						"Napíšte všetky dôležité informácie, o ktorých by sme mali vedieť. Ak nemáte príznaky ochorenia, napíšte dôvod testovania, napr. prechod cez hranicu, plánovaná operácia - uveďte dátum a info, koľko hodín môže byť starý výsledok pred operáciou..."
				},
				covidPass: {}
			}
		}
	}
}
