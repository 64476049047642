"use strict"
import moment from "moment"
import api from "../actions/api"
import RouterStore from "../../../global/store/RouterStore"
import {getFilters} from "../../../global/helpers/api"
import {observable, action} from "mobx"
import Diacritics from "../../../global/helpers/diacritics"
import {getUserCompanyInfo} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"
import AgreementAttributeStore from "./AgreementAttributeStore"
import UIStore from "./UIStore"
import settings from "../../../global/config/settings"

class AgreementsStore {
	@observable agreementListData = []
	@observable agreementParts = []
	@observable isOpenCopyAgreement = false

	@observable insurers = []
	@observable orgunits = []
	allOrgunits = []
	orgunitsJSON = {}
	@observable checkedOrgunitIds = []

	//Ids
	agreementId = undefined
	partnerId = ""
	companyId = ""

	//pridanie novej zmluvy
	@observable isNewAgreement = false
	@observable agreementDateTo = undefined

	@observable agreementFormRef = null

	@observable loadingOrgunits = false

	@action handleChange(key, value) {
		this[key] = value
	}

	@action removeAgreement() {
		const request = {
			_id: GlobalStore.confirmationDialogParams.agreementId,
			_ref: false,
			_type: "EHR.Data.Contract",
			active: false
		}

		api
			.saveAgreement(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					GlobalStore.setNotificationMessage("Agreement.Container.Agreement.Removed")
					this.loadAgreements()
				}
			})
	}

	@action saveAgreement(isNew, form) {
		const request = {
			_id: !isNew && isSafe(this.agreementId) ? this.agreementId : "",
			_ref: false,
			_type: "EHR.Data.Contract",
			active: true,
			entities: [
				{
					_id: !isNew && isNotEmpty(this.companyId) ? this.companyId : "",
					_ref: false,
					_type: "Contract.Data.Contract.Entity",
					active: true,
					entity: {
						_id: `${getUserCompanyInfo().id}`,
						_type: "Entity.Data.Company"
					},
					type: "company",
					validity: {
						from: null,
						to: null
					}
				},
				{
					_id: !isNew && isNotEmpty(this.partnerId) ? this.partnerId : "",
					_ref: false,
					_type: "Contract.Data.Contract.Entity",
					active: true,
					entity: {
						// _id: `${this.agreementInsurance}`,
						_id: `${form.$("insurer").value}`,
						_type: "Entity.Data.Company"
					},
					type: "partner",
					validity: {
						from: null,
						to: null
					}
				}
			],
			number: `${form.$("agreement_number").value}`,
			subject_matter: `${form.$("agreement_subject_matter").value}`,
			validity: {
				from: isNotEmpty(form.$("from").value) ? moment(form.$("from").value).format(settings.DB_DATE_FORMAT) : null,
				to: isNotEmpty(form.$("to").value) ? moment(form.$("to").value).format(settings.DB_DATE_FORMAT) : null
			}
		}

		api
			.saveAgreement(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response._id)) {
					if (isNew) {
						this.showDetail(response._id)
					}
					GlobalStore.setNotificationMessage("Agreement.Container.Agreement.Save")
				}
			})

		this.isOpenNewForm = false
	}

	@action loadAgreements() {
		let request = {
			from: moment().add(-2, "years"),
			to: moment().add(1, "years")
		}

		api
			.loadAgreements(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					let items = []

					response.rows.forEach((row) => {
						if (isSafe(row.entities) && row.entities.length > 0) {
							let exist = row.entities.some((x) => x._id === getUserCompanyInfo().id)
							if (exist) {
								items.push(row)
							}
						}
					})

					this.agreementListData = items //response.rows
				}
			})
	}

	@action loadParts(contractId) {
		AgreementAttributeStore.contractId = contractId

		const request = {
			filters: [
				{
					associated_column: "contract_id",
					values: [{id_value: contractId}]
				}
			],
			from: moment().add(-1, "year"),
			to: moment().add(1, "year")
		}

		api
			.loadParts(request)
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					this.agreementParts = response.rows
				}
			})
	}

	@action showDetail(contractId) {
		if (isNotEmpty(contractId)) {
			this.isNewAgreement = false
			this.loadParts(contractId)
			RouterStore.push(`/agreement?id=${contractId}`)
		} else {
			this.isNewAgreement = true
			RouterStore.push(`/agreement?id=`)
		}
	}

	@action loadInsurences() {
		api
			.loadInsurers()
			.call()
			.then((response) => {
				if (isSafe(response) && isSafe(response.rows)) {
					let tempInsurers = []

					response.rows.forEach((insurer) => {
						tempInsurers.push({
							_id: insurer.company_id,
							code: insurer.company_id,
							name_ext: insurer.name_ext
						})
					})

					this.insurers = tempInsurers
				}
			})
	}

	@action loadAgreement(agreementId) {
		api
			.loadAgreement(agreementId)
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.agreementId = response._id
					this.agreementFormRef.$("agreement_number").value = response.number
					this.agreementFormRef.$("from").value =
						isSafe(response.validity) && isNotEmpty(response.validity.from) ? moment(response.validity.from) : null
					this.agreementFormRef.$("to").value =
						isSafe(response.validity) && isNotEmpty(response.validity.to) ? moment(response.validity.to) : null
					this.agreementFormRef.$("agreement_subject_matter").value = isSafe(response.subject_matter)
						? response.subject_matter
						: ""

					if (isSafe(response.entities) && response.entities.length > 0) {
						let partner = response.entities.filter((ent) => ent.type === "partner")
						let company = response.entities.filter((ent) => ent.type === "company")

						if (isSafe(partner)) {
							this.partnerId = partner[0]._id

							if (isSafe(partner[0].entity)) {
								this.agreementFormRef.$("insurer").value = partner[0].entity._id
							}
						}

						if (isSafe(company)) {
							this.companyId = company[0]._id
						}
					}
				}
			})
	}

	@action resetDataAgreementForm() {
		this.agreementDateTo = undefined
	}

	@action loadOrgunits() {
		this.loadingOrgunits = true
		const req = getFilters([`only_clients=1`])

		api
			.loadCLCompanies(req, "L3")
			.call()
			.then((res) => {
				// this.orgunits = res.rows
				if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
					let orgunits = []
					let json = {}

					res.rows.forEach((comp) => {
						if (isSafe(comp.entity.specific_rels) && comp.entity.specific_rels.length > 0) {
							comp.entity.specific_rels.forEach((org) => {
								orgunits.push({
									company_id: comp.entity._id,
									company_name: comp.entity.name,
									orgunit_id: org._id,
									orgunit_code: org.code,
									orgunit_name: org.name
								})
								json[org._id] = {company_id: comp.entity._id, orgunit_id: org._id}
							})
						}
					})

					this.orgunits = orgunits
					this.allOrgunits = orgunits
					this.orgunitsJSON = json
				}

				this.loadingOrgunits = false
			})
			.catch(() => {
				this.loadingOrgunits = false
			})
	}

	@action openCopyAgreement() {
		this.isOpenCopyAgreement = true
	}

	@action closeCopyAgreement() {
		this.isOpenCopyAgreement = false
		this.orgunits = this.allOrgunits
	}

	@action copyAgreement(form) {
		UIStore.isFormSaving = true
		let req = {
			close_date: form.$("endDate").value.format("YYYY-MM-DD") /* Dátum ukončenia zmluv */,
			contract_id: this.agreementId /* Zdrojová zmluva */,
			destinations: []
		}

		let groupByCompany = {}

		this.checkedOrgunitIds.forEach((orgId) => {
			if (this.orgunitsJSON[orgId].company_id in groupByCompany) {
				groupByCompany[this.orgunitsJSON[orgId].company_id].push(orgId)
			} else {
				groupByCompany[this.orgunitsJSON[orgId].company_id] = [orgId]
			}
		})

		Object.keys(groupByCompany).forEach((key) => {
			req.destinations.push({
				entity_id: key,
				specific_rels: groupByCompany[key]
			})
		})

		api
			.copyAgreement(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isNotEmpty(res.contract_id)) {
					form.reset()
					this.checkedOrgunitIds = []
					this.closeCopyAgreement()
					GlobalStore.setNotificationMessage("Common.label.copyAgreementSuccess")
				} else {
					GlobalStore.setNotificationMessage("Api.unknown.error")
				}
				UIStore.isFormSaving = false
			})
	}

	@action agreementFilter(searchValue) {
		// this.eventFilterValue = searchValue
		delayedCallback(500, () => {
			if (isNotEmpty(searchValue) && searchValue.length > 2) {
				this.orgunits = this.allOrgunits.filter(
					(x) =>
						isSafe(x.orgunit_name) &&
						Diacritics.remove(x.orgunit_name)
							.toLowerCase()
							.includes(Diacritics.remove(searchValue).toLowerCase())
				)
			} else {
				this.orgunits = this.allOrgunits
			}
		})
	}
}

var singleton = new AgreementsStore()
export default singleton
