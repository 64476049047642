import api from "./api"
import moment from "moment"
import UIStore from "../stores/UIStore"
import DataStore from "../stores/DataStore"

export default {
	loadPatientOverview(providerID, patientID, filter, companyID, resetVersionFilter) {
		if (resetVersionFilter) {
			//Reset filtru stavu zaznamu v overview
			UIStore.EHRversionFilterText = ""
			UIStore.EHRversionFilterValue = ""
		}
		let timeFromFilter = ""
		let timeToFilter = ""

		const timeFilters = {
			all: moment("2017-01-01T00:00:00Z").utc(),
			pastTwoYears: moment()
				.utc()
				.subtract(2, "years"),
			// lastYear: moment()
			// 	.utc()
			// 	.subtract(1, "years")
			// 	.startOf("year"),
			thisYear: moment()
				.utc()
				.subtract(1, "years"),
			lastMonth: moment()
				.utc()
				.subtract(1, "month")
				.startOf("month"),
			thisMonth: moment()
				.utc()
				.startOf("month"),
			lastWeek: moment()
				.utc()
				.subtract(1, "week")
				.startOf("week"),
			thisWeek: moment()
				.utc()
				.startOf("week")
		}

		const timeFiltersTo = {
			all: moment()
				.endOf("day")
				.utc(),
			pastTwoYears: moment()
				.endOf("day")
				.utc(),
			// lastYear: moment()
			// 	.utc()
			// 	.subtract(1, "years")
			// 	.endOf("year"),
			thisYear: moment()
				.endOf("day")
				.utc(),
			lastMonth: moment()
				.utc()
				.subtract(1, "month")
				.endOf("month"),
			thisMonth: moment()
				.endOf("day")
				.utc(),
			lastWeek: moment()
				.utc()
				.subtract(1, "week")
				.endOf("week"),
			thisWeek: moment()
				.endOf("day")
				.utc()
		}

		let diagnosisFilter = []
		let typeID = ""

		if (isNotSafe(filter) && isSafe(UIStore.patientOverviewFilter)) {
			filter = UIStore.patientOverviewFilter
		}
		let versionFilter = null
		if (isNotEmpty(UIStore.EHRversionFilterValue)) {
			versionFilter = {
				associated_column: "version",
				predicate: "=",
				values: [
					{
						id_value: UIStore.EHRversionFilterValue
					}
				]
			}
		}

		if (filter) {
			if (filter.diagnoses) {
				diagnosisFilter = filter.diagnoses
			}

			if (filter.typeID) {
				typeID = filter.typeID
			}

			if (filter.time && filter.time.search_string) {
				timeFromFilter = timeFilters[filter.time.search_string].format()
				timeToFilter = timeFiltersTo[filter.time.search_string].format()
			}
		}

		let ixsRequestPatientId = null

		return api
			.loadPatientOverview(
				!UIStore.patientOverviewFilterCheckBox ? "" : providerID,
				patientID,
				diagnosisFilter,
				timeFromFilter,
				timeToFilter,
				typeID,
				companyID,
				versionFilter
			)
			.call(null, patientID, (id) => (ixsRequestPatientId = id))
			.then((response) => {
				if (response.rows) {
					let ehrs = {
						...response,
						ixs_request_id: ixsRequestPatientId,
						rows: response.rows
							.map((row) => {
								//EXTERNAL EHR - version
								let ext_version = null
								if (row.version_status_cv) {
									const version_code = row.version_status_cv
									if (version_code === "VER00") {
										ext_version = "draft"
									}
									if (version_code === "VER04") {
										ext_version = "canceled"
									}
								}

								return {
									attachments: isSafe(row.attachments) ? row.attachments : [],
									created_at: moment(row.created_at)
										.local()
										.toDate(),
									crt_at: moment(row.crt_at)
										.local()
										.toDate(),
									name: row.name,
									digital_signature_status: row.digital_signature_status,
									description: row.description,
									data: row.data,
									ehealth_id: row.ehealth_id,
									external: {
										extract: row.extract,
										version: ext_version,
										request_extension: row.request_extension
									},
									barcode: row.barcode,
									has_deliveries: row.has_deliveries,
									version: row.version,
									doctor_card: row.doctor_card,
									provider_card: row.provider_card,
									symptoms: row.symptoms,
									type_code: row.type_code,
									type_name: row.type_name,
									record_diagnoses: row.record_diagnoses,
									record_id: row.record_id,
									sending_doctor: row.sending_doctor_card,
									substituting_doctor: row.substituting_doctor_card,
									diagnosis_code_ext: row.diagnosis_code_ext,
									diagnosis_name_ext: row.diagnosis_name_ext,
									diagnosis_desc: row.diagnosis_desc,
									clinical_values: row.clinical_values,
									diagnosis: row.diagnosis,
									sickness_abs: {
										number: row.number,
										description_text: row.description_text,
										validity: row.validity,
										sub_type: row.sub_type,
										work_capable_since: row.work_capable_since
									},
									request: {
										receiving_expertise_code_ext: row.receiving_expertise_code_ext,
										receiving_expertise_name_ext: row.receiving_expertise_name_ext,
										professional_activity: row.professional_activity,
										professional_activity_code_ext: row.professional_activity_code_ext,
										professional_activity_name_ext: row.professional_activity_name_ext
									},
									prescription: {
										supplement: row.supplement ? row.supplement : row.product_supplement,
										atc_code_ext: row.atc_code_ext,
										atc_name_ext: row.atc_name_ext,
										product_code: row.product_code,
										repeats_type_name_ext: row.repeats_type_name_ext,
										sending_doctor_name: row.sending_doctor_name,
										substituting_doctor_card: row.substituting_doctor_card,
										repeats_number: row.repeats_number,
										validity: row.validity,
										note: row.note
									},
									survey: row.survey,
									supposed_delivery_date: row.supposed_delivery_date,
									wrt_recorded_event: row.wrt_recorded_event,
									medication: {
										expiration_date: row.expiration_date,
										product_printable_name: row.product_printable_name,
										batch_number: row.batch_number,
										medication_type: row.medication_type,
										administered_at: row.administered_at
									}
								}
							})
							.sort((a, b) => {
								return b.created_at - a.created_at
							})
					}

					//Získame posledný final dekurz pacienta a uložíme si ho
					if (ehrs && ehrs.rows) {
						DataStore.patientDTO.set("lastRecord", ehrs.rows.find((e) => e.type_code.toUpperCase() === "DEK"))
					}
					return ehrs
				}
			})
	},

	loadPatientProblems(patientID) {
		return api.loadPatientProblems(patientID).call()
	},

	loadSeverities() {
		const severitiesData = api.loadSeverities().call()

		const severityValues = severitiesData.rows
			.map((severity) => {
				return {
					value: severity.code,
					label: severity.name_ext,
					order: severity.code_ext
				}
			})
			.sort((a, b) => {
				return parseInt(a.order) - parseInt(b.order)
			})

		return severityValues
	},

	saveProblem(problem) {
		const symptoms = problem.symptoms.map((symptom) => {
			return {
				_id: symptom._id,
				_ref: false,
				_type: "EHR.Data.Problem.Symptom",
				active: true,
				description: symptom.description,
				severity: symptom.severity.code
			}
		})

		const updateSymptoms = {
			_ref: false,
			symptoms: symptoms
		}

		api.updateProblem(updateSymptoms, problem.record_id).call()
	}
}
