import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import classnames from "classnames"
import {withStyles} from "@material-ui/core/styles"
import {Grid, List, Divider, Drawer, ListItem, ListItemText, ListItemIcon, Tooltip} from "@material-ui/core"

import UIStore from "../../stores/UIStore"
import CapitationList from "./capitationList/capitationList"
import DeliveryList from "./deliveryList/deliveryList"
import OtherDeliveriesList from "./otherDeliveriesList/otherDeliveryList"
import SocialInsuranceDeliveryList from "./socialInsuranceDeliveryList/socInsDeliveryList"
import InvoiceList from "./invoiceList/invoiceList"
import ProblemList from "./problems/problemList"
import NonCapitationList from "./nonCapitationList/nonCapitationList"
import InvoiceSettings from "./settings/invoiceSettings"
import DoveraIcon from "../../../../global/ui/icons/DoveraIcon"
import WarningStore from "../../../../global/store/WarningStore"
import api from "../../../ambulance/actions/api"
// import XsTabs from "../../../../global/ui/xsTabs/xsTabs"

import DeliveryStore from "../../stores/DeliveryStore"
import {getUserAccessRights, getSelectedOrgUnitID, getSelectedOrgUnit} from "../../../../global/helpers/actions"
import GenerateInvoiceDialog from "./generateInvoiceDialog/generateInvoiceDialog"
import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import XsLoading from "../../../../global/ui/xsLoading/xsLoading"

import GlobalStore from "../../../../global/store/GlobalStore"

const drawerWidth = 250

const styles = (theme) => ({
	drawer: {
		height: "100%"
	},
	drawerPaper: {
		position: "relative",
		overflow: "hidden",
		whiteSpace: "nowrap",
		height: "100%",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing.unit * 7,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing.unit * 9
		}
	},
	drawerIcon: {
		fontSize: "1.6rem !important",
		width: "15px"
	}
})

@withStyles(styles)
@observer
export default class DeliveriesContainer extends React.Component {
	constructor(props) {
		super(props)
		UIStore.mainMenuTab = "capitations"
		if (UIStore.reportingZSDetailTab == "Capitations") {
			UIStore.reportZSName = <FormattedMessage id="Capitation.List.Patients" />
		}

		this.state = {
			open: false
		}

		if (isSafe(Object.keys(DeliveryStore.insureeJSON)) && Object.keys(DeliveryStore.insureeJSON).length === 0) {
			DeliveryStore.loadInsureeTypes()
		}

		if (isSafe(Object.keys(DeliveryStore.senderJSON)) && Object.keys(DeliveryStore.senderJSON).length === 0) {
			DeliveryStore.loadSenderType()
		}

		if (isSafe(Object.keys(DeliveryStore.refundJSON)) && Object.keys(DeliveryStore.refundJSON).length === 0) {
			DeliveryStore.loadRefundTypes()
		}

		if (isSafe(Object.keys(DeliveryStore.payerJSON)) && Object.keys(DeliveryStore.payerJSON).length === 0) {
			DeliveryStore.loadPayerTypes()
		}

		GlobalStore.refreshCodeLists([
			"insuranceTypes",
			"payerType",
			"deliveryExtension",
			"insureeType",
			"refundType",
			"senderType"
		])
	}

	handleDrawerOpen = () => {
		this.setState({open: true})
	}

	handleDrawerClose = () => {
		this.setState({open: false})
	}

	handleChange = (event, newValue) => {
		UIStore.reportingZSDetailTab = newValue
	}

	onLoadEPZP() {
		const selectedOrgUnit = getSelectedOrgUnit()
		UIStore.isFormSaving = true

		const request = {
			provider_id: getSelectedOrgUnitID(),
			doctor_expertise_id: selectedOrgUnit.personnel_expertise_id
		}

		api
			.loadEp(request)
			.call()
			.then((data) => {
				UIStore.isFormSaving = false
				window.open(data.url, "_blank")
			})
			.catch(() => {
				UIStore.isFormSaving = false
				WarningStore.open(this.props.intl.formatMessage({id: "Api.unknown.error"}), true)
			})
	}

	drawerData = [
		{
			id: "Capitations",
			label: <FormattedMessage id="Capitation.List.Patients" />,
			icon: <i className={classnames("fal fa-user", this.props.classes.drawerIcon)} />
		},
		{
			id: "Deliveries",
			label: <FormattedMessage id="Common.label.medicalDeliveries" />,
			icon: <i className={classnames("fal fa-file-invoice-dollar", this.props.classes.drawerIcon)} />
		},
		{
			id: "SocialInsuranceDeliveries",
			label: <FormattedMessage id="Deliveries.socialinsurance.menu" />,
			icon: <i className={classnames("fal fa-id-badge", this.props.classes.drawerIcon)} />
		},
		{
			id: "OtherDeliveriesList",
			label: <FormattedMessage id="Common.label.otherDeliveries" />,
			icon: <i className={classnames("fal fa-file-contract", this.props.classes.drawerIcon)} />
		},
		{
			id: "Invoices",
			label: <FormattedMessage id="Invoice.List.Invoices" />,
			icon: <i className={classnames("fal fa-euro-sign", this.props.classes.drawerIcon)} />
		},
		{
			id: "NonCapitations",
			label: <FormattedMessage id="NonCapitation.List.Patients" />,
			icon: <i className={classnames("fal fa-user-times", this.props.classes.drawerIcon)} />
		},
		{
			id: "Settings",
			label: <FormattedMessage id="Common.label.settings" />,
			icon: <i className={classnames("fal fa-cog", this.props.classes.drawerIcon)} />
		},
		{
			id: "Problems",
			label: <FormattedMessage id="Common.label.dispensarisation" />,
			icon: <i className={classnames("fal fa-file-alt", this.props.classes.drawerIcon)} />
		}
	]

	render() {
		return (
			<Grid container className="maxHeight">
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Grid item className="maxHeight">
					<Drawer
						variant="permanent"
						className={this.props.classes.drawer}
						classes={{
							paper: classnames(this.props.classes.drawerPaper, !this.state.open && this.props.classes.drawerPaperClose)
						}}
						open={this.state.open}
					>
						<Divider />
						<List>
							<ListItem button onClick={() => (this.state.open ? this.handleDrawerClose() : this.handleDrawerOpen())}>
								{this.state.open ? (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-left", this.props.classes.drawerIcon)} />
									</ListItemText>
								) : (
									<ListItemText className="textRight">
										<i className={classnames("fal fa-chevron-right", this.props.classes.drawerIcon)} />
									</ListItemText>
								)}
							</ListItem>
						</List>
						<Divider />
						<List className="overflowY overflowXHidden">
							{this.drawerData.map((data, key) => {
								return (
									<ListItem
										key={key}
										button
										onClick={() => {
											UIStore.reportingZSDetailTab = data.id
											UIStore.reportZSName = data.label
										}}
										className={classnames({bgGreyLight: UIStore.reportingZSDetailTab == data.id})}
									>
										<Tooltip title={data.label} placement="right">
											<ListItemIcon>{data.icon}</ListItemIcon>
										</Tooltip>
										<ListItemText>{data.label}</ListItemText>
									</ListItem>
								)
							})}
						</List>
					</Drawer>
				</Grid>
				<Grid item className="xs-flex pt-4 pb-4 pr-8 pl-8 maxHeight overflowY">
					<Grid container>
						<Grid item container className="pb-6" justify="space-between">
							<Grid item xs>
								<h2>{UIStore.reportZSName}</h2>
							</Grid>
							<Grid item container xs spacing={8} justify="flex-end">
								<Grid item>
									{isSafe(getUserAccessRights("/amb/ehealth/ep", "RESTP")) && (
										<Tooltip title={<FormattedMessage id="Common.label.epzpTooltip" />} enterDelay={500}>
											<span>
												<XsButton
													className="xs-default xs-outline"
													icon={<DoveraIcon />}
													text={<FormattedMessage id="Common.label.epzp" />}
													onClick={() => this.onLoadEPZP()}
												/>
											</span>
										</Tooltip>
									)}
								</Grid>
								<Grid item>
									<XsIconButton
										rect
										tooltip={<FormattedMessage id="Invoice.form.VirtualCashRegister" />}
										icon={<i className="fal fa-money-bill-alt fa-lg white" />}
										className="xs-success"
										onClick={() => window.open("https://vrp.financnasprava.sk/#!/login", "_blank")}
									/>
								</Grid>
							</Grid>
						</Grid>
						{UIStore.reportingZSDetailTab === "Capitations" && <CapitationList />}
						{UIStore.reportingZSDetailTab === "Deliveries" && <DeliveryList />}
						{UIStore.reportingZSDetailTab === "SocialInsuranceDeliveries" && <SocialInsuranceDeliveryList />}
						{UIStore.reportingZSDetailTab === "OtherDeliveriesList" && <OtherDeliveriesList />}
						{UIStore.reportingZSDetailTab === "Invoices" && isSafe(getUserAccessRights("/fin/invoices", "RESTP")) && (
							<InvoiceList />
						)}
						{UIStore.reportingZSDetailTab === "NonCapitations" && <NonCapitationList />}
						{UIStore.reportingZSDetailTab === "Settings" && <InvoiceSettings />}
						{UIStore.reportingZSDetailTab === "Problems" && <ProblemList />}
					</Grid>
				</Grid>
				<GenerateInvoiceDialog />
			</Grid>
		)
	}
}
