import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import ReactTooltip from "react-tooltip"

import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import VaccinationListStore from "../../../stores/VaccinationListStore"
import GlobalStore from "../../../../../global/store/GlobalStore"

import ExportMedicationToXlsDialogStore from "../../../stores/ExportMedicationToXlsDialogStore"

import fields from "./vaccinationReportFields"

import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class VaccinationFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				if (
					TableStore.tables.hasOwnProperty("vaccinationList") &&
					TableStore.tables["vaccinationList"].hasOwnProperty("index")
				) {
					delete TableStore.tables["vaccinationList"]["index"]
				}

				VaccinationListStore.getFilters(form)
			},
			onError() {}
		}

		if (isEmpty(VaccinationListStore.formRef)) {
			VaccinationListStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}
		this.form = VaccinationListStore.formRef

		VaccinationListStore.getFilters(this.form)

		GlobalStore.refreshCodeLists(["medicationSubTypes"])
	}

	render() {
		VaccinationListStore.isChangedFilter(this.form.values())

		return (
			<Grid container spacing={8} alignItems="center">
				<Grid item xs={2}>
					<SearchSelect white field={this.form.$("org_unit")} items={getUserPersonnelOrgUnits()} required />
				</Grid>
				<Grid item xs={2}>
					<XsInput white field={this.form.$("identifier")} />
				</Grid>
				<Grid item xs={1}>
					<XsDateTimePicker white field={this.form.$("date_from")} showTimeSelect={false} />
				</Grid>
				<Grid item xs={1}>
					<XsDateTimePicker white field={this.form.$("date_to")} showTimeSelect={false} />
				</Grid>
				<Grid item xs={2}>
					<SearchSelect
						white
						field={this.form.$("sub_type")}
						items={
							isSafe(GlobalStore.CL["medicationSubTypes"])
								? GlobalStore.CL["medicationSubTypes"].filter((med) => med._id == "ADMVACC" || med._id == "ADMDRUG")
								: []
						}
					/>
				</Grid>
				<Grid item xs={1} className="pb-0">
					<XsIconButton
						rect
						className="xs-default xs-outline"
						tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
						onClick={this.form.onSubmit}
						icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
					/>
				</Grid>
				<Grid item xs={3}>
					<Grid container justify="flex-end" spacing={8}>
						<Grid item>
							<XsButton
								className="xs-primary"
								text={<FormattedMessage id="Common.label.export" />}
								icon={<i className="fal fa-file-excel fa-lg" />}
								onClick={() => {
									ExportMedicationToXlsDialogStore.open()
								}}
							/>
						</Grid>
						<Grid item>
							<span data-tip data-for="epis">
								<XsButton
									className="xs-default xs-outline"
									text={<FormattedMessage id="Common.label.epis" />}
									onClick={() =>
										window.open("https://www.epis.sk/WebLogon.aspx?returnurl=/HlavnaStranka.aspx", "_blank")
									}
								/>
							</span>
							<ReactTooltip id="epis">
								<FormattedMessage id="Common.label.episDescription" />
							</ReactTooltip>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}
}
