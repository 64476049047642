import React from "react"
import ReactTooltip from "react-tooltip"
import {FormattedMessage, injectIntl} from "react-intl"
import {observer} from "mobx-react"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../global/ui/globalUISchemeBindings"
import {RESOURCES} from "../../../../global/config/constants"
import classnames from "classnames"

import {getUserPersonnelOrgUnits /*, getSelectedOrgUnitResourceID*/} from "../../../../global/helpers/actions"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsIconButton from "../../../../global/ui/xsButton/xsIconButton"
import CalendarStore from "../../stores/CalendarStore"
// import DataStore from "../../stores/DataStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import EditEventDialogStore from "../../stores/EditEventDialogStore"
import {StorageBase} from "../../../../global/storage/storageEx"
import AddEventDialogStore from "../../stores/AddEventDialogStore"

import {Grid, Tooltip} from "@material-ui/core"

@injectIntl
@observer
export default class ResourceView extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					providerId_0: {
						label: " "
					},
					providerId_1: {
						label: " "
					},
					providerId_2: {
						label: " "
					},
					providerId_3: {
						label: " "
					},
					providerId_4: {
						label: " "
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		this.state = {
			resources: isSafe(StorageBase.getObjectByKey(RESOURCES)) ? StorageBase.getObjectByKey(RESOURCES) : [{}]
		}

		CalendarStore.disabledMultiBtn = isEmpty(this.state.resources[0]._id) ? true : false

		// if (isSafe(StorageBase.getObjectByKey(RESOURCES))) {
		// 	const res = StorageBase.getObjectByKey(RESOURCES)

		// 	for (let i = 0; i < res.length; i++) {
		// 		if (isSafe(res[i]) && isNotEmpty(res[i].resource_id)) {
		// 			this.form.$(`providerId_${i}`) = res[i].resource_id
		// 		}
		// 	}
		// }

		this.weeklyRow = {}
	}

	getHourLabelClass = (hour) => {
		return moment()
			.local()
			.get("hours") == hour
			? "xs-weekly-hour xs-hour-active"
			: "xs-weekly-hour"
	}

	isActiveHour = (hour) => {
		return (
			moment()
				.local()
				.get("hours") == hour
		)
	}

	getOpenHours = () => {
		let from = 0
		let to = 23
		let hours = []
		for (let i = from; i <= to; i++) {
			hours.push(i)
		}

		return hours
	}

	componentDidUpdate() {
		this.setPos()
	}

	setPos = () => {
		if (isSafe(this.weeklyViewRef) && !CalendarStore.calendarToggledFull) {
			let hour = moment().hours()

			let scrollVal = this.weeklyRow[hour].offsetTop - 160
			this.weeklyViewRef.scrollTop = scrollVal
		}
	}

	getEventStatus = (isEventEnd, clientId, orderTime) => {
		if (isEventEnd) {
			return (
				<div className="xs-state-icon">
					<Tooltip
						title={this.props.intl.formatMessage({
							id: "Common.label.eventEnd"
						})}
						placement="left"
					>
						<i className="fas fa-user-check fa-lg greenSuccess" />
					</Tooltip>
				</div>
			)
		}

		if (moment(orderTime).isBefore(moment())) {
			if (isSafe(clientId) && CalendarStore.patientDraftIds.includes(clientId)) {
				return (
					<div className="xs-state-icon">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.inProgress"
							})}
							placement="left"
						>
							<i className="fas fa-user-cog fa-lg amberWarning" />
						</Tooltip>
					</div>
				)
			} else {
				return (
					<div className="xs-state-icon">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label.notEventEnd"
							})}
							placement="left"
						>
							<i className="fas fa-user fa-lg greyDefault" />
						</Tooltip>
					</div>
				)
			}
		}
	}

	openAddEventDialog = (hour, resourceId, orgunitId) => {
		if (EditEventDialogStore.isClickedEdit) {
			EditEventDialogStore.isClickedEdit = false
		} else {
			if (
				moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
					.isAfter(moment())
			) {
				CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
				CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
					.set("hour", hour)
					.set("minute", 0)
					.set("second", 0)
					.format("DD.MM.YYYY HH:mm")

				AddEventDialogStore.open(false, {_id: resourceId, provider_id: orgunitId})
			}
		}
	}

	tempElemDrag = null
	dragEvent = null

	drop = (e, hour, resourceId, providerId) => {
		e.preventDefault()
		// const card_id = e.dataTransfer.getData("card_id")

		// const card = document.getElementById(card_id)
		// card.style.display = "flex"
		// this.tempElemDrag.style.display = "none"
		// e.target.appendChild(card)
		// logger("hour", hour, resourceId, providerId)
		EditEventDialogStore.moveEvent(this.dragEvent, providerId, resourceId, hour)

		// logger(card_id)
	}

	dragOverDrop = (e) => {
		e.preventDefault()
	}

	dragStart = (e, event) => {
		const target = e.target

		e.dataTransfer.setData("card_id", target.id)

		this.tempElemDrag = target
		this.dragEvent = event
		// setTimeout(() => {
		// 	target.style.display = "none"
		// }, 0)
	}

	dragOver = (e) => {
		e.stopPropagation()
	}

	renderEvent = (event, index) => {
		const service = isSafe(event.services) && event.services.length > 0 ? event.services[0] : null
		const client = isSafe(event.client) ? event.client : null

		const duration = isSafe(service) && isSafe(service.duration) ? Math.ceil(+service.duration / 60) : 0
		const fullName =
			isSafe(client) && isEmpty(client.last_name) && isEmpty(client.first_name)
				? client.client_text
				: `${client.last_name} ${client.first_name}`
		let subInfo = isSafe(client) && isNotEmpty(client.identifier) ? client.identifier : ""

		if (isNotEmpty(subInfo)) {
			if (isSafe(service) && isNotEmpty(service.service_name_ext)) {
				subInfo += `, ${service.service_name_ext}`
			}
		} else {
			if (isSafe(service) && isNotEmpty(service.service_name_ext)) {
				subInfo = service.service_name_ext
			}
		}

		const isEventEnd = isSafe(event.status) && isSafe(event.status._id) && event.status._id == "F" ? true : false

		return (
			<Grid
				item
				key={index}
				id={event.event_id}
				draggable="true"
				onDragStart={(e) => this.dragStart(e, event)}
				onDragOver={this.dragOver}
				container
				className="bgWhite borderRadius-1 border-greyLight pt-1 pb-1 pl-2 pr-2 xs-resource-item pointer"
				alignItems="center"
				style={{height: "45px"}}
				onClick={() => {
					EditEventDialogStore.isClickedEdit = true
					EditEventDialogStore.open(event, true)
				}}
			>
				<Grid item>{moment(event.interval_from).format("HH:mm")}</Grid>
				<Grid
					item
					className="pa-1 ml-2 mr-2 borderRadius-1 textCenter"
					style={{backgroundColor: service.back_color, color: service.fore_color, width: "40px"}}
				>
					{duration}
				</Grid>
				<Grid item xs container direction="column">
					<Grid item className="blueDark">
						{fullName}
					</Grid>
					<Grid item className="fontSize13 greyDark">
						{subInfo}
					</Grid>
				</Grid>
				<Grid item className="ml-1">
					{this.getEventStatus(isEventEnd, isSafe(client) ? client.client_id : null, event.interval_from)}
				</Grid>
			</Grid>
		)
	}

	changeResources = () => {
		const formValues = this.form.values()
		let newResources = []

		for (let i = 0; i < 5; i++) {
			if (isNotEmpty(formValues[`providerId_${i}`])) {
				const orgunit = GlobalStore.orgunits.filter((x) => x._id == formValues[`providerId_${i}`])

				if (isSafe(orgunit) && orgunit.length > 0) {
					newResources.push(orgunit[0])
				}
			}
		}

		CalendarStore.disabledMultiBtn = false

		StorageBase.updateByKey(RESOURCES, newResources)
		this.setState({resources: newResources})
		CalendarStore.getEvents()
	}

	removeResource = (orgunitId) => {
		const resources = StorageBase.getObjectByKey(RESOURCES)

		let newResources = resources.filter((x) => x._id != orgunitId)
		//odstranenie posledneho fieldu
		this.form.$(`providerId_${newResources.length}`).value = ""

		if (isSafe(newResources) && newResources.length > 0) {
			StorageBase.updateByKey(RESOURCES, newResources)
			this.setState({resources: newResources})
		} else {
			StorageBase.updateByKey(RESOURCES, [{}])
			this.setState({resources: [{}]})
			CalendarStore.disabledMultiBtn = true
		}

		CalendarStore.getEvents()
	}

	addResource = () => {
		const resources = StorageBase.getObjectByKey(RESOURCES)

		let newResources = isSafe(resources) && resources.length > 0 ? resources : []

		newResources.push({})

		StorageBase.updateByKey(RESOURCES, newResources)
		this.setState({resources: newResources})
	}

	inTheFuture = (hour) => {
		return moment(CalendarStore.selectedDay)
			.set("hour", hour)
			.set("minute", 0)
			.set("second", 0)
			.isAfter(moment())
	}

	getOrgunit = (key) => {
		const formValues = this.form.values()

		let orgUnit = getUserPersonnelOrgUnits()

		switch (+key) {
			case 0:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 1:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 2:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_3 &&
						x._id != formValues.providerId_4
				)
				break
			case 3:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_4
				)
				break
			case 4:
				orgUnit = orgUnit.filter(
					(x) =>
						x._id != formValues.providerId_0 &&
						x._id != formValues.providerId_1 &&
						x._id != formValues.providerId_2 &&
						x._id != formValues.providerId_3
				)
				break
		}

		return orgUnit
	}

	render() {
		const expandTooltip = CalendarStore.calendarToggledFull ? "hideCalendar" : "showAllCalendar"

		const {hideFullToggle} = this.props

		return (
			<div className="xs-weekly-view resource-view">
				<div
					className={classnames("xs-week-header mb-2", {"xs-fi": CalendarStore.calendarToggledFull || hideFullToggle})}
				>
					<div className="xs-arrows xs-justify">
						<XsIconButton
							rect
							className="xs-outline xs-primary"
							tooltip={this.props.intl.formatMessage({
								id: "Common.label.addAnotherOrgUnit"
							})}
							icon={<i className="fal fa-plus"></i>}
							onClick={() =>
								this.state.resources.length < getUserPersonnelOrgUnits().length && this.state.resources.length < 5
									? this.addResource()
									: null
							}
						/>
					</div>
					{/* {GlobalStore.orgunits.map((orgunit, key) => { */}
					{this.state.resources.map((orgunit, key) => {
						if (isSafe(orgunit._id)) {
							this.form.$(`providerId_${key}`).value = orgunit._id
						} else {
							this.form.$(`providerId_${key}`).value = ""
						}
						return (
							<Grid container key={key} className="xs-flex pt-1 pb-1 pl-2 pr-2" spacing={8}>
								<Grid item xs>
									<XsSearchSelect
										white
										required
										hideLabel={true}
										field={this.form.$(`providerId_${key}`)}
										// items={getUserPersonnelOrgUnits()}
										items={this.getOrgunit(key)}
										onChange={() => this.changeResources()}
										// onClear={() => this.changeResources()}
									/>
								</Grid>
								<Grid item>
									<XsIconButton
										rect
										className="xs-outline xs-default"
										tooltip={this.props.intl.formatMessage({
											id: "Common.label.removeCalendarOrgUnit"
										})}
										icon={<i className="fal fa-times"></i>}
										onClick={() => this.removeResource(orgunit._id)}
									/>
								</Grid>
							</Grid>
						)
					})}
				</div>
				<div className="xs-weekly-body-scrollbar" ref>
					<div
						ref={(div) => (this.weeklyViewRef = div)}
						className={`xs-weekly-body ${CalendarStore.calendarToggledFull || hideFullToggle ? "xs-unfix" : "xs-fix"}`}
					>
						{this.getOpenHours().map((hour, i) => {
							return (
								<div key={i} ref={(row) => (this.weeklyRow[hour] = row)} className="xs-weekly-row">
									<div className={this.getHourLabelClass(hour)}>{hour}:00</div>
									{this.state.resources.map((orgunit, idx) => {
										const inTheFuture = this.inTheFuture(hour)
										return (
											<Grid
												container
												key={`${i}_${idx}`}
												id={`${i}_${idx}`}
												onDrop={(e) => (inTheFuture ? this.drop(e, hour, orgunit.resource_id, orgunit._id) : null)}
												onDragOver={inTheFuture ? this.dragOverDrop : null}
												className={classnames("xs-flex borderWhite pa-1", {
													pointer: inTheFuture && isNotEmpty(orgunit._id)
												})}
												onClick={() =>
													isNotEmpty(orgunit._id)
														? this.openAddEventDialog(hour, orgunit.resource_id, orgunit._id)
														: GlobalStore.setNotificationMessage("Common.label.firstSelectOrgunit", "", "error")
												}
											>
												{/* <div
												key={`${i}_${idx}`}
												id={`board_${i}_${idx}`}
												style={{width: "300px"}}
												onDrop={this.drop}
												onDragOver={this.dragOverDrop}
											> */}
												{isSafe(CalendarStore.resourceData[orgunit.resource_id]) &&
													isSafe(CalendarStore.resourceData[orgunit.resource_id][hour]) &&
													CalendarStore.resourceData[orgunit.resource_id][hour].length > 0 &&
													CalendarStore.resourceData[orgunit.resource_id][hour].map((event, index) =>
														this.renderEvent(event, index)
													)}
												{/* </div> */}
											</Grid>
										)
									})}
								</div>
							)
						})}
					</div>
				</div>
				{/* {!hideFullToggle && ( */}
				<div
					data-tip
					data-for={expandTooltip}
					className="xs-full-screen"
					onClick={() => (CalendarStore.calendarToggledFull = !CalendarStore.calendarToggledFull)}
				>
					<i className={`fal fa-chevron-${CalendarStore.calendarToggledFull ? "up" : "down"} fa-lg"`} />
				</div>
				{/* )} */}
				<ReactTooltip id={expandTooltip}>
					<FormattedMessage id={`Common.label.${expandTooltip}`} />
				</ReactTooltip>
			</div>
		)
	}
}
