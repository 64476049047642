import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import moment from "moment"

// special import for Material-UI binding
import api from "../../../actions/api"
import XsTableServer from "../../../../../global/ui/xsTableServer/xsTableServer"
import VaccinationListStore from "../../../stores/VaccinationListStore"
import RouterStore from "../../../../../global/store/RouterStore"

import VaccinationFilter from "./vaccinationFilter"
import ExportMedicationToXlsDialog from "./exportMedicationToXlsDialog"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class VaccinationList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false
	}

	onHandleRowClick = (dataRow) => {
		RouterStore.push(`/patient?id=${dataRow.person_id}`)
	}

	render() {
		const applyFilters = VaccinationListStore.filters

		return (
			<Grid container className="xs-vaccination-list">
				<VaccinationFilter />
				<Grid item xs={12}>
					{VaccinationListStore.changedFilters ? (
						<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
							<i
								className="far fa-search fa-lg mr-3 pointer"
								onClick={() => {
									if (
										TableStore.tables.hasOwnProperty("vaccinationList") &&
										TableStore.tables["vaccinationList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["vaccinationList"]["index"]
									}

									VaccinationListStore.getFilters(VaccinationListStore.formRef)
								}}
							></i>
							<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
						</div>
					) : (
						<XsTableServer
							setRef={(table) => (VaccinationListStore.vaccinationReportListRef = table)}
							uri={api.loadVaccination().getPath()}
							filterValue={applyFilters}
							orderValue={[
								{
									asc: false,
									associated_column: "created_at"
								}
							]}
							config={{
								columnDefs: {
									created_at: {
										title: <FormattedMessage id="Common.label.createdAt" />,
										type: "string",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
									},
									administered_at: {
										title: <FormattedMessage id="Common.label.administeredAt" />,
										type: "string",
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										}
									},
									identifier: {
										title: <FormattedMessage id="Patient.labels.IDNumber" />,
										type: "string",
										design: {
											width: "150px",
											body: {
												renderer: (props) => {
													return <div className="blueDark xs-bold">{props.value}</div>
												}
											}
										}
									},
									code_ext: {
										title: <FormattedMessage id="Common.label.drugCode" />,
										type: "action",
										design: {
											width: "100px"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Common.label.name" />,
										type: "action"
										// sortable: true
									},
									batch_number: {
										title: <FormattedMessage id="Common.label.batchNumber" />,
										design: {
											width: "150px"
										},
										type: "string"
									},
									manufacturer_ext: {
										title: <FormattedMessage id="Common.label.manufacture" />,
										design: {
											width: "100px"
										},
										type: "string"
									},
									expiration_date: {
										title: <FormattedMessage id="Common.label.expiration" />,
										design: {
											width: "100px",
											body: {
												formatter: (props) => {
													return isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
												}
											}
										},
										type: "string"
									}
								},
								options: {
									name: "vaccinationList",
									showCursor: true,
									mapper: (dataRow) => {
										return {
											created_at: isSafe(dataRow.created_at) ? dataRow.created_at : "",
											code_ext: isSafe(dataRow.code_ext) ? dataRow.code_ext : "",
											name_ext: isSafe(dataRow.name_ext) ? dataRow.name_ext : "",
											manufacturer_ext: isSafe(dataRow.manufacturer_ext) ? dataRow.manufacturer_ext : "",
											identifier: isSafe(dataRow.identifier) ? dataRow.identifier : "",
											person_id: isSafe(dataRow.person_id) ? dataRow.person_id : "",
											expiration_date: isSafe(dataRow.expiration_date) ? dataRow.expiration_date : "",
											administered_at: isSafe(dataRow.administered_at) ? dataRow.administered_at : "",
											batch_number: isSafe(dataRow.batch_number) ? dataRow.batch_number : ""
										}
									},
									onRowClick: (dataRow) => {
										this.onHandleRowClick(dataRow)
									}
								}
							}}
						/>
					)}
				</Grid>
				<ExportMedicationToXlsDialog />
			</Grid>
		)
	}
}
