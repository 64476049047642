"use strict"

import api from "../actions/api"
import config from "../../../global/config/settings"
import {getUserPersonnelID, printReport, getUserDoctorInfo, getSelectedOrgUnitID} from "../../../global/helpers/actions"
import actionCard from "../actions/patientCard"
import UIStore from "./UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import DataStore from "./DataStore"
import {observable, action} from "mobx"
import moment from "moment"
import settings from "../../../global/config/settings"
import {ixsIsEmpty} from "../../../global/helpers/functions"
import WarningStore from "../../../global/store/WarningStore"

class PatientSickessAbsStore {
	@observable record = null
	@observable recordLoaded = false
	@observable recordID = null
	// @observable sicknessAbsLoaded = false
	// @observable sicknessAbsID = null
	@observable sicknessAbsDeliveries = null
	@observable diagnosis = []
	@observable PNRecordsChecked = false
	@observable deliveriesChips = null
	deliveriesChipsForm
	nextNumberLength = null
	dgnItemId = null
	forceComponentRefresh = null

	//FLAGS
	@observable editFlag = false

	@action clearData(name) {
		this[name] = {}
	}

	@action reset() {
		this.forceComponentRefresh = null
		this.record = null
		this.recordLoaded = false
		this.recordID = null
		// this.sicknessAbsLoaded = false
		// this.sicknessAbsID = null
		this.diagnosis = []
		this.PNRecordsChecked = false
		this.deliveriesChips = null
		this.sicknessAbsDeliveries = null
		this.nextNumberLength = null
		this.dgnItemId = null
	}

	@action set(name, value) {
		this[name] = value
	}

	@action async getNumber(form, intl) {
		//Ak máme novú PN získame číslo zo servera
		//Posielame orgunit, typ EHR a typ PN

		const sub_type = form.$("sub_type").get("value")
		const getRequest = {
			org_unit_id: getSelectedOrgUnitID(),
			record_type_id: "EHR.Data.SicknessAbsence",
			type_id: sub_type
		}
		let r = await api.loadSicknessAbsNumber(getRequest).call()
		const nextNumber = r.next
		const nextNumberL = nextNumber.length
		this.nextNumberLength = nextNumberL
		if (nextNumber && !ixsIsEmpty(nextNumber)) {
			//Ak získame číslo PN zo servera, odstránime posledný znak, predplníme ho do inputu a spravíme focus
			const almostNext = nextNumber.substring(0, nextNumberL - 1)

			form
				.$("number")
				.set(
					"error",
					intl.formatMessage(
						{id: "Patient.form.patientsicknessabs.numberwarning"},
						{sub_type: intl.formatMessage({id: `Patient.form.patientsicknessabs.${sub_type}`})}
					)
				)
			form.$("number").set("rules", `required|min:${nextNumberL}|max:${nextNumberL}`)
			form.$("number").set("value", almostNext)
			if (GlobalStore.defaultFocusFields["patientSicknessAbs"]) {
				GlobalStore.defaultFocusFields["patientSicknessAbs"].focus()
			}

			//Ak sa vymaže zadané číslo PN, zrušíme kontrolu na počet znakov
			form.$("number").observe(({field}) => {
				const currentValue = field.get("value")
				if (ixsIsEmpty(currentValue)) {
					field.set("error", null)
					if (form.$("sub_type").value != "OTH") {
						field.set("rules", `required`)
					}
				}
				if (currentValue.length > 0 && currentValue.length === nextNumberL) {
					field.set("error", null)
				}
			})
		} else {
			form.$("number").set("value", nextNumber)
		}

		//Nastaviť required ak sú prázdne rules
		const rules = form.$("number").get("rules")
		if (!rules) {
			form.$("number").set("rules", `required`)
		}
	}

	@action async loadRecord(record, load = false) {
		if (load) {
			const recordData = await api.loadProxyRecord(record).call()
			this.record = recordData
			this.recordID = recordData ? recordData.id : null
			this.editFlag = true
		} else {
			this.record = record
			this.recordID = record ? record.id : null
			this.editFlag = true
		}
	}

	@action saveForm(data, patientDTO, translations, onSuccess) {
		const patientID = patientDTO.validInfo.client._id
		const items = data.values()
		const providerID = getSelectedOrgUnitID()
		const personnelID = getUserPersonnelID()

		let saveRequest = {
			_ref: false,
			_type: "EHR.Data.SicknessAbsence",
			active: true,
			number: isNotEmpty(items.number) ? items.number : undefined,
			sub_type: items.sub_type,
			description: items.description,
			rel_type: null,
			employer_plain: items.employer_plain,
			work_capable_since: isSafe(items.work_capable_since)
				? moment(items.work_capable_since)
						.startOf("day")
						.format(settings.DB_DATETIME_FORMAT)
				: "",
			validity: {
				from: isSafe(items.from)
					? moment(items.from)
							.startOf("day")
							.format(settings.DB_DATETIME_FORMAT)
					: "",
				to: isSafe(items.to)
					? moment(items.to)
							.endOf("day")
							.format(settings.DB_DATETIME_FORMAT)
					: ""
			},
			maternity_claim_from: isSafe(items.maternity_claim_from)
				? moment(items.maternity_claim_from)
						.startOf("day")
						.format(settings.DB_DATE_FORMAT)
				: "",
			specific_rels: [
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: patientID,
						_type: "Entity.Data.Client"
					},
					type: "pat"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: providerID,
						_type: "Entity.Data.OrgUnit"
					},
					type: "ou"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: personnelID,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "phy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: personnelID,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "sndphy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: providerID,
						_type: "Entity.Data.OrgUnit"
					},
					type: "sndou"
				}
			],
			recorded_events: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Event",
					active: true,
					recorded_at: moment().format(config.DB_DATETIME_FORMAT),
					type: {
						_id: "WRT",
						_type: "EHR.CL.Record.EventType"
					}
				}
			]
		}

		//Update/Edit
		if (items.id) {
			saveRequest["_id"] = items.id
		}

		//RelType
		if (items.rel_type) {
			saveRequest["rel_type"] = {
				_type: "Entity.CL.RelationshipType",
				_id: items.rel_type
			}
		}

		//Dg
		if (items.diagnosis) {
			saveRequest["general_items"] = {
				_id: isSafe(this.dgnItemId) ? this.dgnItemId : undefined,
				_ref: false,
				_type: "EHR.Data.Record.Diagnosis",
				item: {
					_id: items.diagnosis,
					_type: "EHR.CL.Record.Diagnosis"
				},
				type: "dgn",
				diagnosis_type: "PRIMARY"
			}
		}

		//Nadradený dekurz
		if (this.record) {
			saveRequest["master_records"] = [
				{
					_ref: false,
					_type: "EHR.Data.Record.RecordRelationship",
					master: this.record.record_id,
					type: "ASSOC"
				}
			]
		}

		//Ziadost o materske
		if (items.sub_type == "MCLAIM") {
			saveRequest["work_capable_since"] = isSafe(items.childBirth)
				? moment(items.childBirth)
						.startOf("day")
						.format(settings.DB_DATETIME_FORMAT)
				: ""
			saveRequest["number"] = items.numberMclaim
		}

		api
			.saveRecord(saveRequest, patientID)
			.call()
			.then((response) => {
				if (response && response._id) {
					//Save deliveries
					let deliveriesRequest = []
					if (isSafe(this.deliveriesChipsForm)) {
						const dchips = this.deliveriesChipsForm.getData()
						Object.keys(dchips).forEach((key) => {
							let delivery = dchips[key]
							let diagnosis = ""
							if (isNotEmpty(delivery.diagnosis)) {
								diagnosis = delivery.diagnosis
							} else if (
								isSafe(this.diagnosisChipsForm) &&
								isSafe(this.diagnosisChipsForm.getData()) &&
								isSafe(this.diagnosisChipsForm.getData()["0"]) &&
								this.diagnosisChipsForm.getData()["0"].diagnosis === "PRIMARY"
							) {
								diagnosis = this.diagnosisChipsForm.getData()["0"].item
							} else {
								diagnosis = items.diagnosis
							}

							deliveriesRequest.push({
								_ref: false,
								_type: "EHR.Data.Record.SK.Delivery",
								item: {
									_id: delivery.item,
									_type: "EHR.CL.Bill.SK.Delivery"
								},
								diagnosis: {
									_id: diagnosis,
									_type: "EHR.CL.Record.Diagnosis"
								},
								count: delivery.count,
								payer_type: isNotEmpty(delivery.colPaymentType) ? delivery.colPaymentType : null,
								type: "dlv",
								delivery_extension: isNotEmpty(delivery.delivery_extension) ? delivery.delivery_extension : null,
								delivery_date_time: isNotEmpty(delivery.delivery_date_time)
									? moment.isMoment(delivery.delivery_date_time)
										? delivery.delivery_date_time.format(settings.DB_DATE_FORMAT)
										: delivery.delivery_date_time
									: null,
								request_date: isNotEmpty(delivery.request_date)
									? moment.isMoment(delivery.request_date)
										? delivery.request_date.format(settings.DB_DATE_FORMAT)
										: moment(delivery.request_date).format(settings.DB_DATE_FORMAT)
									: null
							})
						})
					}
					api.saveDeliveries(deliveriesRequest, response._id).call()

					data.reset()
					if (onSuccess) {
						onSuccess()
					}
					GlobalStore.setNotificationMessage("Patient.form.patientsicknessabs.savesuccess")
					UIStore.patientCardTab = ""
					UIStore.isFormSaving = false
					if (items.print) {
						this.printSicknessAbs(response._id, patientDTO, translations)
					}
					actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
						DataStore.setPatientEHR(overview)

						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					})
					this.reset()
				}
			})
	}

	@action handleSaveDeliveries(values) {
		this.deliveriesChips = values
	}

	@action printSicknessAbs(recordID, pacInfo, translations) {
		api
			.loadRecord(recordID, "L4")
			.call()
			.then((recordData) => {
				const doctorInfo = getUserDoctorInfo()
				let printValues = {
					name: `${pacInfo.last_name.toUpperCase()} ${pacInfo.first_name}`,
					identifier: pacInfo.identifier,
					address:
						pacInfo.validInfo && pacInfo.validInfo.address
							? `${pacInfo.validInfo.address.city} ${pacInfo.validInfo.address.street} ${pacInfo.validInfo.address.house_number}`
							: "",
					doctorinfo: doctorInfo ? `${doctorInfo.name} ${doctorInfo.code ? doctorInfo.code : ""}` : ""
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				//adresa
				if (pacInfo.validInfo && pacInfo.validInfo.address) {
					const street = pacInfo.validInfo.address.street
					const house_number = pacInfo.validInfo.address.house_number
					const city = pacInfo.validInfo.address.city
					if (isEmpty(street) || isEmpty(house_number) || isEmpty(city)) {
						WarningStore.open(
							"Pacient nema vyplnené všetky položky adresy bydliska. Pokiaľ ich chcete tlačiť, vyplňte ich v osobných údajoch pacienta."
						)
					}
				}

				let isOCR = false

				//zamestnavatel
				if (recordData.sub_type && recordData.sub_type.code == "OCR") {
					isOCR = true

					if (recordData.employer_plain) {
						printValues["employer_plain"] = recordData.employer_plain
					}
				}

				//diagnoza
				if (recordData.general_items && recordData.general_items.length > 0) {
					const dgnItem = recordData.general_items.find((x) => x.type == "dgn")
					if (isSafe(dgnItem) && dgnItem.item && dgnItem.item.code_ext) {
						printValues["diagnosis"] = dgnItem.item.code_ext
					}
				}

				//Dátum a čas
				printValues["created"] = moment(recordData.created_at).format(translations.dateFormat)

				if (isOCR) {
					printReport("nursing_family_member", printValues)
				} else {
					// PN
					printReport("confirmation_temporary_incapacity_work", printValues)
				}
			})
	}

	@action printSicknessAbsTDPN(recordID) {
		const pacInfo = DataStore.patientDTO.get("patient")
		const dateFormat = "DD.MM.YYYY"
		api
			.loadRecord(recordID, "L4")
			.call()
			.then((recordData) => {
				let printValues = {
					name: `${pacInfo.last_name.toUpperCase()} ${pacInfo.first_name}`,
					identifier: pacInfo.identifier,
					address:
						pacInfo.validInfo && pacInfo.validInfo.address
							? `${pacInfo.validInfo.address.city} ${pacInfo.validInfo.address.street} ${pacInfo.validInfo.address.house_number}`
							: "",
					number: recordData.number,
					lastDayOfCurrentMonth: moment()
						.endOf("month")
						.format(dateFormat) //Posledný deň aktuálneho mesiaca
				}

				printReport("sickness_abs_temp_without_template", printValues)
			})
	}

	@action deleteSicknessAbs(sicknessAbsID, onSuccess) {
		UIStore.isFormSaving = true
		const patientID = DataStore.patientDTO.get("patientID")
		const providerID = getSelectedOrgUnitID()
		const updateRequest = {
			_id: sicknessAbsID,
			_type: "EHR.Data.SicknessAbsence",
			_ref: false,
			active: false
		}
		api
			.saveRecord(updateRequest, patientID)
			.call()
			.then((response) => {
				if (response) {
					actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
						DataStore.setPatientEHR(overview)
						this.reset()
						GlobalStore.setNotificationMessage("Patient.form.patientsicknessabs.deleteSuccess")
						UIStore.patientCardTab = ""
						UIStore.isFormSaving = false
						if (onSuccess) {
							onSuccess()
						}

						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					})
				}
			})
	}

	@action insertDateToDeliveries(form, date) {
		if (isSafe(date)) {
			let data = JSON.parse(form.$("deliveriesChipsForm").value)
			if (Object.keys(data).length) {
				Object.keys(data).forEach((i) => {
					if (isEmpty(data[i].col2)) {
						data[i].delivery_date_time = moment(date).format(settings.DB_DATETIME_FORMAT)
					}
				})
				form.$("deliveriesChipsForm").value = JSON.stringify(data)
			}
		}
	}

	@action deliveriesChipsInit(form) {
		if (this.sicknessAbsDeliveries) {
			const deliveriesChips = this.sicknessAbsDeliveries.map((d) => {
				return {
					item: isNotEmpty(d.item) ? d.item._id : "",
					itemLabel: isNotEmpty(d.item) ? `${d.item.code_ext} - ${d.item.name_ext}` : "",
					itemCode: isNotEmpty(d.item) ? d.item.code_ext : "",
					diagnosis: isNotEmpty(d.diagnosis) ? d.diagnosis._id : "",
					count: isNotEmpty(d.count) ? d.count : "",
					colPaymentType: isNotEmpty(d.payer_type) ? d.payer_type : "",
					delivery_extension: isNotEmpty(d.delivery_extension) ? d.delivery_extension._id : null,
					delivery_date_time: isNotEmpty(d.delivery_date_time) ? d.delivery_date_time : null,
					request_date: isNotEmpty(d.request_date) ? d.request_date : ""
				}
			})
			// this.deliveriesChipsForm.bindData(deliveriesChips)
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
			this.loaded = true
		} else if (isSafe(this.record)) {
			if (isNotEmpty(this.record.diagnosis)) {
				form.$("diagnosis").value = this.record.diagnosis
			}
			let delivery_extensionCode =
				isSafe(GlobalStore.CL["deliveryExtension"]) &&
				isSafe(GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN"))
					? GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN").code
					: ""
			let deliveriesChips = [
				{
					item: "80251",
					itemLabel:
						"71-Vystavenie tlačiva Hlásenie choroby z povolania, hlásenie profesionálnej otravy, iného poškodenia zdravia pri práci, vystavenie potvrdenia o zisten...",
					itemCode: "71",
					delivery_extension: delivery_extensionCode,
					diagnosis: isNotEmpty(this.record.diagnosis) ? this.record.diagnosis : "",
					request_date: "",
					count: 1,
					colPaymentType: "S",
					delivery_date_time: moment().format()
				}
			]
			// this.deliveriesChipsForm.bindData(deliveriesChips)
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
			this.loaded = true
			this.deliveriesChipsDefaults(form)
		} else {
			let delivery_extensionCode =
				isSafe(GlobalStore.CL["deliveryExtension"]) &&
				isSafe(GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN"))
					? GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN").code
					: ""
			let deliveriesChips = [
				{
					item: "80251",
					itemLabel:
						"71-Vystavenie tlačiva Hlásenie choroby z povolania, hlásenie profesionálnej otravy, iného poškodenia zdravia pri práci, vystavenie potvrdenia o zisten...",
					itemCode: "71",
					delivery_extension: delivery_extensionCode,
					diagnosis: form.$("diagnosis").value,
					request_date: "",
					count: 1,
					colPaymentType: "S",
					delivery_date_time: moment().format()
				}
			]
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
		}
	}

	@action async loadSicknessAbsForm(form, id, intl) {
		form.reset()
		const sa = await api.loadRecord(id, "L3").call()
		const dg = sa.general_items && sa.general_items.find((gi) => gi._type === "EHR.Data.Record.Diagnosis" && gi.active)
		const deliveries =
			sa.general_items && sa.general_items.filter((gi) => gi._type === "EHR.Data.Record.SK.Delivery" && gi.active)

		if (dg) {
			form.$("diagnosis").set("value", dg.item._id)
			// form.$("diagnosis").set("value", `${dg.item.code_ext} - ${dg.item.name_ext}`);
			this.set("dgnItemId", dg._id)
		}

		if (sa.rel_type) {
			form.$("rel_type").set("value", sa.rel_type._id)
		}
		form.$("sub_type").set("value", sa.sub_type._id)
		form.$("from").set("value", sa.validity.from ? moment(sa.validity.from) : null)
		form.$("to").set("value", sa.validity.to ? moment(sa.validity.to) : null)
		form.$("work_capable_since").set("value", sa.work_capable_since ? moment(sa.work_capable_since) : null)
		form.$("description").set("value", sa.description)
		form.$("number").set("value", sa.number)
		form.$("employer_plain").set("value", sa.employer_plain)
		form.$("id").set("value", sa._id)
		form.$("type_disabled").set("value", true)
		form.$("maternity_claim_from").set("value", sa.maternity_claim_from ? moment(sa.maternity_claim_from) : null)
		if (sa.sub_type._id == "MCLAIM") {
			form.$("numberMclaim").set("value", sa.number)
			form.$("childBirth").set("value", sa.work_capable_since ? moment(sa.work_capable_since) : null)
		}

		if (moment(sa.validity.from).isValid()) {
			this.insertDateToDeliveries(form, moment(sa.validity.from))
		}
		this.set("sicknessAbsDeliveries", deliveries)

		this.changeSicknessAbsType(form.$("sub_type").value, form, intl, true)

		this.checkDateValidity(form)
		this.deliveriesChipsDefaults(form)
		this.deliveriesChipsInit(form)
	}

	changeSicknessAbsType = (curVal, form, intl, formLoad = false) => {
		const number = form.$("number")
		form.$("diagnosis").set("rules", "")
		if (curVal === "DPN" || curVal === "OCR") {
			if (isEmpty(form.$("id").value)) {
				this.getNumber(form, intl)
			}
			if (curVal === "DPN") {
				form.$("diagnosis").set("rules", "required")
			}
		} else {
			if (formLoad == false) {
				number.set("value", "")
			}
			number.set("rules", "")
			number.set("error", null)
		}

		this.checkDateValidity(form)
		this.deliveriesChipsDefaults(form)

		if (curVal === "MCLAIM") {
			form.$("numberMclaim").set("rules", "required|min:8|max:8")
			form.$("childBirth").set("rules", "required")
			form.$("maternity_claim_from").set("rules", "required")
		} else {
			form.$("numberMclaim").set("rules", "")
			form.$("childBirth").set("rules", "")
			form.$("maternity_claim_from").set("rules", "")
		}
	}

	checkDateValidity(form) {
		if (form.$("sub_type").value == "OTH" || form.$("sub_type").value == "MCLAIM") {
			form.$("from").set("rules", "")
		} else {
			form.$("from").set("rules", "required")
		}
	}

	@action deliveriesChipsDefaults(form) {
		// if (isSafe(this.record)) {
		let diagnosis = ""
		if (isSafe(this.record) && isNotEmpty(this.record.diagnosis)) {
			diagnosis = this.record.diagnosis
		} else if (isNotEmpty(form.$("diagnosis").value)) {
			diagnosis = form.$("diagnosis").value
		} else {
			diagnosis = ""
		}

		if (form.$("sub_type").value == "DPN") {
			let delivery_extensionCode =
				isSafe(GlobalStore.CL["deliveryExtension"]) &&
				isSafe(GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN"))
					? GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "DPN").code
					: ""
			let deliveriesChips = [
				{
					item: "80251",
					itemLabel:
						"71-Vystavenie tlačiva Hlásenie choroby z povolania, hlásenie profesionálnej otravy, iného poškodenia zdravia pri práci, vystavenie potvrdenia o zisten...",
					itemCode: "71",
					delivery_extension: delivery_extensionCode,
					diagnosis: diagnosis,
					request_date: "",
					count: 1,
					colPaymentType: "S",
					delivery_date_time: moment().format()
				}
			]
			// this.deliveriesChipsForm.bindData(deliveriesChips)
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
		} else if (form.$("sub_type").value == "OCR") {
			let delivery_extensionCode =
				isSafe(GlobalStore.CL["deliveryExtension"]) &&
				isSafe(GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "OCR"))
					? GlobalStore.CL["deliveryExtension"].find((data) => data.code_ext == "OCR").code
					: ""
			let deliveriesChips = [
				{
					item: "80251",
					itemLabel:
						"71-Vystavenie tlačiva Hlásenie choroby z povolania, hlásenie profesionálnej otravy, iného poškodenia zdravia pri práci, vystavenie potvrdenia o zisten...",
					itemCode: "71",
					delivery_extension: delivery_extensionCode,
					diagnosis: diagnosis,
					request_date: "",
					count: 1,
					colPaymentType: "S",
					delivery_date_time: moment().format()
				}
			]
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
		} else if (form.$("sub_type").value == "OTH" || form.$("sub_type").value == "MCLAIM") {
			form.$("deliveriesChipsForm").value = JSON.stringify([])
		}

		if (isSafe(this.deliveriesChipsForm)) {
			this.deliveriesChipsForm.forceUpdate()
		}
		// }
	}

	@action insertDiagnosisToDeliveries(form) {
		let deliveriesChips = JSON.parse(form.$("deliveriesChipsForm").value)
		deliveriesChips.forEach((row) => {
			if (isEmpty(row.diagnosis)) {
				row.diagnosis = form.$("diagnosis").value
			}
		})
		form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
		if (isSafe(this.deliveriesChipsForm)) {
			this.deliveriesChipsForm.forceUpdate()
		}
	}
}

var singleton = new PatientSickessAbsStore()
export default singleton
