import React from "react"
import moment from "moment"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import {Grid, Paper} from "@material-ui/core"

import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"

import XsTable from "../../../../../global/ui/xsTable/xsTable"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"

import SendNotificationDialog from "./sendNotificationDialog"

import NotifyStore from "../../../stores/NotifyStore"

import "./notify.less"

@injectIntl
@observer
export default class NotifyContainer extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				NotifyStore.getNotification(form)
			},
			onError() {}
		}

		this.form = new MobxReactForm(
			{
				fields: {
					time_from: {
						label: <FormattedMessage id="Common.label.from" />,
						type: "date",
						value: moment()
							.add(-1, "month")
							.startOf("month")
					},
					time_to: {
						label: <FormattedMessage id="Common.label.to" />,
						type: "date",
						value: moment()
					}
				}
			},
			{plugins: {dvr: validatorjs}, hooks, bindings}
		)

		NotifyStore.getNotification(this.form)
	}

	render() {
		return (
			<Grid container direction="column" id="notifyContainer">
				<Grid item>
					<Paper square={true} elevation={1} className="pr-8 pl-8 header">
						<div className="title">
							<FormattedMessage id="Common.label.notifications" />
						</div>
					</Paper>
				</Grid>
				<Grid item className="pl-8 pr-8 pt-4">
					<Grid container justify="space-between">
						<Grid item xs={10} container alignItems="center" spacing={8}>
							<Grid item>
								<XsDateTimePicker white field={this.form.$("time_from")} showTimeSelect={false} />
							</Grid>
							<Grid item>
								<XsDateTimePicker white field={this.form.$("time_to")} showTimeSelect={false} />
							</Grid>
							<Grid item className="pb-0">
								<XsIconButton
									rect
									tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
									className="xs-default xs-outline"
									onClick={this.form.onSubmit}
									icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
								/>
							</Grid>
						</Grid>
						<Grid item xs={2} container alignItems="center" justify="flex-end">
							<Grid item>
								<XsButton
									className="xs-primary"
									onClick={() => NotifyStore.openSendNotification()}
									text={<FormattedMessage id="Common.label.sendNotifications" />}
									icon={<i className="fal fa-bell fa-lg" />}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Grid item className="pl-8 pr-8">
					<XsTable
						config={{
							columnDefs: {
								last_changed: {
									title: <FormattedMessage id="Common.label.date" />,
									type: "string",
									design: {
										width: "200px",
										body: {
											formatter: (props) => {
												return isSafe(props) && isNotEmpty(props) ? moment(props).format("DD.MM.YYYY") : ""
											}
										}
									}
								},
								message_text: {
									title: <FormattedMessage id="Common.label.notification" />,
									type: "string"
								}
							},
							options: {
								showCursor: true,
								mapper: (dataRow) => {
									return {
										last_changed: dataRow.message.last_changed,
										message_text: dataRow.message.message_text
									}
								}
							},
							dataSource: NotifyStore.notificationData
						}}
					/>
				</Grid>
				{NotifyStore.isOpenSendNotification && <SendNotificationDialog />}
			</Grid>
		)
	}
}
