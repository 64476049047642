//@flow
import React from "react"
import ReactTooltip from "react-tooltip"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Timeline from "../timeline/timeline"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsMultiButton from "../../../../../global/ui/xsButton/xsMultiButton"
import XsTable from "../../../../../global/ui/xsTable/xsTable"
import UIStore from "../../../stores/UIStore"
import config from "../../../../../global/config/settings"
import {Grid} from "@material-ui/core"

import EHealthSearchDialog from "./eHealthSearchDialog"
import EHealthConsentDialog from "./eHealthConsentDialog"
import EHealthGetRequestsDialog from "./eHealthGetRequestsDialog"
// import WarningStore from "../../../../../global/store/WarningStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import DataStore from "../../../stores/DataStore"
import InfoDialogStore from "../../../../../global/store/InfoDialogStore"

import EHealthSearchDialogStore from "../../../stores/EHealthSearchDialogStore"
import EHealthConsentDialogStore from "../../../stores/EhealthConsentDialogStore"
import EHealthGetRequestsDialogStore from "../../../stores/EHealthGetRequestsDialogStore"
import RequestsFromLabsDialogStore from "../../../stores/RequestsFromLabsDialogStore"
import RequestsFromLabsDialog from "../timeline/requestsFromLabsDialog"
import SignInLabDialogStore from "../../../stores/SignInLabDialogStore"
import PrintTemplatesStore from "../../../stores/PrintTemplatesStore"
import SignInLabDialog from "../timeline/signInLabDialog"
import GetPatientSummaryEDSStore from "../../../stores/GetPatientSummaryEDSStore"
import GetPatientSummaryEDSDialog from "../patientCard/GetPatientSummaryEDSDialog"
import GetPatientSummaryStore from "../../../stores/GetPatientSummaryStore"
import GetPatientSummaryDialog from "../patientCard/GetPatientSummaryDialog"
import CovidResultDialog from "./covidResultDialog"
import CovidResultStore from "../../../stores/CovidResultStore"
import {GWApiCall} from "../../../../../global/helpers/api"
import {getSelectedOrgUnit, getUser, getUserPersonnelID} from "../../../../../global/helpers/actions"
import XsLoading from "../../../../../global/ui/xsLoading/xsLoading"

@observer
export class Overview extends React.Component {
	filter = {
		time: {
			search_string: "pastTwoYears"
		}
		// diagnoses: []
	}

	generateTimeFilter = () => {
		const values = [
			"Common.timeFilter.all",
			"Common.timeFilter.pastTwoYears",
			// "Common.timeFilter.lastYear",
			"Common.timeFilter.thisYear",
			"Common.timeFilter.lastMonth",
			"Common.timeFilter.thisMonth",
			"Common.timeFilter.lastWeek",
			"Common.timeFilter.thisWeek"
		]

		return {
			items: values.map((value) => {
				return {
					primaryText: this.props.intl.formatMessage({id: value}),
					secondaryText: this.props.intl.formatMessage({id: value}),
					search_string: value.split(".")[2],
					checkValue: false,
					id: value.split(".")[2]
				}
			})
		}
	}

	render() {
		const {patientOverviewData, actionButton} = this.props
		const {filter} = this
		const time_string = filter.time.search_string
		const hideToday = time_string.indexOf("last") !== -1

		let actions = [
			{
				label: <FormattedMessage id="PatientDetail.overview.eHealth.searching" />,
				icon: <i className="fal fa-search fa-lg" />,
				onButtonClick: () => {
					EHealthSearchDialogStore.open()
				}
			},
			{
				label: <FormattedMessage id="PatientDetail.overview.searchIneHealth" />,
				onButtonClick: () => {
					EHealthGetRequestsDialogStore.open()
				}
			},
			{
				label: <FormattedMessage id="Common.label.summaryEDS" />,
				onButtonClick: () => {
					GetPatientSummaryEDSStore.open()
				}
			},
			{
				label: <FormattedMessage id="Common.label.patientSummary" />,
				onButtonClick: () => {
					GetPatientSummaryStore.open()
				}
			},
			{
				label: <FormattedMessage id="Common.label.checkCodeListVersion" />,
				onButtonClick: () => {
					const user = getUser()
					const selectedOrgUnit = getSelectedOrgUnit()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					GWApiCall({
						method: "POST",
						uri: `${config.GW_BASE_URL}/CheckCodeListVersion`,
						body: JSON.stringify({
							ClassName: "OverVerziuCiselnikov_v1",
							Oids: [
								"1.3.158.00165387.100.10.126",
								"1.3.158.00165387.100.10.150",
								"1.3.158.00165387.100.10.158",
								"1.3.158.00165387.100.10.159",
								"1.3.158.00165387.100.10.25",
								"1.3.158.00165387.100.10.34",
								"1.3.158.00165387.100.10.37",
								"1.3.158.00165387.100.10.39",
								"1.3.158.00165387.100.10.43",
								"1.3.158.00165387.100.10.44",
								"1.3.158.00165387.100.10.45",
								"1.3.158.00165387.100.10.51",
								"1.3.158.00165387.100.10.54",
								"1.3.158.00165387.100.10.59",
								"1.3.158.00165387.100.10.66",
								"1.3.158.00165387.100.10.83",
								"1.3.158.00165387.100.10.86"
							],
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((res) => {
							if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
								// WarningStore.generateErrorMessage(res.IsEhealthException, res.ErrorMessage)
								GlobalStore.checkEHGWError(res.ErrorCode, res.ErrorMessage, res.IsEhealthException)
							} else {
								InfoDialogStore.open({
									header: {
										icon: <i className="fal fa-abacus fa-2x" />,
										text: <FormattedMessage id="Common.label.listVersions" />
									},
									content: () => (
										<XsTable
											config={{
												columnDefs: {
													Name: {
														title: <FormattedMessage id="Common.label.name" />,
														type: "string"
													},
													LastChanged: {
														title: <FormattedMessage id="Common.label.lastChanged" />,
														type: "string",
														design: {
															width: "90px"
														}
													},
													Oid: {
														title: <FormattedMessage id="Common.label.oid" />,
														type: "string"
													},
													ValidFrom: {
														title: <FormattedMessage id="Common.label.validFrom" />,
														type: "string",
														design: {
															width: "90px"
														}
													},
													ValidTo: {
														title: <FormattedMessage id="Common.label.validTo" />,
														type: "string",
														design: {
															width: "90px"
														}
													},
													Version: {
														title: <FormattedMessage id="Common.label.version" />,
														type: "string",
														design: {
															width: "60px"
														}
													}
												},
												options: {},
												dataSource: res.Result
											}}
										/>
									)
								})
							}
						})
				}
			}
		]

		return (
			<div>
				{UIStore.EHRfilterValue === "nczivalues" && (
					<Grid container spacing={8} className="pt-4 pl-8">
						<Grid item>
							<XsMultiButton className="xs-default xs-outline" menuWidth={250} buttons={actions} />
						</Grid>
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								onClick={() => EHealthConsentDialogStore.open()}
								text={<FormattedMessage id="Common.label.writeConsent" />}
							/>
						</Grid>
						<Grid item>
							<XsMultiButton
								className="xs-default xs-outline"
								menuWidth={275}
								buttons={[
									{
										label: <FormattedMessage id="Common.label.covidResult" />,
										onButtonClick: () => {
											CovidResultStore.open()
										}
									},
									{
										label: <FormattedMessage id="PrintTemplate.Corona.Title" />,
										onButtonClick: () => {
											let printValues = PrintTemplatesStore.getCommonValues()
											PrintTemplatesStore.open("corona", printValues)
										}
									}
								]}
							/>
						</Grid>
					</Grid>
				)}
				{UIStore.EHRfilterValue === "laboratories" && (
					<Grid container className="pt-4 pl-8">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="PatientDetail.overview.signToLaboratory" />}
								onClick={() => SignInLabDialogStore.open()}
							/>
						</Grid>
					</Grid>
				)}
				{UIStore.EHRfilterValue === "requests" && (
					<Grid container className="pt-4 pl-8">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								text={<FormattedMessage id="PatientDetail.overview.RequestsFromLaboratories" />}
								onClick={() => RequestsFromLabsDialogStore.open()}
							/>
						</Grid>
					</Grid>
				)}
				{UIStore.EHRfilterValue === "medicationAMB" && (
					<Grid container className="pt-4 pl-8">
						<Grid item>
							<span data-tip data-for="epis">
								<XsButton
									className="xs-default xs-outline"
									text={<FormattedMessage id="Common.label.epis" />}
									onClick={() =>
										window.open("https://www.epis.sk/WebLogon.aspx?returnurl=/HlavnaStranka.aspx", "_blank")
									}
								/>
							</span>
							<ReactTooltip id="epis">
								<FormattedMessage id="Common.label.episDescription" />
							</ReactTooltip>
						</Grid>
					</Grid>
				)}
				<Timeline
					items={patientOverviewData}
					hideToday={hideToday}
					timeFilterValues={this.generateTimeFilter()}
					reloadOverview={this.props.reloadOverview}
					actionButton={actionButton}
					actions={this.props.actions}
					// onTimeFilterChange={updateTimeFilter}
					// onDiagnosisFilterChange={updateDiagnosisFilter}
				/>
				<EHealthSearchDialog />
				<EHealthConsentDialog />
				<EHealthGetRequestsDialog />
				<SignInLabDialog />
				<RequestsFromLabsDialog />
				<GetPatientSummaryEDSDialog />
				<CovidResultDialog />
				<GetPatientSummaryDialog />
				{UIStore.isOverviewTemplateLoading && <XsLoading overlay />}
			</div>
		)
	}
}

export default injectIntl(Overview)
