"use strict"

// default imports for all farms
import React from "react"
import {observer} from "mobx-react"
import AddIcon from "@material-ui/icons/Add"
import {FormattedMessage, injectIntl} from "react-intl"
import classNames from "classnames"
import PatientPrescriptionStore from "../../../stores/PatientPrescriptionStore"
import RouterStore from "../../../../../global/store/RouterStore"
import Grid from "@material-ui/core/Grid"
// import PatientPrescriptionContainerStore from "../../../stores/PatientPrescriptionContainerStore"
import DataStore from "../../../stores/DataStore"
import GlobalStore from "../../../../../global/store/GlobalStore"
import SaveIcon from "@material-ui/icons/Done"
import CancelIcon from "@material-ui/icons/Clear"
import PrintIcon from "@material-ui/icons/Print"
import TrashIcon from "@material-ui/icons/DeleteForever"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import dataBindings from "./patientPrescriptionBindings"
import UIStore from "../../../stores/UIStore"
import DrugInteractionsDialogStore from "../../../stores/DrugInteractionsDialogStore"
import DDaction from "../../../actions/patientCard"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"
import {resetForm, insertFormDataByScheme} from "../../../../../global/helpers/bindings"
import {getSelectedOrgUnitID, getUserPersonnelID, getUserDoctorInfo} from "../../../../../global/helpers/actions"
import api from "../../../../ambulance/actions/api"
import moment from "moment"
import config from "../../../../../global/config/settings"
import PrescriptionTemplateDialogStore from "../../../stores/PrescriptionTemplateDialogStore"

@injectIntl
@observer
export default class PatientPrescriptionSaveButtons extends React.Component {
	constructor(props) {
		super(props)
	}

	prescriptionShortcuts = (e) => {
		if (RouterStore.location.pathname === "/patient" && UIStore.patientCardTab == "prescription") {
			//ulozit a zavriet prescription Alt + U
			if (e.altKey && e.keyCode == 85) {
				e.preventDefault()
				this.validateForms(Object.keys(this.props.form), e)
			}
			//ulozit rozpisane prescription Alt + R
			if (e.altKey && e.keyCode == 82) {
				e.preventDefault()
				this.saveDraft()
			}

			//ulozit a vytlacit prescription Alt + P
			if (e.altKey && e.keyCode == 80) {
				e.preventDefault()
				this.validateForms(Object.keys(this.props.form), e, true)
			}

			//pridat novy prescription Alt + A
			if (e.altKey && e.keyCode == 65) {
				e.preventDefault()
				this.props.addForm()
			}
		}
	}

	componentDidMount() {
		document.body.addEventListener("keydown", this.prescriptionShortcuts)
	}

	componentWillUnmount() {
		document.body.removeEventListener("keydown", this.prescriptionShortcuts)
	}

	validateForms(keys, e, print = false) {
		let index = keys[0]
		this.props.form[index].validate().then(({isValid}) => {
			UIStore.isFormSaving = true
			if (isValid && keys.length > 1 && this.props.formValidation(this.props.form[index], index)) {
				keys.shift()
				this.validateForms(keys, e, print)
			} else if (isValid && keys.length == 1 && this.props.formValidation(this.props.form[index], index)) {
				// VALID
				let recipes = []
				Object.keys(this.props.form).forEach((key) => {
					let obj = this.props.form[key]
					obj.$("print").value = print
					recipes.push(this.getJsonFromForm(obj, key))
				})
				DrugInteractionsDialogStore.checkOverinteraction(recipes, () => {
					this.props.options.onClose("PRESCRIPTION", false, false)
					PatientPrescriptionStore.drugPrices.clear()
					PatientPrescriptionStore.drugPricesFull.clear()
				})
			} else {
				// NOTVALID
				UIStore.isFormSaving = false
				GlobalStore.setNotificationMessage(this.props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
				// this.props.invalidForm()
				return false
			}
		})
	}

	getJsonFromForm(form) {
		const dosageValue = form.$("quantization").value
		form.$("flags").value = `${form.$("urgent").value ? "N" : ""}${form.$("pacientPays").value ? "P" : ""}${
			form.$("opiate").value ? "O" : ""
		}`
		form.$("doctor_id").value = getUserPersonnelID()
		form.$("personnel_id").value = getUserPersonnelID()
		form.$("provider_id").value = getSelectedOrgUnitID()
		form.$("repeatingMoment").value = form.$("repeatingMoment").value ? form.$("repeatingMoment").value : 1
		if (form.$("prescriptionType").value == "MEDEQUIP") form.$("repeatingMoment").value = 1
		form.$("unit").value = form.$("unit").value ? form.$("unit").value : null
		form.$("medicationRoute").value = form.$("medicationRoute").value ? form.$("medicationRoute").value : "OTH"
		form.$("repeatType").value =
			form.$("repeatType").value == 0 ? form.$("repeatTypeSecondary").value : form.$("repeatType").value
		form.$("sendingDoctor").value = form.$("sendingDoctor").value === "" ? null : form.$("sendingDoctor").value
		form.$("substitutingDoctor").value =
			form.$("substitutingDoctor").value === "" ? null : form.$("substitutingDoctor").value
		form.$("substitutingDoctorOUPZS").value =
			form.$("substitutingDoctorOUPZS").value === "" ? null : form.$("substitutingDoctorOUPZS").value
		form.$("doctorOUPZS").value = form.$("doctorOUPZS").value === "" ? null : form.$("doctorOUPZS").value
		form.$("substitutingExpertiseId").value =
			form.$("substitutingExpertiseId").value === "" || isEmpty(form.$("substitutingDoctor").value)
				? null
				: form.$("substitutingExpertiseId").value
		form.$("sendingDoctorExpertiseId").value =
			form.$("sendingDoctorExpertiseId").value === "" || isEmpty(form.$("sendingDoctor").value)
				? null
				: form.$("sendingDoctorExpertiseId").value
		form.$("productCode").value =
			form.$("productCode").value == "" || isEmpty(form.$("productCode").value) ? null : form.$("productCode").value
		form.$("diagnosis").value =
			form.$("diagnosis").value == "" || isEmpty(form.$("diagnosis").value) ? null : form.$("diagnosis").value
		form.$("substitutingPersonnelExpertiseId").value =
			form.$("substitutingPersonnelExpertiseId").value === "" ||
			isEmpty(form.$("substitutingPersonnelExpertiseId").value)
				? null
				: form.$("substitutingPersonnelExpertiseId").value
		form.$("sendingDoctorPersonnelExpertiseId").value =
			form.$("sendingDoctorPersonnelExpertiseId").value === "" ||
			isEmpty(form.$("sendingDoctorPersonnelExpertiseId").value)
				? null
				: form.$("sendingDoctorPersonnelExpertiseId").value
		form.$("version").value = "final"
		// form.$("supplement").value = form.$("supplement").value ? htmlToPlain(form.$("supplement").value) : ""
		// form.$("production").value = form.$("production").value ? htmlToPlain(form.$("production").value) : ""
		// form.$("useGuide").value = form.$("useGuide").value ? htmlToPlain(form.$("useGuide").value) : ""

		const recipe = insertFormDataByScheme(
			form,
			dataBindings,
			{
				receptId: form.$("recordId").value == "" ? null : form.$("recordId").value,
				patientId: DataStore.patientDTO.get("patientID"),
				doseEveryUnit: "H",
				print: form.$("print").value,
				days: form.$("days").value ? Number(form.$("days").value) * 24 * 60 : 0,

				components:
					form.$("ingredientsChipsForm").value != "{}" &&
					form.$("ingredientsChipsForm").value != "[]" &&
					form.$("ingredientsChipsForm").value != ""
						? JSON.parse(form.$("ingredientsChipsForm").value)
								.filter((i) => isSafe(i))
								.map((row) => {
									return {
										component_id: row.col1,
										quantity: row.col2,
										unit_id: row.col3,
										_ref: false
									}
								})
						: null,
				prohibitedDrugs:
					form.$("prohibitedChipsForm").value != "{}" &&
					form.$("prohibitedChipsForm").value != "[]" &&
					form.$("prohibitedChipsForm").value != "" &&
					form.$("replacementProhibited").value == true
						? JSON.parse(form.$("prohibitedChipsForm").value)
								.filter((i) => isSafe(i))
								.map((row) => {
									return {
										_id: row.col1,
										_type: "EHR.CL.Medication.Product",
										labelText: row.col1NameExt
									}
								})
						: null
			},
			() => resetForm(form, dataBindings),
			true
		)
		if (dosageValue === "FIX" || dosageValue === "XYZW" || dosageValue === "AE") {
			let dsText
			let _ = form
			if (_.$("quantizationRadio").value === "first")
				dsText = `${+_.$("morning").value}-${+_.$("lunch").value}-${+_.$("evening").value}-${+_.$("sleep").value}`
			else dsText = `${_.$("dosage").value}`
			recipe.dosage = dsText
		} else {
			recipe.dosage = recipe.transcription = recipe.dosage
		}

		recipe["print"] = form.$("print").value

		recipe.dosage_type =
			form.$("quantization").value === "FIX"
				? form.$("quantizationRadio").value == "first"
					? "XYZW"
					: "AE"
				: form.$("quantization").value
		recipe.version = {
			_id: "final",
			_type: "EHR.CL.Record.Version"
		}

		if (isNotEmpty(form.$("repeatPlain").value)) {
			recipe.repeats_type_plain = form.$("repeatPlain").value * 86400
		}
		if (isNotEmpty(form.$("validity").value)) {
			recipe.validity = {
				to: moment(form.$("validity").value).format(config.DB_DATETIME_FORMAT)
			}
		}
		//form.$("relatedEvents").set("value", JSON.stringify(data.relateds))
		if (isNotEmpty(form.$("relatedEvents").value)) {
			recipe.relateds = JSON.parse(form.$("relatedEvents").value)
		}

		recipe["cl_doctor_expertise"] = getUserDoctorInfo().expertise.cl_id

		return recipe
		// DrugInteractionsDialogStore.checkOverinteraction(recipe, form)
		// deleteFromSession();
		// UIStore.overviewTab = "medication"
	}

	saveDraft = () => {
		UIStore.isFormSaving = true
		let validForms = true
		let draftData = []
		Object.keys(this.props.form).forEach((name) => {
			if (isNotSafe(this.props.form[name]) || isEmpty(this.props.form[name].$("productCode").value)) {
				validForms = false
			}
		})
		if (validForms) {
			Object.keys(this.props.form).forEach((name, idx) => {
				draftData.push(this.getJsonFromForm(this.props.form[name], idx))
			})
			draftData.forEach((draft) => {
				if (
					isSafe(draft.record_diagnoses) &&
					isSafe(draft.record_diagnoses[0]) &&
					isSafe(draft.record_diagnoses[0].item) &&
					isEmpty(draft.record_diagnoses[0].item._id)
				) {
					draft.record_diagnoses = []
				}
				draft.version = {
					_id: "draft",
					_type: "EHR.CL.Record.Version"
				}
			})

			api
				.savePrescription(draftData)
				.call()
				.then((draftResponse) => {
					const providerID = getSelectedOrgUnitID()
					DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
						(overview) => {
							DataStore.setPatientEHR(overview)
							let formKeys = Object.keys(this.props.form)
							for (var i = 0, len = formKeys.length; i < len; i++) {
								let name = formKeys[i]
								this.props.form[name].$("recordId").value = draftResponse[i].record_id
								this.props.form[name].$("version").value = "draft"
								if (isNotEmpty(this.props.form[name].$("repeatTypeSecondary").value)) {
									this.props.form[name].$("repeatType").value = 0
								}
							}

							GlobalStore.setNotificationMessage("Common.label.prescriptionDraftSaved")
							UIStore.patientCardTab = ""
							this.props.options.onClose("PRESCRIPTION", false, false)
							PatientPrescriptionStore.drugPrices.clear()
							PatientPrescriptionStore.drugPricesFull.clear()
							UIStore.overviewTab = "medication"
							window.scrollTo(0, 0)

							let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
							if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
								DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
							}

							UIStore.isFormSaving = false
							//setovanie version a recordId nieje synchronne preto timeout
							//Docasne zakomentovane kvoli poziadavke z produ pred nasadzovanim
							// setTimeout(() => {
							// 	this.props.options.deleteOnlyFromStorage("PRESCRIPTION")
							// 	UIStore.isFormSaving = false
							// 	GlobalStore.increaseForms()
							// }, 0)
						}
					)
				})
		} else {
			UIStore.isFormSaving = false
			GlobalStore.setNotificationMessage(this.props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			// this.props.invalidForm()
		}
	}

	saveAsTemplate = (privateCheckbox) => {
		UIStore.isFormSaving = true
		let validForms = true
		Object.keys(this.props.form).forEach((name) => {
			if (isNotSafe(this.props.form[name]) || isEmpty(this.props.form[name].$("productCode").value)) {
				validForms = false
			}
		})
		if (validForms) {
			const saveReq = {
				_type: "EHR.API.JSON.Proxy.Template.Recipe",
				scope: "Pr",
				private: privateCheckbox,
				recipe: this.getJsonFromForm(this.props.form[Object.keys(this.props.form)[0]], 0)
			}

			// delete saveReq.recipe.patient_id

			api
				.savePrescriptionAsTemplate(saveReq)
				.call()
				.then(() => {
					const providerID = getSelectedOrgUnitID()
					DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID"), null, null, true).then(
						(overview) => {
							DataStore.setPatientEHR(overview)
							GlobalStore.setNotificationMessage("Common.label.saveAsTemplateSuccessful")
							UIStore.isFormSaving = false
						}
					)
				})
		} else {
			UIStore.isFormSaving = false
			GlobalStore.setNotificationMessage(this.props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
		}
	}

	checkPrescriptionsVersion = () => {
		let tmp = false
		Object.keys(this.props.form).forEach((name) => {
			if (this.props.form[name].$("version").get("value") == "draft") tmp = true
		})
		return tmp
	}

	checkPrescriptionsLimitations = (callback) => {
		//liek na test->trittico
		if (
			Object.keys(this.props.form).some((key) => {
				if (
					isSafe(this.props.form[key]) &&
					isNotEmpty(this.props.form[key].$("expertiseWarningText").value) &&
					(isEmpty(this.props.form[key].$("sendingDoctorPersonnelExpertiseId").value) ||
						isEmpty(this.props.form[key].$("doctorOUPZS").value) ||
						isEmpty(this.props.form[key].$("doctorDate").value))
				) {
					return true
				} else {
					return false
				}
			})
		) {
			GlobalStore.openConfirmationDialog("xsPrescriptionWithoutDoctor", {
				formSaveCallback: () => {
					callback()
				}
			})
		} else {
			if (isSafe(callback) && typeof callback == "function") callback()
		}
	}

	stornoForms = () => {
		let deleteAll = true
		Object.keys(this.props.form).forEach((name) => {
			let data = this.props.form[name].get("value")
			if (!(data.version == "final" && isNotEmpty(data.recordId))) {
				deleteAll = false
			}
		})
		if (deleteAll) {
			GlobalStore.openConfirmationDialog("xsDeletePrescriptionsOnCancel")
		}
	}

	showPrice = () => {
		return !Object.keys(this.props.form).every((key) => this.props.form[key].$("prescriptionType").value === "RINDIVID")
	}

	showPrint = () => {
		return !Object.keys(this.props.form).some((key) => this.props.form[key].$("repeatType").value == 0)
	}

	showSaveTemplate = () => {
		return (
			Object.keys(this.props.form).every(
				(key) =>
					isNotEmpty(this.props.form[key].$("productCode").value) &&
					this.props.form[key].$("prescriptionType").value != "RINDIVID"
			) && Object.keys(this.props.form).length == 1
		)
	}

	render() {
		let totalPrice = 0
		Object.keys(this.props.form).forEach((key) => {
			if (
				isNotEmpty(
					PatientPrescriptionStore[
						isNotEmpty(this.props.form[key].$("pacientPaysReason").value) ||
						(!this.props.form[key].$("isDrugCategorized").value &&
							this.props.form[key].$("prescriptionType").value == "RBULK")
							? "drugPricesFull"
							: "drugPrices"
					].get(key)
				) &&
				isNotEmpty(this.props.form[key].$("packs").value)
			) {
				totalPrice += +(
					PatientPrescriptionStore[
						isNotEmpty(this.props.form[key].$("pacientPaysReason").value) ||
						(!this.props.form[key].$("isDrugCategorized").value &&
							this.props.form[key].$("prescriptionType").value == "RBULK")
							? "drugPricesFull"
							: "drugPrices"
					].get(key) * this.props.form[key].$("packs").value
				).toFixed(2)
			}
		})
		// PatientPrescriptionStore.drugPrices.forEach((val) => {
		// 	totalPrice += +val
		// })

		return (
			<div className={classNames("xs-patient-form-actions", {"xs-top": this.props.top})}>
				<Grid container direction="column">
					{this.showPrice() && (
						<Grid item container justify="flex-end" alignItems="center" className="fontSize15">
							<FormattedMessage id="Common.label.totalPrice" />:{" "}
							<span className="pl-1 xs-bold">{totalPrice.toFixed(2)}</span>
							<i className="fal fa-euro-sign pl-1"></i>
						</Grid>
					)}
					<Grid item>
						<XsButton
							ref={(btn) => {
								PatientPrescriptionStore.saveAndCloseBtnRef = btn
							}}
							className="xs-success button-prescription-save"
							text={<FormattedMessage id="Patient.form.patientPrescription.SaveClose" />}
							type="submit"
							tabindex={10000}
							onClick={(e) => {
								this.checkPrescriptionsLimitations(() => {
									this.validateForms(Object.keys(this.props.form), e)
								})
							}}
							icon={<SaveIcon />}
						/>
						{this.showPrint() && (
							<XsButton
								ref={(btn) => {
									PatientPrescriptionStore.saveAndPrintBtnRef = btn
								}}
								className="xs-success xs-outline button-prescription-save-and-print"
								text={<FormattedMessage id="Patient.form.patientinfo.saveandprint" />}
								tabindex={10001}
								onClick={(e) => {
									this.checkPrescriptionsLimitations(() => {
										this.validateForms(Object.keys(this.props.form), e, true)
									})
								}}
								icon={<PrintIcon />}
							/>
						)}
						<XsButton
							ref={(btn) => {
								PatientPrescriptionStore.saveDraftBtnRef = btn
							}}
							className="xs-success xs-outline button-prescription-save-draft"
							text={<FormattedMessage id="Patient.form.patientPrescription.SaveDraft" />}
							tabindex={10002}
							onClick={() => {
								this.checkPrescriptionsLimitations(this.saveDraft)
							}}
							icon={<SaveIcon />}
						/>
						<XsButton
							ref={(btn) => {
								PatientPrescriptionStore.addNewBtnRef = btn
							}}
							className="xs-success xs-outline button-prescription-add-new"
							text={<FormattedMessage id="Patient.form.patientPrescription.addForm" />}
							tabindex={10003}
							onClick={() => {
								this.props.addForm()
							}}
							icon={<AddIcon />}
						/>

						{this.checkPrescriptionsVersion() && (
							<XsButton
								className="xs-danger xs-outline button-prescription-draft-delete"
								text={<FormattedMessage id="Common.label.delete" />}
								tabindex={10004}
								onClick={() => {
									GlobalStore.openConfirmationDialog("xsDeletePrescriptionDraft")
								}}
								icon={<TrashIcon />}
							/>
						)}

						<XsButton
							className="xs-danger xs-outline button-prescription-cancel"
							text={<FormattedMessage id="Common.form.close" />}
							tabindex={10005}
							onClick={() => {
								this.props.options.onClose("PRESCRIPTION", false, true)
								PatientPrescriptionStore.drugPrices.clear()
								// let deleteAll = true
								// Object.keys(this.props.form).forEach((name) => {
								// 	let data = this.props.form[name].get("value")
								// 	if (!(data.version == "final" && isNotEmpty(data.recordId))) {
								// 		deleteAll = false
								// 	}
								// })
								// if (deleteAll) {
								// 	GlobalStore.openConfirmationDialog("xsDeletePrescriptionsOnCancel")
								// } else {
								// 	this.props.clearAllPrescriptions()
								// 	PatientPrescriptionStore.prohibitedChips = {}
								// 	PatientPrescriptionStore.ingredientsChips = {}
								// 	PatientPrescriptionStore.represcribeID = null
								// 	PatientPrescriptionStore.represcribeLoaded = false
								// 	PatientPrescriptionStore.ingredientsChipsForm = {}
								// 	PatientPrescriptionStore.prohibitedChipsForm = {}
								// 	PatientPrescriptionStore.doctorOUPZSItems = {}
								// 	PatientPrescriptionStore.substitutingDoctorOUPZSItems = {}
								// 	UIStore.patientCardTab = ""
								// }
							}}
							icon={<CancelIcon />}
						/>
						{this.showSaveTemplate() && (
							<XsButton
								className="xs-success xs-outline button-prescription-save-and-print"
								text={<FormattedMessage id="Common.label.saveAsTemplate" />}
								tabindex={10006}
								onClick={() => {
									PrescriptionTemplateDialogStore.open([], null, this.saveAsTemplate)
								}}
								icon={<i className="fal fa-file-alt fa-lg"></i>}
							/>
						)}
						{GlobalStore.confirmationDialogOpened == "xsPrescriptionWithoutDoctor" && (
							<XsConfirmationDialog
								type="warning"
								name="xsPrescriptionWithoutDoctor"
								title={<FormattedMessage id="Common.label.warning" />}
								text={<FormattedMessage id="Common.label.prescriptionWarningWithoutDoctor" />}
								onConfirmation={() => {
									if (
										isSafe(GlobalStore.confirmationDialogParams.formSaveCallback()) &&
										typeof GlobalStore.confirmationDialogParams.formSaveCallback() == "function"
									) {
										GlobalStore.confirmationDialogParams.formSaveCallback()
									}
								}}
								confirmBtn={{
									icon: "",
									text: "Common.dialog.Yes"
								}}
							/>
						)}
						<XsConfirmationDialog
							name="xsDeletePrescriptionDraft"
							title={<FormattedMessage id="Common.label.warning" />}
							text={<FormattedMessage id="Patient.form.patientPrescription.deleteConfirmationMessage" />}
							onConfirmation={() =>
								PatientPrescriptionStore.deleteDraft(this.props.form, () => {
									this.props.options.onClose("PRESCRIPTION", false, false)
									PatientPrescriptionStore.drugPrices.clear()
									PatientPrescriptionStore.drugPricesFull.clear()
								})
							}
						/>
						<XsConfirmationDialog
							name="xsDeletePrescriptionsOnCancel"
							title={<FormattedMessage id="Common.label.warning" />}
							text={<FormattedMessage id="Patient.form.patientPrescription.deletePrescriptionsOnCancel" />}
							onConfirmation={() =>
								PatientPrescriptionStore.deletePrescriptionsOnCancel(this.props.form, () => {
									this.props.options.onClose("PRESCRIPTION", false, false)
									PatientPrescriptionStore.drugPrices.clear()
									PatientPrescriptionStore.drugPricesFull.clear()
								})
							}
						/>
					</Grid>
				</Grid>
			</div>
		)
	}
}
