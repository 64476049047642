import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import classnames from "classnames"
import {Store, get} from "idb-keyval"
import {getUser} from "../../../../global/helpers/actions"

import {Grid, Badge, TextField} from "@material-ui/core"

import ChatWindow from "./chatWindow"

import NotificationCenterStore from "./NotificationCenterStore"

import "./notificationCenter.less"

@observer
export default class NotificationCenter extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpen: false
		}

		this.aaa = null

		NotificationCenterStore.getUsers()
	}

	getNewMessageCount = (count) => {
		if (count > 99) {
			return "99+"
		} else {
			return count
		}
	}

	render() {
		const me = getUser()
		return (
			<div className="messenger">
				{isSafe(NotificationCenterStore.data) &&
					Object.keys(NotificationCenterStore.data).length > 0 &&
					Object.keys(NotificationCenterStore.data).map((key, idx) => {
						if (idx < 3) {
							return <ChatWindow key={key} userId={key} />
						}
					})}
				<div className="chatSidebar">
					{NotificationCenterStore.isOpenSidebar ? (
						<React.Fragment>
							<Grid
								container
								alignItems="center"
								justify="space-between"
								className="pa-2 header pointer"
								onClick={() => NotificationCenterStore.closeSidebar()}
							>
								<Grid item>Chat</Grid>
								<Grid item>
									<i className={classnames("fal fa-times greyDefault")} style={{fontSize: "24px"}}></i>
								</Grid>
							</Grid>
							<div className="sidebarUsers">
								{isSafe(NotificationCenterStore.users) &&
									NotificationCenterStore.users.length > 0 &&
									NotificationCenterStore.users.map((user, idx) => {
										if (user._id != me.user._id) {
											return (
												<div
													key={idx}
													className="sidebarItem pl-2 pr-2 pt-1 pb-1"
													onClick={async () => {
														let mailbox = new Store(`mailbox-${me.user._id}`, "mailbox")
														let value = await get(user._id, mailbox)
														if (value) {
															if (
																isNotSafe(NotificationCenterStore.data[user._id]) &&
																isSafe(Object.keys(NotificationCenterStore.data)) &&
																Object.keys(NotificationCenterStore.data).length > 2
															) {
																delete NotificationCenterStore.data[Object.keys(NotificationCenterStore.data)[0]]
															}
															NotificationCenterStore.data[user._id] = value
														} else {
															if (
																isNotSafe(NotificationCenterStore.data[user._id]) &&
																isSafe(Object.keys(NotificationCenterStore.data)) &&
																Object.keys(NotificationCenterStore.data).length > 2
															) {
																delete NotificationCenterStore.data[Object.keys(NotificationCenterStore.data)[0]]
															}

															NotificationCenterStore.messages[user._id] = []
															NotificationCenterStore.data[user._id] = NotificationCenterStore.messages[user._id]
														}
														NotificationCenterStore.chatMessage[user._id] = ""

														if (NotificationCenterStore.receiveMessages.includes(user._id)) {
															NotificationCenterStore.receiveMessages = NotificationCenterStore.receiveMessages.filter(
																(x) => x != user._id
															)
														}

														if (isNotEmpty(NotificationCenterStore.searchUser)) {
															NotificationCenterStore.searchUsers("")
														}
													}}
												>
													<Grid container alignItems="center" className="pt-1 pb-1">
														<Grid item>
															<i className="fas fa-user" style={{fontSize: "24px"}}></i>
														</Grid>
														<Grid item xs className="pl-3 pr-2">
															<Grid item className="dot">
																{user.email}
															</Grid>
														</Grid>
														<Grid item style={{width: "25px"}}>
															{NotificationCenterStore.receiveMessages.includes(user._id) ? (
																<span className="bgRedDanger snowWhite borderRadius-1 pl-1 pr-1">
																	{this.getNewMessageCount(
																		NotificationCenterStore.receiveMessages.filter((x) => x == user._id).length
																	)}
																</span>
															) : (
																<i
																	className={classnames("fas fa-circle", {
																		greenSuccess: NotificationCenterStore.onlineUsers.includes(user._id),
																		greyLight: !NotificationCenterStore.onlineUsers.includes(user._id)
																	})}
																></i>
															)}
														</Grid>
													</Grid>
												</div>
											)
										}
									})}
							</div>
							<Grid container>
								<Grid item xs className="textfield">
									<TextField
										autoFocus={true}
										value={NotificationCenterStore.searchUser}
										onChange={(e) => NotificationCenterStore.searchUsers(e.target.value)}
									/>
								</Grid>
							</Grid>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Grid container className="pa-1" onClick={() => NotificationCenterStore.openSidebar()}>
								<Grid item>
									{new Set(NotificationCenterStore.receiveMessages).size > 0 ? (
										<Badge badgeContent={new Set(NotificationCenterStore.receiveMessages).size} className="chatBadge">
											<i className={classnames("fal fa-users greyDefault")} style={{fontSize: "24px"}}></i>
										</Badge>
									) : (
										<i className={classnames("fal fa-users greyDefault")} style={{fontSize: "24px"}}></i>
									)}
								</Grid>
							</Grid>
						</React.Fragment>
					)}
				</div>
			</div>
		)
	}
}
