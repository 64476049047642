"use strict"

import {observable, action} from "mobx"
import {getSelectedOrgUnitID, exportToExcel} from "../../../global/helpers/actions"
import config from "../../../global/config/settings"

class ExportToXlsDialogStore {
	@observable isOpen = false
	// formRef = null

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		// UIStore.isFormSaving = false
		// if (isSafe(this.formRef)) {
		// 	this.formRef.reset()
		// }
	}

	@action ExportToXls(form) {
		let formValues = form.values()

		let req = {
			uri: `/amb/entity/providers/${getSelectedOrgUnitID()}/events`,
			filters: [
				{
					associated_column: "time_from",
					values: [
						{
							id_value: formValues.from
								.add(-1, "days")
								.endOf("day")
								.format(config.DB_DATETIME_FORMAT)
						}
					]
				},
				{
					associated_column: "time_to",
					values: [
						{
							id_value: formValues.to.format(config.DB_DATETIME_FORMAT)
						}
					]
				}
			]
		}

		if (isNotEmpty(formValues.status_id)) {
			req.filters.push({
				associated_column: "status_id",
				values: [
					{
						id_value: formValues.status_id
					}
				]
			})
		}

		if (isNotEmpty(formValues.provided_service) && formValues.provided_service.length > 0) {
			let values = []

			formValues.provided_service.forEach((ser) => {
				values.push({
					id_value: ser.id
				})
			})

			req.filters.push({
				associated_column: "provided_service_id",
				values: values
			})
		}

		exportToExcel(req, () => {
			this.close(), form.reset()
		})
	}
}

var singleton = new ExportToXlsDialogStore()
export default singleton
