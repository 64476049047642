"use strict"

import React from "react"
import {observer} from "mobx-react"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsAutocomplete from "../../../../../global/ui/xsInput/xsAutocomplete"
import XsChipForm from "../../../../../global/ui/xsChipForm/xsChipForm"
import XsChip from "../../../../../global/ui/xsChip/xsChip"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import AddIcon from "@material-ui/icons/Add"
import RouterStore from "../../../../../global/store/RouterStore"
import api from "../../../actions/api"
import {FormattedMessage} from "react-intl"
import PatientRecordStore from "../../../stores/PatientRecordStore"
import DeliveriesFormAddItems from "./deliveriesFormAddItems"
import GlobalStore from "../../../../../global/store/GlobalStore"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsDateTimePicker from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import moment from "moment"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegisterCacheStore from "../../../../../global/store/RegistersCacheStore"
import XsConfirmationDialog from "../../../../../global/ui/xsDialog/xsConfirmationDialog"

@observer
export default class DeliveriesForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showSecondChip: {}
		}
		if (isNotEmpty(props.form.$("deliveriesFormAddItems").value)) {
			PatientRecordStore.deliveriesAddItemChips = JSON.parse(props.form.$("deliveriesFormAddItems").value)
		}
	}

	componentDidMount() {
		// PatientRecordStore.loadProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
	}

	handleDeliveryChange = (form, name, code_ext) => {
		let values = form.get("value")
		if (isSafe(name)) {
			values.col1NameExt = name
		}
		if (isSafe(code_ext)) {
			values.col1CodeExt = code_ext
		}
		form.set(values)
	}

	handleDeliveryFocus = () => {
		if (
			isSafe(PatientRecordStore.deliveriesChipsForm) &&
			typeof PatientRecordStore.deliveriesChipsForm.handleFocus == "function"
		)
			PatientRecordStore.deliveriesChipsForm.handleFocus()
	}

	handlePayerTypeChanged = (form) => {
		let paymentType = form.fields.get("colPaymentType")
		let delivery_extension = form.fields.get("delivery_extension")
		if (isSafe(paymentType) && paymentType.value == "S") {
			if (isSafe(delivery_extension)) {
				delivery_extension.set("rules", "required")
			}
		} else if (isSafe(paymentType)) {
			if (isSafe(delivery_extension)) {
				delivery_extension.set("rules", "")
			}
		}
	}

	handleDeliveryExtensionChanged = (form, opening) => {
		let delivery_extension = form.fields.get("delivery_extension")
		let dateField = form.fields.get("colDateOfRequest")
		let delExtObj = null

		if (isNotEmpty(delivery_extension.value)) {
			delExtObj = GlobalStore.CL["deliveryExtension"].find((row) => row._id == delivery_extension.value)
			if (isSafe(delExtObj) && delExtObj.code_ext == "VYZI") {
				if (isSafe(dateField)) {
					dateField.set("rules", "required")
				}
			} else if (isSafe(delivery_extension)) {
				dateField.set("rules", "")
			}
		} else {
			dateField.set("rules", "")
		}
		if (!opening) {
			this.forceUpdate()
		}
	}

	handleInsureTypeChanged = (form) => {
		const insureeType = form.fields.get("col4")
		const sendingType = form.fields.get("colSendingType")
		logger("insure", insureeType)
		if (insureeType.value == "N" && isEmpty(sendingType.value)) {
			sendingType.value = "A"
		}
	}

	render() {
		return (
			<div>
				<SearchSelect
					items={PatientRecordStore.serviceData.map((resource) => {
						return {label: resource.name_ext, value: resource.code}
					})}
					onChange={(obj) => {
						PatientRecordStore.providedServicesSearchValue = obj
						if (
							!PatientRecordStore.checkForProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, "")) &&
							PatientRecordStore.readonlyform == false
						) {
							PatientRecordStore.setProvidedServices(
								this.props.form,
								obj,
								RouterStore.location.search.replace(/([^0-9])+/, ""),
								true
							)
						} else {
							PatientRecordStore.addProvidedServices(this.props.form, obj)
						}
					}}
					value={PatientRecordStore.providedServicesSearchValue}
					hideLabel={true}
					placeholder={<FormattedMessage id="Patient.form.patientrecord.deliveriesFromService" />}
					required
				/>
				<SearchSelect
					items={GlobalStore.CL["senderType"].map((resource) => {
						return {label: resource.name_ext, value: resource.code}
					})}
					onChange={(obj) => {
						PatientRecordStore.insertSendingTypeToDelivieries(this.props.form, obj)
					}}
					value={PatientRecordStore.defaultSendingType}
					hideLabel={true}
					placeholder={<FormattedMessage id="Common.label.insertSendingType" />}
					required
				/>
				<div>
					<FormattedMessage id="Patient.form.patientrecord.deliveries" />
				</div>
				<XsChipForm
					ref={(chipForm) => {
						PatientRecordStore.deliveriesChipsForm = chipForm
					}}
					field={this.props.chipFormField}
					configuration={{
						header: {
							chipsRenderer: (key, dataRow, clickHandler) => (
								<XsChip
									key={key}
									tooltip={isSafe(dataRow["col1NameExt"]) ? dataRow["col1NameExt"]["value"] : ""}
									label={isSafe(dataRow["col1CodeExt"]) ? dataRow["col1CodeExt"]["value"] : ""}
									onClick={(e) => clickHandler(false, e, key)}
								/>
							),

							addButtonRenderer: (clickHandler) => (
								<XsIconButton className="xs-success xs-outline" icon={<AddIcon />} onClick={clickHandler} />
							)
						},
						columns: [
							{
								name: "col1",
								label: <FormattedMessage id="Common.label.delivery" />,
								width: (field, colIdx, index, fields) => {
									return isNotEmpty(fields.get().colDateOfRequest.rules) &&
										fields.get().colDateOfRequest.rules.includes("required")
										? 1
										: 2
								},
								validationRule: "required",
								renderer: (field, index, fieldKey, onChangeHandler, returnFormData, fields, focusRef) => (
									<XsAutocomplete
										ref={(i) => focusRef(i, index)}
										field={field}
										minLengthForSearch="1"
										clearText
										// data={RegisterCacheStore.deliveriesRegister}
										api={api.loadDeliveries}
										searchFilter={"code_ext"}
										loadApi={api.loadSpecificDelivery}
										renderFooterInModal={true}
										inputRenderer={(obj) => obj.code_ext + "-" + obj.name_ext}
										saveValue={(obj) => obj.code}
										postAction={(obj) => {
											this.handleDeliveryChange(
												onChangeHandler(fieldKey),
												obj.code_ext + "-" + obj.name_ext,
												obj.code_ext
											)
										}}
										onMountWithoutLoad={() => {
											setTimeout(() => {
												this.handleDeliveryFocus()
											}, 0)
										}}
										tabindex={+index + 1}
										modalConfig={(textValue, clickHandler) => {
											return {
												columnDefs: {
													code_ext: {
														title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.code_ext" />,
														design: {
															width: "10%"
														},
														type: "string",
														dbName: "code_ext",
														filter: {
															gridWidth: 3,
															defaultValue: "",
															renderElement: "input"
														}
													},
													name_ext: {
														title: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.name_ext" />,
														type: "string",
														dbName: "search_column",
														design: {
															width: "90%"
														},
														filter: {
															gridWidth: 3,
															defaultValue: textValue,
															renderElement: "input"
														}
													}
												},
												options: {
													showCursor: true,
													onRowClick: (dataRow) => {
														clickHandler(dataRow)
													}
												}
											}
										}}
									/>
								)
							},
							{
								name: "col1NameExt",
								renderer: () => {},
								width: 0
							},
							{
								name: "col1CodeExt",
								renderer: () => {},
								width: 0
							},
							{
								name: "col2",
								label: <FormattedMessage id="Patient.form.patientrecord.diagnosis" />,
								width: 1,
								validationRule: "required|string",
								renderer: (field, index) => (
									<XsAutocompleteLocal
										field={field}
										minLengthForSearch="3"
										clearText
										data={RegisterCacheStore.diagnosisRegister}
										inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
										saveValue={(obj) => obj.code}
										firstLoad={() => {
											this.handleDeliveryFocus()
										}}
										tabindex={+index + 1}
									/>
								),
								defaultValue: () => {
									return isSafe(PatientRecordStore.diagnosisChipsForm) &&
										isSafe(PatientRecordStore.diagnosisChipsForm.getData()) &&
										isSafe(PatientRecordStore.diagnosisChipsForm.getData()["0"]) &&
										PatientRecordStore.diagnosisChipsForm.getData()["0"].col2 === "PRIMARY"
										? PatientRecordStore.diagnosisChipsForm.getData()["0"].col1
										: ""
								}
							},
							{
								name: "col3",
								label: <FormattedMessage id="Delivery.List.Count" />,
								width: 1,
								validationRule: "required|integer|min:1",
								renderer: (field, index) => <XsInput field={field} min="1" type="number" tabindex={+index + 1} />,
								defaultValue: 1
							},
							{
								name: "colPaymentType",
								label: <FormattedMessage id="Common.label.payerType" />,
								width: 1,
								validationRule: "required",
								defaultValue: "Z",
								renderer: (field, index, fieldKey, onChangeHandler) => (
									<SearchSelect
										field={field}
										items={GlobalStore.CL["payerType"]}
										tabindex="-1"
										getOptionLabel={(row) => `${row.code_ext} - ${row.name_ext}`}
										onChange={() => this.handlePayerTypeChanged(onChangeHandler(fieldKey))}
										onClear={() => setTimeout(() => this.handlePayerTypeChanged(onChangeHandler(fieldKey)), 0)}
									/>
								)
							},
							{
								name: "delivery_extension",
								label: <FormattedMessage id="Patient.form.patientrecord.deliveryTable.deliveryExtension" />,
								width: 1,
								validationRule: "",
								renderer: (field, index, fieldKey, onChangeHandler) => (
									<SearchSelect
										field={field}
										tooltipProp="description"
										items={GlobalStore.CL["deliveryExtension"]}
										onChange={() => this.handleDeliveryExtensionChanged(onChangeHandler(fieldKey))}
										onClear={() => setTimeout(() => this.handleDeliveryExtensionChanged(onChangeHandler(fieldKey)), 0)}
									/>
								)
							},
							{
								name: "col4",
								label: <FormattedMessage id="Delivery.List.InsureeType" />,
								width: 1,
								validationRule: "",
								renderer: (field, index, fieldKey, onChangeHandler) => (
									<SearchSelect
										field={field}
										items={GlobalStore.CL["insureeType"]}
										tabindex="-1"
										onChange={() => {
											this.handleInsureTypeChanged(onChangeHandler(fieldKey))
										}}
									/>
								)
							},
							{
								name: "colSendingType",
								label: <FormattedMessage id="Delivery.List.SenderTypyShort" />,
								width: 1,
								validationRule: "",
								renderer: (field) => <SearchSelect field={field} items={GlobalStore.CL["senderType"]} tabindex="-1" />,
								defaultValue: () => {
									if (isSafe(this.props.form) && this.props.form.$("hasDispensaryDiagnosis").value == true) {
										return "D"
									} else {
										return isSafe(this.props.form) && isNotEmpty(this.props.form.$("doctorPersonnelExpertiseId").value)
											? "S"
											: ""
									}
								}
							},
							{
								name: "col5",
								label: <FormattedMessage id="Delivery.List.RefundType" />,
								width: 1,
								validationRule: "",
								renderer: (field) => <SearchSelect field={field} items={GlobalStore.CL["refundType"]} tabindex="-1" />
							},
							{
								name: "col6",
								label: <FormattedMessage id="Delivery.List.MovementShort" />,
								width: 1,
								validationRule: "",
								renderer: (field) => <XsInput field={field} tabindex="-1" />
							},
							{
								name: "colDateOfRequest",
								label: <FormattedMessage id="Patient.form.patientsicknessabs.deliveries.requestdate" />,
								width: 1,
								validationRule: "",
								renderer: (field) =>
									isNotEmpty(field.get("rules")) && field.get("rules").includes("required") ? (
										<XsDateTimePicker field={field} showTimeSelect={false} tabindex="-1" />
									) : null
							},
							{
								name: "col7",
								label: <FormattedMessage id="Common.label.date" />,
								width: 1,
								validationRule: "required",
								renderer: (field) => <XsDateTimePicker field={field} showTimeSelect={false} tabindex="-1" />,
								defaultValue: () => {
									return moment().format(process.env.DB_DATETIME_FORMAT)
								}
							}
						],
						extraRow: {
							name: "addItems",
							label: "",
							renderer: (index, fieldKey) => (
								<DeliveriesFormAddItems
									index={index}
									fieldKey={fieldKey}
									deliveryFormField={this.props.chipFormField}
								/>
							)
						},
						options: {
							showLoadingOnOpening: true,
							disabled: false,
							isFormMode: false,
							showInCenter: true,
							maxWidth: true,
							plusButtonTabindex: (index) => {
								return +index + 1
							},
							onSubmit: (saveForm) => {
								PatientRecordStore.checkDeliverysForConstraints(saveForm)
							},
							onRemoveRow: (index, fieldKey) => {
								PatientRecordStore.deliveriesAddItemChips[fieldKey] = []
								setTimeout(() => {
									Object.keys(PatientRecordStore.deliveriesAddItemChipsForm).forEach((key) => {
										if (key >= fieldKey) {
											if (isSafe(PatientRecordStore.deliveriesAddItemChipsForm[key]))
												PatientRecordStore.deliveriesAddItemChipsForm[key].reloadFromData()
										}
									})
									PatientRecordStore.deliveriesAddItemChipsForm[fieldKey].reloadFromData()
								}, 0)
								//
								// if (
								// 	isSafe(PatientRecordStore.deliveriesAddItemChipsForm) &&
								// 	isSafe(PatientRecordStore.deliveriesAddItemChipsForm[index])
								// ) {
								// 	logger("bindujem", PatientRecordStore.deliveriesAddItemChipsForm)
								// 	PatientRecordStore.deliveriesAddItemChipsForm[index].bindData([])
								// }
							},
							onFormOpen: (rowsField, form) => {
								Object.keys(PatientRecordStore.deliveriesAddItemChipsForm).forEach((x) => {
									const idx = +x
									if (isSafe(PatientRecordStore.deliveriesAddItemChipsForm[idx]))
										PatientRecordStore.deliveriesAddItemChipsForm[idx].bindData(
											PatientRecordStore.deliveriesAddItemChips[idx]
										)
								})
								setTimeout(() => {
									let rows = rowsField.get()
									Object.keys(rows).forEach((key) => {
										this.handleDeliveryExtensionChanged(form.$(`rows[${key}]`), true)
									})
								}, 0)
							},
							onFormClose: () => {},
							onFormSave: (values) => {
								this.props.form.$("deliveriesFormAddItems").value = JSON.stringify(
									PatientRecordStore.deliveriesAddItemChips
								)
								PatientRecordStore.checkDeliverysAndSave(
									this.props.form,
									values,
									RouterStore.location.search.replace(/([^0-9])+/, ""),
									this.props.form.$("recordId").value
								)
							}
						},
						data: PatientRecordStore.deliveriesChips
					}}
				/>
				{GlobalStore.confirmationDialogOpened == "xsRewriteSendingType" && (
					<XsConfirmationDialog
						name="xsRewriteSendingType"
						type="danger"
						title={<FormattedMessage id="Common.label.warning" />}
						text={<FormattedMessage id="Common.label.rewriteSendingType" />}
						onConfirmation={() => GlobalStore.confirmationDialogParams.rewriteCallback()}
					/>
				)}
			</div>
		)
	}
}
