"use strict"

import api from "../../../modules/ambulance/actions/api"
import {getFilters} from "../../../global/helpers/api"
import {observable, action} from "mobx"
import moment from "moment"
import actionCard from "../actions/patientCard"
import DataStore from "../stores/DataStore"
import ProblemsStore from "./ProblemsStore"
import PatientSicknessAbsStore from "../stores/PatientSicknessAbsStore"
import UIStore from "../stores/UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import {
	getUserDoctorInfo,
	printReport,
	getSelectedOrgUnit,
	getSelectedOrgUnitID,
	getUserPersonnelID,
	getSelectedOrgUnitResourceID,
	getUser
} from "../../../global/helpers/actions"
import settings from "../../../global/config/settings"
import {ixsIsEmpty, ixsIsUndefined} from "../../../global/helpers/functions"
import DDaction from "../actions/patientCard"
// import actionPatientInfo from "../actions/patientInfo"
import WarningStore from "../../../global/store/WarningStore"
import renderHTML from "react-render-html"

class PatientRecordStore {
	@observable personel = []
	@observable clinicalValuesChips = []
	clinicalValuesChipsForm
	@observable diagnosisChips = []
	diagnosisChipsForm
	@observable recordID = null
	@observable record = null
	@observable recordLoaded = false
	@observable masterRecord = null
	@observable masterRecordLoaded = false
	@observable providers = []
	@observable dropedFiles = []
	deliveriesChipsForm
	@observable deliveriesChips
	deliveriesAddItemChipsForm = {}
	@observable deliveriesAddItemChips = {}
	@observable validationSnackbar = false
	@observable oupzsItems
	@observable substitutingDoctorOUPZSItems = []
	@observable providedServicesSearchValue
	@observable defaultSendingType
	@observable clinicalValueSets = []
	@observable clinicalSetsDialogOpen = false
	@observable clinicalSetDialogChipsForm
	@observable clinicalSetDialogChipsFormData
	@observable serviceData = []
	diagnosisAddButton = null

	textAreaCursors = {}

	@observable doctorLoadedFlag = false
	@observable deliveriesLoadedFlag = false
	@observable represcribeFlag = false

	clinicalValuesChipsFormFocus = null

	diagnosisId = {}
	bmiData = {}
	forceComponentRefresh = null

	@action clearData(name) {
		this[name] = {}
	}

	@action reset(beforeInsert) {
		// this.recordID = null
		this.personel = []
		this.providers = []
		this.record = null
		this.masterRecord = null
		this.masterRecordLoaded = false
		this.recordLoaded = false
		this.diagnosisChips = []
		this.diagnosisChipsForm = null
		this.clinicalValuesChips = []
		this.clinicalValuesChipsForm = null
		this.deliveriesChips = []
		this.deliveriesSearchId = {}
		this.diagnosisDeliveriesId = {}
		this.diagnosisId = {}
		this.bmiData = {}
		this.validationSnackbar = false
		this.dropedFiles = []
		this.oupzsItems = undefined
		this.deliveriesAddItemChipsForm = {}
		this.deliveriesAddItemChips = {}
		this.textAreaCursors = {}
		this.clinicalValuesChipsFormFocus = null
		this.substitutingDoctorOUPZSItems = null
		this.doctorLoadedFlag = false
		this.deliveriesLoadedFlag = false
		this.represcribeFlag = false
		this.forceComponentRefresh = null
		this.diagnosisAddButton = null

		if (!beforeInsert) {
			this.readonlyform = false
		}

		// this.clearProvidedServices(RouterStore.location.search.replace(/([^0-9])+/, ""))
	}

	@action deleteRecord(recordID, type = "EHR.Data.Ambulance") {
		const updateRequest = {
			_id: recordID,
			_type: type,
			_ref: false,
			active: false
		}
		api
			.updateRecord(updateRequest)
			.call()
			.then((response) => {
				if (response) {
					const providerID = getSelectedOrgUnitID()
					const patientID = DataStore.patientDTO.get("patientID")

					//Refresh PatientOverview
					actionCard.loadPatientOverview(providerID, patientID).then((overview) => {
						DataStore.setPatientEHR(overview)

						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					})

					// if (recordID == this.recordID) {
					this.reset()
					UIStore.patientCardTab = ""
					// }
					GlobalStore.setNotificationMessage("Patient.form.patientrecord.deletedraftsuccess")
				}
			})
	}

	@action validateDeliveryChips() {
		let isFormValid = true
		if (isSafe(this.deliveriesChipsForm)) {
			let deliveryChips = []
			let tmp = this.deliveriesChipsForm.getData()
			Object.keys(tmp).forEach((field) => {
				deliveryChips[+field] = tmp[field]
			})
			deliveryChips.forEach((row, idx) => {
				if (row.col1CodeExt == "H0006" || row.col1NameExt.includes("H0006")) {
					let addItems = this.deliveriesAddItemChips[idx]
					if (isSafe(addItems)) {
						let isValid = false
						addItems.forEach((addItem) => {
							if (
								isSafe(addItem.product) &&
								addItem.product.includes("SKOR") &&
								addItem.product.trim().length == 6 &&
								isFinite(addItem.product.trim().slice(4))
							) {
								isValid = true
							}
						})
						if (!isValid) {
							isFormValid = false
						}
					} else {
						isFormValid = false
					}
				}
			})
		}

		return isFormValid
	}

	@action checkDeliverysAndSaveForm(data, onSuccess, draftOnly = false, draftID = null) {
		//Zkontroluje ci Vykony obsahuju vykon H0006 ak ano k tomuto vykonu musi byt pripocitatelna polozka SKOR s nejakymi 2 cislami napriklad SKOR12 ak tam je ulozi formular s vykonmi inak sa otovi xsConfirmationDialog v ktorom sa pyta ci aj tak chces ulozit
		if (
			(isSafe(this.deliveriesChipsForm) &&
				isSafe(this.diagnosisChips) &&
				isSafe(this.diagnosisChips[0]) &&
				isNotEmpty(this.diagnosisChips[0].col1)) ||
			draftOnly
		) {
			let isFormValid = this.validateDeliveryChips()
			if (isFormValid) {
				this.saveForm(data, onSuccess, draftOnly, draftID)
			} else {
				UIStore.isFormSaving = false
				GlobalStore.openConfirmationDialog("xsDeliveriesValidationFailed", {
					deliveriesValidatinFailedCallback: () => {
						this.saveForm(data, onSuccess, draftOnly, draftID)
					}
				})
			}
		} else {
			this.validationSnackbar = true
			UIStore.isFormSaving = false
			GlobalStore.defaultFocusFields["patientRecordForm"].focus()
		}
	}

	@action saveForm(data, onSuccess, draftOnly = false, draftID = null) {
		let items = data.values()
		let openSicknessAbsForm = false
		const patientID = DataStore.patientDTO.get("patientID")
		const personnelID = getUserPersonnelID()
		const providerID = getSelectedOrgUnitID()
		let recordRequest = {
			_ref: false,
			_type: "EHR.Data.Ambulance",
			confidentiality_level: items.confidentialityLevel,
			version: {
				_type: "EHR.CL.Record.Version",
				_id: draftOnly ? "draft" : "final"
			},
			specific_rels: [
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: patientID,
						_type: "Entity.Data.Client"
					},
					type: "pat"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: personnelID,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "phy"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: providerID,
						_type: "Entity.Data.OrgUnit"
					},
					type: "ou"
				}
			],
			summaries: [
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.record.replace(/\t/g, "&nbsp;&nbsp;"),
					type: "AMB"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.OF.replace(/\t/g, "&nbsp;&nbsp;"),
					type: "OF"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.iexam.replace(/\t/g, "&nbsp;&nbsp;"),
					type: "IEXAM"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.Recomm.replace(/\t/g, "&nbsp;&nbsp;"),
					type: "Recomm"
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.Summary",
					content: items.diagnosisSummary.replace(/\t/g, "&nbsp;&nbsp;"),
					type: "DC"
				}
			],
			recorded_events: [],
			general_items: [
				{
					item: {
						_id: getUserDoctorInfo().expertise.cl_id,
						_type: "EHR.CL.Expertise"
					},
					type: "phyexp",
					_ref: false,
					_type: "EHR.Data.Record.Expertise"
				}
			],
			dispensarisation: items.dispensarisation,
			priority: items.recordPriority
		}

		//Edit draftu
		if (draftID) {
			recordRequest["_id"] = draftID
		}

		//Edit draftu id Personnel relacie
		if (draftID && items.personnelSpecificRelId) {
			const pos = recordRequest.specific_rels.map((row) => row.specific_rel._type).indexOf("EHR.Data.Entity.Personnel")
			recordRequest.specific_rels[pos]._id = items.personnelSpecificRelId
		}

		//Edit draftu id OrgUnit relacie
		if (draftID && items.orgunitSpecificRelId) {
			const pos = recordRequest.specific_rels.map((row) => row.specific_rel._type).indexOf("Entity.Data.OrgUnit")
			recordRequest.specific_rels[pos]._id = items.orgunitSpecificRelId
		}

		//Edit draftu id Client relacie
		if (draftID && items.clientSpecificRelId) {
			const pos = recordRequest.specific_rels.map((row) => row.specific_rel._type).indexOf("Entity.Data.Client")
			recordRequest.specific_rels[pos]._id = items.clientSpecificRelId
		}

		//Dátum vyšetrenia
		if (items.examdate) {
			recordRequest["recorded_events"].push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: items.examdate,
				type: {
					_id: "WRT",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		//Dátum požiadavky
		if (items.requestdate) {
			recordRequest["recorded_events"].push({
				_ref: false,
				_type: "EHR.Data.Record.Event",
				active: true,
				recorded_at: items.requestdate,
				type: {
					_id: "REQ",
					_type: "EHR.CL.Record.EventType"
				}
			})
		}

		//Nadradené EHR
		if (this.masterRecord) {
			recordRequest["master_records"] = [
				{
					_ref: false,
					_type: "EHR.Data.Record.RecordRelationship",
					master: this.masterRecord.record_id,
					type: "SND"
				}
			]
		}

		//Dg
		if (this.diagnosisChips) {
			this.diagnosisChips.map((dg, index) => {
				if (isSafe(dg) && dg.col1) {
					const newDg = {
						_ref: false,
						_type: "EHR.Data.Record.Diagnosis",
						item: {
							_id: dg.col1,
							_type: "EHR.CL.Record.Diagnosis"
						},
						type: "dgn",
						description: dg.col3,
						diagnosis_type: dg.col2,
						life_threatening: isSafe(dg.col4) ? dg.col4 : false,
						item_order: index
					}

					recordRequest["general_items"].push(newDg)
				}
			})
		}

		//Expertise
		if (items.doctorExpertiseId) {
			const expertiseObj = {
				item: {
					_id: items.doctorExpertiseId,
					_type: "EHR.CL.Expertise"
				},
				type: "sndphyexp",
				_ref: false,
				_type: "EHR.Data.Record.Expertise"
			}
			recordRequest["general_items"].push(expertiseObj)
		}

		//Odporúčací lekár
		if (items.doctorPersonnelId) {
			if (isNotEmpty(items.doctorPersonnelId)) {
				recordRequest["specific_rels"].push({
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: items.doctorPersonnelId,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "sndphy"
				})
			}
			if (isNotEmpty(items.doctorOUPZS)) {
				recordRequest["specific_rels"].push({
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel",
					specific_rel: {
						_id: items.doctorOUPZS,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "sndou"
				})
			}
		}

		//Zastupovany lekar
		if (items.substitutingPersonnelExpertiseId) {
			recordRequest["general_items"].push({
				item: {
					_id: items.substitutingExpertiseId,
					_type: "EHR.CL.Expertise"
				},
				type: "subphyexp",
				_ref: false,
				_type: "EHR.Data.Record.Expertise"
			})
			if (isNotEmpty(items.substitutingDoctor)) {
				recordRequest["specific_rels"].push({
					specific_rel: {
						_id: items.substitutingDoctor,
						_type: "EHR.Data.Entity.Personnel"
					},
					type: "subphy",
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel"
				})
			}
			if (isNotEmpty(items.substitutingDoctorOUPZS)) {
				recordRequest["specific_rels"].push({
					specific_rel: {
						_id: items.substitutingDoctorOUPZS,
						_type: "Entity.Data.OrgUnit"
					},
					type: "subou",
					_ref: false,
					_type: "EHR.Data.Record.SpecificRel"
				})
			}
		}
		//BMI
		if (this.bmiData.height || this.bmiData.weight || this.bmiData.BMI) {
			recordRequest["clinical_values"] = [
				{
					_ref: false,
					_type: "EHR.Data.Record.ClinicalValue",
					measure: {
						_id: "height",
						_type: "EHR.CL.Record.Measure"
					},
					unit: {
						_id: "CM",
						_type: "EHR.CL.Record.Unit"
					},
					value: this.bmiData.height
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.ClinicalValue",
					measure: {
						_id: "weight",
						_type: "EHR.CL.Record.Measure"
					},
					unit: {
						_id: "KG",
						_type: "EHR.CL.Record.Unit"
					},
					value: this.bmiData.weight
				},
				{
					_ref: false,
					_type: "EHR.Data.Record.ClinicalValue",
					measure: {
						_id: "bmi",
						_type: "EHR.CL.Record.Measure"
					},
					value: this.bmiData.BMI
				}
			]
		}

		//Klinické hodnoty
		if (this.clinicalValuesChips.length) {
			let dataFiltered = this.clinicalValuesChips.filter((i) => isSafe(i) && !(isEmpty(i.col1) || isEmpty(i.col2)))
			const newCV = dataFiltered.map((cv, idx) => {
				let obj = {
					_ref: false,
					_type: "EHR.Data.Record.ClinicalValue",
					item_order: idx,
					measure: {
						_id: cv.col1,
						_type: "EHR.CL.Record.Measure"
					},
					value: cv.col2.replace(",", ".")
				}

				if (isNotEmpty(cv.col3)) {
					obj.unit = {
						_id: cv.col3,
						_type: "EHR.CL.Record.Unit"
					}
				}

				if (isNotEmpty(cv.note)) {
					obj["_notes"] = [
						{
							_ref: false,
							_type: "IXS.Data.Note",
							type: "General",
							value: cv.note
						}
					]
				}

				if (isNotEmpty(cv.date)) {
					obj["measured_at"] = cv.date
				}

				return obj
			})
			if (recordRequest.clinical_values && recordRequest.clinical_values.length > 0) {
				recordRequest.clinical_values.push(...newCV)
			} else {
				recordRequest["clinical_values"] = newCV
			}
		}

		if (isSafe(items.note) && isNotEmpty(items.note)) {
			recordRequest["_notes"] = [
				{
					_ref: false,
					_type: "IXS.Data.Note",
					type: "groupprivate",
					value: htmlToPlain(items.note)
				}
			]
		}
		// Názov vyšetrenia
		if (isNotEmpty(items.examinationName)) {
			recordRequest.summaries.push({
				_ref: false,
				_type: "EHR.Data.Record.Summary",
				content: items.examinationName,
				type: "SEARCHNAME"
			})
		}
		//Naviazať na udalosť
		// if (eventID) {
		//   recordRequest["relateds"] = {
		//     "_ref": false,
		//     "_type": "EHR.Data.Record.Related",
		//     "rel_class_name": "Service.Data.Event",
		//     "rel_object_id": eventID,
		//     "type": "predecessor"
		//   };
		// }
		api
			.saveRecord(recordRequest, patientID)
			.call()
			.then((response) => {
				let overviewLoaded = false
				if (
					isSafe(response) &&
					isSafe(response.ixs_signer_data) &&
					isSafe(response.ixs_signer_data.payload) &&
					isSafe(response.ixs_signer_data.payload.text)
				) {
					const selectedOrgUnit = getSelectedOrgUnit()
					const user = getUser()
					const OUPZS = isSafe(selectedOrgUnit) ? selectedOrgUnit.identifiers.find((i) => i.type === "JRUZID") : null
					const spec = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_id : null
					const specVer = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_jruz_version : null
					const personnelJRUZId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_jruz_id : null
					const personnelExpClId = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_cl_id : null

					const patientClientInfo = isSafe(DataStore.patientDTO.get("patient").validInfo)
						? DataStore.patientDTO.get("patient").validInfo.client
						: null
					const JRUZIDObj = isSafe(patientClientInfo.identifiers)
						? patientClientInfo.identifiers.find((row) => row.type === "JRUZID")
						: null

					fetch(`${settings.GW_BASE_URL}/WriteEHRExtract`, {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json"
						},
						body: JSON.stringify({
							ClassName: "ZapisZaznamOVysetreni_v5",
							ExtId: response.record_external_id,
							EHRExtract: response.ixs_signer_data.payload.text,
							TimeCommitted: response.actual_time,
							AmbeePatientId: DataStore.patientDTO.get("patientID"),
							PatientIdentificationNumber: DataStore.patientDTO.get("patient").identifier,
							PatientJRUZId: isSafe(JRUZIDObj) ? JRUZIDObj.value : "",
							AmbeeAuthorizationToken: isSafe(user) ? `IXS ${user.ixstoken}` : null,
							AmbeeOrgUnitId: isSafe(selectedOrgUnit) ? selectedOrgUnit._id : null,
							AmbeePersonnelExpertiseCLId: isSafe(personnelExpClId) ? personnelExpClId : null,
							AmbeePersonnelId: getUserPersonnelID(),
							OrgUnitJRUZId: isSafe(OUPZS) && isSafe(OUPZS.value) ? OUPZS.value : null,
							PersonnelExpertiseJRUZId: isSafe(spec) ? spec : null,
							PersonnelExpertiseJRUZVersion: specVer,
							PersonnelJRUZId: isSafe(personnelJRUZId) ? personnelJRUZId : null,
							RecordSignerData: isSafe(response.record_signer_data)
								? response.record_signer_data.map((x) => {
										return {
											Ref: x._ref,
											Type: x._type,
											RecordClass: x.record_class,
											RecordExternalId: x.record_external_id,
											RecordId: x.record_id
										}
								  })
								: null
						})
					})
						.then((response) => response.text().then((text) => (text ? JSON.parse(text) : {})))
						.then((res) => {
							api.setSent(response.ixs_signer_data.ext_id).call()
							if (isSafe(res) && isNotEmpty(res.ErrorMessage)) {
								WarningStore.open("<b>Vytlačte papierový záznam!</b></br>" + res.ErrorMessage)
							}
						})
						.catch(() => {})
				}

				let deliveriesRequest = []
				if (isSafe(this.deliveriesChipsForm)) {
					const dchips = this.deliveriesChipsForm.getData()
					let idx = 0
					//dchips.map((delivery, idx) => {
					Object.keys(dchips).forEach((key) => {
						let delivery = dchips[key]
						//Ak bol zadaný výkon 71 po uložení otvoríme PN formulár a prepojíme ich
						if (delivery.col1CodeExt == "71") {
							openSicknessAbsForm = true
						}

						let deliveryRow = {
							_ref: false,
							_type: "EHR.Data.Record.SK.Delivery",
							item: {
								_id: delivery.col1,
								_type: "EHR.CL.Bill.SK.Delivery"
							},
							count: delivery.col3,
							type: "dlv",
							payer_type: isNotEmpty(delivery.colPaymentType) ? delivery.colPaymentType : null,
							insuree_type: isNotEmpty(delivery.col4) ? delivery.col4 : null,
							refund_type: isNotEmpty(delivery.col5) ? delivery.col5 : null,
							delivery_extension: isNotEmpty(delivery.delivery_extension) ? delivery.delivery_extension : null,
							movement: delivery.col6,
							delivery_date_time: moment(delivery.col7).format(process.env.DB_DATETIME_FORMAT),
							sender_type: isNotEmpty(delivery.colSendingType) ? delivery.colSendingType : null,
							request_date: isNotEmpty(delivery.colDateOfRequest)
								? moment.isMoment(delivery.colDateOfRequest)
									? delivery.colDateOfRequest.format(settings.DB_DATE_FORMAT)
									: moment(delivery.colDateOfRequest).format(settings.DB_DATE_FORMAT)
								: null
						}

						if (
							isNotEmpty(delivery.col2) ||
							(isSafe(this.diagnosisChipsForm) &&
								isSafe(this.diagnosisChipsForm.getData()) &&
								isSafe(this.diagnosisChipsForm.getData()["0"]) &&
								isNotEmpty(this.diagnosisChipsForm.getData()["0"].col1))
						) {
							deliveryRow["diagnosis"] = {
								_id: isNotEmpty(delivery.col2)
									? delivery.col2
									: isSafe(this.diagnosisChipsForm) &&
									  isSafe(this.diagnosisChipsForm.getData()) &&
									  isSafe(this.diagnosisChipsForm.getData()["0"]) &&
									  this.diagnosisChipsForm.getData()["0"].col2 === "PRIMARY"
									? this.diagnosisChipsForm.getData()["0"].col1
									: "",
								_type: "EHR.CL.Record.Diagnosis"
							}
						}

						deliveriesRequest.push(deliveryRow)

						if (isSafe(this.deliveriesAddItemChips[idx])) {
							deliveriesRequest[idx].sub_items = this.deliveriesAddItemChips[idx].map((chip) => {
								let product = {}
								let description = null
								let delivery_item = null
								let _type = "EHR.Data.Record.SK.Delivery.Product"
								if (chip.type == "adidrug" || chip.type == "adidev" || chip.type == "adicntr") {
									product["_id"] = chip.product
									if (chip.type == "adidrug") {
										product["_type"] = "EHR.CL.Medication.SK.Drug"
									} else if (chip.type == "adidev") {
										product["_type"] = "EHR.CL.Medication.SK.MedicalDevice"
									} else if (chip.type == "adicntr") {
										product = null
										delivery_item = {
											_id: chip.product,
											_type: "EHR.CL.Bill.SK.DeliveryItem"
										}
										_type = "EHR.Data.Record.Delivery.CustomItem"
									}
								} else {
									product = null
									description = chip.product
								}
								return {
									_ref: false,
									type: "adi",
									sub_type: chip.type,
									quantity: chip.quantity,
									unit_price: chip.unit,
									product,
									delivery_item,
									description,
									_type
								}
							})
						}
						idx++
					})
					overviewLoaded = true
					api
						.saveDeliveries(deliveriesRequest, response._id)
						.call()
						.then(() => {
							actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
								DataStore.setPatientEHR(overview)
								if (isSafe(overview) && isSafe(overview.filters)) {
									let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
									if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
										DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
									}
								}
							})
							if (items.print) {
								this.printRecord(response._id, DataStore.patientDTO.get("patient"))
							}
						})
				}

				if (isSafe(this.dropedFiles) && this.dropedFiles.length) {
					let req = []
					let files = this.dropedFiles
					let binaryDataArr = []
					let getdata = function(index) {
						let data = files[index]
						let binaryData
						var reader = new FileReader()
						reader.readAsBinaryString(data)
						reader.onloadend = function() {
							binaryData = reader.result
							binaryDataArr.push(binaryData)
							req.push({
								_id: isNotEmpty(data._id) ? data._id : null,
								_ref: false,
								_type: "IXS.Data.Attachment",
								active: true,
								rel_class_name: "EHR.Data.Ambulance",
								rel_object_id: response._id,
								content_type: isNotEmpty(data.type) ? data.type : "application/octet-stream",
								// "content": binaryData.split(",")[1],
								type: "General",
								file_name: data.name,
								size: data.size
							})
							if (files.length > index + 1) getdata(index + 1)
							else {
								api
									.saveAttachment(req)
									.call()
									.then((res) => {
										Object.keys(res).forEach((key) => {
											if (isSafe(files[key])) {
												files[key]._id = res[key]._id
											}
											api.saveAttachmentContent(res[key]._id, binaryDataArr[+key]).call()
										})
									})
							}
						}
					}
					getdata(0)
				}

				//Tlač dekurzu bez zadaných výkonov
				if (items.print && !isSafe(this.deliveriesChipsForm)) {
					this.printRecord(response._id, DataStore.patientDTO.get("patient"))
				}

				if (response._id && onSuccess) {
					onSuccess(response)
				}
				if (!draftOnly) {
					//Resetovanie
					this.deliveriesChips = []
					this.diagnosisChips = []
					this.clinicalValuesChip = []
					this.readonlyform = false
					this.masterRecord = null
					this.masterRecordLoaded = false
					this.bmiData = {}
					this.diagnosisChipsForm = null
					this.clinicalValuesChipsForm = null
					this.deliveriesAddItemChipsForm = {}
					this.deliveriesAddItemChips = {}
					this.dropedFiles = []
					this.recordLoaded = false
				}
				UIStore.isFormSaving = false
				// data.reset()
				window.scrollTo(0, 0)
				GlobalStore.setNotificationMessage(
					draftOnly ? "Patient.form.patientrecord.savedraftsuccess" : "Patient.form.patientrecord.savesuccess",
					`DEK-${response._id}`
				)
				if (!draftOnly) {
					UIStore.patientCardTab = ""
				}
				if (!overviewLoaded) {
					actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
						DataStore.setPatientEHR(overview)
						let filterDiag = overview.filters.filter((x) => x.associated_column === "diagnosis")
						if (isSafe(filterDiag) && isSafe(filterDiag[0])) {
							DataStore.patientDTO.set("diagnosis", filterDiag[0].values)
						}
					})
				}

				//Ak bol zadaný výkon 71 otvoríme PN formulár a prepojíme ho s novým dekurzom
				if (openSicknessAbsForm) {
					this.openSicknessAbsForm(response._id)
				}
			})
	}

	async openSicknessAbsForm(recordID) {
		PatientSicknessAbsStore.recordLoaded = false
		await PatientSicknessAbsStore.loadRecord(recordID, true)
		UIStore.patientCardTab = "sicknessabs"
	}

	@action loadRecordData(id, readonly = false) {
		// this.recordID = id
		this.readonlyform = readonly
	}

	@action loadRecord(record) {
		this.record = record
		// this.recordID = record ? record.id : null
	}

	@action async insertFormData(form, forceLoadDelivieries) {
		let recordId = form.$("recordId").value
		form.reset()
		this.reset(true)
		if (isNotEmpty(recordId)) {
			form.$("recordId").value = recordId
		}
		this.recordLoaded = true
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
		form.$("doctorPersonnelExpertiseId").focus()
		if (isNotEmpty(form.$("recordId").value)) {
			let recordData = await api.loadRecord(form.$("recordId").value, "L3").call()
			const isDraft = recordData.version === "draft"
			let doctorExpertiseId = ""
			let doctorPersonnelId = ""
			let subDoctorPersonnelId = ""
			let subDoctorExpertiseId = ""
			this.recordLoaded = true
			form.$("version").set("value", recordData.version)
			form.$("dispensarisation").set("value", recordData.dispensarisation)
			form.$("recordPriority").set("value", isSafe(recordData.priority) ? recordData.priority.code : "")
			form.$("confidentialityLevel").set("value", recordData.confidentiality_level)

			const amb = recordData.summaries.find((i) => i.type._id === "AMB")
			const OF = recordData.summaries.find((i) => i.type._id === "OF")
			const iexam = recordData.summaries.find((i) => i.type._id === "IEXAM")
			const diagnosisSummary = recordData.summaries.find((i) => i.type._id === "DC")
			const recomm = recordData.summaries.find((i) => i.type._id === "Recomm")
			const examinationName = recordData.summaries.find((i) => i.type._id === "SEARCHNAME")
			const note = isSafe(recordData._notes) ? recordData._notes.find((i) => i.type === "groupprivate") : null
			const examdate = isSafe(recordData.recorded_events)
				? recordData.recorded_events.find((i) => i.type == "WRT")
				: null

			form.$("record").set("value", isSafe(amb) ? htmlStyleToPlain(amb.content) : "")
			form.$("OF").set("value", isSafe(OF) ? htmlStyleToPlain(OF.content) : "")
			form.$("iexam").set("value", isSafe(iexam) ? htmlStyleToPlain(iexam.content) : "")
			form
				.$("diagnosisSummary")
				.set("value", isSafe(diagnosisSummary) ? htmlStyleToPlain(diagnosisSummary.content) : "")
			form.$("Recomm").set("value", isSafe(recomm) ? htmlStyleToPlain(recomm.content) : "")
			form.$("examinationName").set("value", isSafe(examinationName) ? examinationName.content : "")
			form.$("note").set("value", isSafe(note) ? htmlStyleToPlain(note.value) : "")
			// pri znovupredpisat je datum dnesny a pri bodovani je z DB
			if (this.readonlyform == true) {
				form.$("examdate").set("value", isSafe(examdate) ? examdate.recorded_at : "")
			}

			//Pri loade draftu loadujem aj Id relacii v specific_rels
			if (isDraft) {
				const personel = recordData.specific_rels.find((row) => row.specific_rel._type == "EHR.Data.Entity.Personnel")
				const orgunit = recordData.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.OrgUnit")
				const client = recordData.specific_rels.find((row) => row.specific_rel._type == "Entity.Data.Client")

				if (isSafe(personel)) {
					form.$("personnelSpecificRelId").value = personel._id
				}

				if (isSafe(orgunit)) {
					form.$("orgunitSpecificRelId").value = orgunit._id
				}

				if (isSafe(client)) {
					form.$("clientSpecificRelId").value = client._id
				}
			}

			form
				.$("requestdate")
				.set(
					"value",
					isSafe(recordData.recorded_events) && isSafe(recordData.recorded_events.find((i) => i.type == "REQ"))
						? recordData.recorded_events.find((i) => i.type == "REQ").recorded_at
						: ""
				)

			if (isSafe(recordData.specific_rels) && recordData.specific_rels.length) {
				let doctor = recordData.specific_rels.find((i) => i.type === "sndphy")
				if (isSafe(doctor)) {
					doctorPersonnelId = doctor.specific_rel._id
				}
				let oupzs = recordData.specific_rels.find((i) => i.type === "sndou")
				if (isSafe(oupzs)) {
					form.$("doctorOUPZS").set("value", oupzs.specific_rel._id)
				}
				let subDoctor = recordData.specific_rels.find((i) => i.type === "subphy")
				if (isSafe(subDoctor)) {
					subDoctorPersonnelId = subDoctor.specific_rel._id
				}
				let subOupzs = recordData.specific_rels.find((i) => i.type === "subou")
				if (isSafe(subOupzs)) {
					form.$("substitutingDoctorOUPZS").set("value", subOupzs.specific_rel._id)
				}
			}

			if (isSafe(recordData.general_items) && recordData.general_items.length) {
				let tmpData = []
				let tmpDoctor = recordData.general_items.find(
					(obj) => obj._type == "EHR.Data.Record.Expertise" && obj.type == "sndphyexp"
				)
				if (isSafe(tmpDoctor) && isSafe(tmpDoctor.item)) {
					doctorExpertiseId = tmpDoctor.item._id
				}

				let tmpSubDoctor = recordData.general_items.find(
					(obj) => obj._type == "EHR.Data.Record.Expertise" && obj.type == "subphyexp"
				)
				if (isSafe(tmpSubDoctor) && isSafe(tmpSubDoctor.item)) {
					subDoctorExpertiseId = tmpSubDoctor.item._id
				}

				tmpData = recordData.general_items.filter((data) => isSafe(data.diagnosis_type))
				tmpData = tmpData.sort((a, b) => (a.diagnosis_type._id === "PRIMARY" ? -1 : a.item_order - b.item_order))
				this.diagnosisChips = tmpData.map((i) => {
					// this.diagnosisId[idx] = i.item._id
					return {
						col1: i.item._id,
						col1NameExt: i.item.code_ext + "-" + i.item.name_ext,
						col2: i.diagnosis_type.code,
						col3: i.description,
						col4: isSafe(i.life_threatening) ? i.life_threatening : false
					}
				})

				if (this.diagnosisChips.length > 0) {
					form.$("diagnosisChipsForm").value = JSON.stringify(this.diagnosisChips)
				} else {
					form.$("diagnosisChipsForm").value = JSON.stringify([
						{col1: "", col1NameExt: "", col2: "PRIMARY", col3: "", col4: false}
					])
				}

				let expertiseObj = recordData.general_items.find((i) => i.type === "sndphyexp")
				if (isSafe(expertiseObj)) {
					form.$("doctorExpertiseId").set("value", expertiseObj.item._id)
				}

				let subExpertiseObj = recordData.general_items.find((i) => i.type === "subphyexp")
				if (isSafe(subExpertiseObj)) {
					form.$("substitutingExpertiseId").set("value", subExpertiseObj.item._id)
				}
			}
			//Load specifickeho lekara na zaklade jeho expertiseId a  presonnelId
			if (isNotEmpty(doctorExpertiseId) && isNotEmpty(doctorPersonnelId)) {
				let filters = getFilters([
					`expertise_id=${doctorExpertiseId}`,
					`personnel_id=${doctorPersonnelId}`,
					`provider_id=${form.$("doctorOUPZS").value}`
				])
				let response = await api.loadPersonnel(filters).call()
				if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
					form.$("doctorPersonnelExpertiseId").set("value", response.rows[0].personnel_expertise_id)
					form.$("doctorPersonnelId").set("value", response.rows[0].personnel_id)
				}
			}

			if (isNotEmpty(subDoctorExpertiseId) && isNotEmpty(subDoctorPersonnelId)) {
				let filters = getFilters([
					`expertise_id=${subDoctorExpertiseId}`,
					`personnel_id=${subDoctorPersonnelId}`,
					`provider_id=${form.$("substitutingDoctorOUPZS").value}`
				])
				let response = await api.loadPersonnel(filters).call()
				if (isSafe(response) && isSafe(response.rows) && response.rows.length) {
					form.$("substitutingPersonnelExpertiseId").set("value", response.rows[0].personnel_expertise_id)
					form.$("substitutingDoctor").set("value", response.rows[0].personnel_id)
				}
			}

			if (isSafe(recordData.clinical_values) && recordData.clinical_values.length) {
				this.clinicalValuesChips = recordData.clinical_values
					.filter((i) => i.measure.code != "bmi")
					.map((i) => {
						return {
							col1: i.measure.code,
							col1NameExt: i.measure.name_ext,
							col2: ("" + i.value).replace(".", ","),
							col3: isSafe(i.unit) ? i.unit.code : "",
							note: isSafe(i._notes) && isSafe(i._notes[0]) ? i._notes[0].value : "",
							date: isSafe(i.measured_at) ? i.measured_at : null
						}
					})
			}
			if (this.readonlyform == true || isDraft || forceLoadDelivieries) {
				let deliveriesChips = {}
				let deliveriesAddItemChips = {}
				if (isSafe(recordData.general_items) && recordData.general_items.length) {
					let tmpDiagnosis = recordData.general_items.filter(
						(i) => isSafe(i.item) && i.item._type === "EHR.CL.Bill.SK.Delivery"
					)
					deliveriesChips = tmpDiagnosis.map((data, idx) => {
						if (isSafe(data.sub_items) && data.sub_items.length) {
							data.sub_items.sort((a, b) => {
								return a.sub_item_order - b.sub_item_order
							})
							this.deliveriesAddItemChips[idx] = data.sub_items.map((x) => {
								let productId = ""
								if (x.sub_type == "adidrug" || x.sub_type == "adidev") {
									if (isSafe(x.product)) {
										productId = x.product._id
									}
								} else if (x.sub_type == "adicntr") {
									if (isSafe(x.delivery_item)) {
										productId = x.delivery_item._id
									}
								} else {
									productId = x.description
								}

								return {
									type: x.sub_type,
									typeNameExt:
										isSafe(GlobalStore.CL["generalSubItemType"]) &&
										isSafe(GlobalStore.CL["generalSubItemType"].find((i) => i._id == x.sub_type))
											? GlobalStore.CL["generalSubItemType"].find((i) => i._id == x.sub_type).name_ext
											: "",
									product: productId,
									quantity: x.quantity,
									unit: x.unit_price,
									unitEndText: isSafe(x.product) ? x.product.basic_statement_unit : ""
								}
							})
						} else {
							deliveriesAddItemChips[idx] = []
						}

						let col7Data = null
						if (isDraft || forceLoadDelivieries) {
							col7Data = moment()
						} else if (isSafe(data.delivery_date_time)) {
							col7Data = moment(data.delivery_date_time).format(process.env.DB_DATETIME_FORMAT)
						} else {
							null
						}

						return {
							col1: data.item._id,
							col2: isSafe(data.diagnosis) && isNotEmpty(data.diagnosis._id) ? data.diagnosis._id : "",
							col3: +data.count,
							colPaymentType: isSafe(data.payer_type) ? data.payer_type : "",
							col4: isSafe(data.insuree_type) ? data.insuree_type : "",
							col5: isSafe(data.refund_type) ? data.refund_type : "",
							col6: isSafe(data.movement) ? data.movement : "",
							delivery_extension: isSafe(data.delivery_extension) ? data.delivery_extension._id : "",
							col7: isSafe(col7Data) ? col7Data : "",
							colSendingType: isSafe(data.sender_type) ? data.sender_type : "",
							colDateOfRequest: isSafe(data.request_date) ? data.request_date : ""
							// col7: ""
						}
					})
				}

				// this.deliveriesChips = deliveriesChips
				// this.deliveriesAddItemChips = deliveriesAddItemChips

				if (isSafe(deliveriesChips)) {
					form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChips)
					// this.deliveriesChipsForm.bindData()
				}
				let doctor = getUserDoctorInfo()
				if (isSafe(doctor.expertise)) {
					//ak  ma  lekar  jeden  z  kodov  tak  citlivost  v  dekurze  je  vysoka  EMA-8505
					if (["144", "005", "074", "105", "223", "352", "073", "367"].includes(doctor.expertise.code_ext)) {
						form.$("confidentialityLevel").value = "high"
						form.$("confidentialityLevelDisable").value = true
					}
				}
				await this.loadChipText(form)
			}

			form.$("clinicalValuesChipsForm").value = JSON.stringify(this.clinicalValuesChips)
			// this.diagnosisChipsForm.bindData(this.diagnosisChips)
			// this.clinicalValuesChipsForm.bindData(this.clinicalValuesChips)

			// GlobalStore.refresh = Date.now()
			if (recordData.version == "draft") {
				recordData._attachments.forEach(async (attachment) => {
					let response = await api.loadAttachmentData(attachment.api_url).call()
					response.blob().then((blob) => {
						let tmp = new File([blob], attachment.file_name, {
							type: attachment.content_type
						})
						if (recordData.version == "draft") {
							tmp._id = attachment._id
						}
						tmp.api_url = attachment.api_url
						this.dropedFiles.push(tmp)
					})
				})
			}
			this.represcribeFlag = true
			// if (this.readonlyform == false) {
			// 	actionPatientInfo.saveFormToStorage(null, form, "PatientRecordForm", 0, null)
			// }
		}
	}

	@action async printRecord(recordID, pacInfo) {
		UIStore.isFormSaving = true
		await GlobalStore.refreshCodeListsSync(["sicknessAbsenceTypes"])
		api
			.loadRecord(recordID, "L4")
			.call()
			.then((recordData) => {
				const doctorInfo = getUserDoctorInfo()
				const orgUnit = getSelectedOrgUnit()
				let skipRecord = []

				const nextEventName = DataStore.patientDTO.get("nextEventName") ? DataStore.patientDTO.get("nextEventName") : ""
				const nextVisit = DataStore.patientDTO.get("nextVisit")
					? `<br/><b>Najbližšia návšteva:</b> ${moment(DataStore.patientDTO.get("nextVisit")).format(
							"DD.MM.YYYY, HH:mm"
					  )} ${nextEventName}`
					: ""

				const pacAddress = pacInfo.validInfo.address
				let printAddress = ""
				if (pacInfo.validInfo && pacAddress) {
					printAddress += `${pacAddress.city ? `${pacAddress.city}, ` : ""}`
					printAddress += `${
						pacAddress.street
							? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
							: ""
					}`
					printAddress += ` ${pacAddress.zip}`
				}

				let printValues = {
					name: `${pacInfo.full_name}`,
					identifier: pacInfo.identifier,
					address: pacInfo.validInfo && pacAddress ? printAddress : "",
					doctorcode: doctorInfo ? doctorInfo.code : "",
					doctorname: doctorInfo ? doctorInfo.name : "",
					dgs: "",
					dgs_plain: "<b>Diagnóza:</b> ",
					cvs: "",
					nextVisit: nextVisit,
					anamnesis: "",
					medication: "",
					medication_plain: "",
					prescription: "",
					prescription_plain: "",
					summaries_plain: "",
					request: "",
					request_plain: "",
					cvshidden: "hidden"
				}

				//OrgUnit
				if (orgUnit) {
					const primaryAddress = doctorInfo.address
					const businessContact = doctorInfo.contact
					const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
					printValues["orgunitname"] = orgUnit.name_ext
					printValues["orgunitaddress"] = primaryAddress ? primaryAddress.full_address : ""
					if (businessContact) {
						printValues["orgunitemail"] = businessContact.email ? businessContact.email : ""
						printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
							businessContact.phone ? businessContact.phone : ""
						}`
					}
					printValues["orgunitcode"] = providerCode ? providerCode.value : ""
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				//Date and Time
				const dateAndTime = recordData.recorded_events.find((re) => re.type === "WRT")
				if (dateAndTime) {
					printValues.dateTime = moment(dateAndTime.recorded_at).format("DD.MM.YYYY, HH:mm")
				}

				//Dg
				//Primary
				const primaryDg = recordData.general_items.find(
					(dg) => dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id === "PRIMARY"
				)
				if (primaryDg) {
					printValues.dgs += `${primaryDg.item.code_ext} - ${primaryDg.item.name_ext}<br/>`
					printValues.dgs_plain += `${primaryDg.item.code_ext} - ${primaryDg.item.name_ext}`
				}

				//Secondary and others
				recordData.general_items
					.filter(
						(dg) => dg._type === "EHR.Data.Record.Diagnosis" && dg.diagnosis_type && dg.diagnosis_type._id !== "PRIMARY"
					)
					.map((dg) => {
						printValues.dgs += `${dg.item.code_ext} - ${dg.item.name_ext}<br/>`
						printValues.dgs_plain += `, ${dg.item.code_ext} - ${dg.item.name_ext}`
					})

				//Klinické hodnoty
				if (recordData.clinical_values && recordData.clinical_values.length > 0) {
					printValues.cvshidden = ""
					recordData.clinical_values
						.sort((a, b) => a.item_order - b.item_order)
						.map((cv, idx) => {
							const unit = cv.unit ? cv.unit.code_ext : ""
							const notes = cv._notes && cv._notes.length > 0 ? cv._notes.map((n) => n.value).join(", ") : ""
							const measured_at = cv.measured_at ? `(${moment(measured_at).format("DD.MM.YYYY")})` : ""
							if (idx !== 0) {
								printValues.cvs += ",   "
							}
							printValues.cvs += `${cv.measure.name_ext} - ${
								typeof cv.value === "number" ? cv.value.toString().replace(".", ",") : cv.value.replace(".", ",")
							} ${unit} ${notes} ${measured_at}`
						})
				}

				const ehrs = DataStore.patientDTO.get("patientEHR")

				//Pridružené anamnézy, preskripcia, PN začína alebo končí príp. žiadanka v rámci rovnakého dňa
				if (ehrs && ehrs.rows) {
					//Anamnéza - IBA JEDNA posledná za zvolené oddelenie na základe isnull(created_at, crt_at)
					const anams = ehrs.rows
						.filter(
							(a) => a.type_code.toUpperCase() === "ANA" && a.provider_card.org_unit_id === getSelectedOrgUnitID()
						)
						.sort((a, b) => {
							const va = a.created_at === null ? a.crt_at : a.created_at
							const vb = b.created_at === null ? b.crt_at : b.created_at
							return moment(vb).toDate() - moment(va).toDate()
						})

					if (anams && anams.length > 0) {
						const anam = anams[0]
						if (anam.data) {
							anam.data.map((sum) => {
								if (sum.content) {
									printValues[
										"anamnesis"
									] += `<span class="twelve"><b>${sum.type.name_ext}:</b><p>${sum.content}</p></span><br/>`
								}
							})
						}
					}

					//Soc. poist. - všetky ak majú vyplnenú platnosť OD
					const pns = ehrs.rows.filter(
						(r) =>
							r.sickness_abs.validity &&
							r.sickness_abs.sub_type &&
							r.sickness_abs.validity.from &&
							((moment(recordData.created_at).isAfter(moment(r.sickness_abs.validity.from), "day") &&
								r.sickness_abs.validity.to === null) ||
								moment(recordData.created_at).isBetween(
									r.sickness_abs.validity.from,
									r.sickness_abs.validity.to,
									"day",
									"[]"
								))
					)

					if (pns && pns.length > 0) {
						printValues["sicknessabs"] = ""
						printValues["sicknessabs_plain"] = ""
						pns.map((pn) => {
							const subtype = pn.sickness_abs.sub_type
								? GlobalStore.CL["sicknessAbsenceTypes"].find((sat) => sat._id === pn.sickness_abs.sub_type)
								: null
							if (subtype) {
								const dateFrom = pn.sickness_abs.validity.from
									? moment(pn.sickness_abs.validity.from, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
									: ""
								const dateTo = pn.sickness_abs.validity.to
									? moment(pn.sickness_abs.validity.to, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
									: ""
								printValues["sicknessabs"] += `<br/><p><b>${subtype.name_ext}</b> od ${dateFrom} ${
									dateTo ? `do ${dateTo}` : ""
								} ${pn.sickness_abs.number}</p>`

								printValues["sicknessabs_plain"] += ` <b>${subtype.code_ext}</b> od ${dateFrom} ${
									dateTo ? `do ${dateTo}` : ""
								} ${pn.sickness_abs.number}`
							}
						})
					}

					//Žiadanka - Všetky ak boli vytvorené v rovnaký deň ako dekurz
					const reqs = ehrs.rows.filter(
						(r) =>
							(r.type_code.toUpperCase() === "OV" || r.type_code.toUpperCase() === "ZV") &&
							moment(r.created_at).isSame(moment(recordData.created_at), "day")
					)

					if (reqs && reqs.length > 0) {
						printValues.request = ""
						reqs.map((req) => {
							skipRecord.push(req.record_id)

							if (req.request) {
								let pactivity = ""
								if (req.request.professional_activity_name_ext) {
									pactivity = `, ${req.request.professional_activity_name_ext}`
								}
								printValues.request += `<br/><p><b>${req.type_name}:</b></p>
				<p>Odbornosť: ${req.request.receiving_expertise_code_ext} - ${req.request.receiving_expertise_name_ext}${pactivity}</p>`

								printValues.request_plain += `<b>${req.type_name}:</b> Odbornosť: ${req.request.receiving_expertise_code_ext} - ${req.request.receiving_expertise_name_ext}${pactivity} `
							}
						})
					}

					//Prescripcia - všetká finálna v rovnaký deň ako dekurz
					const rxs = ehrs.rows.filter(
						(r) =>
							r.type_code.toUpperCase() === "RX" &&
							r.version === "final" &&
							moment(r.created_at).isSame(moment(recordData.created_at), "day")
					)
					if (rxs && rxs.length > 0) {
						printValues["prescription"] += `<p><b>Preskripcia:</b></p>`
						printValues["prescription_plain"] += ` <b>Preskripcia: </b>`
						rxs.map((r) => {
							const prescriptionName = htmlToPlain(r.name)
							printValues["prescription"] += `<p>${prescriptionName}</p>`
							printValues["prescription_plain"] += `${prescriptionName} `
						})
					}

					//Medikácia - v rovnaký deň ako dekurz
					const meds = ehrs.rows.filter(
						(r) =>
							r.type_code.toUpperCase() === "POD" && moment(r.created_at).isSame(moment(recordData.created_at), "day")
					)
					if (meds && meds.length > 0) {
						printValues["medication"] += `<br/><b>Medikácia:</b>`
						printValues["medication_plain"] += ` <b>Medikácia: </b>`
						meds.map((med) => {
							const m = med.medication
							let expdate = ""
							if (m.expiration_date) {
								expdate = moment(m.expiration_date).format("M/Y")
							}
							const medicationText = `${m.product_printable_name} ${m.batch_number} ${expdate}`
							printValues["medication"] += `<br/>${medicationText}`
							printValues["medication_plain"] += `${medicationText} `
						})
					}
				}

				//Odporúčajúci lekár
				const recomDoctorSR = recordData.specific_rels.find((sr) => sr.type === "sndphy")
				const recomDoctor = recomDoctorSR
					? `<p>Odosielajúci lekár: ${recomDoctorSR.specific_rel.entity.full_name}</p>`
					: ""

				//Zastupovaný lekár
				const subDoctorSR = recordData.specific_rels.find((sr) => sr.type === "subphy")
				const subDoctor = subDoctorSR ? `<p>Zastupovaný lekár: ${subDoctorSR.specific_rel.entity.full_name}</p>` : ""

				//Naviazané výkony v riadku
				let deliveriesText = ""
				let dcnt = 0
				const deliveries = recordData.general_items.filter((gi) => gi._type === "EHR.Data.Record.SK.Delivery")
				deliveries.length > 0 &&
					deliveries.map((d) => {
						if (d.item) {
							dcnt++
							deliveriesText += `${dcnt === 1 ? "" : ", "}${d.item.code_ext}`
						}
					})

				//Summaries
				const sumAMB = recordData.summaries.find((sum) => {
					return sum.type._id === "AMB"
				})
				const sumRecomm = recordData.summaries.find((sum) => {
					return sum.type._id === "Recomm"
				})
				const sumOF = recordData.summaries.find((sum) => {
					return sum.type._id === "OF"
				})
				const sumDC = recordData.summaries.find((sum) => {
					return sum.type._id === "DC"
				})
				const sumIEXAM = recordData.summaries.find((sum) => {
					return sum.type._id === "IEXAM"
				})

				const sumAMBText = sumAMB && sumAMB.content && !ixsIsEmpty(sumAMB.content) ? `${sumAMB.content}` : null
				const sumOFText = sumOF && sumOF.content && !ixsIsEmpty(sumOF.content) ? `${sumOF.content}` : null
				const sumDCText = sumDC && sumDC.content && !ixsIsEmpty(sumDC.content) ? `${sumDC.content}` : null
				const sumIEXAMText =
					sumIEXAM && sumIEXAM.content && !ixsIsEmpty(sumIEXAM.content) ? `${sumIEXAM.content}` : null
				const sumRecommText =
					sumRecomm && sumRecomm.content && !ixsIsEmpty(sumRecomm.content) ? `${sumRecomm.content}` : null

				printValues["summaries_plain"] += sumOFText ? `<b>Subjektívne ťažkosti:</b> ${htmlToPlain(sumOFText)} ` : ""
				printValues["summaries_plain"] += sumAMBText ? `<b>Objektívny nález:</b> ${htmlToPlain(sumAMBText)} ` : ""
				printValues["summaries_plain"] += sumIEXAMText
					? `<b>Zobrazovacie vyšetrenie:</b> ${htmlToPlain(sumIEXAMText)} `
					: ""
				printValues["summaries_plain"] += sumRecommText ? `<b>Odporúčanie:</b> ${htmlToPlain(sumRecommText)} ` : ""
				printValues["summaries_plain"] += sumDCText ? `<b>Diagnostický záver:</b> ${htmlToPlain(sumDCText)} ` : ""

				printValues["summaryAMB"] = sumAMBText ? `<b>Objektívny nález:</b><p>${sumAMBText}</p>` : ""
				printValues["sumRecomm"] = sumRecommText ? `<b>Odporúčanie:</b><p>${sumRecommText}</p>` : ""
				printValues["sumOF"] = sumOFText ? `<b>Subjektívne ťažkosti:</b><p>${sumOFText}</p>` : ""
				printValues["sumDC"] = sumDCText ? `<b>Diagnostický záver:</b><p>${sumDCText}</p>` : ""
				printValues["sumIEXAM"] = sumIEXAMText ? `<b>Zobrazovacie vyšetrenie:</b><p>${sumIEXAMText}</p>` : ""
				printValues["doctorrecom"] = recomDoctor
				printValues["doctorsub"] = subDoctor
				printValues["deliveries"] = !ixsIsEmpty(deliveriesText) ? `<br/><b>Výkony:</b> ${deliveriesText}` : ""

				printValues["priority"] = recordData.priority
					? `<b>Urgentnosť záznamu:</b> ${recordData.priority.name_ext}`
					: ""

				//Naviazané EHR na dekurz
				if (recordData.enslaved_records) {
					//Žiadanky a PN
					const slaveData = recordData.enslaved_records.filter(
						(er) =>
							er.active &&
							er.slave.active &&
							!skipRecord.includes(er.slave._id) &&
							(er.slave._type === "EHR.Data.Specialist.Request" ||
								er.slave._type === "EHR.Data.Radiology.Request" ||
								er.slave._type === "EHR.Data.SicknessAbsence")
					)
					if (slaveData && slaveData.length > 0) {
						let sItemCnt = 0
						slaveData.map((s) => {
							//PN
							if (s.slave._type === "EHR.Data.SicknessAbsence" && ixsIsUndefined(printValues.sicknessabs)) {
								const dateFrom =
									s.slave && s.slave.validity && s.slave.validity.from
										? moment(s.slave.validity.from, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
										: ""
								const dateTo =
									s.slave && s.slave.validity && s.slave.validity.to
										? moment(s.slave.validity.to, settings.DB_DATETIME_FORMAT).format("DD.MM.YYYY")
										: ""
								// const subtype = s.slave && s.slave.sub_type ? `${s.slave.sub_type.name_ext} ` : ""
								let subtypeName = ""
								let subtypeCodeExt = ""
								if (s.slave && s.slave.sub_type && GlobalStore.CL["sicknessAbsenceTypes"]) {
									const subtype = GlobalStore.CL["sicknessAbsenceTypes"].find((sat) => sat._id === s.slave.sub_type)
									if (subtype) {
										subtypeName = subtype.name_ext
										subtypeCodeExt = subtype.code_ext
									}
								}
								printValues["sicknessabs"] = `<br/><p><b>${subtypeName}</b> od ${dateFrom} ${
									dateTo ? `do ${dateTo}` : ""
								} ${s.slave.number}</p>`

								printValues["sicknessabs_plain"] = `<b>${subtypeCodeExt}</b> od ${dateFrom} ${
									dateTo ? `do ${dateTo}` : ""
								} ${s.slave.number}`
							}

							if (s.slave._type === "EHR.Data.Specialist.Request" || s.slave._type === "EHR.Data.Radiology.Request") {
								printValues["request"] += "<br/><b>Žiadanka:</b>"
							}

							api
								.loadRecord(s.slave._id, "L4")
								.call()
								.then((requestData) => {
									sItemCnt++
									const expertise = requestData.general_items.find(
										(gi) => gi._type === "EHR.Data.Record.Expertise" && gi.type === "recvouexp"
									)

									const pactivity = requestData.general_items.find(
										(gi) => gi._type === "EHR.Data.Record.ProfessionalActivity"
									)
									if (expertise) {
										printValues["request"] += `<br/>Odbornosť: ${expertise.item.code_ext} - ${expertise.item.name_ext}`
									}

									if (pactivity) {
										printValues["request"] += `, ${pactivity.item.name_ext}`
									}
									if (sItemCnt === slaveData.length) {
										UIStore.isFormSaving = false
										printReport("record", printValues)
									}
								})
						})
					} else {
						UIStore.isFormSaving = false
						printReport("record", printValues)
					}
				} else {
					UIStore.isFormSaving = false
					printReport("record", printValues)
				}
			})
	}

	@action checkDeliverysAndSave(form, values, id) {
		//Zkontroluje ci Vykony obsahuju vykon H0006 ak ano k tomuto vykonu musi byt pripocitatelna polozka SKOR s nejakymi 2 cislami napriklad SKOR12 ak tam je ulozi vykony inak sa otovi xsConfirmationDialog v ktorom sa pyta ci aj tak chces ulozit
		let isFormValid = this.validateDeliveryChips()
		if (isFormValid) {
			this.handleSaveDeliveries(form, values, id)
		} else {
			UIStore.isFormSaving = false
			GlobalStore.openConfirmationDialog("xsDeliveriesValidationFailed", {
				deliveriesValidatinFailedCallback: () => {
					this.handleSaveDeliveries(form, values, id)
				}
			})
		}
	}

	@action handleSaveDeliveries(form, values, id) {
		//form.$("recordId").value
		this.deliveriesChips = values
		let deliveriesRequest = []
		if (isNotEmpty(form.$("recordId").value) && this.readonlyform) {
			let openSicknessAbsForm = false
			this.deliveriesChips.map((delivery, idx) => {
				if (delivery.col1CodeExt == "71") {
					openSicknessAbsForm = true
				}
				deliveriesRequest.push({
					_ref: false,
					_type: "EHR.Data.Record.SK.Delivery",
					item: {
						_id: delivery.col1,
						_type: "EHR.CL.Bill.SK.Delivery"
					},
					diagnosis: {
						_id: delivery.col2,
						_type: "EHR.CL.Record.Diagnosis"
					},
					count: delivery.col3,
					type: "dlv",
					payer_type: isNotEmpty(delivery.colPaymentType) ? delivery.colPaymentType : null,
					insuree_type: isNotEmpty(delivery.col4) ? delivery.col4 : null,
					refund_type: isNotEmpty(delivery.col5) ? delivery.col5 : null,
					movement: delivery.col6,
					delivery_extension: isNotEmpty(delivery.delivery_extension) ? delivery.delivery_extension : null,
					delivery_date_time: moment(delivery.col7).format(process.env.DB_DATETIME_FORMAT),
					sender_type: isNotEmpty(delivery.colSendingType) ? delivery.colSendingType : null
				})
				if (isSafe(this.deliveriesAddItemChips[idx])) {
					deliveriesRequest[idx].sub_items = this.deliveriesAddItemChips[idx].map((chip) => {
						let product = {}
						let description = null
						let delivery_item = null
						let _type = "EHR.Data.Record.SK.Delivery.Product"
						if (chip.type == "adidrug" || chip.type == "adidev" || chip.type == "adicntr") {
							product["_id"] = chip.product
							if (chip.type == "adidrug") {
								product["_type"] = "EHR.CL.Medication.SK.Drug"
							} else if (chip.type == "adidev") {
								product["_type"] = "EHR.CL.Medication.SK.MedicalDevice"
							} else if (chip.type == "adicntr") {
								product = null
								delivery_item = {
									_id: chip.product,
									_type: "EHR.CL.Bill.SK.DeliveryItem"
								}
								_type = "EHR.Data.Record.Delivery.CustomItem"
							}
						} else {
							product = null
							description = chip.product
						}
						return {
							_ref: false,
							type: "adi",
							sub_type: chip.type,
							quantity: chip.quantity,
							unit_price: chip.unit,
							product,
							delivery_item,
							description,
							_type
						}
					})
				}
			})
			api
				.saveDeliveries(deliveriesRequest, form.$("recordId").value)
				.call()
				.then(() => {
					this.clearProvidedServices(id)
					const patientID = DataStore.patientDTO.get("patient").validInfo.client._id
					const providerID = getSelectedOrgUnitID()
					actionCard.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
						DataStore.setPatientEHR(overview)
					})
					if (openSicknessAbsForm) {
						this.openSicknessAbsForm(form.$("recordId").value)
					}
				})
		}
	}

	@action setProvidedServices(form, event, patientId, forceLoad) {
		DataStore.providedServicesNames[patientId] = event.label
		api
			.loadProvidedService(event.value)
			.call()
			.then((res) => {
				if (isSafe(res.relateds) && Array.isArray(res.relateds) && res.relateds.length > 0) {
					DataStore.providedServices[patientId] = res.relateds
					this.loadProvidedServices(form, patientId, forceLoad)
				} else {
					// this.deliveriesChipsForm.bindData([])
					// form.$("deliveriesChipsForm").value = JSON.stringify([])
					if (isEmpty(form.$("recordId").value) && !this.readonlyform) {
						form.$("deliveriesChipsForm").value = JSON.stringify([
							{
								col1: "",
								col1NameExt: "",
								col2: "PRIMARY",
								col3: "",
								col4: ""
							}
						])
					}
				}
			})
	}

	@action async loadProvidedServices(form, id, forceLoad) {
		if (
			isSafe(DataStore.providedServices) &&
			isSafe(DataStore.providedServices[id]) &&
			DataStore.providedServices[id].length
		) {
			DataStore.providedServices[id].sort((a, b) => {
				return a.item_order - b.item_order
			})
			if ((!this.readonlyform && isEmpty(form.$("recordId").value)) || forceLoad) {
				let hasDiagnosis = false
				if (
					isNotSafe(JSON.parse(form.$("diagnosisChipsForm").value)["0"]) ||
					(isSafe(JSON.parse(form.$("diagnosisChipsForm").value)["0"]) &&
						isEmpty(JSON.parse(form.$("diagnosisChipsForm").value)["0"].col1))
				) {
					this.loadDiagnosisFromProvidedServices(
						form,
						DataStore.providedServices[id].filter((row) => row.rel_class_name == "EHR.CL.Record.Diagnosis")
					)
				} else {
					hasDiagnosis = true
				}
				let newMainDiagnosis =
					isSafe(JSON.parse(form.$("diagnosisChipsForm").value)) &&
					isSafe(JSON.parse(form.$("diagnosisChipsForm").value)["0"])
						? JSON.parse(form.$("diagnosisChipsForm").value)["0"].col1
						: ""

				let setDispensary = false
				let diagnosisForm = JSON.parse(form.$("diagnosisChipsForm").value)
				if (
					isSafe(ProblemsStore.problemsDTO) &&
					Array.isArray(ProblemsStore.problemsDTO) &&
					ProblemsStore.problemsDTO.length &&
					isSafe(diagnosisForm)
				) {
					let diagnosisCodes = Object.keys(diagnosisForm).map((key) => diagnosisForm[key].col1)
					if (
						ProblemsStore.problemsDTO.some(
							(row) =>
								isSafe(row.problem_type) &&
								row.problem_type.code == "DISPENSARY" &&
								diagnosisCodes.includes(row.diagnosis)
						)
					) {
						setDispensary = true
						form.$("hasDispensaryDiagnosis").value = true
						form.$("dispensarisation").value = true
					}
				}

				let relatedDeliveryItems
				try {
					relatedDeliveryItems = JSON.parse(form.$("deliveriesFormAddItems").value)
				} catch (e) {
					relatedDeliveryItems = {}
				}
				let oldDeliveries = JSON.parse(form.$("deliveriesChipsForm").value)
				const deliveriesChipsFormLength = oldDeliveries.length
				let newDeliveries = DataStore.providedServices[id]
					.filter((row) => row.rel_class_name == "EHR.CL.Bill.Delivery")
					.map((data, index) => {
						let relatedDiagnosis = DataStore.providedServices[id].find(
							(diag) => diag.rel_class_name == "EHR.CL.Record.Diagnosis" && data.item_order == diag.item_order
						)
						let relatedDeliveryItem = DataStore.providedServices[id].find(
							(diag) => diag.rel_class_name == "EHR.CL.Bill.DeliveryItem" && data.item_order == diag.item_order
						)
						if (isSafe(relatedDeliveryItem)) {
							let relatedDeliveryItemPrice = null
							if (GlobalStore.CL["deliveryItem"]) {
								relatedDeliveryItemPrice = GlobalStore.CL["deliveryItem"].find(
									(item) => item._id == relatedDeliveryItem.rel_object_id
								).price_in_currency
							}

							let relatedDeliveryId = GlobalStore.CL["deliveryItem"].find(
								(item) => item._id == relatedDeliveryItem.rel_object_id
							)._id

							relatedDeliveryItems["" + (index + deliveriesChipsFormLength)] = [
								{
									product: relatedDeliveryId,
									quantity: 1,
									type: "adicntr",
									typeNameExt: "Zmluvná pripočitateľná položka",
									unit: relatedDeliveryItemPrice
								}
							]
						}

						return {
							col1: data.rel_object_id,
							col2: isSafe(relatedDiagnosis) ? relatedDiagnosis.rel_object_id : newMainDiagnosis,
							col3: isNotEmpty(data.quantity) ? data.quantity : "",
							colPaymentType: "Z",
							col4: "",
							col5: "",
							col6: "",
							delivery_extension: "",
							col7: moment().format(process.env.DB_DATETIME_FORMAT),
							colSendingType: setDispensary ? "D" : isNotEmpty(form.$("doctorPersonnelExpertiseId").value) ? "S" : "",
							colDateOfRequest: ""
						}
					})

				//Load specialnych cien ktore si lekari zmenili a teda niesu v ciselniku
				for (let index = 0; index < Object.keys(relatedDeliveryItems).length; index++) {
					const key = Object.keys(relatedDeliveryItems)[index]
					if (isSafe(relatedDeliveryItems[key]) && isSafe(relatedDeliveryItems[key][0])) {
						let item = relatedDeliveryItems[key][0]
						if (isNotEmpty(item.product)) {
							let price = await api
								.getDeliveryItemPrice(item.product, {
									org_unit_id: getSelectedOrgUnitID(),
									insurer_id: DataStore.patientDTO.get("insurerId"),
									date: moment().format("YYYY-MM-DD")
								})
								.call()
							if (isSafe(price)) {
								relatedDeliveryItems[key][0].unit = price.price_in_currency
							}
						}
					}
				}

				newDeliveries = oldDeliveries.concat(newDeliveries)
				form.$("deliveriesChipsForm").value = JSON.stringify(newDeliveries)
				form.$("deliveriesFormAddItems").value = JSON.stringify(relatedDeliveryItems)
				this.deliveriesAddItemChips = relatedDeliveryItems
				await this.loadChipText(form, () => {})
				// if (isEmpty(form.$("recordId").value)) {
				let deliveryData = JSON.parse(form.$("deliveriesChipsForm").value)
				for (let index = 0; index < deliveryData.length; index++) {
					let row = deliveryData[index]
					if (row.col1CodeExt == "H0006") {
						let overviewData = await api
							.loadPatientOverview(
								getSelectedOrgUnitID(),
								DataStore.patientDTO.get("patientID"),
								null,
								moment(form.$("examdate").value)
									.startOf("day")
									.format(settings.DB_DATETIME_FORMAT),
								moment(form.$("examdate").value)
									.endOf("day")
									.format(settings.DB_DATETIME_FORMAT)
							)
							.call()
						let scoring = overviewData.rows.reverse().find((row) => row.type_id == "EHR.Data.Scoring")
						if (isSafe(scoring)) {
							let addItems = JSON.parse(form.$("deliveriesFormAddItems").value)
							addItems[index] = [
								{
									type: "adigen",
									typeNameExt: "Iná pripočítateľná položka",
									product: "SKOR0" + scoring.value,
									quantity: 1,
									unitEndText: "",
									unit: 0
								}
							]
							form.$("deliveriesFormAddItems").value = JSON.stringify(addItems)
							this.deliveriesAddItemChips = addItems
						}
						// }
					}
				}

				let clinicalValues = []
				if (DataStore.providedServices[id].filter((row) => row.rel_class_name == "EHR.CL.Record.Measure").length) {
					try {
						clinicalValues = JSON.parse(form.$("clinicalValuesChipsForm").value)
					} catch (e) {
						clinicalValues = []
					}
					DataStore.providedServices[id]
						.filter((row) => row.rel_class_name == "EHR.CL.Record.Measure")
						.forEach((service) => {
							const measure = GlobalStore.CL["recordMeasures"].find((mes) => mes._id == service.rel_object_id)
							clinicalValues.push({
								col1: measure.code,
								col1NameExt: measure.name_ext,
								col2: "",
								col3: measure.default_unit_id,
								note: "",
								date: ""
							})
						})
				}
				let data = []
				if (!hasDiagnosis) {
					let diagnosis = isSafe(JSON.parse(form.$("diagnosisChipsForm").value))
						? JSON.parse(form.$("diagnosisChipsForm").value)[0]
						: null

					if (isSafe(diagnosis) && isNotEmpty(diagnosis.col1)) {
						let filter = getFilters([`diagnosis=${diagnosis.col1}`])
						let response = await api.loadDiagnosisMeasure(filter).call()
						if (
							isSafe(response) &&
							isSafe(response.rows) &&
							isSafe(response.rows[0]) &&
							isSafe(response.rows[0].items) &&
							response.rows[0].items.length
						) {
							let items = response.rows[0].items
							items.forEach((val) => {
								data.push({
									col1: val.measure._id,
									col1NameExt: val.measure.name_ext,
									col2: "",
									col3: isSafe(val.measure.default_unit) ? val.measure.default_unit._id : "",
									note: "",
									date: ""
								})
							})
						}
					}
				}

				if (data.length || clinicalValues.length) {
					const newData = data.concat(clinicalValues)
					form.$("clinicalValuesChipsForm").value = JSON.stringify(newData)
					if (isSafe(this.clinicalValuesChipsForm)) {
						this.clinicalValuesChipsForm.bindData(newData)
						setTimeout(() => {
							this.clinicalValuesChipsForm.showForm()
						}, 0)
					}
				}

				if (isEmpty(form.$("recordId").value)) {
					if (
						DataStore.providedServices[id].filter((row) => row.rel_class_name == "EHR.Data.Template.Summary").length
					) {
						DataStore.providedServices[id]
							.filter((row) => row.rel_class_name == "EHR.Data.Template.Summary")
							.forEach((templateSumary) => {
								//load templatu podla ID
								const template = DataStore.templatesAll["RECORD"].records.find(
									(t) => t._id === templateSumary.rel_object_id
								)
								//Podla id urcim o aky field v dekurze ide
								if (isSafe(template)) {
									let fieldName = null
									switch (template.type_id) {
										case "AMB":
											fieldName = "record"
											break
										case "OF":
											fieldName = "OF"
											break
										case "DC":
											fieldName = "diagnosisSummary"
											break
										case "Recomm":
											fieldName = "Recomm"
											break
										default:
											fieldName = null
									}

									if (isNotEmpty(fieldName)) {
										form.$(fieldName).value = template.template_stream
									}
								}
							})
					}
				}

				if (isSafe(form.$("recordId").value) && isNotEmpty(form.$("recordId").value) && this.readonlyform) {
					this.deliveriesChipsForm.props.configuration.options.onFormSave(this.deliveriesChips)
				}
			}
		}
	}

	@action checkForProvidedServices(id) {
		if (
			isSafe(DataStore.providedServices) &&
			isSafe(DataStore.providedServices[id]) &&
			DataStore.providedServices[id].length
		) {
			return true
			// return (
			// 	DataStore.providedServices[id]
			// 		.filter((row) => row.rel_class_name == "EHR.CL.Record.Diagnosis")
			// 		.map((i) => i.rel_object_id).length > 0
			// )
		} else {
			return false
		}
	}

	@action loadDiagnosisFromProvidedServices(form, data) {
		//Vycistenie formu pred predplnenim ak to nieje dekurz ktory je v readonly mode
		if (isEmpty(form.$("recordId").value) && !this.readonlyform) {
			form.$("diagnosisChipsForm").value = JSON.stringify([
				{
					col1: "",
					col1NameExt: "",
					col2: "PRIMARY",
					col3: "",
					col4: ""
				}
			])
		}
		let ids = data.map((i) => i.rel_object_id)
		if (isSafe(ids) && ids.length) {
			let uniqueIds = Array.from(new Set(ids))
			let tmp = uniqueIds.map((i, idx) => {
				return {
					col1: i,
					col1NameExt: "",
					col2: idx == 0 ? "PRIMARY" : "SECONDARY",
					col3: "",
					col4: ""
				}
			})
			if (isEmpty(form.$("recordId").value) && !this.readonlyform) {
				form.$("diagnosisChipsForm").set(JSON.stringify(tmp))
				if (isSafe(this.forceComponentRefresh)) {
					this.forceComponentRefresh()
				}
			}
		}
	}

	@action clearProvidedServices(id) {
		if (
			isSafe(DataStore.providedServices) &&
			isSafe(DataStore.providedServices[id]) &&
			DataStore.providedServices[id].length
		) {
			DataStore.providedServices[id] = []
		}
		if (isSafe(DataStore.providedServicesNames) && isNotEmpty(DataStore.providedServicesNames[id])) {
			DataStore.providedServicesNames[id] = null
		}
	}

	@action async loadChipText(form, callback) {
		if (isNotEmpty(form.$("deliveriesChipsForm").value)) {
			let data = JSON.parse(form.$("deliveriesChipsForm").value)
			let dataArr = []
			let idsArr = []

			Object.keys(data).forEach((field) => {
				dataArr[+field] = data[field]
			})
			dataArr.forEach((item) => {
				idsArr.push(item.col1)
			})

			if (idsArr.length) {
				let filters = {filters: [{associated_column: "code", predicate: "=", values: []}]}
				idsArr.forEach((i) => {
					filters.filters[0].values.push({id_value: i})
				})
				let response = await api.loadSpecificDelivery(filters).call()
				dataArr.forEach((i) => {
					let dataRow = response.rows.find((row) => row._id == i.col1)
					if (isSafe(dataRow)) {
						i.col1NameExt = dataRow.code_ext + "-" + dataRow.name_ext
						i.col1CodeExt = dataRow.code_ext
					}
				})
				// this.deliveriesChipsForm.bindData(dataArr)
				form.$("deliveriesChipsForm").set(JSON.stringify(dataArr))
				this.deliveriesLoadedFlag = true
				if (isSafe(this.deliveriesChipsForm)) {
					this.deliveriesChipsForm.forceUpdate()
				}
				if (isSafe(callback) && typeof callback === "function") {
					callback()
				}

				// .then((res) => {
				// 	dataArr.forEach((i) => {
				// 		let dataRow = res.rows.find((row) => row._id == i.col1)
				// 		if (isSafe(dataRow)) {
				// 			i.col1NameExt = dataRow.code_ext + "-" + dataRow.name_ext
				// 			i.col1CodeExt = dataRow.code_ext
				// 		}
				// 	})
				// 	this.deliveriesChipsForm.bindData(dataArr)
				// 	this.deliveriesLoadedFlag = true
				// 	if (isSafe(callback) && typeof callback === "function") {
				// 		callback()
				// 	}
				// })
				// .catch(() => {
				// 	this.deliveriesLoadedFlag = true
				// })
			}
		}
	}

	@action setDeliveryDiagnosis(value, form) {
		if (
			isSafe(form) &&
			isSafe(form.get("value")) &&
			form.get("value").col2 === "PRIMARY" &&
			isSafe(this.deliveriesChipsForm)
		) {
			let data = this.deliveriesChipsForm.getData()
			if (Object.keys(data).length) {
				Object.keys(data).forEach((i) => {
					if (isEmpty(data[i].col2)) {
						data[i].col2 = value
					}
				})
				this.deliveriesChipsForm.bindData(data)
			}
		}
	}

	@action setSendingType(form) {
		if (
			isSafe(form) &&
			isNotEmpty(form.$("doctorPersonnelExpertiseId").value) &&
			(isEmpty(form.$("recordId").value) || form.$("version").value == "draft")
		) {
			let data = this.deliveriesChipsForm.getData()
			if (Object.keys(data).length) {
				Object.keys(data).forEach((i) => {
					if (isEmpty(data[i].colSendingType)) {
						data[i].colSendingType = "S"
					}
				})
				form.$("deliveriesChipsForm").value = JSON.stringify(data)
				this.deliveriesChipsForm.bindData(data)
			}
		}
	}

	@action deleteLoadedAttachemnt(id, index) {
		let data = {
			_id: id,
			active: false
		}
		api
			.saveAttachment(data)
			.call()
			.then(() => {
				this.dropedFiles.splice(index, 1)
			})
	}

	@action insertServiceNameToForm(form, id) {
		if (isSafe(DataStore.providedServicesNames) && isNotEmpty(DataStore.providedServicesNames[id])) {
			form.$("OF").set(DataStore.providedServicesNames[id])
			form.$("record").set(DataStore.providedServicesNames[id])
		}
	}

	@action generateRecordFromPrescription(recipes) {
		if (isSafe(recipes)) {
			const diagnosis = recipes[0].record_diagnoses[0].item._id
			const patientID = DataStore.patientDTO.get("patientID")
			const personnelID = getUserPersonnelID()
			const providerID = getSelectedOrgUnitID()
			const substitutingDoctorId = isNotEmpty(recipes[0].substituting_doctor_id)
				? recipes[0].substituting_doctor_id
				: ""
			const substitutingDoctorExpertiseId = isNotEmpty(recipes[0].substituting_doctor_expertise_id)
				? recipes[0].substituting_doctor_expertise_id
				: ""
			const substituting_provider_id = isNotEmpty(recipes[0].substituting_provider_id)
				? recipes[0].substituting_provider_id
				: ""

			let recordRequest = {
				_ref: false,
				_type: "EHR.Data.Ambulance",
				confidentiality_level: "STANDARD",
				version: {
					_type: "EHR.CL.Record.Version",
					_id: "final"
				},
				specific_rels: [
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: patientID,
							_type: "Entity.Data.Client"
						},
						type: "pat"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: personnelID,
							_type: "EHR.Data.Entity.Personnel"
						},
						type: "phy"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: providerID,
							_type: "Entity.Data.OrgUnit"
						},
						type: "ou"
					}
				],
				summaries: [
					{
						_ref: false,
						_type: "EHR.Data.Record.Summary",
						content: "<p>PRESKRIPCIA LIEKU</p>",
						type: "AMB"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.Summary",
						content: "<p>PRESKRIPCIA LIEKU</p>",
						type: "OF"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.Summary",
						content: "",
						type: "Recomm"
					}
				],
				recorded_events: [
					{
						_ref: false,
						_type: "EHR.Data.Record.Event",
						active: true,
						recorded_at: moment().format(settings.DB_DATETIME_FORMAT),
						type: {
							_id: "WRT",
							_type: "EHR.CL.Record.EventType"
						}
					}
				],
				general_items: [
					{
						_ref: false,
						_type: "EHR.Data.Record.Diagnosis",
						item: {
							_id: diagnosis,
							_type: "EHR.CL.Record.Diagnosis"
						},
						type: "dgn",
						description: "",
						diagnosis_type: "PRIMARY",
						life_threatening: false
					}
				]
			}

			if (isNotEmpty(substitutingDoctorExpertiseId)) {
				recordRequest["general_items"].push({
					item: {
						_id: substitutingDoctorExpertiseId,
						_type: "EHR.CL.Expertise"
					},
					type: "subphyexp",
					_ref: false,
					_type: "EHR.Data.Record.Expertise"
				})
				if (isNotEmpty(substitutingDoctorId)) {
					recordRequest["specific_rels"].push({
						specific_rel: {
							_id: substitutingDoctorId,
							_type: "EHR.Data.Entity.Personnel"
						},
						type: "subphy",
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel"
					})
				}
				if (isNotEmpty(substituting_provider_id)) {
					recordRequest["specific_rels"].push({
						specific_rel: {
							_id: substituting_provider_id,
							_type: "Entity.Data.OrgUnit"
						},
						type: "subou",
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel"
					})
				}
			}

			api
				.saveRecord(recordRequest, patientID)
				.call()
				.then((record) => {
					let filter = [{associated_column: "code_ext", predicate: "=", values: [{id_value: "70"}]}]
					// if (
					// 	isNotEmpty(DataStore.patientDTO.get("insurerShortName")) &&
					// 	(DataStore.patientDTO
					// 		.get("insurerShortName")
					// 		.toLowerCase()
					// 		.includes("vszp") ||
					// 		DataStore.patientDTO
					// 			.get("insurerShortName")
					// 			.toLowerCase()
					// 			.includes("uzp"))
					// ) {
					// 	filter[0].values[0].id_value = "70"
					// }
					api
						.loadDeliveries({filters: filter})
						.call()
						.then((res) => {
							if (isSafe(res) && isSafe(res.rows) && isSafe(res.rows[0])) {
								let delivery = [
									{
										_ref: false,
										_type: "EHR.Data.Record.SK.Delivery",
										item: {
											_id: res.rows[0]._id,
											_type: "EHR.CL.Bill.SK.Delivery"
										},
										diagnosis: {
											_id: diagnosis,
											_type: "EHR.CL.Record.Diagnosis"
										},
										count: 1,
										type: "dlv",
										delivery_date_time: moment().format(process.env.DB_DATETIME_FORMAT)
									}
								]
								api
									.saveDeliveries(delivery, record._id)
									.call()
									.then(() => {
										DDaction.loadPatientOverview(providerID, DataStore.patientDTO.get("patientID")).then((overview) => {
											DataStore.setPatientEHR(overview)
											UIStore.isFormSaving = false
										})
									})
							}
						})
				})
		}
	}

	@action checkIfPatientHasRecordToday(recipes) {
		api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				[],
				moment()
					.startOf("day")
					.utc()
					.format(),
				moment()
					.endOf("day")
					.utc()
					.format()
			)
			.call()
			.then((response) => {
				let data = response.rows
				if (isSafe(data)) {
					let filteredData = data.filter((i) => i.type_code == "DEK")
					if (isNotSafe(filteredData) || (isSafe(filteredData) && filteredData.length == 0)) {
						GlobalStore.openConfirmationDialog("xsGenerateRecordFromPrescription", {recipes: recipes})
					}
				}
			})
	}

	@action async loadLastPrescriptionSendingDoctor(form) {
		let response = await api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				[],
				moment()
					.startOf("day")
					.utc()
					.format(),
				moment()
					.endOf("day")
					.utc()
					.format()
			)
			.call()
		let data = response.rows
		if (isSafe(data)) {
			let filteredData = data.find((i) => i.type_code == "RX")
			if (isSafe(filteredData) && isSafe(filteredData.substituting_doctor_card)) {
				form.$("substitutingPersonnelExpertiseId").value = filteredData.substituting_doctor_card.personnel_expertise_id
				form.$("substitutingDoctorOUPZS").value = filteredData.substituting_provider_id
			}
		}
		// .then((response) => {
		// 	let data = response.rows
		// if (isSafe(data)) {
		// 	let filteredData = data.find((i) => i.type_code == "RX")
		// 	if (isSafe(filteredData) && isSafe(filteredData.substituting_doctor_card)) {
		// 		form.$("substitutingPersonnelExpertiseId").value = filteredData.substituting_doctor_card.personnel_expertise_id
		// 		form.$("substitutingDoctorOUPZS").value = filteredData.substituting_provider_id
		// 	}
		// }
		// 	this.doctorLoadedFlag = true
		// })
		// .catch(() => {
		// 	this.doctorLoadedFlag = true
		// })
	}

	@action loadClinicalValuesFromDiagnosis() {
		if (
			isSafe(this.diagnosisChipsForm) &&
			isSafe(this.diagnosisChipsForm.getData()) &&
			isSafe(this.diagnosisChipsForm.getData()["0"]) &&
			this.diagnosisChipsForm.getData()["0"].col2 === "PRIMARY" &&
			isNotEmpty(this.diagnosisChipsForm.getData()["0"].col1)
		) {
			let diagnosis = this.diagnosisChipsForm.getData()["0"]
			let filter = getFilters([`diagnosis=${diagnosis.col1}`])
			api
				.loadDiagnosisMeasure(filter)
				.call()
				.then((response) => {
					if (
						isSafe(response) &&
						isSafe(response.rows) &&
						isSafe(response.rows[0]) &&
						isSafe(response.rows[0].items) &&
						response.rows[0].items.length
					) {
						let items = response.rows[0].items
						let data = []
						items.forEach((val) => {
							data.push({
								col1: val.measure._id,
								col1NameExt: val.measure.name_ext,
								col2: null,
								col3: isSafe(val.measure.default_unit) ? val.measure.default_unit._id : null,
								note: "",
								date: ""
							})
						})
						this.clinicalValuesChipsForm.bindData(data)
					}
				})
		}
	}

	generateStringToRecordTextArea = (form) => {
		if (form.$("clinicalValuesChipsForm").value != "{}" && form.$("clinicalValuesChipsForm").value != "[]") {
			let clinicalVals = JSON.parse(form.$("clinicalValuesChipsForm").value)
			let req = clinicalVals.map((cv, idx) => {
				let obj = {
					_ref: false,
					_type: "EHR.Data.Record.ClinicalValue",
					item_order: idx,
					measure: {
						_id: cv.col1,
						_type: "EHR.CL.Record.Measure"
					},
					value: cv.col2.replace(",", ".")
				}

				if (isNotEmpty(cv.col3)) {
					obj.unit = {
						_id: cv.col3,
						_type: "EHR.CL.Record.Unit"
					}
				}

				if (isNotEmpty(cv.note)) {
					obj["_notes"] = [
						{
							_ref: false,
							_type: "IXS.Data.Note",
							type: "General",
							value: cv.note
						}
					]
				}
				return obj
			})

			api
				.cvTranscription(req)
				.call()
				.then((response) => {
					if (isNotEmpty(response._root)) {
						form.$("record").value += response._root
					}
				})
		}
	}

	@action clinicalSetsDialogClose() {
		this.clinicalSetsDialogOpen = false
	}

	@action async loadClinicalValSets() {
		const filter = getFilters([`specific_rel=${getSelectedOrgUnitID()}`])
		let sets = await api.loadMeasuresSet(filter).call()
		this.clinicalValueSets = sets.rows
	}

	@action saveClinicalValsToSet(form) {
		const chipVals = JSON.parse(form.$("chipData").value)
		let saveData = {
			_ref: false,
			_type: "EHR.Data.Template.MeasureSet",
			active: true,
			name: form.$("name").value,
			specific_rel: {
				_id: getSelectedOrgUnitID(),
				_type: "Entity.Data.OrgUnit"
			},
			items: []
		}

		if (isNotEmpty(form.$("_id").value)) {
			saveData["_id"] = form.$("_id").value
		}

		saveData.items = chipVals.map((chip, index) => {
			return {
				_ref: false,
				_type: "EHR.Data.Template.MeasureSetItem",
				active: true,
				item_order: index,
				measure: {
					_id: chip.col1,
					_type: "EHR.CL.Record.Measure"
				}
			}
		})
		api
			.setMeasuresSet(saveData)
			.call()
			.then(() => {
				this.loadClinicalValSets(form)
				form.reset()
				UIStore.isFormSaving = false
			})
	}
	setToClinivalVal = (form, id) => {
		const clinSet = this.clinicalValueSets.find((obj) => obj._id == id)
		if (isSafe(clinSet)) {
			let chipData = clinSet.items.map((item) => {
				return {
					col1: item.measure._id,
					col1NameExt: item.measure.name_ext,
					col2: null,
					col3: isSafe(item.measure.default_unit) ? item.measure.default_unit._id : null,
					note: "",
					date: ""
				}
			})
			let curentValue = JSON.parse(form.$("clinicalValuesChipsForm").value)
			if (isSafe(curentValue) && Array.isArray(curentValue)) {
				curentValue.push(...chipData)
			} else {
				curentValue = chipData
			}
			form.$("clinicalValuesChipsForm").set("value", JSON.stringify(curentValue))
			if (isSafe(this.forceComponentRefresh)) {
				this.forceComponentRefresh()
			}
			if (isSafe(this.clinicalValuesChipsForm)) {
				setTimeout(() => {
					this.clinicalValuesChipsForm.showForm()
				}, 0)
			}
		}
	}

	@action deleteClinicalSet(form, id) {
		let saveData = {
			_ref: false,
			_type: "EHR.Data.Template.MeasureSet",
			active: false,
			specific_rel: {
				_id: getSelectedOrgUnitID(),
				_type: "Entity.Data.OrgUnit"
			},
			_id: id
		}

		api
			.setMeasuresSet(saveData)
			.call()
			.then(() => {
				this.loadClinicalValSets(form)
				UIStore.isFormSaving = false
			})
	}

	@action async checkRecordForToday(callback) {
		let overviewData = await api
			.loadPatientOverview(
				getSelectedOrgUnitID(),
				DataStore.patientDTO.get("patientID"),
				null,
				moment()
					.startOf("day")
					.format(settings.DB_DATETIME_FORMAT),
				moment()
					.endOf("day")
					.format(settings.DB_DATETIME_FORMAT)
			)
			.call()
		let record = overviewData.rows.find((row) => row.type_id == "EHR.Data.Ambulance")
		if (isSafe(record) && isSafe(callback) && typeof callback == "function") {
			callback()
		}
	}

	@action async addProvidedServices(form, service) {
		UIStore.isFormSaving = true
		api
			.loadProvidedService(service.value)
			.call()
			.then(async (res) => {
				if (isSafe(res.relateds) && Array.isArray(res.relateds) && res.relateds.length > 0) {
					// Pridanie diagnoz
					let diagnosisChipsForm = JSON.parse(form.$("diagnosisChipsForm").value)
					if (this.readonlyform == false) {
						let diagnosis = res.relateds.filter((row) => row.rel_class_name == "EHR.CL.Record.Diagnosis")
						if (isSafe(diagnosis) && diagnosis.length) {
							if (isSafe(diagnosisChipsForm["0"]) && isNotEmpty(diagnosisChipsForm["0"].col1)) {
								let ids = diagnosis.map((i) => i.rel_object_id)
								if (isSafe(ids) && ids.length) {
									let uniqueIds = Array.from(new Set(ids))
									let tmp = uniqueIds.map((i) => {
										return {
											col1: i,
											col1NameExt: "",
											col2: "SECONDARY",
											col3: "",
											col4: ""
										}
									})
									diagnosisChipsForm = diagnosisChipsForm.concat(tmp)
								}
							} else {
								let ids = diagnosis.map((i) => i.rel_object_id)
								if (isSafe(ids) && ids.length) {
									let uniqueIds = Array.from(new Set(ids))
									diagnosisChipsForm = uniqueIds.map((i, idx) => {
										return {
											col1: i,
											col1NameExt: "",
											col2: idx == 0 ? "PRIMARY" : "SECONDARY",
											col3: "",
											col4: ""
										}
									})
								}
							}
						}
					}

					let newMainDiagnosis =
						isSafe(diagnosisChipsForm) && isSafe(diagnosisChipsForm["0"]) ? diagnosisChipsForm["0"].col1 : ""

					let hasDiagnosis = isEmpty(newMainDiagnosis) ? false : true
					let setDispensary = false
					let diagnosisForm = JSON.parse(form.$("diagnosisChipsForm").value)
					if (
						isSafe(ProblemsStore.problemsDTO) &&
						Array.isArray(ProblemsStore.problemsDTO) &&
						ProblemsStore.problemsDTO.length &&
						isSafe(diagnosisForm)
					) {
						let diagnosisCodes = Object.keys(diagnosisForm).map((key) => diagnosisForm[key].col1)
						if (
							ProblemsStore.problemsDTO.some(
								(row) =>
									isSafe(row.problem_type) &&
									row.problem_type.code == "DISPENSARY" &&
									diagnosisCodes.includes(row.diagnosis)
							)
						) {
							setDispensary = true
							form.$("hasDispensaryDiagnosis").value = true
							form.$("dispensarisation").value = true
						}
					}
					//Pridanie vykonov
					let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
					let relatedDeliveryItems = isNotEmpty(form.$("deliveriesFormAddItems").value)
						? JSON.parse(form.$("deliveriesFormAddItems").value)
						: {}
					const deliveriesChipsFormLength = deliveriesChipsForm.length

					let newDeliveries = res.relateds
						.filter((row) => row.rel_class_name == "EHR.CL.Bill.Delivery")
						.map((data, index) => {
							let relatedDiagnosis = res.relateds.find(
								(diag) => diag.rel_class_name == "EHR.CL.Record.Diagnosis" && data.item_order == diag.item_order
							)
							let relatedDeliveryItem = res.relateds.find(
								(diag) => diag.rel_class_name == "EHR.CL.Bill.DeliveryItem" && data.item_order == diag.item_order
							)
							if (isSafe(relatedDeliveryItem)) {
								let relatedDeliveryItemPrice = null
								if (GlobalStore.CL["deliveryItem"]) {
									relatedDeliveryItemPrice = GlobalStore.CL["deliveryItem"].find(
										(item) => item._id == relatedDeliveryItem.rel_object_id
									).price_in_currency
								}

								let relatedDeliveryId = GlobalStore.CL["deliveryItem"].find(
									(item) => item._id == relatedDeliveryItem.rel_object_id
								)._id

								relatedDeliveryItems["" + (index + deliveriesChipsFormLength)] = [
									{
										product: relatedDeliveryId,
										quantity: 1,
										type: "adicntr",
										typeNameExt: "Zmluvná pripočitateľná položka",
										unit: relatedDeliveryItemPrice
									}
								]
							}

							return {
								col1: data.rel_object_id,
								col2: isSafe(relatedDiagnosis) ? relatedDiagnosis.rel_object_id : newMainDiagnosis,
								col3: isNotEmpty(data.quantity) ? data.quantity : "",
								colPaymentType: "Z",
								col4: "",
								col5: "",
								col6: "",
								delivery_extension: "",
								col7: moment().format(process.env.DB_DATETIME_FORMAT),
								colSendingType: setDispensary ? "D" : isNotEmpty(form.$("doctorPersonnelExpertiseId").value) ? "S" : "",
								colDateOfRequest: ""
							}
						})

					// Load specialnych cien ktore si lekari zmenili a teda niesu v ciselniku
					for (let index = deliveriesChipsFormLength; index < Object.keys(relatedDeliveryItems).length; index++) {
						const key = Object.keys(relatedDeliveryItems)[index]
						if (isSafe(relatedDeliveryItems[key]) && isSafe(relatedDeliveryItems[key][0])) {
							let item = relatedDeliveryItems[key][0]
							if (isNotEmpty(item.product)) {
								let price = await api
									.getDeliveryItemPrice(item.product, {
										org_unit_id: getSelectedOrgUnitID(),
										insurer_id: DataStore.patientDTO.get("insurerId"),
										date: moment().format("YYYY-MM-DD")
									})
									.call()
								if (isSafe(price)) {
									relatedDeliveryItems[key][0].unit = price.price_in_currency
								}
							}
						}
					}

					deliveriesChipsForm = deliveriesChipsForm.concat(newDeliveries)
					if (!this.readonlyform) {
						form.$("diagnosisChipsForm").set(JSON.stringify(diagnosisChipsForm))
					}
					form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChipsForm)
					await this.loadChipText(form, () => {})
					let deliveryData = JSON.parse(form.$("deliveriesChipsForm").value)
					for (let index = 0; index < deliveryData.length; index++) {
						let row = deliveryData[index]
						if (row.col1CodeExt == "H0006") {
							let overviewData = await api
								.loadPatientOverview(
									getSelectedOrgUnitID(),
									DataStore.patientDTO.get("patientID"),
									null,
									moment(form.$("examdate").value)
										.startOf("day")
										.format(settings.DB_DATETIME_FORMAT),
									moment(form.$("examdate").value)
										.endOf("day")
										.format(settings.DB_DATETIME_FORMAT)
								)
								.call()
							let scoring = overviewData.rows.reverse().find((row) => row.type_id == "EHR.Data.Scoring")
							if (isSafe(scoring)) {
								relatedDeliveryItems[index] = [
									{
										type: "adigen",
										typeNameExt: "Iná pripočítateľná položka",
										product: "SKOR0" + scoring.value,
										quantity: 1,
										unitEndText: "",
										unit: 0
									}
								]
							}
						}
					}

					form.$("deliveriesFormAddItems").value = JSON.stringify(relatedDeliveryItems)
					this.deliveriesAddItemChips = relatedDeliveryItems

					if (isSafe(form.$("recordId").value) && isNotEmpty(form.$("recordId").value) && this.readonlyform) {
						this.deliveriesChipsForm.props.configuration.options.onFormSave(deliveriesChipsForm)
					}

					let clinicalValues = []
					if (res.relateds.filter((row) => row.rel_class_name == "EHR.CL.Record.Measure").length) {
						try {
							clinicalValues = JSON.parse(form.$("clinicalValuesChipsForm").value)
						} catch (e) {
							clinicalValues = []
						}
						res.relateds
							.filter((row) => row.rel_class_name == "EHR.CL.Record.Measure")
							.forEach((service) => {
								const measure = GlobalStore.CL["recordMeasures"].find((mes) => mes._id == service.rel_object_id)
								clinicalValues.push({
									col1: measure.code,
									col1NameExt: measure.name_ext,
									col2: "",
									col3: measure.default_unit_id,
									note: "",
									date: ""
								})
							})
					}
					let data = []
					if (!hasDiagnosis) {
						let diagnosis = isSafe(JSON.parse(form.$("diagnosisChipsForm").value))
							? JSON.parse(form.$("diagnosisChipsForm").value)[0]
							: null

						if (isSafe(diagnosis) && isNotEmpty(diagnosis.col1)) {
							let filter = getFilters([`diagnosis=${diagnosis.col1}`])
							let response = await api.loadDiagnosisMeasure(filter).call()
							if (
								isSafe(response) &&
								isSafe(response.rows) &&
								isSafe(response.rows[0]) &&
								isSafe(response.rows[0].items) &&
								response.rows[0].items.length
							) {
								let items = response.rows[0].items
								items.forEach((val) => {
									data.push({
										col1: val.measure._id,
										col1NameExt: val.measure.name_ext,
										col2: "",
										col3: isSafe(val.measure.default_unit) ? val.measure.default_unit._id : "",
										note: "",
										date: ""
									})
								})
							}
						}
					}

					if (!this.readonlyform && (data.length || clinicalValues.length)) {
						const newData = data.concat(clinicalValues)
						form.$("clinicalValuesChipsForm").value = JSON.stringify(newData)
						if (isSafe(this.clinicalValuesChipsForm)) {
							this.clinicalValuesChipsForm.bindData(newData)
							setTimeout(() => {
								this.clinicalValuesChipsForm.showForm()
							}, 0)
						}
					}

					UIStore.isFormSaving = false
				} else {
					UIStore.isFormSaving = false
				}
			})
	}

	@action getServicesForPatient() {
		let filters = [{associated_column: "client_id", values: [{id_value: DataStore.patientDTO.get("patientID")}]}]
		api
			.loadServices(getSelectedOrgUnitResourceID(), {filters: filters})
			.call()
			.then((res) => {
				let items = []
				if (isSafe(res) && res.length > 0) {
					res.forEach((service) => {
						let duration = isSafe(service.duration) ? +service.duration / 60 : 0
						items.push({
							code: service.provided_service_id,
							name_ext: `${service.name_ext} (${duration} min.)`,
							duration: duration,
							item_order: isSafe(service.item_order) ? service.item_order : Number.MAX_VALUE,
							color: service.color
						})
					})
				}

				// sort podla item_order v pripade rovnosti podla name_ext
				items = items.sort((a, b) => {
					return (
						(isSafe(a.item_order) ? +a.item_order : Number.MAX_VALUE) -
							(isSafe(b.item_order) ? +b.item_order : Number.MAX_VALUE) ||
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				})

				this.serviceData = items
			})
	}

	@action checkDeliverysForConstraints(saveCallback) {
		let data = this.deliveriesChipsForm.getData()
		logger("DATA", data)
		if (Object.keys(data).length > 0 || data.length > 0) {
			let req = {
				patient_id: DataStore.patientDTO.get("patientID"),
				insurer_id: DataStore.patientDTO.get("insurerId"),
				provider_id: getSelectedOrgUnitID(),
				deliveries: []
			}

			req.deliveries = Object.keys(data).map((objKey) => {
				let delivery = data[objKey]
				return {
					item_id: delivery.col1,
					delivery_date_time: delivery.col7
				}
			})

			api
				.getDeliveryConflicts(req)
				.call()
				.then((response) => {
					logger(response)
					if (response.entries.length) {
						let periodicityError = ""
						let combinationError = ""
						response.entries.forEach((entry) => {
							if (isSafe(entry.constraints) && Array.isArray(entry.constraints) && entry.constraints.length > 0) {
								entry.constraints.forEach((constrain) => {
									if (constrain._type == "EHR.CL.Bill.SK.DeliveryPeriod") {
										let periodicityObj = GlobalStore.CL.medicationRepeats.find(
											(per) => per._id == constrain.allowed_period_unit._id
										)
										periodicityError += `${entry.item.code_ext}-${entry.item.name_ext} ${
											constrain.allowed_amount_in_period
										} za ${constrain.allowed_period_amount} ${periodicityObj.name_ext.toLowerCase()} <br/>`
									} else if (constrain._type == "EHR.CL.Bill.SK.DeliveryNoCombination") {
										if (isSafe(constrain.no_item)) {
											combinationError += `${constrain.no_item.code_ext}-${constrain.no_item.name_ext} <br/>`
										}
									}
								})
							}
						})
						if (isNotEmpty(periodicityError) || isNotEmpty(combinationError)) {
							let text = ""
							if (isNotEmpty(periodicityError)) {
								text += "<b>Obmedzená periodicita vykázania:</b> <br />" + periodicityError
							}
							if (isNotEmpty(combinationError)) {
								text += "<b>Zakázaná kombinácia:</b> <br />" + combinationError
							}
							GlobalStore.openConfirmationDialog("xsDeliveryConflictsDialog", {
								dialogText: renderHTML(text),
								confirmAction: saveCallback
							})
						}
					} else {
						saveCallback()
					}
					//obmedzena periodicita vykazania,
					//zakazana kombinacia
				})
		} else {
			saveCallback()
		}
	}

	@action insertSendingTypeToDelivieries(form, sendingTypeObj) {
		UIStore.isFormSaving = true
		let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
		const setSenderToAll = () => {
			deliveriesChipsForm.forEach((row) => {
				row.colSendingType = sendingTypeObj.value
			})
			form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChipsForm)
			this.deliveriesChipsForm.bindData(deliveriesChipsForm)
		}

		if (deliveriesChipsForm.every((row) => isEmpty(row.colSendingType))) {
			setSenderToAll()
		} else {
			GlobalStore.openConfirmationDialog("xsRewriteSendingType", {rewriteCallback: setSenderToAll})
		}
		UIStore.isFormSaving = false
	}

	@action insertDispensaryToDeliveries(form, diagnosis) {
		UIStore.isFormSaving = true
		if (
			isSafe(ProblemsStore.problemsDTO) &&
			Array.isArray(ProblemsStore.problemsDTO) &&
			ProblemsStore.problemsDTO.length > 0
		) {
			const problem = ProblemsStore.problemsDTO.find(
				(row) =>
					isSafe(row.problem_type) &&
					row.problem_type.code == "DISPENSARY" &&
					row.diagnosis_code_ext == diagnosis.code_ext
			)
			if (isSafe(problem)) {
				let deliveriesChipsForm = JSON.parse(form.$("deliveriesChipsForm").value)
				deliveriesChipsForm.forEach((row) => {
					row.colSendingType = isEmpty(row.colSendingType) ? "D" : row.colSendingType
				})
				form.$("deliveriesChipsForm").value = JSON.stringify(deliveriesChipsForm)
				if (isSafe(this.deliveriesChipsForm)) {
					this.deliveriesChipsForm.bindData(deliveriesChipsForm)
				}
				form.$("hasDispensaryDiagnosis").value = true
				form.$("dispensarisation").value = true
			}
		}
		UIStore.isFormSaving = false
	}
}

var singleton = new PatientRecordStore()
export default singleton
