"use strict"

import {observable, action} from "mobx"
import moment from "moment"

import {Store, get, set} from "idb-keyval"
import {getUser} from "../../../../global/helpers/actions"
// import config from "../../../../global/config/settings"

const signalR = require("@microsoft/signalr")

import api from "../../actions/api"

class NotificationCenterStore {
	mailbox = undefined
	connection = new signalR.HubConnectionBuilder()
		.withUrl("https://dev.ambee.sk:5055/messageHub")
		// .withUrl(config.API_CHAT_URL)
		.configureLogging(signalR.LogLevel.Information)
		.build()

	@observable isOpenSidebar = false

	@observable searchUser = ""

	allUsers = []
	@observable users = []
	@observable onlineUsers = []
	@observable data = {}
	@observable chatMessage = {}
	@observable receiveMessages = []
	@observable receiveNotify = []

	@observable messages = {}

	@observable isOpenNotification = false
	@observable selectedNotify = null

	@action searchUsers(value) {
		this.searchUser = value

		delayedCallback(300, () =>
			value.length >= 2
				? (this.users = this.allUsers.filter((x) => isSafe(x.email) && x.email.includes(value)))
				: (this.users = this.allUsers)
		)
	}

	@action async startConnection(userId) {
		const user = getUser()
		userId = user.user._id
		try {
			await this.connection.start()
			this.loadNotify()
			this.subscribe(userId)
			this.registerMethod(userId)
			logger("connected")
		} catch (err) {
			logger("myerr", err)
			setTimeout(() => this.startConnection(userId), 5000)
		}
	}

	@action async addMessage(mailbox, user, messageObj) {
		let value = await get(user, mailbox)
		if (value) {
			value.push(messageObj)
			await set(user, value, mailbox)
		} else {
			await set(user, [messageObj], mailbox)
		}
	}

	@action async updateIndexDB(notify) {
		const user = getUser()

		this.mailbox = new Store(`mailbox-${user.user._id}`, "mailbox")
		let notifyArr = await get("notify", this.mailbox)

		const index = notifyArr.findIndex(
			(x) => moment(x.time).format("YYYY.MM.DD HH.mm.ss") == moment(notify.time).format("YYYY.MM.DD HH.mm.ss")
		)

		if (index != -1) {
			notifyArr[index].new = false

			await set("notify", notifyArr, this.mailbox)

			this.receiveNotify = notifyArr
		}
	}

	@action async subscribe(userId) {
		const user = getUser()
		this.mailbox = new Store(`mailbox-${userId}`, "mailbox")
		const lastSyncDate = await get("lastSyncDate", this.mailbox)
		const data = await this.connection.invoke("Subscribe", `IXS ${user.ixstoken || ""}`, userId, lastSyncDate)

		if (data) {
			for (const row of data.rows) {
				if (row.message.message_type === "message") {
					if (row.message.folder === "inbox") {
						await this.addMessage(this.mailbox, row.message.user_from._id, {
							time: row.message.last_changed,
							message: row.message.message_text,
							folder: "inbox",
							type: row.message.message_type
						})
						this.receiveMessages.push(row.message.user_from._id)
					} else if (row.message.folder === "outbox") {
						for (const user of row.message.users_to) {
							await this.addMessage(this.mailbox, user._id, {
								time: row.message.last_changed,
								message: row.message.message_text,
								folder: "outbox",
								type: row.message.message_type
							})
						}
					}
				}

				if (row.message.message_type === "notify") {
					const obj = {
						time: row.message.last_changed,
						message: row.message.message_text,
						folder: "inbox",
						type: row.message.message_type,
						new: true
					}

					await this.addMessage(this.mailbox, "notify", obj)
					this.receiveNotify.push(obj)
				}
			}

			await set("lastSyncDate", data.time_from_server, this.mailbox)
		}
	}

	@action registerMethod(userId) {
		this.connection.on("ReceiveMessage", async (time, user, message, type) => {
			this.mailbox = new Store(`mailbox-${userId}`, "mailbox")

			if (type === "message") {
				await this.addMessage(this.mailbox, user, {time, message, folder: "inbox", type})
				await set("lastSyncDate", time, this.mailbox)

				//otvori sa okno usera ktory mi pise
				if (isSafe(this.data[user])) {
					let value = await get(user, this.mailbox)
					// logger("mailbox", value)
					if (value) {
						this.data[user] = value
					}
				} else {
					this.receiveMessages.push(user)
				}
			}

			if (type === "notify") {
				await this.addMessage(this.mailbox, "notify", {time, message, folder: "inbox", type, new: true})

				this.receiveNotify.push({time, message, folder: "inbox", type, new: true})
			}
		})

		this.connection.on("OnlineUsersChanged", (users) => {
			this.onlineUsers = users
		})
	}

	@action async sendMessage(userIds, message) {
		const user = getUser()
		this.mailbox = new Store(`mailbox-${user.user._id}`, "mailbox")
		const data = await this.connection.invoke(
			"SendMessage",
			`IXS ${user.ixstoken || ""}`,
			[userIds],
			message,
			"message"
		)

		if (data) {
			for (const user of [userIds]) {
				await this.addMessage(this.mailbox, user, {
					time: data.last_changed,
					message,
					folder: "outbox",
					type: "message"
				})
			}

			await set("lastSyncDate", data.last_changed, this.mailbox)
			// logger("userIds", userIds)
			let value = await get(userIds, this.mailbox)
			// logger("mailbox", value)
			if (value) {
				this.data[userIds] = value
			}
			// logger("ak odoslem spravu, treba refreshnut ")
		}
	}

	@action async sendNotification(userIds, form) {
		const formValues = form.values()
		const user = getUser()

		logger("formValues", formValues)
		this.mailbox = new Store(`mailbox-${user.user._id}`, "mailbox")
		const data = await this.connection.invoke(
			"SendNotification",
			`IXS ${user.ixstoken || ""}`,
			userIds,
			formValues.message,
			moment(formValues.valid_from).utc(),
			moment(formValues.valid_to).utc(),
			formValues.priority
		)

		if (data) {
			for (const user of userIds) {
				await this.addMessage(this.mailbox, user, {
					time: data.last_changed,
					message: formValues.message,
					folder: "outbox",
					type: "notify"
				})
			}

			await set("lastSyncDate", data.last_changed, this.mailbox)
		}
	}

	@action getUsers() {
		api
			.getUsers()
			.call()
			.then((users) => {
				this.allUsers = users.rows
				this.users = users.rows
			})
	}

	@action openSidebar() {
		this.isOpenSidebar = true
	}

	@action closeSidebar() {
		this.isOpenSidebar = false
	}

	@action openNotification() {
		this.isOpenNotification = true
	}

	@action closeNotification() {
		this.isOpenNotification = false
		this.selectedNotify = null
	}

	@action async loadNotify() {
		const me = getUser()

		const mailbox = new Store(`mailbox-${me.user._id}`, "mailbox")
		const notify = await get("notify", mailbox)

		this.receiveNotify = notify
	}
}

var singleton = new NotificationCenterStore()
export default singleton
