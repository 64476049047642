"use strict"

import {observable, action} from "mobx"
import moment from "moment"
import {getSelectedOrgUnitResourceID, getSelectedOrgUnitID, getUserCompanyInfo} from "../../../global/helpers/actions"
import Diacritics from "../../../global/helpers/diacritics"
import DataStore from "./DataStore"
import UIStore from "./UIStore"
import GlobalStore from "../../../global/store/GlobalStore"
import api from "../actions/api"
import settings from "../../../global/config/settings"
import {RESOURCES} from "../../../global/config/constants"
import {StorageBase} from "../../../global/storage/storageEx"

class CalendarStore {
	@observable countDays = 0
	@observable monthlyEvents = undefined
	@observable showDatePicker = false
	@observable calendarToggledFull = false

	@observable patientDraftIds = []

	@observable inputDateTimeIsValid = ""
	@observable selectedDay = moment().set("minute", this.getRoundMinutes(moment()))
	activeWeek = moment()
		.startOf("week")
		.utc()
		.format()
	activeMonth = moment()
		.startOf("month")
		.format()
	@observable selectedSlotDateTime = ""
	@observable orderedPatientsData = {}

	@observable eventsByDay = []
	@observable resourceData = []
	allResourceData = []

	@observable eventsData = []
	allData = []

	@observable countEvents = 0

	@observable disabledMultiBtn = false

	dailyViewRef = null

	@action setCalendarRange(range, setPos = false) {
		delayedCallback(500, () => {
			switch (range) {
				case "today":
					UIStore.calendarTimePeriod = "today"
					this.selectedDay = moment().set("minute", this.getRoundMinutes(moment()))
					this.inputDateTimeIsValid = this.getRoundTime(moment())

					this.getEvents(setPos)
					break
				case "tomorrow":
					UIStore.calendarTimePeriod = "tomorrow"
					this.selectedDay = moment()
						.add(1, "day")
						.set("minute", this.getRoundMinutes(moment()))
					this.inputDateTimeIsValid = this.getRoundTime(moment().add(1, "day"))
					this.getEvents()
					break
				case "otherDay":
					UIStore.calendarTimePeriod = "otherDay"
					this.getEvents(setPos)
					break
				case "week":
					UIStore.calendarTimePeriod = "week"
					this.activeWeek = moment(this.selectedDay)
						.clone()
						.startOf("week")
						.utc()
						.format()
					DataStore.calendarFrom = moment(this.activeWeek)
					DataStore.calendarTo = moment(this.activeWeek)
						.clone()
						.add(7, "days")
					this.selectedDay = moment(this.activeWeek)
						.clone()
						.startOf("week")
					this.inputDateTimeIsValid = this.getRoundTime(moment(this.activeWeek).startOf("week"))
					this.getEvents()
					break
				case "month":
					UIStore.calendarTimePeriod = "month"
					this.activeMonth = moment(this.selectedDay)
						.clone()
						.startOf("month")
						.format()
					this.selectedDay = moment(this.activeMonth)
						.clone()
						.startOf("month")
					this.inputDateTimeIsValid = this.getRoundTime(moment(this.activeMonth).startOf("month"))
					this.getMonthEvent()
					break
				case "resources":
					UIStore.calendarTimePeriod = "resources"
					this.getEvents()
			}
		})
	}

	eventFilterValue = ""

	@action eventFilter(searchValue) {
		this.eventFilterValue = searchValue
		delayedCallback(500, () => {
			if (isNotEmpty(searchValue)) {
				if (UIStore.calendarTimePeriod == "resources") {
					let filteredResources = {}
					Object.keys(this.allResourceData).forEach((resId) => {
						Object.keys(this.allResourceData[resId]).forEach((hour) => {
							let hourFilteredData = this.allResourceData[resId][hour].filter(
								(x) =>
									isSafe(x.client) &&
									(Diacritics.remove(x.client.client_text)
										.toLowerCase()
										.includes(Diacritics.remove(searchValue).toLowerCase()) ||
										Diacritics.remove(x.services[0].service_name_ext)
											.toLowerCase()
											.includes(Diacritics.remove(searchValue).toLowerCase()) ||
										Diacritics.remove(x.client.insurer_short_name).toLowerCase() ==
											Diacritics.remove(searchValue).toLowerCase())
							)

							if (isSafe(hourFilteredData) && hourFilteredData.length > 0) {
								if (!filteredResources.hasOwnProperty(resId)) {
									filteredResources[resId] = {}
								}

								if (!filteredResources[resId].hasOwnProperty(hour)) {
									filteredResources[resId][hour] = hourFilteredData
								}
							}
						})
					})

					this.resourceData = filteredResources
				} else {
					this.eventsData = this.allData.filter(
						(x) =>
							isSafe(x.event) &&
							isSafe(x.event.client) &&
							(Diacritics.remove(x.event.client.client_text)
								.toLowerCase()
								.includes(Diacritics.remove(searchValue).toLowerCase()) ||
								Diacritics.remove(x.event.services[0].service_name_ext)
									.toLowerCase()
									.includes(Diacritics.remove(searchValue).toLowerCase()) ||
								Diacritics.remove(x.event.client.insurer_short_name).toLowerCase() ==
									Diacritics.remove(searchValue).toLowerCase())
					)
				}
			} else {
				this.eventsData = this.allData
				this.resourceData = this.allResourceData
			}
		})
	}

	@action getEvents(setPosition = false) {
		let values = []

		if (UIStore.calendarTimePeriod == "resources") {
			const resources = StorageBase.getObjectByKey(RESOURCES)

			if (isSafe(resources) && resources.length > 0) {
				resources.forEach((res) => {
					if (isNotEmpty(res.resource_id)) {
						values.push({
							id_value: res.resource_id
						})
					}
				})
			}
		} else {
			values.push({
				id_value: getSelectedOrgUnitResourceID()
			})
		}

		let from = moment(this.selectedDay)
			.startOf("day")
			.utc()
			.format()

		let to = moment(this.selectedDay)
			.endOf("day")
			.utc()
			.format()

		if (
			UIStore.calendarTimePeriod == "today" ||
			UIStore.calendarTimePeriod == "resources" ||
			UIStore.calendarTimePeriod == "otherDay"
		) {
			let request = {
				filters: [
					{
						associated_column: "company_id",
						values: [
							{
								id_value: getUserCompanyInfo().id
							}
						]
					}
				],
				time_from: from,
				time_to: to
			}

			api
				.loadDraftReport(request)
				.call()
				.then((res) => {
					if (isSafe(res) && isSafe(res.rows) && res.rows.length > 0) {
						this.patientDraftIds = res.rows.map((x) => x.patient_id)
					} else {
						this.patientDraftIds = []
					}
				})
		}

		if (UIStore.calendarTimePeriod === "week") {
			UIStore.isFormSaving = true
			from = moment(this.selectedDay)
				.startOf("week")
				.utc()
				.format()
			to = moment(this.selectedDay)
				.startOf("week")
				.add(7, "day")
				.utc()
				.format()
		}

		if (UIStore.calendarTimePeriod === "month") {
			UIStore.isFormSaving = true
			from = moment(this.selectedDay)
				.startOf("week")
				.utc()
				.format()
			to = moment(this.selectedDay)
				.add(1, "month")
				.endOf("week")
				.utc()
				.format()
		}

		const req = {
			row_offset: 1,
			row_count_show: 10000,
			filters: [
				{
					associated_column: "primary_resource",
					values: values
				}
			],
			time_from: from,
			time_to: to
		}

		api
			.getServiceEventList(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					if (UIStore.calendarTimePeriod == "week") {
						let items = {}

						res.rows.forEach((event) => {
							const date = moment(event.interval_from).date()
							const hour = moment(event.interval_from).hours()

							if (isSafe(items[date])) {
								let hours = items[date][hour]
								if (isSafe(hours)) {
									hours.push(event)
								} else {
									items[date][hour] = [event] //items[+darr[2]][+tarr[0]]
								}
							} else {
								items[date] = {} //items[+darr[2]] = {}
								items[date][hour] = [event] //items[+darr[2]][+tarr[0]] = [event]
							}
						})

						let fromIsSame =
							moment(from).isSame(DataStore.calendarFrom, "day") &&
							moment(from).isSame(DataStore.calendarFrom, "month") &&
							moment(from).isSame(DataStore.calendarFrom, "year")

						let toIsSame =
							moment(to).isSame(DataStore.calendarTo, "day") &&
							moment(to).isSame(DataStore.calendarTo, "month") &&
							moment(to).isSame(DataStore.calendarTo, "year")

						if (fromIsSame && toIsSame) {
							this.eventsData = items
						}
					} else if (UIStore.calendarTimePeriod == "month") {
						let items = []

						res.rows.forEach((event) => {
							const month = moment(event.interval_from).month()
							const date = moment(event.interval_from).date()

							if (isSafe(items[month])) {
								// let day = items[month][date] //month[+darr[2]]
								if (isSafe(items[month][date])) {
									items[month][date].push(event)
								} else {
									items[month][date] = [event]
								}
							} else {
								items[month] = {}
								items[month][date] = [event]
							}
						})

						this.eventsData = items
					} else if (UIStore.calendarTimePeriod == "resources") {
						let items = {}

						res.rows.forEach((event) => {
							const resourceId = event.primary_resource._id
							const eventHour = moment(event.interval_from).hours()

							let resource = items[resourceId] //items[+darr[2]]
							if (isSafe(resource)) {
								let hour = resource[eventHour]
								if (isSafe(hour)) {
									hour.push(event)
								} else {
									items[resourceId][eventHour] = [event] //items[+darr[2]][+tarr[0]]
								}
							} else {
								items[resourceId] = {} //items[+darr[2]] = {}
								items[resourceId][eventHour] = [event] //items[+darr[2]][+tarr[0]] = [event]
							}
						})

						this.allResourceData = items

						if (isEmpty(this.eventFilterValue)) {
							this.resourceData = items
						} else {
							this.eventFilter(this.eventFilterValue)
						}
					} else {
						let dayNum = moment(this.selectedDay).day()
						let openFrom = undefined
						let openTo = undefined

						if (isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[dayNum])) {
							openFrom = +GlobalStore.openingHour[dayNum].from
							openTo = +GlobalStore.openingHour[dayNum].to
						}

						const events = res.rows

						let items = []

						if (events.length > 0) {
							if (isSafe(openFrom) && isSafe(openTo)) {
								for (let i = 0; i < events.length; i++) {
									let eventFrom = moment(events[i].interval_from)
									if (
										i === 0 &&
										moment().isBefore(
											moment(this.selectedDay)
												.set("hour", openTo)
												.set("minute", 0)
										) &&
										(openFrom < eventFrom.hours() || (openFrom == eventFrom.hours() && 0 < eventFrom.minutes()))
									) {
										let freeTime = eventFrom.hours() * 60 + eventFrom.minutes() - openFrom * 60
										items.push({
											timeFrom: moment(eventFrom)
												.clone()
												.set("hour", openFrom)
												.set("minute", 0),
											timeTo: eventFrom,
											freeTime: freeTime
										})
									}

									items.push({time: eventFrom, event: events[i]})

									if (events.length > 1 && i < events.length - 1 && moment().isBefore(events[i + 1].interval_from)) {
										let duration =
											isSafe(events[i].services) &&
											events[i].services.length > 0 &&
											isSafe(events[i].services[0]) &&
											isSafe(events[i].services[0].duration)
												? +events[i].services[0].duration
												: 0
										let currEventMinutes =
											duration > 0
												? eventFrom.hours() * 60 + eventFrom.minutes() + duration / 60
												: eventFrom.hours() * 60 + eventFrom.minutes()
										let nextEventMinutes =
											moment(events[i + 1].interval_from).hours() * 60 + moment(events[i + 1].interval_from).minutes()

										if (currEventMinutes < nextEventMinutes) {
											let dur = duration > 0 ? duration : 0
											let freeTime = nextEventMinutes - currEventMinutes

											items.push({
												timeFrom: eventFrom.clone().add(dur / 60, "minutes"),
												timeTo: moment(events[i + 1].interval_from),
												freeTime: freeTime
											})
										}
									}

									if (i === events.length - 1) {
										let index = events.length - 1
										let lastEvent = events[index]
										let duration =
											isSafe(lastEvent.services) &&
											lastEvent.services.length > 0 &&
											isSafe(lastEvent.services[0]) &&
											isSafe(lastEvent.services[0].duration)
												? +lastEvent.services[0].duration
												: 0
										let endOfEventTime =
											duration > 0
												? moment(events[index].interval_from).add(duration / 60, "minutes")
												: moment(events[index].interval_from)

										if (
											openTo > endOfEventTime.hours() &&
											moment().isBefore(
												moment(this.selectedDay)
													.set("hour", openTo)
													.set("minute", 0)
											)
										) {
											let freeTime = openTo * 60 - (endOfEventTime.hours() * 60 + endOfEventTime.minutes())
											items.push({
												timeFrom: endOfEventTime,
												timeTo: moment(endOfEventTime)
													.clone()
													.set("hour", +openTo)
													.set("minute", 0),
												freeTime: freeTime
											})
										}
									}
								}
							} else {
								for (let i = 0; i < events.length; i++) {
									items.push({time: events[i].interval_from, event: events[i]})
								}
							}
						} else {
							if (
								isSafe(openFrom) &&
								isSafe(openTo) &&
								moment().isBefore(
									moment(this.selectedDay)
										.set("hour", openTo)
										.set("minute", 0)
								)
							) {
								let freeTime = (openTo - openFrom) * 60
								items.push({
									timeFrom: moment(this.selectedDay)
										.set("hour", openFrom)
										.set("minute", 0),
									timeTo: moment(this.selectedDay)
										.set("hour", openFrom)
										.set("minute", 0),
									freeTime: freeTime
								})
							}
						}

						this.allData = items

						if (isEmpty(this.eventFilterValue)) {
							this.eventsData = items
						} else {
							this.eventFilter(this.eventFilterValue)
						}
					}

					this.countEvents = res.rows.length

					if (setPosition) {
						this.dailyViewRef.setPos()
					}
				} else {
					this.eventsData = []
					this.allData = []
					this.allResourceData = []
					this.countEvents = 0
				}
				UIStore.isFormSaving = false
			})
	}

	@action getRoundTime(dateTime) {
		let minute = dateTime.minute()
		let result = ""

		if (Math.ceil(minute / 5) * 5 < 60) {
			result = dateTime.set("minute", Math.ceil(minute / 5) * 5).format("DD.MM.YYYY HH:mm")
		} else {
			result = dateTime.set("minute", 55).format("DD.MM.YYYY HH:mm")
		}

		return result
	}

	@action getRoundMinutes(dateTime) {
		let minute = dateTime.minute()

		let result = Math.ceil(minute / 5) * 5

		if (result >= 60) {
			result = 55
		}

		return result
	}

	@action handleEventsByDay(sDateTime) {
		let from = undefined
		let to = undefined

		if (moment(sDateTime).diff(moment().startOf("month")) >= 0) {
			if (moment(sDateTime).month() === moment().month() && moment(sDateTime).year() === moment().year()) {
				from = moment().format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			} else {
				from = moment(sDateTime)
					.startOf("month")
					.format(settings.DB_DATE_FORMAT)
				to = moment(sDateTime)
					.endOf("month")
					.format(settings.DB_DATE_FORMAT)
			}

			const eventsByDayRequest = {
				filters: [
					{
						associated_column: "resource_id",
						values: [
							{
								id_value: getSelectedOrgUnitResourceID()
							}
						]
					}
				],
				from: from,
				to: to,
				spare: false
			}

			if (isNotEmpty(from) && isNotEmpty(to)) {
				api
					.eventsByDay(eventsByDayRequest)
					.call()
					.then((response) => {
						if (isSafe(response) && isSafe(response.rows) && response.rows.length > 0) {
							let colors = []

							response.rows.forEach((row) => {
								if (row.duration > 0) {
									let openingTime = 0
									if (isSafe(GlobalStore.openingHour) && isSafe(GlobalStore.openingHour[moment(row.date).day()])) {
										let time = GlobalStore.openingHour[moment(row.date).day()]

										openingTime = (+time.to - +time.from) * 60 * 60
									}

									let durPercent = 100

									if (openingTime > 0) {
										durPercent = (row.duration / openingTime) * 100
									}

									if (durPercent > 80) {
										colors.push({date: row.date, color: "#921700"})
									} else if (durPercent > 60) {
										colors.push({date: row.date, color: "#c73a0a"})
									} else if (durPercent > 40) {
										colors.push({date: row.date, color: "#d77624"})
									} else if (durPercent > 20) {
										colors.push({date: row.date, color: "#e7a135"})
									} else if (durPercent > 0) {
										colors.push({date: row.date, color: "#f2c545"})
									}
								}
							})

							this.eventsByDay = colors
						}

						from = undefined
						to = undefined
					})
			}
		} else {
			this.eventsByDay = []
		}
	}

	@action invertColor = (color, fontColor, isEventEnd = false, inThePast = false, dailyView = false) => {
		if (isSafe(color) && isNotEmpty(color)) {
			let hex = color.slice(1)

			let r = parseInt(hex.slice(0, 2), 16)
			let g = parseInt(hex.slice(2, 4), 16)
			let b = parseInt(hex.slice(4, 6), 16)

			if (isEventEnd && !inThePast && !dailyView) {
				let borderColor = `rgb(${r}, ${g}, ${b})`
				let backgroundColor = "#ffffff"
				let textColor = `rgb(${r}, ${g}, ${b})`

				if (isSafe(fontColor)) {
					textColor = fontColor
				}

				return {color: textColor, backgroundColor: backgroundColor, borderColor: borderColor, fontWeight: 400}
			} else {
				let borderColor = `rgb(${r}, ${g}, ${b})`
				let backgroundColor = `rgba(${r}, ${g}, ${b}, 0.7)`
				let textColor = "#000000"

				if (isSafe(fontColor)) {
					textColor = fontColor
				} else {
					if (186 > r * 0.299 + g * 0.587 + b * 0.114) {
						textColor = "#ffffff"
					}
				}

				return {color: textColor, backgroundColor: backgroundColor, borderColor: borderColor, fontWeight: 300}
			}
		} else {
			return {color: "white", backgroundColor: "rgba(128, 128, 128, 0.7)", borderColor: "rgba(128, 128, 128)"}
		}
	}

	@action loadPatientsOrder(from, to) {
		const providerID = getSelectedOrgUnitID()
		const request = {
			time_from: from,
			time_to: to
		}

		api
			.loadPatientsAgenda(providerID, request)
			.call()
			.then((events) => {
				let items = {}
				const localEvents = events.map((event) => {
					return {event: event, from: moment(event.interval.from)}
				})

				localEvents.forEach((localEvent) => {
					const localHour = localEvent.from.hours()

					if (!items.hasOwnProperty(localHour)) {
						items[localHour] = [localEvent.event]
					} else {
						items[localHour].push(localEvent.event)
					}
				})
				this.orderedPatientsData = items
			})
	}

	/* ---------- WeeklyView ---------- */

	onLoadPeviousWeek = () => {
		// this.activeWeek = moment(this.activeWeek).add(-7, "days")
		this.selectedDay = moment(this.activeWeek).add(-7, "days")
		this.setCalendarRange("week")
	}

	onLoadNextWeek = () => {
		// this.activeWeek = moment(this.activeWeek).add(7, "days")
		this.selectedDay = moment(this.activeWeek).add(7, "days")
		this.setCalendarRange("week")
	}

	/* ---------- MonthlyView ---------- */

	@action getMonthEvent() {
		const dateFrom = moment(this.activeMonth)
			.startOf("month")
			.startOf("week")
		const dateTo = moment(this.activeMonth)
			.endOf("month")
			.endOf("week")
		DataStore.calendarFrom = dateFrom
		DataStore.calendarTo = dateTo

		const countDays = dateTo.diff(dateFrom, "days")

		let eventDays = []
		for (let i = 0; i <= countDays; i++) {
			const startDay = dateFrom.clone()
			const day = startDay.add(i, "day")
			eventDays.push(day.format(settings.DB_DATE_FORMAT))
		}

		this.monthlyEvents = eventDays
		this.getEvents()
	}

	onLoadPeviousMonth = () => {
		// this.activeMonth = moment(this.activeMonth).add(-1, "M")
		this.selectedDay = moment(this.activeMonth)
			.clone()
			.add(-1, "M")
		this.setCalendarRange("month")
	}

	onLoadNextMonth = () => {
		// this.activeMonth = moment(this.activeMonth).add(1, "M")
		this.selectedDay = moment(this.activeMonth)
			.clone()
			.add(1, "M")
		this.setCalendarRange("month")
	}

	onLoadActualMonth = () => {
		this.activeMonth = moment()
			.startOf("month")
			.format()
		this.setCalendarRange("month")
	}
}

var singleton = new CalendarStore()
export default singleton
