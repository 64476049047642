import React from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
// import moment from "moment"
// import api from "../../../actions/api"
import moment from "moment"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"
import XsDateTimePicker from "../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsSearchSelect from "../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsSearchdropdownNoForm from "../../../../global/ui/xsSearchdropdown/xsSearchdropdownNoForm"

import fields from "./exportEventsToXlsFields"
import bindings from "../../../../global/ui/globalUISchemeBindings"

import DataStore from "../../stores/DataStore"
import UIStore from "../../stores/UIStore"
import GlobalStore from "../../../../global/store/GlobalStore"
import CalendarStore from "../../stores/CalendarStore"
// import GlobalStore from "../../../../../global/store/GlobalStore"
import ExportEventsToXlsDialogStore from "../../stores/ExportEventsToXlsDialogStore"
import XsLoading from "../../../../global/ui/xsLoading/xsLoading"

@injectIntl
@observer
export default class ExportEventsToXlsDialog extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				ExportEventsToXlsDialogStore.ExportToXls(form)
			},
			onError() {
				GlobalStore.setNotificationMessage(props.intl.formatMessage({id: "Common.warning.message.emptyform"}))
			}
		}
		this.form = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})

		GlobalStore.refreshCodeLists(["eventStatus"])
	}

	render() {
		if (UIStore.calendarTimePeriod == "month") {
			this.form.$("from").set("value", moment(CalendarStore.selectedDay).startOf("month"))
			this.form.$("to").set("value", moment(CalendarStore.selectedDay).endOf("month"))
		} else if (UIStore.calendarTimePeriod == "week") {
			this.form.$("from").set("value", moment(CalendarStore.selectedDay).startOf("week"))
			this.form.$("to").set("value", moment(CalendarStore.selectedDay).endOf("week"))
		} else {
			this.form.$("from").set("value", moment(CalendarStore.selectedDay).startOf("day"))
			this.form.$("to").set("value", moment(CalendarStore.selectedDay).endOf("day"))
		}

		return (
			<div>
				{UIStore.isFormSaving && <XsLoading overlay={true} />}
				<Dialog
					id="xsExportEventsToXlsDialog"
					open={ExportEventsToXlsDialogStore.isOpen}
					onClose={() => (this.form.reset(), ExportEventsToXlsDialogStore.close())}
					className="xs-base-dialog"
					classes={{
						paper: "xs-paper-dialog xs-width-paper-600"
					}}
					disableBackdropClick={true}
					maxWidth="md"
				>
					<DialogTitle className="xs-info">
						<div className="xs-header">
							<div
								className="xs-absolute xs-close-btn"
								onClick={() => (this.form.reset(), ExportEventsToXlsDialogStore.close())}
							>
								<i className="fal fa-times fa-lg" />
							</div>
							<div className="xs-header-icon">
								<i className="fal fa-file-excel fa-2x" />
							</div>
							<div className="xs-header-title">
								<FormattedMessage id="Common.label.export" />
							</div>
						</div>
					</DialogTitle>
					<DialogContent className="xs-content">
						<Grid container spacing={8}>
							<Grid item xs={6}>
								<XsDateTimePicker field={this.form.$("from")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={6}>
								<XsDateTimePicker field={this.form.$("to")} showTimeSelect={false} />
							</Grid>
							<Grid item xs={6}>
								<div className="searchDropdownDiv">
									<div style={{fontSize: "10px", paddingBottom: "4px", color: "#78849E", paddingLeft: "7px"}}>
										{<FormattedMessage id="Common.label.providedServices" />}
									</div>
									<XsSearchdropdownNoForm
										// label={<FormattedMessage id="Capitation.form.insurers" />}
										style={{
											backgroundColor: "rgb(241, 243, 248)",
											border: "1px solid rgb(192, 197, 207)",
											color: "#60606F"
										}}
										id="provided_service"
										field={this.form.$("provided_service")}
										items={DataStore.resourcesData.map((res) => {
											return {
												secondaryText: res.name_ext,
												// primaryText: res.name_ext,
												search_string: res.name_ext,
												id: res._id,
												checkValue: false
											}
										})}
										multiSelect={true}
										onClose={(data) => {
											this.form.$("provided_service").set(data)
											// ExportCapitationDialogStore.insurers = data
										}}
									/>
								</div>
								{/* <XsSearchSelect field={this.form.$("provided_service")} items={DataStore.resourcesData} /> */}
							</Grid>
							<Grid item xs={6}>
								<XsSearchSelect field={this.form.$("status_id")} items={GlobalStore.CL["eventStatus"]} />
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className="xs-footer xs-space-between">
						<XsButton
							className="xs-danger xs-outline"
							onClick={() => {
								this.form.reset()
								ExportEventsToXlsDialogStore.close()
							}}
							text={<FormattedMessage id="Common.label.cancel" />}
							icon={<i className="fal fa-times fa-lg" />}
						/>
						<XsButton
							className="xs-success"
							type="submit"
							onClick={(e) => {
								this.form.onSubmit(e)
							}}
							text={<FormattedMessage id="Common.label.exportingToExcel" />}
							icon={<i className="fal fa-file-excel fa-lg" />}
						/>
					</DialogActions>
				</Dialog>
			</div>
		)
	}
}
