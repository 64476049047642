import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"
import Grid from "@material-ui/core/Grid"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import classnames from "classnames"

import {Table, TableBody, TableCell, TableHead, TableRow, TablePagination} from "@material-ui/core"

import TablePaginationActions from "../../../../../global/ui/xsTableServer/xsTablePaginationActions"

// special import for Material-UI binding
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import SearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import ExportPatientWithoutPPToXlsDialog from "./exportPatientWithoutPPToXlsDialog"
import PatientsWithoutPPStore from "../../../stores/PatientsWithoutPPStore"
import RouterStore from "../../../../../global/store/RouterStore"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"

// definitions of form fields
import fields from "./patientsWithoutPPListFields"

import moment from "moment"
import {getUserPersonnelOrgUnits} from "../../../../../global/helpers/actions"

@injectIntl
@observer
export default class PatientsWithoutPPList extends React.Component {
	constructor(props) {
		super(props)
		UIStore.isFormSaving = false

		let page = 0
		let rowsPerPage = 10

		if (isNotEmpty("patientsWithoutPPList") && TableStore.tables.hasOwnProperty("patientsWithoutPPList")) {
			page = TableStore.tables["patientsWithoutPPList"].page
		}

		if (isNotEmpty("patientsWithoutPPList") && TableStore.tables.hasOwnProperty("patientsWithoutPPList")) {
			rowsPerPage = TableStore.tables["patientsWithoutPPList"].rowsPerPage
		}

		this.state = {
			page: page,
			rowsPerPage: rowsPerPage,
			order: false,
			orderBy: null,
			sortType: "",
			filters: {
				time_from: moment()
					.startOf("month")
					.format("YYYY-MM-DD"),
				time_to: moment()
					.endOf("month")
					.format("YYYY-MM-DD"),
				insurance_type_id: null
			},
			loadingInsurers: true
		}

		PatientsWithoutPPStore.page = 0
		PatientsWithoutPPStore.rowsPerPage = 10

		const hooks = {
			onSubmit() {},
			onSuccess() {},
			onError() {}
		}

		if (isEmpty(PatientsWithoutPPStore.formRef)) {
			PatientsWithoutPPStore.formRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = PatientsWithoutPPStore.formRef
		PatientsWithoutPPStore.getFilters(this.form, this.state)
	}

	handleChangePage = (event, page) => {
		if (
			isNotEmpty("patientsWithoutPPList") &&
			TableStore.tables.hasOwnProperty("patientsWithoutPPList") &&
			TableStore.tables["patientsWithoutPPList"].hasOwnProperty("index")
		) {
			delete TableStore.tables["patientsWithoutPPList"]["index"]
		}

		this.setState(
			{
				page
			},
			PatientsWithoutPPStore.prepareReport({rowsPerPage: this.state.rowsPerPage, page: page})
		)
	}

	handleChangeRowsPerPage = (event) => {
		if (
			isNotEmpty("patientsWithoutPPList") &&
			TableStore.tables.hasOwnProperty("patientsWithoutPPList") &&
			TableStore.tables["patientsWithoutPPList"].hasOwnProperty("index")
		) {
			delete TableStore.tables["patientsWithoutPPList"]["index"]
		}

		this.setState(
			{
				rowsPerPage: event.target.value
			},
			PatientsWithoutPPStore.prepareReport({rowsPerPage: event.target.value, page: this.state.page})
		)
	}

	componentWillUnmount() {
		if (isSafe(PatientsWithoutPPStore.refreshInterval)) {
			clearInterval(PatientsWithoutPPStore.refreshInterval)
		}
	}

	onRowClick = (dataRow, idx) => {
		if (dataRow.person_id) {
			TableStore.tables["patientsWithoutPPList"] = {
				index: idx,
				rowsPerPage: this.state.rowsPerPage,
				page: this.state.page
			}

			RouterStore.push(`/patient?id=${dataRow.person_id}`)
		}
	}

	render() {
		PatientsWithoutPPStore.isChangedFilter(this.form.values())

		return (
			<Grid container className="xs-dailyReport-list">
				<Grid item xs={12}>
					<Grid container spacing={8} alignItems="center">
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("orgunit")} items={getUserPersonnelOrgUnits()} required />
						</Grid>
						<Grid item xs={2}>
							<SearchSelect white field={this.form.$("insurer")} items={RegistersCacheStore.insurersRegister} />
						</Grid>
						<Grid item xs={2}>
							<XsInput white field={this.form.$("identifier")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								className="xs-default xs-outline"
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								onClick={() => {
									if (
										TableStore.tables.hasOwnProperty("patientsWithoutPPList") &&
										TableStore.tables["patientsWithoutPPList"].hasOwnProperty("index")
									) {
										delete TableStore.tables["patientsWithoutPPList"]["index"]
									}

									PatientsWithoutPPStore.getFilters(this.form, this.state)
								}}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
						<Grid item xs={5}>
							<Grid container justify="flex-end" spacing={8}>
								<Grid item>
									<XsButton
										className={"xs-primary"}
										text={<FormattedMessage id="Common.label.export" />}
										icon={<i className="fal fa-file-excel fa-lg" />}
										onClick={() => {
											PatientsWithoutPPStore.open()
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					{PatientsWithoutPPStore.changedFilters ? (
						<Grid container direction="column">
							<Grid item>
								<div className="xs-table-no-data pa-3 borderRadius-1 mt-8">
									<i
										className="far fa-search fa-lg mr-3 pointer"
										onClick={() => {
											if (
												TableStore.tables.hasOwnProperty("patientsWithoutPPList") &&
												TableStore.tables["patientsWithoutPPList"].hasOwnProperty("index")
											) {
												delete TableStore.tables["patientsWithoutPPList"]["index"]
											}

											PatientsWithoutPPStore.getFilters(this.form, this.state)
										}}
									></i>
									<FormattedMessage id="Common.label.changeFilterSettingsClkickOnTheMagnifyingToApplyThem" />
								</div>
							</Grid>
						</Grid>
					) : (
						<Grid container direction="column">
							<Grid item>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell>
												<FormattedMessage id="Common.label.fullName" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Patient.labels.IDNumber" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.insurer" />
											</TableCell>
											<TableCell>
												<FormattedMessage id="Common.label.dateOfNextPP" />
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{isSafe(PatientsWithoutPPStore.patientWithoutData) &&
										PatientsWithoutPPStore.patientWithoutData.length > 0 ? (
											PatientsWithoutPPStore.patientWithoutData.map((currRow, idx) => {
												let selectedRow = false

												if (
													TableStore.tables.hasOwnProperty("patientsWithoutPPList") &&
													isNotEmpty(TableStore.tables["patientsWithoutPPList"].index) &&
													idx == TableStore.tables["patientsWithoutPPList"].index
												) {
													selectedRow = true
												}

												return (
													<TableRow key={idx}>
														<TableCell
															className={classnames("blueDark pointer xs-bold", {bgBlueLight: selectedRow})}
															onClick={() => this.onRowClick(currRow, idx)}
														>
															{currRow.full_name}
														</TableCell>
														<TableCell className={classnames({bgBlueLight: selectedRow})}>
															{isSafe(currRow.identifier) ? currRow.identifier : ""}
														</TableCell>
														<TableCell className={classnames({bgBlueLight: selectedRow})}>
															{isSafe(currRow.insurer) ? currRow.insurer.name_ext : ""}
														</TableCell>
														<TableCell className={classnames({bgBlueLight: selectedRow})}>
															{isSafe(currRow.next_date) ? moment(currRow.next_date).format("DD.MM.YYYY") : ""}
														</TableCell>
													</TableRow>
												)
											})
										) : (
											<TableRow key={1}>
												<TableCell className="xs-table-no-data" colSpan={5}>
													<FormattedMessage id={PatientsWithoutPPStore.infoMessage} />
												</TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Grid>
							<Grid item>
								<div className="xs-grid-footer">
									<TablePagination
										component="div"
										className="xs-table-pagination"
										count={PatientsWithoutPPStore.totalCount}
										// disabled={this.state.loadingInsurers}
										rowsPerPage={this.state.rowsPerPage}
										rowsPerPageOptions={[5, 10, 25, 50]}
										labelRowsPerPage={<FormattedMessage id="Table.Pagination.RowPerPage" />}
										labelDisplayedRows={(pager) =>
											`${pager.from}-${pager.to} ${this.props.intl.formatMessage({id: "Table.Pagination.Of"})} ${
												pager.count
											}`
										}
										page={this.state.page}
										onChangePage={this.handleChangePage}
										onChangeRowsPerPage={this.handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
				<ExportPatientWithoutPPToXlsDialog />
			</Grid>
		)
	}
}
