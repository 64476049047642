import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage} from "react-intl"
import moment from "moment"
import classnames from "classnames"

import {Grid, Dialog, DialogContent, DialogTitle, DialogActions} from "@material-ui/core"

import XsButton from "../../../../global/ui/xsButton/xsButton"

import NotificationCenterStore from "./NotificationCenterStore"

@observer
export default class NotificationDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsNotificationDialog"
				open={NotificationCenterStore.isOpenNotification}
				onClose={() => NotificationCenterStore.closeNotification()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa-bell fa-2x" />
						</div>
						<div className="xs-header-title">
							<FormattedMessage id="Common.label.warning" />
						</div>
					</div>
				</DialogTitle>
				<DialogContent className="pa-4">
					<Grid container direction="column">
						{isSafe(NotificationCenterStore.selectedNotify) &&
						isNotEmpty(NotificationCenterStore.selectedNotify.message) ? (
							<React.Fragment>
								<Grid
									item
									container
									className="xs-bold pointer"
									onClick={() => (NotificationCenterStore.selectedNotify = null)}
								>
									<Grid item className="pr-2">
										<i className="fas fa-arrow-left"></i>
									</Grid>
									<Grid item>{moment(NotificationCenterStore.selectedNotify.time).format("DD.MM.YYYY HH:mm:ss")}</Grid>
								</Grid>
								<Grid item className="pt-6">
									{NotificationCenterStore.selectedNotify.message}
								</Grid>
							</React.Fragment>
						) : (
							<React.Fragment>
								{isSafe(NotificationCenterStore.receiveNotify) && NotificationCenterStore.receiveNotify.length > 0 ? (
									NotificationCenterStore.receiveNotify
										.sort((a, b) => moment(b.time).format("YYYYMMDDHHmmss") - moment(a.time).format("YYYYMMDDDHHmmss"))
										.map((not, idx) => (
											<Grid
												item
												container
												key={idx}
												className={classnames("pa-3 borderRadius-1 borderGreyLight pointer mt-1", {
													bgWhite: not.new,
													bgSnowWhite: !not.new
												})}
												onClick={() => {
													NotificationCenterStore.selectedNotify = not
													if (not.new) {
														NotificationCenterStore.updateIndexDB(not)
													}
												}}
											>
												<Grid item xs={3}>
													{moment(not.time).format("DD.MM.YYYY HH:mm.ss")}
												</Grid>
												<Grid item xs={8} className="cutText">
													{not.message}
												</Grid>
												<Grid item xs={1} className="greenDark textCenter">
													{not.new ? "nová" : ""}
												</Grid>
											</Grid>
										))
								) : (
									<Grid item container justify="center" className="pa-3 borderRadius-1 bgWhite borderGreyLight mt-1">
										<Grid item className="xs-bold">
											Nenašli sa žiadne upozornenia
										</Grid>
									</Grid>
								)}
							</React.Fragment>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<Grid container justify="flex-end">
						<Grid item>
							<XsButton
								className="xs-default xs-outline"
								icon={<i className="fal fa-times fa-lg" />}
								text={<FormattedMessage id="Common.form.close" />}
								onClick={() => NotificationCenterStore.closeNotification()}
							/>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
		)
	}
}
