import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress, Grid} from "@material-ui/core"

import XsButton from "../../global/ui/xsButton/xsButton"
// import XsIconButton from "../../global/ui/xsButton/xsIconButton"
// import DeleteIcon from "@material-ui/icons/Clear"

import InstallGuideStore from "./InstallGuideStore"

@injectIntl
@observer
export default class InstallGuideDialog extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		return (
			<Dialog
				id="xsInstallGuideDialog"
				open={InstallGuideStore.isOpen}
				onClose={() => InstallGuideStore.close()}
				className="xs-base-dialog"
				classes={{
					paper: "xs-paper-dialog xs-width-paper-900"
				}}
				disableBackdropClick={true}
				maxWidth="md"
			>
				<DialogTitle className="xs-info">
					<div className="xs-header">
						<div className="xs-absolute xs-close-btn" onClick={() => InstallGuideStore.close()}>
							<i className="fal fa-times fa-lg" />
						</div>
						{InstallGuideStore.donwloading ? (
							<React.Fragment>
								<div className="xs-header-icon">
									{/* <i className="fal fa-clipboard-check fa-2x" /> */}
									<CircularProgress size={40} />
								</div>
								<div className="xs-header-title">Preberám technické komponenty, môže to chvíľu trvať...</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<div className="xs-header-icon">
									<i className="fal fa-book-open fa-2x" />
								</div>
								<div className="xs-header-title">Tchnické komponenty stiahnuté.</div>
							</React.Fragment>
						)}
						<div className="xs-header-subtitle">Prečítajte si návod na inštaláciu komponentu</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content overflowY">
					<Grid container direction="column">
						<Grid item className="xs-bold pb-2">
							<h4>Návod na inštaláciu</h4>
						</Grid>
						<Grid item>
							<p>
								Po dokončení sťahovania komponentu kliknite ľavým tlačidlom myši na stiahnutý inštalátor, ako je to
								zobrazené na obrázku.
							</p>
						</Grid>
						<Grid item container justify="center">
							<Grid item>
								<img src="/public/images/eHGWSetup.png" height="400" />
							</Grid>
						</Grid>
						<Grid item>
							<p>
								Po kliknutí sa spustí inštalácia. Podľa typu operačného systému možno ešte budete vyzvaní na potvrdenie
								spustenia inštalácie.
							</p>
							<p>
								Inštalácia prebehne automaticky. Po jej úspešnom dokončení sa vpravom dolnom rohu obrazovky (tzv.
								windows „tray“) zobrazí ikona aplikácie, ako je zobrazené na obrázku.
							</p>
						</Grid>
						<Grid item container justify="center">
							<Grid item>
								<img src="/public/images/windowsTray.png" height="150" />
							</Grid>
						</Grid>
						<Grid item>
							<p>
								Týmto je inštalácia dokončená. Po vložení vašej karty zdravotníckeho pracovníka (EPZP) do čítačky kariet
								sa môžete prihlásiť stlačením tlačidla „Prihlásiť“.
							</p>
							<p>
								POZOR, po vložení karty je potrebné chvíľu počkať (cca 15 sekúnd), kým operačný systém WINDOWS
								zaregistruje vašu kartu aaž následne stlačiť tlačidlo prihlásiť.
							</p>
							<p>Počas prihlásenia Vás môže systém niekoľkokrát vyzvať na zadanie vášho PIN kódu ku karte EPZP.</p>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer">
					<XsButton
						className="xs-default xs-outline"
						text={<FormattedMessage id="Common.label.ok" />}
						onClick={() => InstallGuideStore.close()}
					/>
				</DialogActions>
			</Dialog>
		)
	}
}
