"use strict"

import React from "react"
import {FormattedMessage, injectIntl} from "react-intl"
import classnames from "classnames"
import {observer} from "mobx-react"
import moment from "moment"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import bindings from "../../../../../global/ui/globalUISchemeBindings"
import fields from "./otherDeliveryListFilterFormFields"
import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"
import {Grid, Tooltip} from "@material-ui/core"
import GlobalStore from "../../../../../global/store/GlobalStore"
import OtherDeliveriesStore from "../../../stores/OtherDeliveriesStore"
import XsPeriodPicker from "../../../../../global/ui/xsPeriodPicker/xsPeriodPicker"
import XsMultiButton from "../../../../../global/ui/xsButton/xsMultiButton"
import {getSelectedOrgUnitID, getSelectedOrgUnit /*, getUserPersonnelID*/} from "../../../../../global/helpers/actions"
import XsSearchSelect from "../../../../../global/ui/xsSearchSelect/xsSearchSelect"
import XsInput from "../../../../../global/ui/xsInput/xsInput"
import {XsDateTimePicker} from "../../../../../global/ui/xsDateTimePicker/xsDateTimePicker"
import XsAutocompleteLocal from "../../../../../global/ui/xsInput/xsAutocompleteLocal"
import RegistersCacheStore from "../../../../../global/store/RegistersCacheStore"
import InvoiceEnterDialogStore from "../../../stores/InvoiceEnterDialogStore"
import EnterInvoiceNumberDialog from "../deliveryList/enterInvoiceNumberDialog"
import AddDeliveryStore from "../../../stores/AddDeliveryStore"
import GenerateInvoiceDialogStore from "../../../stores/GenerateInvoiceDialogStore"
import ExportRepairBatchStore from "../../../stores/ExportRepairBatchStore"
import UIStore from "../../../stores/UIStore"
import TableStore from "../../../stores/TableStore"

@injectIntl
@observer
export default class OtherDeliveryListFilter extends React.Component {
	constructor(props) {
		super(props)

		const hooks = {
			onSubmit(form) {
				logger("submit", form.values())
			},
			onSuccess(form) {
				logger("success", form.values())
			},
			onError() {}
		}

		if (isEmpty(OtherDeliveriesStore.filterRef)) {
			OtherDeliveriesStore.filterRef = new MobxReactForm(fields.load(), {plugins: {dvr: validatorjs}, hooks, bindings})
		}

		this.form = OtherDeliveriesStore.filterRef

		const payerTypeField = this.form.$("payerType")

		if (payerTypeField) {
			OtherDeliveriesStore.selectedPayerType = payerTypeField.get("value")
		}
	}

	render() {
		OtherDeliveriesStore.isChangedFilter(this.form.values())

		const provider_id = getSelectedOrgUnitID()
		const selectedOrgUnit = getSelectedOrgUnit()
		const personnel_expertise_id = isSafe(selectedOrgUnit) ? selectedOrgUnit.personnel_expertise_id : null

		let actions = [
			{
				label: <FormattedMessage id="Capitation.form.ExportBatch" />,
				icon: <i className="fal fa-save fa-lg" />,
				disabled: OtherDeliveriesStore.isEditable,
				onButtonClick: () => {
					InvoiceEnterDialogStore.open({
						doctor_expertise_id: +personnel_expertise_id,
						insurer_id: null,
						payer_type: this.form.$("payerType").value,
						provider_id: +provider_id,
						date_from: this.form.$("time_from").value,
						date_to: this.form.$("time_to").value,
						translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
						translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
							id: "Capitation.form.GetSummaryDeliveriesError"
						})
					})
				}
			},
			{
				label: <FormattedMessage id="Common.label.ExportRepairBatch" />,
				icon: <i className="fal fa-save fa-lg" />,
				disabled: OtherDeliveriesStore.isEditable || ExportRepairBatchStore.selectedIds.length == 0,
				onButtonClick: () => {
					ExportRepairBatchStore.open({
						doctor_expertise_id: +personnel_expertise_id,
						insurer_id: +this.form.$("insurer").value,
						provider_id: +provider_id,
						date_from: this.form.$("time_to").value,
						date_to: this.form.$("time_from").value,
						translatedExportBatchError: this.props.intl.formatMessage({id: "Capitation.form.ExportBatchError"}),
						translatedGetSummaryDeliveriesError: this.props.intl.formatMessage({
							id: "Capitation.form.GetSummaryDeliveriesError"
						})
					})
				}
			},
			{
				label: <FormattedMessage id="Common.label.export" />,
				icon: <i className="fal fa-file-excel fa-lg" />,
				disabled: OtherDeliveriesStore.isEditable,
				onButtonClick: () => {
					OtherDeliveriesStore.getFilters(true)
				}
			}
		]

		if (OtherDeliveriesStore.selectedPayerType === "P") {
			actions.push({
				label: <FormattedMessage id="Common.label.exportToPDF" />,
				icon: <i className="fal fa-file-pdf fa-lg" />,
				disabled: OtherDeliveriesStore.isEditable,
				onButtonClick: () => {
					OtherDeliveriesStore.getFilters(false, true)
				}
			})
		}

		return (
			<React.Fragment>
				<Grid item container justify="flex-end" alignItems="center" spacing={8}>
					{/* <Grid item>
						{!OtherDeliveriesStore.isPayerTypeSelected && (
							<React.Fragment>
								<i className="fas fa-exclamation-triangle fa-lg amberDark pr-2"></i>
								<span className="greyDark xs-bold">
									<FormattedMessage id="Common.label.payerTypeFilterRequired" />
								</span>
							</React.Fragment>
						)}
					</Grid> */}
					<Grid item>
						<XsButton
							disabled={OtherDeliveriesStore.isEditable}
							className={OtherDeliveriesStore.isEditable ? "xs-default xs-outline" : "xs-primary"}
							text={<FormattedMessage id="Capitation.form.ExportInvoice" />}
							icon={<i className="fal fa-save fa-lg" />}
							onClick={() => {
								UIStore.previousReportingZSDetailTab = "OtherDeliveriesList"

								let req = {
									from: this.form.$("time_from").value,
									to: this.form.$("time_to").value,
									org_unit_id: +provider_id,
									payer_type_id: this.form.$("payerType").value
								}

								if (ExportRepairBatchStore.selectedIds.length > 0) {
									req["filter_delivery_ids"] = ExportRepairBatchStore.selectedIds
								}

								GenerateInvoiceDialogStore.generateInvoiceOtherDeliveries(req)
							}}
						/>
					</Grid>
					<Grid item>
						<XsMultiButton className="xs-primary" menuWidth={200} buttons={actions} />
					</Grid>
					{OtherDeliveriesStore.isEditable ? (
						<React.Fragment>
							<Grid item>
								<XsButton
									className="xs-success"
									text={<FormattedMessage id="Common.label.save" />}
									icon={<i className="far fa-save-alt fa-lg"></i>}
									onClick={() => {
										if (OtherDeliveriesStore.isValidGrid) {
											OtherDeliveriesStore.saveChanges()
											OtherDeliveriesStore.isEditable = false
											OtherDeliveriesStore.changedData = {}
											OtherDeliveriesStore.changedKeysTest = {}
											OtherDeliveriesStore.removeDeliveryIds = []
										} else {
											GlobalStore.setNotificationMessage("Patient.form.patientPrescription.invalidForm")
										}
									}}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className="xs-danger xs-outline"
									onClick={() => {
										OtherDeliveriesStore.resetGrid()
									}}
									text={<FormattedMessage id="Common.label.cancel" />}
									icon={<i className="fal fa-times fa-lg" />}
								/>
							</Grid>
						</React.Fragment>
					) : (
						<React.Fragment>
							<Grid item>
								<XsButton
									className={classnames("xs-outline", {
										"xs-primary": !OtherDeliveriesStore.changedFilters,
										"xs-default": OtherDeliveriesStore.changedFilters
									})}
									disabled={OtherDeliveriesStore.changedFilters}
									text={<FormattedMessage id="Common.label.edit" />}
									onClick={() => {
										OtherDeliveriesStore.isEditable = true
										OtherDeliveriesStore.idSubItemsRow = ""
										ExportRepairBatchStore.selectedIds = []
									}}
									icon={<i className="far fa-pencil-alt fa-lg"></i>}
								/>
							</Grid>
							<Grid item>
								<XsButton
									className={classnames({
										"xs-success": !OtherDeliveriesStore.changedFilters,
										"xs-default xs-outline": OtherDeliveriesStore.changedFilters
									})}
									disabled={OtherDeliveriesStore.changedFilters}
									onClick={() => AddDeliveryStore.open("otherDelivery")}
									text={<FormattedMessage id="Common.label.addDelivery" />}
									icon={<i className="fal fa-plus" />}
								/>
							</Grid>
						</React.Fragment>
					)}
				</Grid>
				<Grid item>
					<Grid container alignItems="center" spacing={8}>
						<Grid item xs={2}>
							<XsPeriodPicker
								white
								disabled={OtherDeliveriesStore.isEditable}
								defaultValue={this.form.$("time_from").value}
								onChange={(val) => {
									this.form.$("time_from").value = val.from
									this.form.$("time_to").value = val.to
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								disabled={OtherDeliveriesStore.isEditable}
								field={this.form.$("from")}
								showTimeSelect={false}
								minDate={moment(this.form.$("time_from").value).startOf("month")}
								maxDate={moment(this.form.$("time_from").value).endOf("month")}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsDateTimePicker
								white
								disabled={OtherDeliveriesStore.isEditable}
								field={this.form.$("to")}
								showTimeSelect={false}
								minDate={moment(this.form.$("time_from").value).startOf("month")}
								maxDate={moment(this.form.$("time_from").value).endOf("month")}
							/>
						</Grid>
						<Grid item xs={2}>
							<XsSearchSelect
								white
								disabled={OtherDeliveriesStore.isEditable}
								field={this.form.$("payerType")}
								onChange={(val) => (OtherDeliveriesStore.selectedPayerType = val)}
								items={GlobalStore.CL["payerType"]}
								required
							/>
						</Grid>
						<Grid item xs={1}>
							<XsInput white disabled={OtherDeliveriesStore.isEditable} field={this.form.$("person_identifier")} />
						</Grid>
						<Grid item xs={2}>
							<XsAutocompleteLocal
								white
								disabled={OtherDeliveriesStore.isEditable}
								field={this.form.$("diagnosis")}
								minLengthForSearch="3"
								clearText
								data={RegistersCacheStore.diagnosisRegister}
								inputRenderer={(obj) => obj.code_ext + " " + obj.name_ext}
								saveValue={(obj) => obj.code}
								modalConfig={(textValue, clickHandler) => {
									return {
										columnDefs: {
											code_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesCode" />,
												type: "string",
												dbName: "code_ext",
												design: {
													width: "150px"
												},
												filter: {
													gridWidth: 3,
													defaultValue: "",
													renderElement: "input"
												}
											},
											name_ext: {
												title: <FormattedMessage id="Delivery.list.modal.diagnosesName" />,
												type: "string",
												dbName: "search_column",
												filter: {
													gridWidth: 9,
													defaultValue: textValue,
													renderElement: "input"
												}
											}
										},
										options: {
											showCursor: true,
											onRowClick: (dataRow) => {
												clickHandler(dataRow)
											}
										}
									}
								}}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsSearchSelect
								white
								disabled={OtherDeliveriesStore.isEditable}
								field={this.form.$("insurer")}
								items={RegistersCacheStore.insurersRegister}
							/>
						</Grid>
						<Grid item xs={1}>
							<XsInput disabled={OtherDeliveriesStore.isEditable} white field={this.form.$("deliveries")} />
						</Grid>
						<Grid item xs={1} className="pb-0">
							<XsIconButton
								rect
								disabled={OtherDeliveriesStore.isEditable}
								tooltip={this.props.intl.formatMessage({id: "Common.label.applyFilter"})}
								className="xs-default xs-outline"
								onClick={() => {
									if (isEmpty(this.form.$("payerType").value)) {
										GlobalStore.setNotificationMessage("Common.label.payerTypeFilterRequired")
									} else {
										if (
											isNotEmpty("otherDeliveryList") &&
											TableStore.tables.hasOwnProperty("otherDeliveryList") &&
											TableStore.tables["otherDeliveryList"].hasOwnProperty("index")
										) {
											delete TableStore.tables["otherDeliveryList"]["index"]
										}

										OtherDeliveriesStore.getFilters(false, false, true)
										OtherDeliveriesStore.page = 0
									}
								}}
								icon={<i className="far fa-search fa-lg xs-greyDefault"></i>}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container spacing={8} alignItems="center">
					<Grid item>
						<FormattedMessage id="Common.label.countSelectedItem" />:
					</Grid>
					<Grid item>{ExportRepairBatchStore.selectedIds.length}</Grid>
					<Grid item className="ml-3">
						<Tooltip
							title={this.props.intl.formatMessage({
								id: "Common.label cancelSelected"
							})}
						>
							<i
								className={classnames("fal fa-times fontSize21 pointer", {
									greyDefault: ExportRepairBatchStore.selectedIds.length == 0,
									blueDark: ExportRepairBatchStore.selectedIds.length > 0
								})}
								onClick={() => (ExportRepairBatchStore.selectedIds = [])}
							></i>
						</Tooltip>
					</Grid>
				</Grid>

				<EnterInvoiceNumberDialog
					insurers={RegistersCacheStore.insurersRegister}
					payers={GlobalStore.CL["payerType"]}
					isOtherDeliveries={true}
				/>
			</React.Fragment>
		)
	}
}
