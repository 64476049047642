export default {
	DEBUG: process.env.DEBUG || false,
	SERVERROLE: process.env.SERVERROLE || "PROD",
	API_BASE_URL: process.env.API_BASE_URL,
	API_LENGTH_THRESHOLD: 1048576,
	HOMEPAGE: process.env.HOMEPAGE || "/patients",
	SHOW_ERROR_DETAILS: process.env.SHOW_ERROR_DETAILS,
	GW_BASE_URL: process.env.GW_BASE_URL,
	API_CHAT_URL: process.env.API_CHAT_URL,
	MPDF_API: process.env.MPDF_API,
	// MPDF_API: "http://localhost/my-app/mpdf/api.php",
	BROWSER_LOCALE:
		process.env.BROWSER_LOCALE ||
		(navigator.languages && navigator.languages[0]) ||
		navigator.language ||
		navigator.userLanguage ||
		"sk-SK",
	DB_DATETIME_FORMAT: process.env.DB_DATETIME_FORMAT || "YYYY-MM-DDTHH:mm:ss.SSSZ",
	DB_DATE_FORMAT: process.env.DB_DATE_FORMAT || "YYYY-MM-DD",
	AUTOSAVE_INTERVAL: {
		local: process.env.AUTOSAVE_INTERVAL_LOCAL || 5000,
		server: process.env.AUTOSAVE_INTERVAL_SERVER || 300000
	},
	MIN_GW_VERSION: {
		major: 2,
		minor: 0,
		build: 0,
		revision: 0
	},
	API_CLIENT_TIMEOUT: 120000,
	OVERVIEW_PAGE_SIZE: 20,
	INVOICE_SETTINGS: {due_date_days: 21, default_constant_symbol: "0308"}
}
