"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import WarningStore from "../../../global/store/WarningStore"
import InvoiceFormStore from "../../ambulance/stores/InvoiceFormStore"
import UIStore from "../stores/UIStore"
import {getSelectedOrgUnitID} from "../../../global/helpers/actions"
import {StorageBase} from "../../../global/storage/storageEx"

class InvoiceEnterDialogStore {
	@observable isOpen = false
	@observable payers = []

	doctor_expertise_id = undefined
	insurer_id = undefined
	provider_id = undefined
	// disposition = undefined
	date_from = undefined
	date_to = undefined
	translatedExportBatchError = undefined
	translatedGetSummaryDeliveriesError = undefined

	invoiceNumber = ""
	batchType = undefined
	insurersData = []
	insurers = []
	dispositionData = [
		{
			secondaryText: "N",
			primaryText: "nová (SK poistenci)",
			search_string: "N - nová (SK poistenci)",
			id: "N",
			checkValue: true
		},
		{
			secondaryText: "E",
			primaryText: "nová (EU poistenci)",
			search_string: "E - nová (EU poistenci)",
			id: "E",
			checkValue: true
		},
		{
			secondaryText: "I",
			primaryText: "nová (Cudzinci a bezdomovci)",
			search_string: "I - nová (Cudzinci a bezdomovci)",
			id: "I",
			checkValue: true
		}
	]
	disposition = [
		{
			secondaryText: "N",
			primaryText: "nová (SK poistenci)",
			search_string: "N - nová (SK poistenci)",
			id: "N",
			checkValue: true
		},
		{
			secondaryText: "E",
			primaryText: "nová (EU poistenci)",
			search_string: "E - nová (EU poistenci)",
			id: "E",
			checkValue: true
		},
		{
			secondaryText: "I",
			primaryText: "nová (Cudzinci a bezdomovci)",
			search_string: "I - nová (Cudzinci a bezdomovci)",
			id: "I",
			checkValue: true
		}
	]

	formRef

	@action open(obj) {
		api
			.getDefaultBatchType({org_unit: getSelectedOrgUnitID()})
			.call()
			.then((res) => {
				this.formRef.$("batchType").set("value", res.code_ext)
			})

		if (isNotEmpty(obj.payer_type)) {
			this.formRef.$("payerType").set("value", obj.payer_type)
		}

		this.doctor_expertise_id = obj.doctor_expertise_id
		this.insurer_id = obj.insurer_id
		this.provider_id = obj.provider_id
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.isOpen = true
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
	}

	@action handleChange(key, value) {
		this[key] = value
	}

	@action openInvoiceOnly(obj, filterStateObj) {
		this.doctor_expertise_id = obj.doctor_expertise_id
		this.insurer_id = obj.insurer_id
		this.provider_id = obj.provider_id
		this.date_from = obj.date_from
		this.date_to = obj.date_to
		this.translatedExportBatchError = obj.translatedExportBatchError
		this.translatedGetSummaryDeliveriesError = obj.translatedGetSummaryDeliveriesError
		if (isSafe(filterStateObj)) {
			// Storage.setItem("deliveryListFilters", filterStateObj)
			StorageBase.updateByKey("deliveryListFilters", filterStateObj)
		}
		this.getSummaryDeliveries()
	}

	@action getSummaryDeliveries() {
		UIStore.isFormSaving = true
		api
			.loadSummaryDeliveries({
				from: this.date_from,
				to: this.date_to,
				insurer_id: this.insurer_id,
				org_unit_id: this.provider_id
			})
			.call()
			.then((data) => {
				let invoiceData = {}
				if (isSafe(data.sub_totals) && data.sub_totals.length > 0) {
					let items = []
					data.sub_totals.forEach((row) => {
						if (isSafe(row.sum) && row.sum > 0) {
							items.push({
								itemName:
									"Výkony (" +
									row.delivery_group.name_ext +
									(row.insurance_type._id == "GH" ? "" : " ,") +
									row.insurance_type.name_ext +
									")",
								itemUnitPrice: "",
								itemQuantity: "",
								itemPrice: row.sum
							})
						}
						if (isSafe(row.sum_sub) && row.sum_sub > 0) {
							items.push({
								itemName:
									"Pripočítateľné položky (" +
									row.delivery_group.name_ext +
									(row.insurance_type._id == "GH" ? "" : " ,") +
									row.insurance_type.name_ext +
									")",
								itemUnitPrice: "",
								itemQuantity: "",
								itemPrice: row.sum_sub
							})
						}
					})
					invoiceData = {items: items}
				}
				// if (isNotEmpty(this.invoiceNumber)) {
				// 	invoiceData["invoice_number"] = this.invoiceNumber
				// }
				InvoiceFormStore.setNewInvoiceData(invoiceData)
				UIStore.isFormSaving = false
			})
			.catch(() => {
				WarningStore.open(this.translatedGetSummaryDeliveriesError)
				UIStore.isFormSaving = false
			})

		this.isOpen = false
	}

	callbackFn = () => {
		// // logger("cislo fa", formData.$("invoice_number").get("value"))
		// const invoiceNumberFromInvoice = isSafe(formData) ? formData.$("invoice_number").get("value") : null
		// this.download(invoiceNumberFromInvoice)
	}

	@action download(invoiceNumber) {
		UIStore.isFormSaving = true
		api
			.downloadDeliveries(this.batchType, {
				doctor_expertise_id: this.doctor_expertise_id,
				insurer_id: this.insurer_id,
				provider_id: this.provider_id,
				disposition: this.disposition,
				invoice_number: isSafe(invoiceNumber) ? invoiceNumber : this.invoiceNumber,
				date: this.date_from
			})
			.call()
			.then((response) => {
				if (response.ok == true) {
					let fileName = isSafe(response.headers.get("content-disposition"))
						? response.headers.get("content-disposition").split("filename=")[1]
						: "davka.001"
					response.blob().then((blob) => {
						let FileSaver = require("file-saver") // eslint-disable-line no-undef
						FileSaver.saveAs(blob, fileName)
					})
					UIStore.isFormSaving = false
				} else {
					WarningStore.open(response.statusText)
					UIStore.isFormSaving = false
				}
			})
			.catch(() => {
				WarningStore.open(this.translatedExportBatchError)
				UIStore.isFormSaving = false
			})

		this.close()
	}

	@action downloadMultipleDeliveries(form) {
		UIStore.isFormSaving = true
		let invoicesIds = form.$("rows").value

		let counter = 0
		let totalCount = this.insurers.length * this.disposition.length

		this.insurers.forEach((ins) => {
			this.disposition.forEach((disp) => {
				let filter = {
					doctor_expertise_id: this.doctor_expertise_id,
					insurer_id: ins.id,
					provider_id: this.provider_id,
					invoice_number: invoicesIds.shift(),
					disposition: disp.id,
					date: this.date_from
				}

				if (isNotEmpty(form.$("payerType").value)) {
					filter["payer_type"] = form.$("payerType").value
				}

				api
					.downloadDeliveries(form.$("batchType").value, filter)
					.call()
					.then((response) => {
						counter++
						if (response.ok == true) {
							let fileName = isSafe(response.headers.get("content-disposition"))
								? response.headers.get("content-disposition").split("filename=")[1]
								: "davka.001"
							response.blob().then((blob) => {
								let FileSaver = require("file-saver") // eslint-disable-line no-undef
								FileSaver.saveAs(blob, fileName)
							})
							if (counter == totalCount) {
								UIStore.isFormSaving = false
							}
						} else {
							WarningStore.open(response.statusText)
							UIStore.isFormSaving = false
						}
					})
					.catch(() => {
						WarningStore.open(this.translatedExportBatchError)
						UIStore.isFormSaving = false
					})
			})
		})
		this.close()
	}

	@action close() {
		if (isSafe(this.formRef)) {
			this.formRef.reset()
			this.formRef.update({rows: []})
		}
		this.isOpen = false
		this.doctor_expertise_id = undefined
		this.insurer_id = undefined
		this.provider_id = undefined
		this.disposition = undefined
		this.date_from = undefined
		this.date_to = undefined
		this.insurers = []
		this.insurersData = []
		this.disposition = this.dispositionData = [
			{
				secondaryText: "N",
				primaryText: "nová (SK poistenci)",
				search_string: "N - nová (SK poistenci)",
				id: "N",
				checkValue: true
			},
			{
				secondaryText: "E",
				primaryText: "nová (EU poistenci)",
				search_string: "E - nová (EU poistenci)",
				id: "E",
				checkValue: true
			},
			{
				secondaryText: "I",
				primaryText: "nová (Cudzinci a bezdomovci)",
				search_string: "I - nová (Cudzinci a bezdomovci)",
				id: "I",
				checkValue: true
			}
		]
		this.invoiceNumber = ""
	}
}

var singleton = new InvoiceEnterDialogStore()
export default singleton
