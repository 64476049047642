import {observable, action} from "mobx"

import api from "../actions/api"
import {
	getSelectedOrgUnitID,
	getUserPersonnelID,
	printReport,
	getUserDoctorInfo,
	getSelectedOrgUnit
} from "../../../global/helpers/actions"
import GlobalStore from "../../../global/store/GlobalStore"
import DDaction from "../actions/patientCard"
import DataStore from "./DataStore"
import UIStore from "./UIStore"

class ScoringStore {
	@observable isOpen = false
	@observable scoringList = null
	@observable scoringTemplate = null
	@observable scoringResult = null
	scoringFormLoaded = false

	@action loadList() {
		api
			.loadScoringList()
			.call()
			.then((r) => {
				this.scoringList = r.rows
			})
	}

	@action open(item) {
		if (item && item.id) {
			api
				.loadScoring(item.id)
				.call()
				.then((r) => {
					this.scoringTemplate = r
					this.isOpen = true
				})
		}
	}

	@action close() {
		this.isOpen = false
		this.scoringTemplate = null
		this.scoringResult = null
		this.scoringFormLoaded = false
	}

	@action storno(scoringId) {
		UIStore.isFormSaving = true
		const request = {
			_id: scoringId,
			_type: "EHR.Data.Scoring",
			active: false
		}

		api
			.stornoRecords(request)
			.call()
			.then(() => {
				DDaction.loadPatientOverview(
					getSelectedOrgUnitID(),
					DataStore.patientDTO.get("patientID"),
					null,
					null,
					true
				).then((overview) => {
					DataStore.setPatientEHR(overview)
					UIStore.isFormSaving = false
				})
			})
	}

	@action save(callback, printScoring = false) {
		//Máme vyhodnotený skóring
		if (this.scoringResult) {
			UIStore.isFormSaving = true
			const patientID = DataStore.patientDTO.get("patientID")
			const providerID = getSelectedOrgUnitID()

			api
				.saveRecord(this.scoringResult, patientID)
				.call()
				.then((response) => {
					if (response) {
						GlobalStore.setNotificationMessage("Patient.form.scoring.successsave")
						//Po ulozeni skore je potrebe toto skore pridat do dekurzu
						if (isSafe(callback) && typeof callback == "function") {
							let result = null
							if (this.scoringResult && this.scoringResult.clinical_values) {
								const cvResult = this.scoringResult.clinical_values.find(
									(cv) => cv.active && cv._type === "EHR.Data.Record.ClinicalValue"
								)
								if (cvResult) {
									result = cvResult.value
								}
							}

							logger(result)
							callback(result)
							if (printScoring) {
								this.printScoring(response._id)
							}
						}
						this.close()
						UIStore.isFormSaving = false
						DDaction.loadPatientOverview(providerID, patientID, null, null, true).then((overview) => {
							DataStore.setPatientEHR(overview)
						})
					}
				})
		}
	}

	printScoring(recordID) {
		UIStore.isFormSaving = true

		api
			.loadRecord(recordID, "L3")
			.call()
			.then((scoringData) => {
				let scoringTable = ""
				const pacInfo = DataStore.patientDTO.get("patient")
				const doctorInfo = getUserDoctorInfo()
				const orgUnit = getSelectedOrgUnit()

				const pacAddress = pacInfo.validInfo.address
				let printAddress = ""
				if (pacInfo.validInfo && pacAddress) {
					printAddress += `${pacAddress.city ? `, ${pacAddress.city}, ` : ""}`
					printAddress += `${
						pacAddress.street
							? `${pacAddress.street}${pacAddress.house_number ? ` ${pacAddress.house_number}` : ""},`
							: ""
					}`
					printAddress += pacAddress.zip
				}

				let printValues = {
					name: `${pacInfo.full_name}`,
					identifier: pacInfo.identifier,
					address: pacInfo.validInfo && pacAddress ? printAddress : "",
					doctorcode: doctorInfo ? doctorInfo.code : "",
					doctorname: doctorInfo ? doctorInfo.name : ""
				}

				//OrgUnit
				if (orgUnit) {
					const primaryAddress = doctorInfo.address
					const businessContact = doctorInfo.contact
					const providerCode = orgUnit.identifiers.find((i) => i.type === "PROVIDERCODE")
					printValues["orgunitname"] = orgUnit.name_ext
					printValues["orgunitaddress"] = primaryAddress ? primaryAddress.full_address : ""
					if (businessContact) {
						printValues["orgunitemail"] = businessContact.email ? businessContact.email : ""
						printValues["orgunitcontact"] = `${businessContact.mobile ? businessContact.mobile : ""} ${
							businessContact.phone ? businessContact.phone : ""
						}`
					}
					printValues["orgunitcode"] = providerCode ? providerCode.value : ""
				}

				//Insurer
				if (pacInfo.validInfo && pacInfo.validInfo.insurance) {
					printValues[
						"insurer"
					] = `${pacInfo.validInfo.insurance.insurer.code_ext} - ${pacInfo.validInfo.insurance.insurer.name_ext}`
				}

				const answers =
					scoringData.clinical_values &&
					scoringData.clinical_values.filter((cv) => cv.active && cv._type === "EHR.Data.Scoring.Answer")
				const result =
					scoringData.clinical_values &&
					scoringData.clinical_values.find((cv) => cv.active && cv._type === "EHR.Data.Record.ClinicalValue")

				if (answers) {
					answers
						.sort((a, b) => a.question.item_order - b.question.item_order)
						.map((a, idx) => {
							const answer = a.answer
							const question = a.question
							scoringTable += `<tr>
              <td>${idx + 1}. ${question.description}</td>
              <td>${answer.description}</td>
              <td>${answer.value}</td>
              </tr>`
						})
				}

				if (scoringData.survey) {
					printValues["title"] = scoringData.survey.name_ext
				}

				printValues["scoring"] = scoringTable
				printValues["total"] = result && result.value

				printReport("scoring", printValues)

				UIStore.isFormSaving = false
			})
	}

	@action evalute(form) {
		const survey = this.scoringTemplate

		//Musí byť validný formulár
		if (form && form.values() && form.isValid && survey) {
			const formValues = form.values()
			const patientID = DataStore.patientDTO.get("patientID")
			const personnelID = getUserPersonnelID()
			const providerID = getSelectedOrgUnitID()

			const evalRequest = {
				_ref: false,
				_type: "EHR.Data.Scoring",
				active: true,
				clinical_values: [],
				specific_rels: [
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: patientID,
							_type: "Entity.Data.Client"
						},
						type: "pat"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: personnelID,
							_type: "EHR.Data.Entity.Personnel"
						},
						type: "phy"
					},
					{
						_ref: false,
						_type: "EHR.Data.Record.SpecificRel",
						specific_rel: {
							_id: providerID,
							_type: "Entity.Data.OrgUnit"
						},
						type: "ou"
					}
				],
				survey: {
					_id: survey._id,
					_type: "EHR.CL.Scoring.Survey"
				}
			}

			//Questions
			for (let key in formValues) {
				const pureKey = key.replace("question-", "")
				if (pureKey !== "eval") {
					evalRequest.clinical_values.push({
						_ref: false,
						_type: "EHR.Data.Scoring.Answer",
						active: true,
						answer: {
							_id: formValues[key],
							_type: "EHR.CL.Scoring.Answer"
						},
						question: {
							_id: pureKey,
							_type: "EHR.CL.Scoring.Question"
						},
						unit: null,
						value: ""
					})
				}
			}

			api
				.loadScoringEval(evalRequest)
				.call()
				.then((res) => {
					if (res) {
						this.scoringResult = res
					}
				})
		}
	}
}

var singleton = new ScoringStore()
export default singleton
