import React from "react"
import {observer} from "mobx-react"
// import {FormattedMessage} from "react-intl"
import classnames from "classnames"
import moment from "moment"

import {Grid, TextField} from "@material-ui/core"

import NotificationCenterStore from "./NotificationCenterStore"

import "./notificationCenter.less"

@observer
export default class ChatWindow extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			isOpen: false,
			chatData: {}
		}

		this.chatRef = null
	}

	componentDidUpdate() {
		this.setPos()
	}

	componentDidMount() {
		this.setPos()
	}

	setPos = () => {
		this.chatRef.scrollTop = this.chatRef.scrollHeight
	}

	keyPress(e, userId) {
		if (e.keyCode == 13) {
			// NotificationCenterStore.messages[userId].push({
			// 	folder: "outbox",
			// 	message: NotificationCenterStore.chatMessage[userId],
			// 	time: moment().toISOString(),
			// 	type: "message"
			// })
			NotificationCenterStore.sendMessage(userId, NotificationCenterStore.chatMessage[userId])

			NotificationCenterStore.chatMessage[userId] = ""
			this.setPos()
		}
	}

	render() {
		const {userId} = this.props

		return (
			<div key={userId} className="chatWindow">
				<Grid container direction="column" className="maxHeight">
					<Grid item container className="pa-2 header" justify="space-between" alignItems="center">
						<Grid item>{NotificationCenterStore.allUsers.find((x) => x._id == userId).email}</Grid>
						<Grid item>
							<i
								className="fal fa-times greyDefault pointer"
								style={{fontSize: "24px"}}
								onClick={() => {
									delete NotificationCenterStore.data[userId]
								}}
							></i>
						</Grid>
					</Grid>
					<Grid item style={{height: "268px", width: "284px"}}>
						<div className="overflowY maxHeight" ref={(chat) => (this.chatRef = chat)}>
							{isSafe(NotificationCenterStore.data[userId]) &&
								NotificationCenterStore.data[userId].length > 0 &&
								NotificationCenterStore.data[userId].map((message, i) => {
									return (
										<React.Fragment key={`${userId}_${i}`}>
											<div className="timestamp">{moment(message.time).format("DD.MM.YYYY HH:mm")}</div>
											<div>
												<div
													className={classnames("chatItem", {
														inbox: message.folder == "outbox",
														outbox: message.folder == "inbox"
													})}
												>
													{message.message}
												</div>
											</div>
										</React.Fragment>
									)
								})}
						</div>
					</Grid>
					<Grid item className="textfield">
						<TextField
							autoFocus={true}
							value={NotificationCenterStore.chatMessage[userId]}
							onChange={(e) => (NotificationCenterStore.chatMessage[userId] = e.target.value)}
							onKeyDown={(e) => this.keyPress(e, userId)}
						/>
					</Grid>
				</Grid>
			</div>
		)
	}
}
