import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"
import XsIconButton from "../../../../../global/ui/xsButton/xsIconButton"

import {TableHead, Table, TableBody, TableCell, TableRow} from "@material-ui/core"

import PricePerPointForm from "./pricePerPointForm"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import {TextField} from "@material-ui/core"

@injectIntl
@observer
export default class PricePerPointGrid extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			// page: 0,
			// rowsPerPage: 10,
			order: false,
			orderBy: null,
			sortType: ""
		}
	}

	handleChangePage = (event, page) => {
		if (!AgreementAttributeStore.isEditAttributeGrid) {
			this.setState({
				page
			})
		}
	}

	handleChangeRowsPerPage = (event) => {
		if (!AgreementAttributeStore.isEditAttributeGrid) {
			this.setState({
				rowsPerPage: event.target.value
			})
		}
	}

	hasDuplicityDelivery = () => {
		let hasDuplicity = false

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			let addedDeliveryCode = AgreementAttributeStore.pricePerPointFormDelivery.split(" ")[0]
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				let existDeliveryCode =
					AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].deliveries[0].code_ext

				if (addedDeliveryCode.trim() === existDeliveryCode.trim()) {
					hasDuplicity = true
					return true
				}
			})
		}

		return hasDuplicity
	}

	tableBodyRows
	tableBody() {
		this.tableBodyRows = []

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter) &&
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).length > 0
		) {
			// dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).forEach((currRow, idx) => {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).forEach(
				(rowId, idx) => {
					if (AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].active) {
						this.tableBodyRows.push(
							<TableRow key={idx}>
								<TableCell>
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<XsInputSearchFilter
											api={api.loadDeliveries}
											minLengthForSearch={3}
											// isValid={isEmpty(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].deliveries)}
											onButtonClick={(value) =>
												delayedCallback(500, () => AgreementAttributeStore.searchDeliveries(value, rowId))
											}
											data={AgreementAttributeStore.deliveriesGrid[rowId]}
											onSearchClose={() => (AgreementAttributeStore.deliveriesGrid[rowId] = [])}
											defaultValue={AgreementAttributeStore.deliveriesGridJSON[rowId]}
											className="xs-input"
											inputRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											valueRenderer={(dataRow) => {
												return dataRow.code_ext + " " + dataRow.name_ext
											}}
											chooseItem={(value) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"deliveries",
													value._id
												)
											}
											onClear={() =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"deliveries",
													""
												)
											}
											modalTitle={<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />}
											modalConfig={(textValue, clickHandler) => {
												return {
													columnDefs: {
														code_ext: {
															title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryCode" />,
															type: "string",
															dbName: "code_ext",
															design: {
																width: "150px"
															},
															filter: {
																gridWidth: 3,
																defaultValue: "",
																renderElement: "input"
															}
														},
														name_ext: {
															title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryName" />,
															type: "string",
															dbName: "search_column",
															filter: {
																gridWidth: 9,
																defaultValue: textValue,
																renderElement: "input"
															}
															// sortable: true
														}
													},
													options: {
														// selectRow: true,
														showCursor: true,
														onRowClick: (dataRow) => {
															clickHandler(dataRow)
														}
													}
												}
											}}
										/>
									) : (
										AgreementAttributeStore.deliveriesGridJSON[rowId]
									)}
								</TableCell>
								<TableCell className="xs-text-right xs-width-150">
									{AgreementAttributeStore.isEditAttributeGrid ? (
										<TextField
											// defaultValue={currRow.price_in_currency}
											value={
												AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
													.price_in_currency
											}
											// onChange={(e) => AgreementAttributeStore.updateAttribute(rowId, "price_in_currency", e.target.value)}
											onChange={(e) =>
												AgreementAttributeStore.changedAttribute(
													AgreementAttributeStore.typeFilter,
													rowId,
													"price_in_currency",
													e.target.value
												)
											}
											InputProps={{
												className: "xs-input",
												classes: {
													root: !isPrice(
														AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
															.price_in_currency
													)
														? "xs-input-error"
														: ""
												}
											}}
											// /> : AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].price_in_currency
										/>
									) : (
										priceFormat(
											AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId]
												.price_in_currency
										)
									)}
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && (
									<TableCell className="xs-text-center xs-delete-action">
										<XsIconButton
											icon={<i className="fal fa-trash-alt fa-lg xs-greyDefault" />}
											onClick={
												() =>
													AgreementAttributeStore.changedAttribute(
														AgreementAttributeStore.typeFilter,
														rowId,
														"active",
														false
													) /*GlobalStore.openConfirmationDialog("xsDeleteAgreementAttribute", { attributeId: AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][rowId].id })*/
											}
										/>
									</TableCell>
								)}
							</TableRow>
						)
					}
				}
			)
		} else {
			this.tableBodyRows.push(
				<TableRow key={1}>
					<TableCell className="xs-table-no-data" colSpan={2}>
						<FormattedMessage id="Table.NoData" />
					</TableCell>
				</TableRow>
			)
		}
	}

	render() {
		this.tableBody()

		return (
			<div className="xs-table-ex">
				<div className="xs-table">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<FormattedMessage id="Common.label.delivery" />
								</TableCell>
								<TableCell className="xs-text-right">
									<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />
								</TableCell>
								{AgreementAttributeStore.isEditAttributeGrid && <TableCell />}
							</TableRow>
						</TableHead>
						<TableBody>{this.tableBodyRows}</TableBody>
					</Table>
				</div>
				<div className="xs-attribute-form">
					<PricePerPointForm />
				</div>
			</div>
		)
	}
}
