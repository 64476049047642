import React from "react"
import {observer} from "mobx-react"
import mime from "mime-types"
import FileIcon, {defaultStyles} from "react-file-icon"
import {FormattedMessage} from "react-intl"
import ReactTooltip from "react-tooltip"
import api from "../../../../actions/api"
import renderHTML from "react-render-html"

import "./recordRow.less"
import XsExpansionPanel from "../../../../../../global/ui/xsExpansionPanel/xsExpansionPanel"

import UIStore from "../../../../stores/UIStore"

const RecordRow = observer(
	({
		summary,
		diagnosisCode,
		diagnosisName,
		sendingDoctor,
		substitutingDoctor,
		clinicalValues,
		attachments,
		lifeThreatening
	}) => {
		const summaryOF = summary.find((s) => s.type._id === "OF")
		const summaryAMB = summary.find((s) => s.type._id === "AMB")
		const summaryDC = summary.find((s) => s.type._id === "DC")
		const summaryReccom = summary.find((s) => s.type._id === "Recomm")
		const summaryIEXAM = summary.find((s) => s.type._id === "IEXAM")

		return (
			<XsExpansionPanel attachments={attachments} maxHeight={20} glOpen={UIStore.isOpenAllRecordsInEHROverview}>
				<div className="recordRow">
					{sendingDoctor && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientrecord.sendingDoctor" />:
							</span>{" "}
							{sendingDoctor.full_name} ({sendingDoctor.personnel_code})
						</div>
					)}
					{substitutingDoctor && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientrecord.substitutingDoctor" />:
							</span>{" "}
							{substitutingDoctor.full_name} ({substitutingDoctor.personnel_code})
						</div>
					)}
					{diagnosisCode && (
						<div>
							<span className="title">
								<FormattedMessage id="Patient.form.patientrecord.diagnosis" />:
							</span>{" "}
							<b>
								{diagnosisCode} - {diagnosisName}{" "}
							</b>
							{lifeThreatening ? (
								<span>
									<i className="fas fa-exclamation-triangle xs-redDanger" data-tip data-for="lifethreading" />
									<ReactTooltip id="lifethreading">
										<FormattedMessage id="Common.label.lifethreading" />
									</ReactTooltip>
								</span>
							) : (
								""
							)}
						</div>
					)}
					{/* {summary.map((sum, index) => {
        return (<div key={index} dangerouslySetInnerHTML={{__html: sum.content}}></div>);
      })} */}
					<br />
					{isSafe(summaryOF) && isNotEmpty(summaryOF.content) && (
						<div>
							<b>
								<FormattedMessage id="Patient.form.patientrecord.ST" />
							</b>
							{renderHTML(isSafe(summaryOF.content) ? summaryOF.content.replace(/\\"/gm, '"') : "") //eslint-disable-line
							}
						</div>
					)}
					{isSafe(summaryAMB) && isNotEmpty(summaryAMB.content) && (
						<div>
							<b>
								<FormattedMessage id="Patient.form.problemForm.OF" />
							</b>
							{renderHTML(isSafe(summaryAMB.content) ? summaryAMB.content.replace(/\\"/gm, '"') : "") //eslint-disable-line
							}
						</div>
					)}
					{isSafe(summaryDC) && isNotEmpty(summaryDC.content) && (
						<div>
							<b>
								<FormattedMessage id="Patient.form.patientrecord.DiagnosisSummary" />
							</b>
							{renderHTML(isSafe(summaryDC.content) ? summaryDC.content.replace(/\\"/gm, '"') : "") //eslint-disable-line
							}
						</div>
					)}
					{isSafe(summaryReccom) && isNotEmpty(summaryReccom.content) && (
						<div>
							<b>
								<FormattedMessage id="Patient.form.problemForm.Recomm" />
							</b>
							{renderHTML(isSafe(summaryReccom.content) ? summaryReccom.content.replace(/\\"/gm, '"') : "") //eslint-disable-line
							}
						</div>
					)}
					{isSafe(summaryIEXAM) && isNotEmpty(summaryIEXAM.content) && (
						<div>
							<b>
								<FormattedMessage id="Common.label.iexam" />
							</b>
							{renderHTML(isSafe(summaryIEXAM.content) ? summaryIEXAM.content.replace(/\\"/gm, '"') : "") //eslint-disable-line
							}
						</div>
					)}
					{clinicalValues &&
						clinicalValues.map((cv, ix) => (
							<div key={ix}>
								{cv.measure_name_ext}:{" "}
								<b>
									{cv.value} {cv.unit_code_ext}
								</b>
								<span> {cv.note_value}</span>
							</div>
						))}
					<div className="xs-overview-attachments">
						{isSafe(attachments) &&
							attachments.length > 0 &&
							attachments.map((attach, idx) => (
								// <a className="xs-attachment" key={idx} href={`${config.API_BASE_URL}${attach.api_url}`} download>
								<div
									className="xs-attachment"
									key={idx}
									onClick={() =>
										/*funguj(`${config.API_BASE_URL}${attach.api_url}`) */ api.downloadFile(attach.api_url)
									}
								>
									<div>
										<FileIcon
											size={40}
											extension={mime.extension(attach.content_type)}
											{...defaultStyles[mime.extension(attach.content_type)]}
										/>
									</div>
									{/* <div className="xs-attachment-name">{attach.file_name} ({isSafe(attach.size) && isFinite(attach.size) ? +attach.size / 1000 + " kB" : ""})</div> */}
									<div
										className={"xs-attachment-name"}
										data-content-start={attach.file_name.substr(0, Math.floor(attach.file_name.length / 2))}
										data-content-end={
											"\u200E" + attach.file_name.substr(Math.floor(attach.file_name.length / 2)) + "\u200E"
										}
									/>
									<div className="xs-attachment-name">
										({isSafe(attach.size) && isFinite(attach.size) ? +attach.size / 1000 + " kB" : ""})
									</div>
								</div>
							))}
					</div>
				</div>
			</XsExpansionPanel>
		)
	}
)

export default RecordRow
