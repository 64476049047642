import React from "react"
import {observer} from "mobx-react"
import {FormattedMessage, injectIntl} from "react-intl"

import {Grid, TextField} from "@material-ui/core"

import XsButton from "../../../../../global/ui/xsButton/xsButton"
import XsInputSearchFilter from "../../../../../global/ui/xsInput/xsInputSearchFilter"

import api from "../../../actions/api"
import AgreementAttributeStore from "../../../stores/AgreementAttributeStore"
import WarningStore from "../../../../../global/store/WarningStore"

@injectIntl
@observer
export default class PricePerPointForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isSaving: false
		}
	}

	enableSave = () => {
		this.setState({isSaving: false})
	}

	hasDuplicityDelivery = () => {
		let hasDuplicity = false

		if (
			isSafe(AgreementAttributeStore.attributeDataJSON) &&
			AgreementAttributeStore.attributeDataJSON.hasOwnProperty(AgreementAttributeStore.typeFilter)
		) {
			Object.keys(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter]).some((attrKey) => {
				logger("aa", AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey])
				if (
					isSafe(AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].deliveries[0])
				) {
					let existDeliveryCode =
						AgreementAttributeStore.attributeDataJSON[AgreementAttributeStore.typeFilter][attrKey].deliveries[0]._id

					if (`${AgreementAttributeStore.pricePerPointFormDeliveryId}`.trim() === `${existDeliveryCode}`.trim()) {
						hasDuplicity = true
						return true
					}
				}
			})
		}

		return hasDuplicity
	}

	render() {
		return (
			<Grid container direction="row" spacing={8} alignItems="flex-end">
				<Grid item xs={6}>
					<XsInputSearchFilter
						api={api.loadDeliveries}
						label={<FormattedMessage id="Common.label.delivery" />}
						minLengthForSearch={3}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						// isValid={isEmpty(AgreementAttributeStore.pricePerPointFormDelivery)}
						onButtonClick={(value) => delayedCallback(500, () => AgreementAttributeStore.searchDeliveries(value))}
						data={AgreementAttributeStore.deliveries}
						onChange={(value) => (AgreementAttributeStore.pricePerPointFormDelivery = value)}
						onSearchClose={() => (AgreementAttributeStore.deliveries = [])}
						// defaultValue={AgreementAttributeStore.pricePerPointFormDeliveryId}
						value={AgreementAttributeStore.pricePerPointFormDelivery}
						inputRenderer={(dataRow) => {
							return dataRow.code_ext + " - " + dataRow.name_ext
						}}
						valueRenderer={(dataRow) => {
							return dataRow.code_ext + " - " + dataRow.name_ext
						}}
						chooseItem={(value) => {
							AgreementAttributeStore.pricePerPointFormDelivery = value.code_ext + " " + value.name_ext
							AgreementAttributeStore.pricePerPointFormDeliveryId = value._id
						}}
						modalTitle={<FormattedMessage id="Agreement.Container.Detail.Attribute.Deliveries" />}
						modalConfig={(textValue, clickHandler) => {
							return {
								columnDefs: {
									code_ext: {
										title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryCode" />,
										type: "string",
										dbName: "code_ext",
										design: {
											width: "150px"
										},
										filter: {
											gridWidth: 3,
											defaultValue: "",
											renderElement: "input"
										}
									},
									name_ext: {
										title: <FormattedMessage id="Agreement.Container.Detail.Attribute.DeliveryName" />,
										type: "string",
										dbName: "search_column",
										filter: {
											gridWidth: 9,
											defaultValue: textValue,
											renderElement: "input"
										}
										// sortable: true
									}
								},
								options: {
									// selectRow: true,
									showCursor: true,
									onRowClick: (dataRow) => {
										clickHandler(dataRow)
									}
								}
							}
						}}
					/>
				</Grid>
				<Grid item xs={3}>
					<TextField
						value={AgreementAttributeStore.agreementAttributeCurrency}
						disabled={AgreementAttributeStore.isEditAttributeGrid}
						onChange={(e) => (AgreementAttributeStore.agreementAttributeCurrency = e.target.value)}
						label={
							<span className="xs-bold">
								<FormattedMessage id="Agreement.Container.Detail.Attribute.Price" />*
							</span>
						}
						InputLabelProps={{
							shrink: true
						}}
						InputProps={{
							classes: {
								root:
									!isPrice(AgreementAttributeStore.agreementAttributeCurrency) &&
									!AgreementAttributeStore.isEditAttributeGrid
										? "xs-input-error"
										: ""
							}
						}}
					/>
				</Grid>
				<Grid item xs={3} className="xs-form-actions">
					<XsButton
						className={AgreementAttributeStore.isEditAttributeGrid ? "xs-default xs-outline" : "xs-success xs-outline"}
						disabled={this.state.isSaving || AgreementAttributeStore.isEditAttributeGrid}
						text={<FormattedMessage id="Common.label.add" />}
						onClick={() => {
							if (!isPrice(AgreementAttributeStore.agreementAttributeCurrency)) {
								WarningStore.open(
									`${this.props.intl.formatMessage({id: "Patient.form.patientPrescription.invalidForm"})}`
								)
							} else if (this.hasDuplicityDelivery()) {
								WarningStore.open(
									`${this.props.intl.formatMessage({id: "Agreement.Container.Detail.Attribute.duplicityDelivery"})}`
								)
							} else {
								this.setState({isSaving: true}, AgreementAttributeStore.saveAttributes(this.enableSave.bind()))
							}
						}}
					/>
				</Grid>
			</Grid>
		)
	}
}
