import {ApiCall} from "../../../global/helpers/api"
import config from "../../../global/config/settings"
import {getUser} from "../../../global/helpers/actions"
import {getUserCompanyInfo} from "../../../global/helpers/actions"

export default {
	loadPatientsAgenda(relationshipID, request) {
		return new ApiCall(`/entity/providers/${relationshipID}/events`, "POST", request)
	},

	// loadPatients(relationshipID) {
	//   return new ApiCall(`/amb/providers/${relationshipID}/patients`, "POST");
	// },

	loadPatients(relationshipID, searchRequest) {
		if (isSafe(searchRequest)) {
			return new ApiCall(`/amb/providers/${relationshipID}/patients`, "POST", searchRequest)
		} else {
			return new ApiCall(`/amb/providers/${relationshipID}/patients`, "POST")
		}
	},

	loadCompanyPatients(companyId, searchRequest) {
		if (isSafe(searchRequest)) {
			return new ApiCall(`/amb/entity/companies/${companyId}/patients`, "POST", searchRequest)
		} else {
			return new ApiCall(`/amb/entity/companies/${companyId}/patients`, "POST")
		}
	},

	loadCompanyAutocompletePatients(searchRequest) {
		return new ApiCall(`/amb/entity/companies/${getUserCompanyInfo().id}/patients`, "POST", searchRequest)
	},

	loadCompanyDeliveries(companyId, searchRequest, levelFlag = "") {
		if (isSafe(searchRequest)) {
			return new ApiCall(
				`/amb/entity/companies/${companyId}/deliveries?level_flag=CD${levelFlag}`,
				"POST",
				searchRequest
			)
		} else {
			return new ApiCall(`/amb/entity/companies/${companyId}/deliveries?level_flag=CD${levelFlag}`, "POST")
		}
	},

	loadClientAgenda(relationshipID, timeFrom, timeTo) {
		return new ApiCall(`/entity/clients/${relationshipID}/events`, "POST", {
			time_from: timeFrom,
			time_to: timeTo
		})
	},

	updateClientAgenda(agendaID, status) {
		return new ApiCall(`/service/events/${agendaID}`, "POST", {
			_type: "Service.Data.Event",
			_id: agendaID,
			status: status
		})
	},

	loadSlots(providerID, loadSlotsRequest) {
		return new ApiCall(`/entity/providers/${providerID}/slots`, "POST", {
			...loadSlotsRequest
		})
	},

	createEvent(request) {
		//array with one slot
		return new ApiCall("/service/orders", "PUT", request)
	},

	downloadFile(path) {
		const user = getUser()
		fetch(`${config.API_BASE_URL}${path}`, {
			method: "GET",
			mode: "cors",
			headers: {
				Authorization: `IXS ${user.ixstoken || ""}`
			}
		})
			.then((response) => {
				const fileName = response.headers.get("content-disposition").split("filename=")[1]
				response.blob().then((blob) => {
					var FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
				})
			})
			.catch(() => {})
		// return new ApiCall(`${path}`, "GET");
	},

	downloadCapitation(postObj) {
		return new ApiCall({
			path: "/amb/batch/generate/SK748n",
			method: "POST",
			headers: {
				"Content-Type": "text/plain"
			},
			body: postObj,
			transform: false
		})
	},

	downloadDeliveries(batchType, postObj) {
		return new ApiCall({
			path: `/amb/batch/generate/SK${batchType}`,
			method: "POST",
			headers: {
				"Content-Type": "text/plain"
			},
			body: postObj,
			transform: false
		})
	},

	downloadSocialInsuranceDeliveries(postObj) {
		return new ApiCall({
			path: "/amb/batch/generate/ZZD",
			method: "POST",
			headers: {
				"Content-Type": "text/plain"
			},
			body: postObj,
			transform: false
		})
	},

	loadEvent(eventId) {
		return new ApiCall(`/service/events/${eventId}`, "GET")
	},

	updateEvent(request, clientID) {
		return new ApiCall(`/service/events/${clientID}`, "PUT", request)
	},

	deleteEvent(eventId) {
		return new ApiCall(`/service/events/${eventId}`, "DELETE")
	},

	completeEvent(eventId) {
		return new ApiCall("/service/eventstate", "POST", {
			_id: eventId,
			_type: "Service.Data.Event",
			_ref: false,
			status: "F"
		})
	},

	loadPatientOverview(providerID, patientID, diagnosisFilter, timeFrom, timeTo, typeID, companyID, versionFilter) {
		let body = {
			filters: [
				{
					associated_column: "patient_id",
					values: [
						{
							id_value: patientID
						}
					]
				}
			]
		}
		if (isNotEmpty(providerID)) {
			body.filters.push({
				associated_column: "provider_id",
				values: [
					{
						id_value: providerID
					}
				]
			})
		} else {
			body.filters.push({
				associated_column: "company_id",
				values: [{id_value: companyID}]
			})
		}
		if (diagnosisFilter) {
			body.filters.push({
				associated_column: "diagnosis",
				values: diagnosisFilter
			})
		}

		if (timeTo) {
			body.time_to = timeTo
		}

		if (timeFrom) {
			body.time_from = timeFrom
		}

		if (versionFilter) {
			body.filters.push(versionFilter)
		}

		return new ApiCall("/amb/ehr/overview", "POST", body)
	},

	loadFilteredEHROverview(filters) {
		let body = {
			filters: filters
		}

		return new ApiCall("/amb/ehr/overview", "POST", body)
	},

	loadEHROverview(searchRequest) {
		return new ApiCall(`/amb/ehr/overview`, "POST", searchRequest)
	},

	loadProblems(searchRequest) {
		return new ApiCall(`/amb/ehr/problems?level_flag=CDL3`, "POST", searchRequest)
	},

	loadEHROverviewCDL4(searchRequest) {
		return new ApiCall("/amb/ehr/overview?level_flag=CDL4", "POST", searchRequest)
	},

	loadPatientsOverview(searchRequest) {
		return new ApiCall("/amb/entity/patients/overview", "POST", searchRequest)
	},

	loadPatientProblems(patientID) {
		return new ApiCall(`/amb/entity/patients/${patientID}/problems?level_flag=CDL3`, "GET")
	},

	loadPatientsFilters(searchRequest) {
		return new ApiCall(`/amb/entity/patients-filters`, "POST", searchRequest)
	},

	loadInsurers() {
		return new ApiCall("/amb/entity/insurers", "GET")
	},

	loadAllergies(patientID) {
		return new ApiCall(`/amb/entity/patients/${patientID}/allergies`, "GET")
	},

	loadAlerts(patientID) {
		return new ApiCall(`/amb/entity/patients/${patientID}/alerts`, "GET")
	},

	loadTags(className, objectID, groupID) {
		return new ApiCall(`/object/tags/${className}/${objectID}/group/${groupID}?level_flag=CD`, "GET")
	},

	loadCapitation(searchRequest, levelFlag = "L3") {
		return new ApiCall(`/amb/entity/capitations?level_flag=CD${levelFlag}`, "POST", searchRequest)
	},

	saveCapitation(saveRequest) {
		return new ApiCall("/amb/entity/capitations", "PUT", saveRequest)
	},

	loadSummaryCapitations(searchRequest) {
		return new ApiCall("/amb/entity/capitations/summary", "POST", searchRequest)
	},

	loadSummaryDeliveries(searchRequest) {
		return new ApiCall("/amb/entity/deliveries/summary?level_flag=CD", "POST", searchRequest)
	},

	loadSocSummaryDeliveries(searchRequest) {
		return new ApiCall("/amb/entity/deliveries/socsummary", "POST", searchRequest)
	},

	saveConsentPackage(saveRequest) {
		return new ApiCall("/crm/consents", "PUT", saveRequest)
	},

	loadTitles() {
		return new ApiCall("/cl/Entity.CL.Title", "GET")
	},

	loadRoutes() {
		return new ApiCall("/discovery/routes", "GET")
	},

	loadConsentType() {
		return new ApiCall("/cl/CRM.CL.ConsentType", "GET")
	},

	loadHealthType() {
		return new ApiCall("/cl/EHR.CL.HealthCare.Type", "GET")
	},

	loadSeverities() {
		return new ApiCall("/cl/EHR.CL.Severity", "GET")
	},

	loadAllergyTypes(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Entity.Allergy", "POST", searchRequest)
	},

	loadCLServices() {
		return new ApiCall(`/service/cl/services`, "POST")
	},

	loadServices(resourceID, request) {
		return new ApiCall(`/service/resources/${resourceID}/services`, "POST", request)
	},

	loadOpeningDays(resourceID) {
		return new ApiCall(`/service/resources/${resourceID}/od`, "POST")
	},

	saveOpeningDays(resourceID, saveRequest) {
		return new ApiCall(`/service/resources/${resourceID}/od`, "PUT", saveRequest)
	},

	loadCompanyServices(companyID) {
		return new ApiCall(`/service/company/${companyID}/providedservices?level_flag=CD`, "POST")
	},

	loadProvidedService(psID) {
		return new ApiCall(`/service/providedservices/${psID}?level_flag=CDL3`, "GET")
	},

	updateProvidedService(saveRequest) {
		return new ApiCall(`/service/providedservices`, "PUT", saveRequest)
	},

	saveProvidedService(saveRequest) {
		return new ApiCall(`/service/providedservices`, "PUT", saveRequest)
	},

	saveProvidedServiceRelateds(psID, type, saveRequest) {
		return new ApiCall(`/service/providedservices/${psID}/relateds/${type}`, "PUT", saveRequest)
	},

	loadConsents(searchRequest, levelFlag = "L3") {
		return new ApiCall(`/crm/consents?level_flag=CD${levelFlag}`, "POST", searchRequest)
	},

	saveCodelistValue(codelist, method, saveRequest) {
		return new ApiCall(`${codelist}`, method, saveRequest)
	},

	loadInvoices(searchRequest, levelFlag = "L3") {
		return new ApiCall(`/fin/invoices?level_flag=CD${levelFlag}`, "POST", searchRequest)
	},

	loadInvoice(invoiceID, levelFlag = "L3") {
		return new ApiCall(`/fin/invoices/${invoiceID}?level_flag=CD${levelFlag}`, "POST")
	},

	updateInvoice(updateRequest) {
		return new ApiCall(`/fin/invoices`, "PUT", updateRequest)
	},

	loadAlertTypes(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Entity.Alert", "POST", searchRequest)
	},

	loadAllergens(searchRequest) {
		return new ApiCall("/cl/Entity.CL.Allergen", "POST", searchRequest)
	},

	loadGenders() {
		return new ApiCall("/cl/Entity.CL.Gender", "GET")
	},

	loadMeasures(filterType) {
		const loadTypes = filterType ? "||" + filterType : ""
		return new ApiCall(`/cl/EHR.CL.Record.Measure${loadTypes}`, "GET")
	},

	loadUnits() {
		return new ApiCall("/cl/EHR.CL.Record.Unit", "GET")
	},

	loadInsureeTypes() {
		return new ApiCall("/cl/EHR.CL.Record.Delivery.InsureeType", "GET")
	},

	loadSenderType() {
		return new ApiCall("/cl/EHR.CL.Record.Delivery.SenderType", "GET")
	},

	loadRefundTypes() {
		return new ApiCall("/cl/EHR.CL.Record.Delivery.RefundType", "GET")
	},

	loadPayerTypes() {
		return new ApiCall("/cl/EHR.CL.Record.Delivery.PayerType", "GET")
	},

	loadSubItemTypes() {
		return new ApiCall("/cl/EHR.CL.Record.GeneralSubItemType", "GET")
	},

	loadSubItemSubTypes() {
		return new ApiCall("/cl/EHR.CL.Record.GeneralSubItemSubType", "GET")
	},

	loadEndReason() {
		return new ApiCall("/cl/Entity.CL.SpecificRelRelationship.EndReason", "GET")
	},

	loadEntityInfo(entityID, level = "") {
		return new ApiCall(`/entity/entities/${entityID}?level_flag=CD${level}`, "GET")
	},

	loadSpecificRel(patientID, level = "") {
		return new ApiCall(`/entity/specificrels/${patientID}?level_flag=CD${level}`, "GET")
	},

	loadContactPersons(patientID, level = "L3") {
		return new ApiCall(`/entity/specificrels/${patientID}/contactpersons?level_flag=CD${level}`, "GET")
	},

	saveSpecificRel(saveRequest) {
		return new ApiCall("/entity/specificrels", "PUT", saveRequest)
	},

	dajKodPreZdravotnuKartu(request) {
		return new ApiCall("/amb/ehealth/proxy/dajkodprezdravotnukartu", "POST", request)
	},

	dajzdravotnukartu(request) {
		return new ApiCall("/amb/ehealth/proxy/dajzdravotnukartu", "POST", request)
	},

	loadAvatar(entityID, avatarID) {
		return new ApiCall({
			path: `/entity/entities/${entityID}/avatars/${avatarID}`,
			method: "GET",
			transform: false
		})
	},

	updateEntityInfo(updateRequest) {
		return new ApiCall("/entity/entities", "PUT", updateRequest)
	},

	saveNewEntity(saveRequest) {
		return new ApiCall("/entity/entities?level_flag=CI", "PUT", saveRequest)
	},

	saveSpecRelToSpecRel(saveRequest) {
		return new ApiCall("/entity/portfolio", "PUT", saveRequest)
	},

	saveInvoice(saveRequest) {
		return new ApiCall("/fin/invoices", "PUT", saveRequest)
	},

	updateProblem(updateRequest, recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}/symptoms`, "PUT", updateRequest)
	},

	saveSymptom(data, recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}/symptoms`, "PUT", data)
	},

	// loadDiagnosis(searchRequest) {
	// 	return new ApiCall("/cl/EHR.CL.Record.Diagnosis", "POST", searchRequest)
	// },

	loadDiagnosisCl(searchRequest) {
		return new ApiCall("/amb/ehr/cl/diagnosesnochapter", "POST", searchRequest)
	},

	loadDiagnosis(searchRequest) {
		return new ApiCall("/amb/ehr/cl/diagnoses", "POST", searchRequest)
	},

	loadEmployeeCategories() {
		return new ApiCall("/cl/Entity.CL.EmployeeCategory", "GET")
	},

	loadEmployeeContracts(filters, levelFlag = "L3") {
		return new ApiCall(`/entity/contracts?level_flag=CD${levelFlag}`, "POST", filters)
	},

	loadOrgUnits(filter, level = "") {
		let levelFlag = ""
		if (isNotEmpty(level)) {
			levelFlag = `?level_flag=CD${level}`
		}
		return new ApiCall(`/entity/orgunits${levelFlag}`, "POST", filter)
	},

	saveContract(data) {
		return new ApiCall("/entity/contracts", "PUT", data)
	},

	loadDeliveries(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Bill.SK.Delivery", "POST", searchRequest)
	},

	loadDeliveryItems(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Bill.SK.DeliveryItem", "POST", searchRequest)
	},

	saveDeliveries(deliveryRequest, recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}/delivery?level_flag=CD`, "PUT", deliveryRequest)
	},

	loadDrugs(searchRequest) {
		return new ApiCall("/amb/ehr/cl/drugs?level_flag=CDL2", "POST", searchRequest) //?level_flag=CDL2
	},

	loadProviders(searchRequest) {
		return new ApiCall("/amb/entity/providers", "POST", searchRequest)
	},

	checkPortfolio(searchRequest, providerId) {
		return new ApiCall(`/amb/entity/providers/${providerId}/patients`, "POST", searchRequest)
	},

	saveRecord(recordRequest, patientId) {
		return new ApiCall(`/amb/entity/patients/${patientId}/records`, "PUT", recordRequest)
	},

	saveContact(contactRequest) {
		return new ApiCall("/entity/contacts", "PUT", contactRequest)
	},

	saveInsurance(insuranceRequest) {
		return new ApiCall("/entity/insurances", "PUT", insuranceRequest)
	},

	saveAnamnesis(anamnesisRequest, patientId) {
		return new ApiCall(`/amb/entity/patients/${patientId}/records`, "PUT", anamnesisRequest)
	},

	saveAlerts(saveRequest, patientId) {
		return new ApiCall(`/amb/entity/patients/${patientId}/alerts`, "PUT", saveRequest)
	},

	saveAllergies(saveRequest, patientId) {
		return new ApiCall(`/amb/entity/patients/${patientId}/allergies`, "PUT", saveRequest)
	},

	loadDiagnosisType() {
		return new ApiCall("/cl/EHR.CL.Record.DiagnosisType", "GET")
	},

	loadSicknessAbsType() {
		return new ApiCall("/cl/EHR.CL.SicknessAbsence.Type", "GET")
	},

	loadSicknessAbsNumber(getRequest) {
		return new ApiCall("/amb/ehr/sequence", "POST", getRequest)
	},

	loadContactType() {
		return new ApiCall("/cl/Entity.CL.ContactType", "GET")
	},

	loadPersonnel(searchRequest) {
		return new ApiCall("/amb/entity/personnel", "POST", searchRequest)
	},

	loadPersonnelAndProvider(searchRequest) {
		return new ApiCall("/amb/entity/personnelandprovider", "POST", searchRequest)
	},

	loadRecord(recordID, level = "") {
		return new ApiCall(`/amb/ehr/records/${recordID}?level_flag=CD${level}`, "GET")
	},

	updateRecord(saveRequest) {
		return new ApiCall(`/amb/ehr/records`, "PUT", saveRequest)
	},

	loadMedicationUnits() {
		return new ApiCall("/cl/EHR.CL.Medication.Unit", "GET")
	},

	loadTemplates(searchRequest) {
		return new ApiCall("/amb/entity/summary/template", "POST", searchRequest)
	},

	saveTemplate(templateRequest) {
		return new ApiCall("/amb/entity/summary/template", "PUT", templateRequest)
	},

	loadMedRoutes() {
		return new ApiCall("/cl/EHR.CL.Medication.Route", "GET")
	},

	loadRepeatingMoments() {
		return new ApiCall("/cl/EHR.CL.Medication.RepeatingMoment", "GET")
	},

	loadQuantizations() {
		return new ApiCall("/cl/EHR.CL.Medication.DosageType", "GET")
	},

	loadProxyRecord(recordID) {
		return new ApiCall(`/amb/ehr/proxyrecord/${recordID}`, "GET")
	},

	savePrescription(prescription) {
		return new ApiCall("/amb/ehr/proxyrecord", "PUT", prescription)
	},

	loadPrescription(recordID) {
		return new ApiCall(`/amb/ehr/proxyrecord/${recordID}?level_flag=CDL3`, "GET")
	},

	loadPartners(searchRequest) {
		return new ApiCall(`/entity/partners?level_flag=CD`, "POST", searchRequest)
	},

	getInvoiceNumber(orgunit) {
		return new ApiCall(`/fin/invoicesinit`, "POST", orgunit)
	},

	getInvoicesTemplate(filters) {
		return new ApiCall(`/fin/invoicestemplate`, "POST", filters)
	},

	deleteInvoiceTemplate(id) {
		return new ApiCall(`/fin/invoicestemplate`, "PUT", {
			_id: id,
			_type: "Fin.API.JSON.Proxy.Template.Invoice",
			active: false
		})
	},

	getSelectedInvoicesTemplate(id) {
		return new ApiCall(`/fin/invoicestemplate/${id}`, "GET")
	},

	saveInvoicesTemplate(data) {
		return new ApiCall(`/fin/invoicestemplate`, "PUT", data)
	},

	loadSubType() {
		return new ApiCall("/cl/EHR.CL.Medication.SubType", "GET")
	},

	loadAttributeType() {
		return new ApiCall("/cl/EHR.CL.Contract.AttributeType", "GET")
	},

	loadExpertise(searchRequest, type) {
		return new ApiCall("/cl/EHR.CL.Expertise" + (type ? "||" + type : ""), "POST", searchRequest)
	},

	loadAssesingPhysicians(searchRequest) {
		return new ApiCall("/amb/entity/assesingphysicians", "POST", searchRequest)
	},

	loadDoseUnit() {
		return new ApiCall("/cl/EHR.CL.Medication.DoseUnit", "GET")
	},

	loadOpeningHours(resourceID) {
		return new ApiCall(`/service/resources/${resourceID}/oh`, "POST")
	},

	saveOpeningHours(saveRequest, resourceID) {
		return new ApiCall(`/service/resources/${resourceID}/oh`, "PUT", saveRequest)
	},

	saveProblemForn(problem, patientId) {
		return new ApiCall(`/amb/entity/patients/${patientId}/problems`, "PUT", problem)
	},

	loadlimitations(searchRequest) {
		return new ApiCall("/amb/ehr/atc/limitations", "POST", searchRequest)
	},

	saveSignaturedData(signaturedData, recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}/signature`, "PUT", signaturedData)
	},

	loadIVL() {
		return new ApiCall("/cl/EHR.CL.Medication.SK.Preparative", "GET")
	},

	loadIngrediens(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Medication.Ingredient", "POST", searchRequest)
	},

	loadCountries() {
		return new ApiCall("/cl/Entity.CL.Country", "GET")
	},

	loadRegions() {
		return new ApiCall("/cl/Entity.CL.Address.Region", "GET")
	},

	loadCounty() {
		return new ApiCall("/cl/Entity.CL.Address.County", "GET")
	},

	loadInsuranceTypes() {
		return new ApiCall("/cl/EHR.CL.Patient.InsuranceType", "GET")
	},

	loadIngredientsUnit() {
		return new ApiCall("/cl/EHR.CL.Record.Unit", "GET")
	},

	loadMedDev(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Medication.SK.MedicalDevice?level_flag=CDL2", "POST", searchRequest)
	},

	loadCustomItem(request) {
		return new ApiCall("/amb/ehr/cl/deliveryitem", "POST", request)
	},

	loadDrug(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Medication.SK.Drug", "POST", searchRequest)
	},

	loadMedFood(searchRequest) {
		return new ApiCall("/amb/ehr/cl/dietetic?level_flag=CDL2", "POST", searchRequest)
	},
	saveAttachment(data) {
		return new ApiCall("/object/attachment", "PUT", data)
	},
	loadEventsRelateds(filter) {
		return new ApiCall("/service/eventsrelateds?level_flag=CD", "POST", filter)
	},
	checkDuplicityPerson(data) {
		return new ApiCall("/object/identify?level_flag=CD", "POST", data)
	},
	getDuplicityPerson(filter) {
		return new ApiCall("/entity/entities?level_flag=CD", "POST", filter)
	},
	acquireLock(id) {
		return new ApiCall({
			path: "/session/lock/acquire",
			method: "POST",
			body: {
				object_type: "Entity.Data.Person",
				object_id: id
			}
		})
	},
	releaseLock(id) {
		return new ApiCall({
			path: "/session/lock/release",
			method: "POST",
			body: {
				object_type: "Entity.Data.Person",
				object_id: id
			}
		})
	},
	overpoistenca(patientId, providerId, hideError = false) {
		return new ApiCall(
			"/amb/ehealth/proxy/overpoistenca",
			"POST",
			{
				patient_id: patientId,
				provider_id: providerId
			},
			hideError
		)
	},

	savePatientTags(saveRequest, id) {
		return new ApiCall(`/object/tags/Entity.Data.Client/${id}/group/Patient`, "PUT", saveRequest)
	},
	saveDelivery(saveRequest) {
		return new ApiCall("/amb/ehr/delivery", "PUT", saveRequest)
	},
	checkInsurance(ZPcode, identifier, providerId) {
		return new ApiCall("/amb/ehealth/proxy/overpoistenca", "POST", {
			kod_zp: ZPcode,
			rodne_cislo: identifier,
			provider_id: providerId
		})
	},
	checkOverinterakcie(recept) {
		return new ApiCall("/amb/ehealth/proxy/overinterakcie", "POST", recept)
	},
	findGenerics(patientId, providerId, productCode) {
		return new ApiCall("/amb/ehealth/proxy/najdigenerika", "POST", {
			patient_id: patientId,
			provider_id: providerId,
			product_id: productCode
		})
	},
	signatureRecord(id, xml) {
		return new ApiCall(`/amb/ehr/records/${id}/signature/PUT`, "PUT", {
			signature: xml
		})
	},

	signatureMultiplePrescriptions(req) {
		return new ApiCall(`/amb/ehr/signature/PUT`, "PUT", req)
	},

	deleteSignatureMultiplePrescriptions(req) {
		return new ApiCall(`/amb/ehr/signature/DELETE`, "PUT", req)
	},
	checkDoctorAndOUPZS(patientId, providerId, doctorExpertiseId, checkedProviderId) {
		return new ApiCall("/amb/ehealth/proxy/overlekaraajnz", "POST", {
			patient_id: patientId,
			provider_id: providerId,
			checked_doctor_expertise_id: doctorExpertiseId,
			checked_provider_id: checkedProviderId
		})
	},
	loadPacientInteractions(providerId, patientId) {
		return new ApiCall("/amb/ehealth/proxy/dajinterakciepoistenca", "POST", {
			provider_id: providerId,
			patient_id: patientId
		})
	},
	loadDrugBook(providerId, patientId) {
		return new ApiCall("/amb/ehealth/proxy/dajliekovukartu", "POST", {
			provider_id: providerId,
			patient_id: patientId
		})
	},
	loadEhealthPrescriptionEntry(barcode, providerId, patientId) {
		return new ApiCall("/amb/ehealth/proxy/dajpreskripcnyzaznam", "POST", {
			barcode: barcode,
			provider_id: providerId,
			patient_id: patientId
		})
	},
	deactivatePrescription(id) {
		return new ApiCall("/amb/ehealth/proxy/zneplatnipreskripcnyzaznam", "POST", {
			record_id: id
		})
	},
	deleteRecord(id) {
		return new ApiCall(`/amb/ehr/records/${id}`, "DELETE")
	},
	deleteTemplate(id, req) {
		return new ApiCall(`/amb/ehr/records/${id}`, "DELETE", req)
	},
	deleteRecordSignature(id, signature) {
		return new ApiCall(`/amb/ehr/records/${id}/signature/DELETE`, "PUT", {
			signature: signature
		})
	},
	stornoRecords(request) {
		return new ApiCall(`/amb/ehr/records`, "PUT", request)
	},
	najdilieky(patientId, providerId, productCode) {
		return new ApiCall({
			path: "/amb/ehealth/proxy/najdilieky",
			method: "POST",
			body: {
				patient_id: patientId,
				provider_id: providerId,
				product_id: productCode
			},
			transform: true
		})
	},
	loadAllUsers(filter) {
		return new ApiCall(`/entity/entityusers?level_flag=CDL3`, "POST", filter)
	},
	loadSpecificUser(filter) {
		return new ApiCall(`/entity/entityusers?level_flag=CDL2`, "POST", filter)
	},
	loadSpecificDelivery(id) {
		return new ApiCall(`/amb/ehr/cl/EHR.CL.Bill.SK.Delivery`, "POST", id)
	},

	loadSpecificDeliveryItem(id) {
		return new ApiCall(`/amb/ehr/cl/EHR.CL.Bill.SK.DeliveryItem`, "POST", id)
	},

	dajsumudlhu(request) {
		return new ApiCall("/amb/ehealth/proxy/dajsumudlhu", "POST", request)
	},

	dajsymbolypreuhradu(request) {
		return new ApiCall("/amb/ehealth/proxy/dajsymbolypreuhradu", "POST", request)
	},

	zaevidujuhradu(request) {
		return new ApiCall("/amb/ehealth/proxy/zaevidujuhradu", "POST", request)
	},

	stornosymbolovpreuhradu(request) {
		return new ApiCall("/amb/ehealth/proxy/stornosymbolovpreuhradu", "POST", request)
	},

	aktualizujkontaktneudajepoistenca(request) {
		return new ApiCall("/amb/ehealth/proxy/aktualizujkontaktneudajepoistenca", "POST", request)
	},

	eventsByDay(request) {
		return new ApiCall(`/service/eventsbyday`, "POST", request)
	},

	loadAgreements(request) {
		return new ApiCall("/contract/contracts", "POST", request)
	},

	loadAgreement(id, request) {
		return new ApiCall(`/contract/contracts/${id}?level_flag=CD`, "POST", request)
	},

	saveAgreement(request) {
		return new ApiCall(`/contract/contracts`, "PUT", request)
	},

	loadParts(request) {
		return new ApiCall("/contract/parts?level_flag=CD", "POST", request)
	},

	loadPart(partId) {
		return new ApiCall(`/contract/parts/${partId}?level_flag=CD`, "GET")
	},

	savePart(request) {
		return new ApiCall("/contract/parts", "PUT", request)
	},

	saveAttributes(request) {
		return new ApiCall("/contract/attributes", "PUT", request)
	},

	copyAgreement(req) {
		return new ApiCall("/contract/contract-copy", "POST", req)
	},

	loadDrugFromDrugCode(drugCode) {
		return new ApiCall(`/amb/ehealth/drug/${drugCode}`, "GET")
	},

	loadScoringEval(scoringRequest) {
		return new ApiCall(`/amb/ehr/scoring/eval`, "POST", scoringRequest)
	},

	loadScoringList() {
		return new ApiCall(`/amb/ehr/cl/scoring`, "GET")
	},

	loadScoring(scoringID, level = "L3") {
		return new ApiCall(`/amb/ehr/cl/scoring/${scoringID}?level_flag=CD${level}`, "GET")
	},

	saveAttachmentContent(id, content) {
		// return new ApiCall(`/object/attachment/${id}/content`,"POST",content)
		return new ApiCall({
			path: `/object/attachment/${id}/content`,
			method: "POST",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			body: content,
			transform: false
		})
	},

	deletePrescriptions(data) {
		return new ApiCall(`/amb/ehr/records`, "DELETE", data)
	},

	loadAttachmentData(link) {
		return new ApiCall(`${link}`, "GET")
	},

	saveNewTagType(method, request) {
		return new ApiCall("/object/tags/cl", method, request)
	},

	loadExternalCredentials(userId) {
		return new ApiCall(`/sec/user/${userId}/externalcredentials?level_flag=CD`, "GET")
	},

	loadEhealthPatientContacts(patientId) {
		return new ApiCall(`/amb/ehealth/patients/${patientId}/contacts`, "GET")
	},

	loadEntityRegion(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.Region`, "POST", filter)
	},

	loadEntityCounty(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.County`, "POST", filter)
	},

	loadEntityCity(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.City`, "POST", filter)
	},

	loadEntityZip(filter) {
		return new ApiCall(`/cl/Entity.CL.Address.Zip`, "POST", filter)
	},

	loadImplantGroup(filter) {
		return new ApiCall(`/cl/EHR.CL.Medication.ImplantGroup`, "POST", filter)
	},

	loadClassification(filter) {
		return new ApiCall(`/cl/EHR.CL.ATC.Classification`, "POST", filter)
	},

	searchExaminationRecords(request) {
		return new ApiCall("/SearchExaminationRecords", "POST", request)
	},

	loadNonCapitation(searchRequest) {
		return new ApiCall("/amb/entity/non-capitations", "POST", searchRequest)
	},

	loadNCZIIdentifiers(request) {
		return new ApiCall("/object/identifiers/NCZI/*", "POST", request)
	},

	savePrintTemplate(request) {
		return new ApiCall("/amb/ehr/records", "PUT", request)
	},

	loadPrintTemplate(recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}?level_flag=CDL2`, "GET")
	},

	loadEUPatients(searchRequest) {
		return new ApiCall("/amb/entity/foreign-patients?level_flag=CD", "POST", searchRequest)
	},

	loadSymptoms(searchRequest) {
		return new ApiCall("/cl/EHR.CL.Entity.Symptom", "POST", searchRequest)
	},
	resetRecordAfterDelete(data) {
		return new ApiCall(`/amb/ehr/records`, "PUT", data)
	},

	moveRecords(request, sourceOuId) {
		return new ApiCall(`/amb/entity/providers/${sourceOuId}/transfers`, "POST", request)
	},

	loadImplant(patientID) {
		return new ApiCall(`/amb/entity/patients/${patientID}/implants`, "GET")
	},

	loadBloodType(patientID) {
		return new ApiCall(`/amb/entity/patients/${patientID}/bloodtypes`, "GET")
	},

	loadPriceList(request, flag = "L3") {
		return new ApiCall(`/service/pricelists?level_flag=CD${flag}`, "POST", request)
	},

	saveExpertiseContract(personnelId, expertiseId, data) {
		return new ApiCall(`/amb/entity/personnel/${personnelId}/expertises/${expertiseId}/contracts`, "PUT", data)
	},

	setSent(extId) {
		return new ApiCall(`/amb/ehealth/proxy/recordissent/${extId}`, "POST")
	},

	loadCLCompanies(request, level) {
		return new ApiCall(`/entity/companies?level_flag=CD${level}`, "POST", request)
	},

	loadInstallFiles() {
		return new ApiCall(`/amb/ehealth/signer/installfiles?level_flag=CD`, "POST")
	},

	uploadInstallFile(data) {
		return new ApiCall(`/amb/ehealth/signer/installfiles`, "PUT", data)
	},

	deleteInstallFile(id) {
		return new ApiCall(`/amb/ehealth/signer/installfiles/${id}`, "DELETE")
	},

	downloadInstallFile(id) {
		return new ApiCall({
			path: `/amb/ehealth/signer/installfiles/${id}`,
			method: "GET",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			transform: false
		})
	},

	downloadEHGWInstallator() {
		return new ApiCall({
			path: `/amb/ehealth/signer/setupfiles`,
			method: "GET",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			transform: false
		})
	},

	downloadEZdravieInstallator() {
		return new ApiCall({
			path: `/amb/ehealth/signer/ezdraviesetupfiles`,
			method: "GET",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			transform: false
		})
	},

	saveEHGWAttachmentContent(id, content) {
		return new ApiCall({
			path: `/amb/ehealth/signer/installfiles/${id}/content`,
			method: "PUT",
			headers: {
				"Content-Type": "application/octet-stream"
			},
			body: content,
			transform: false
		})
	},
	loadEntityOrgUnits(filter) {
		return new ApiCall("/amb/entity/orgunits?level_flag=CD", "POST", filter)
	},

	loadDiagnosisMeasure(filter) {
		return new ApiCall("/amb/ehr/template/diagnosismeasure?level_flag=CDL2", "POST", filter)
	},

	loadEhealthQueue(filter) {
		return new ApiCall("/amb/ehealth/queue", "POST", filter)
	},

	saveExternalCredentials(id, data) {
		return new ApiCall(`/user/users/${id}/externalcredentials`, "PUT", data)
	},

	writeSpaRequest(request) {
		return new ApiCall("/amb/ehealth/navrhy/KS", "POST", request)
	},

	loadSpaRequests(request) {
		return new ApiCall("/amb/ehealth/navrhy", "POST", request)
	},

	loadPatientWithoutPP(request) {
		return new ApiCall("/amb/report/generate/SKPC0", "POST", request)
	},

	loadPatientWithPP(request) {
		return new ApiCall("/amb/report/generate/SKPC1", "POST", request)
	},

	loadVaccination(request) {
		return new ApiCall("/amb/report/generate/SKVACC", "POST", request)
	},

	prepareReport(type, request) {
		return new ApiCall(`/amb/report/prepare/${type}`, "POST", request)
	},

	listReport(headerId, request) {
		return new ApiCall(`/amb/report/list/${headerId}?level_flag=CD2`, "POST", request)
	},

	getDefaultBatchType(request) {
		return new ApiCall("/amb/batch/types/default", "POST", request)
	},

	cvTranscription(request) {
		return new ApiCall("/amb/ehr/cvtranscription", "POST", request)
	},

	loadAnnamnesis(providerId, patientId) {
		return new ApiCall(`/amb/entity/providers/${providerId}/patients/${patientId}/anamnesis`)
	},

	loadAllEnumerators(request) {
		return new ApiCall(`/cl/IXS.Base.Extend.CodeList`, "POST", request)
	},

	loadDeliveryItemsWithPrice(searchRequest) {
		return new ApiCall("/amb/ehr/cl/deliveryitem", "POST", searchRequest)
	},
	loadAllDrafts(filter) {
		return new ApiCall("/amb/ehr/recorddraftsset", "POST", filter)
	},

	loadCLIndicativeGroupWithFilter(request) {
		return new ApiCall(`/cl/EHR.CL.Baths.IndicativeGroup`, "POST", request)
	},

	getInvoiceConfig(companyId, request) {
		return new ApiCall(`/amb/entity/companies/${companyId}/invoiceconfig?level_flag=CDL3`, "GET", request)
	},

	saveInvoiceConfig(companyId, request) {
		return new ApiCall(`/amb/entity/companies/${companyId}/invoiceconfig`, "PUT", request)
	},

	loadMeasuresSet(filter) {
		return new ApiCall("/amb/ehr/template/measureset?level_flag=CD", "POST", filter)
	},

	setMeasuresSet(data) {
		return new ApiCall("/amb/ehr/template/measureset", "PUT", data)
	},

	generalAll(request) {
		return new ApiCall("/amb/batch/generateall", "POST", request)
	},

	getEmployee(companyId, request) {
		return new ApiCall(`/entity/entities/${companyId}/employees`, "POST", request)
	},

	dajZiadankySVLZPoistenca(request) {
		return new ApiCall(`/amb/ehealth/dzpelab/dajziadankysvlzpoistenca`, "POST", request, true)
	},

	dajVysledkySVLZPoistenca(request) {
		return new ApiCall(`/amb/ehealth/dzpelab/dajvysledkysvlz`, "POST", request)
	},

	loadDrugReport(filter) {
		return new ApiCall(`/amb/report/drugs`, "POST", filter)
	},

	getPrintPosition(request) {
		return new ApiCall(`/object/printer`, "POST", request)
	},

	aprovePrintPosition(request) {
		return new ApiCall(`/object/printer/approve`, "POST", request)
	},

	loadNcziA01Report(filter) {
		return new ApiCall(`/amb/ehealth/reports/A01/list`, "POST", filter)
	},

	exportNcziA01Report(filter) {
		return new ApiCall(`/amb/ehealth/reports/A01/xml`, "POST", filter)
	},

	loadDraftReport(filter) {
		return new ApiCall(`/amb/report/drafts`, "POST", filter)
	},

	invalidateRecordSignature(id, signature) {
		return new ApiCall(`/amb/ehr/records/${id}/signature/INVALIDATE`, "PUT", {
			signature: signature
		})
	},

	invalidateMultipleRecordSignature(signature) {
		return new ApiCall(`/amb/ehr/signature/INVALIDATE`, "PUT", signature)
	},

	deliveryItem() {
		return new ApiCall(`/cl/EHR.CL.Bill.DeliveryItem`, "GET")
	},

	getDeliveryItems(request) {
		return new ApiCall(`/cl/EHR.CL.Bill.DeliveryItem`, "POST", request)
	},

	getDeliveryItemPrice(deliveryItemId, request) {
		return new ApiCall(`/amb/ehr/cl/deliveryitem/${deliveryItemId}/price`, "POST", request)
	},

	exportBatch(request) {
		return new ApiCall(`/amb/batch/batch515`, "POST", request)
	},

	saveDeliveriesAdd(deliveryRequest, recordID) {
		return new ApiCall(`/amb/ehr/records/${recordID}/delivery?level_flag=CD`, "POST", deliveryRequest)
	},

	deleteDelevery(deliveryId) {
		return new ApiCall(`/amb/ehr/delivery/${deliveryId}`, "DELETE")
	},

	checkLabExternalCredentials(userId, request) {
		return new ApiCall(`/user/users/${userId}/externalcredentials`, "POST", request)
	},

	dajOverovaciTokenLab(request) {
		return new ApiCall(`/amb/ehealth/dzpelab/dajoverovacitoken`, "POST", request)
	},

	savePrescriptionAsTemplate(request) {
		return new ApiCall(`/amb/ehr/med/templates`, "PUT", request)
	},

	loadPrescriptionAsTemplate(request) {
		return new ApiCall(`/amb/ehr/med/templates`, "POST", request)
	},

	deletePrescriptionTemplate(templateId) {
		return new ApiCall(`/amb/ehr/med/templates/${templateId}`, "DELETE")
	},

	loadPrescriptionDeleteInfo(req) {
		return new ApiCall(`/amb/ehr/signature/DELETE`, "POST", req)
	},

	getDZPELabSMSCodeNeeded() {
		return new ApiCall(`/cl/IXS.CL.Data.SysParam/DZPELabSMSCodeNeeded`, "GET", null, true)
	},

	dajkodprezdravotnukartu(request) {
		return new ApiCall(`/amb/ehealth/proxy/dajkodprezdravotnukartu`, "POST", request)
	},

	dajPrihlasovacieUdaje(request) {
		return new ApiCall(`/amb/ehealth/dzpelab/dajprihlasovacieudaje`, "POST", request)
	},

	loadEp(request) {
		return new ApiCall("/amb/ehealth/ep", "POST", request)
	},

	getExpertiseExamTag(personnelCLExpertiseCode) {
		return new ApiCall(`/amb/ehealth/cl/expertiseexamtag/${personnelCLExpertiseCode}`, "GET")
	},

	paySummary(request) {
		return new ApiCall(`/amb/entity/deliveries/paysummary`, "POST", request)
	},

	getInvitationToken(patientId, request) {
		return new ApiCall(`/amb/entity/patients/${patientId}/register`, "POST", request)
	},

	registerMail(patientId, request) {
		return new ApiCall(`/amb/entity/patients/${patientId}/registermail`, "POST", request)
	},

	getDeliveryConflicts(req) {
		return new ApiCall(`/amb/ehr/delivery-conflicts?level_flag=CD`, "POST", req)
	},

	deletePatient(providerId, patientId) {
		return new ApiCall(`/amb/entity/providers/${providerId}/patients/${patientId}/decapitate`, "POST")
	},

	movePatientData(providerId, patientId, req) {
		return new ApiCall(`/amb/entity/providers/${providerId}/patients/${patientId}/transfer`, "POST", req)
	},

	getServiceEventList(request) {
		return new ApiCall(`/service/eventslist`, "POST", request)
	},

	saveInvoicePartner(req) {
		return new ApiCall(`/fin/invoicing-partner`, "POST", req)
	},

	downloadDispensarisation(request) {
		return new ApiCall(`/amb/batch/generate/SK799a`, "POST", request)
	},

	getUsers() {
		return new ApiCall(`/object/messages/users`, "POST")
	},

	loadServiceResources(req) {
		return new ApiCall(`/service/resources?level_flag=CDL3`, "POST", req)
	},

	copyProvidedServices(req) {
		return new ApiCall(`/service/providedservices-copy`, "POST", req)
	},

	covidResults(req) {
		return new ApiCall(`/amb/ehealth/mojeezdravie/results`, "POST", req)
	},

	getMessages(req) {
		return new ApiCall(`/object/messages`, "POST", req)
	},

	downloadEHGWInstallatorNais() {
		return new ApiCall("u/setupfiles", "GET")
	},

	setUserSettings(request) {
		return new ApiCall(`/object/user/settings`, "PUT", request)
	},

	getUserSettings(userId) {
		return new ApiCall(`/object/user/settings/${userId}`, "GET")
	}
}
