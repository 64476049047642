"use strict"

import {observable, action} from "mobx"
// import moment from "moment"
import api from "../actions/api"
// import RouterStore from "../../../global/store/RouterStore"
import {getFilters} from "../../../global/helpers/api"
import {getSelectedOrgUnitResourceID} from "../../../global/helpers/actions"
// import GlobalStore from "../../../global/store/GlobalStore"
// import AgreementAttributeStore from "./AgreementAttributeStore"
import UIStore from "../stores/UIStore"
// import settings from "../../../global/config/settings"

class CopyServiceDialogStore {
	@observable isOpen = false
	@observable checkedResourcesIds = []
	@observable checkedOrgunitIds = []
	source_resource_id = null

	@action open() {
		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
	}

	@action loadOrgunits() {
		UIStore.isFormSaving = true
		const searchRequest = getFilters([`class_name=Entity.Data.OrgUnit`])
		api
			.loadServiceResources(searchRequest)
			.call()
			.then((response) => {
				this.orgunits = response.rows.map((row) => {
					row.specific_rel["resource"] = row.resource
					return row.specific_rel
				})
				UIStore.isFormSaving = false
			})
			.catch(() => {
				UIStore.isFormSaving = false
			})
	}

	@action copyAgreement(form) {
		let req = {
			invalidate_date: form.$("endDate").value /* Dátum ukončenia zmluv */,
			source_resource_id: getSelectedOrgUnitResourceID(),
			services: this.checkedResourcesIds,
			target_resources: this.checkedOrgunitIds
		}
		api
			.copyProvidedServices(req)
			.call()
			.then(() => {
				form.reset()
				this.checkedOrgunitIds = []
				this.checkedResourcesIds = []
				this.close()
			})
	}
}

var singleton = new CopyServiceDialogStore()
export default singleton
