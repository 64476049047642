"use strict"

import {observable, action} from "mobx"
import api from "../actions/api"
import {getFilters} from "../../../global/helpers/api"
import {getSelectedOrgUnitID, getSelectedOrgUnitResourceID, getUserCompanyInfo} from "../../../global/helpers/actions"
import moment from "moment"
import UIStore from "./UIStore"
import DataStore from "./DataStore"
import CalendarStore from "./CalendarStore"
import GlobalStore from "../../../global/store/GlobalStore"
import WarningStore from "../../../global/store/WarningStore"
import RouterStore from "../../../global/store/RouterStore"
import {StorageBase} from "../../../global/storage/storageEx"
import {RESOURCES} from "../../../global/config/constants"

class AddEventDialogStore {
	@observable isOpen = false
	@observable step = 1
	@observable selectedPatient = {}
	@observable selectedSlot = undefined
	@observable serviceId = ""
	@observable serviceObj = {}
	@observable patientList = new Array()
	@observable inputDateTimeIsValid = moment().format("DD.MM.YYYY hh:mm")
	@observable slotsData = []
	@observable note = ""
	@observable headerMessage = "Calendar.addEvent.dialog.headerMessage"
	@observable isWarningMessage = false
	isOpenedNextVisitDialog = false

	@observable unknowPatientName = undefined

	notServiceTxt = ""

	@observable isNonPayer = false
	@observable serviceData = []
	// overbooking = false

	@observable showPatientEvents = false
	@observable patientEventsData = []

	resourceId = null
	providerId = null

	searchFormRef = null
	@observable isOpenOverTheAddButton = false

	@action getResourceID() {
		return isNotEmpty(this.resourceId) ? this.resourceId : getSelectedOrgUnitResourceID()
	}

	@action getProviderID() {
		return isNotEmpty(this.providerId) ? this.providerId : getSelectedOrgUnitID()
	}

	@action patientEvents() {
		api
			.loadClientAgenda(
				this.selectedPatient.client_id,
				moment()
					.startOf("day")
					.utc(),
				moment()
					.add(12, "months")
					.utc()
			)
			.call()
			.then((res) => (this.patientEventsData = isSafe(res) ? res : []))

		this.showPatientEvents = true
	}

	@action getServices() {
		UIStore.isFormSaving = true

		let filters = [{associated_column: "client_id", values: [{id_value: this.selectedPatient.client_id}]}]

		api
			.loadServices(this.getResourceID(), {filters: filters})
			.call()
			.then((res) => {
				let items = []
				if (isSafe(res) && res.length > 0) {
					res.forEach((service) => {
						let duration = isSafe(service.duration) ? +service.duration / 60 : 0
						items.push({
							code: service.provided_service_id,
							name_ext: `${service.name_ext} (${duration} min.)`,
							duration: duration,
							item_order: isSafe(service.item_order) ? service.item_order : Number.MAX_VALUE,
							color: service.color
						})
					})
				}

				// sort podla item_order v pripade rovnosti podla name_ext
				items = items.sort((a, b) => {
					return (
						(isSafe(a.item_order) ? +a.item_order : Number.MAX_VALUE) -
							(isSafe(b.item_order) ? +b.item_order : Number.MAX_VALUE) ||
						(isSafe(a.name_ext) ? a.name_ext : "").localeCompare(isSafe(b.name_ext) ? b.name_ext : "")
					)
				})

				if (items.length > 0) {
					this.serviceObj = {label: items[0].name_ext, value: items[0].code}
					this.serviceId = items[0].code

					this.setSlots()
				}

				this.serviceData = items
				UIStore.isFormSaving = false
			})
	}

	@action searchPatient() {
		const formValues = this.searchFormRef.values()
		let searchRequest = getFilters([`search_column=${formValues.search}`])

		if (formValues.currOrgunit) {
			if (UIStore.calendarTimePeriod == "resources" && this.isOpenOverTheAddButton) {
				const resources = StorageBase.getObjectByKey(RESOURCES)

				this.providerId = formValues.provider
				this.resourceId = resources.find((x) => x._id == formValues.provider).resource_id
			} else {
				if (UIStore.calendarTimePeriod != "resources") {
					this.providerId = null
					this.resourceId = null
				}
			}
			searchRequest = getFilters([`search_column=${formValues.search}`, `provider_id=${this.getProviderID()}`])
		}

		delayedCallback(500, () =>
			formValues.search.length >= 3
				? api
						.loadCompanyPatients(getUserCompanyInfo().id, searchRequest)
						.call()
						.then((response) => {
							this.patientList = response.rows
						})
				: (this.patientList = [])
		)
	}

	@action onHandleSelectPatient(patient, open = false) {
		this.step = 2
		this.selectedPatient = patient
		this.patientEventsData = []

		this.getServices()
		this.showPatientEvents = false
		//ci je neplatic
		api
			.overpoistenca(patient.client_id, this.getProviderID())
			.call()
			.then((response) => {
				if (isSafe(response)) {
					this.isNonPayer =
						isSafe(response.ma_narok_na_odkladnu_zs) &&
						isSafe(response.je_neplatic) &&
						!response.ma_narok_na_odkladnu_zs &&
						response.je_neplatic
							? true
							: false
				}
			})

		if (open) {
			this.isOpen = true
		}
	}

	@action onHandleUnknowPatient() {
		this.getServices()
		this.unknowPatientName = this.searchFormRef.values().search
		this.showPatientEvents = false
		this.patientEventsData = []
		this.step = 2
	}

	@action handleChangeResource(serviceObj) {
		this.serviceObj = serviceObj
		this.serviceId = `${serviceObj.value}`
		UIStore.isFormSaving = true
		this.setSlots()
	}

	@action handleChangeNote(e) {
		this.note = e.target.value
	}

	@action setSlots() {
		const slotRequest = {
			events: [
				{
					_ref: false,
					_type: "SRVZO.API.REST.Proxy.Event",
					services: [{_ref: false, _type: "SRVZO.API.REST.Proxy.ScheduledService", service_id: this.serviceId}]
				}
			],
			interval_from: CalendarStore.selectedDay.toISOString(), //this.selectedDateTime.toISOString(),
			primary_resource_id: this.getResourceID(),
			max_slots: 100,
			services: [{_ref: false, _type: "SRVZO.API.REST.Proxy.OrderedService", service_id: this.serviceId}],
			_type: "SRVZO.API.REST.Proxy.Order"
		}

		api
			.loadSlots(this.getProviderID(), slotRequest)
			.call()
			.then((slots) => {
				if (isSafe(slots) && isSafe(slots.messages) && slots.messages.length > 0 && isSafe(slots.messages[0].value)) {
					if (slots.messages[0].value === "OH") {
						this.headerMessage = "Calendar.addEvent.dialog.OH"
						this.isWarningMessage = true
					} else if (slots.messages[0].value === "OVERBOOKING") {
						this.headerMessage = "Calendar.addEvent.dialog.OVERBOOKING"
						this.isWarningMessage = true
					}
				} else {
					this.headerMessage = "Calendar.addEvent.dialog.headerMessage"
					this.isWarningMessage = false
				}

				this.slotsData = slots.slots
				UIStore.isFormSaving = false

				CalendarStore.loadPatientsOrder(
					moment(CalendarStore.selectedDay)
						.startOf("day")
						.utc()
						.format(),
					moment(CalendarStore.selectedDay)
						.endOf("day")
						.utc()
						.format()
				)
			})
	}

	@action save(openPatientDetail = false) {
		UIStore.isFormSaving = true

		if (isSafe(this.slotsData[0])) {
			const request = this.getSaveRequest(this.slotsData[0])

			api
				.createEvent([request])
				.call()
				.then((response) => {
					if (this.isOpenedNextVisitDialog) {
						const searchRequest = getFilters([`client_id=${response[0].client_id}`])

						api
							.loadCompanyPatients(getUserCompanyInfo().id, searchRequest)
							.call()
							.then((patients) => {
								if (isSafe(patients) && isSafe(patients.rows) && patients.rows.length > 0) {
									DataStore.patientDTO.set("nextVisit", patients.rows[0].next_event_from)
									DataStore.patientDTO.set("nextEventId", patients.rows[0].next_event_id)
									DataStore.patientDTO.set("nextEventName", patients.rows[0].next_event_service_name)
								}
							})
					}

					if (openPatientDetail) {
						const searchRequest = getFilters([`event_id=${response[0].event_id}`])
						searchRequest.row_count_show = 1000
						DataStore.providedServicesNames[response[0].person_id] = response[0].service_names
						api
							.loadEventsRelateds(searchRequest)
							.call()
							.then((res) => {
								DataStore.providedServices[response[0].person_id] = res.rows
								RouterStore.push(`/patient?id=${response[0].person_id}`)
							})
					}

					if (isSafe(response) && response.length > 0) {
						GlobalStore.setNotificationMessage("Calendar.addEvent.dialog.saveSuccess")
					}

					this.isOpenedNextVisitDialog = false

					this.close()
				})
		} else {
			WarningStore.open(this.notServiceTxt)
			UIStore.isFormSaving = false
		}
	}

	@action saveAndAddNewEvent() {
		UIStore.isFormSaving = true

		if (isSafe(this.slotsData[0])) {
			const request = this.getSaveRequest(this.slotsData[0])

			api
				.createEvent([request])
				.call()
				.then((response) => {
					if (isSafe(response) && response.length > 0) {
						GlobalStore.setNotificationMessage("Calendar.addEvent.dialog.saveSuccess")
					}

					UIStore.isFormSaving = false
					CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)
				})
		} else {
			WarningStore.open(this.notServiceTxt)
			UIStore.isFormSaving = false
		}
	}

	getSaveRequest = (slot) => {
		let request = slot

		let utcFrom = moment(CalendarStore.selectedDay)
			.set("second", 0)
			.utc() //moment(this.selectedDateTime).utc();

		let from = utcFrom.format()
		let duration = this.serviceData.filter((x) => x.code == this.serviceId)[0].duration
		// let duration = DataStore.resourcesData.filter((x) => x.code == this.serviceId)[0].duration

		let to = utcFrom.add(+duration, "minutes").format()

		request.interval_from = from
		request.interval_to = to
		request.events[0].interval.from = from
		request.events[0].interval.to = to

		request.events[0].note = this.note
		// request.note = this.note

		if (isSafe(this.unknowPatientName)) {
			request.client = this.unknowPatientName
		} else {
			request.client_id = this.selectedPatient.client_id
		}

		request.primary_resource_id = this.getResourceID()
		// if (this.overbooking) {
		request.force_overbooking = true
		// }

		request["services"] = [
			{
				service_id: this.serviceId,
				_type: "SRVZO.API.REST.Proxy.OrderedService",
				_ref: false
			}
		]

		return request
	}

	@action open(isAddButton, resource) {
		if (resource) {
			this.resourceId = resource._id
			this.providerId = resource.provider_id
		} else {
			this.resourceId = null
			this.providerId = null
		}

		this.isOpen = true
		CalendarStore.selectedSlotDateTime = ""

		//ziskanie farby podla trvania udalosti pocas dna
		CalendarStore.handleEventsByDay(CalendarStore.selectedDay)

		//pridavanie cez button
		if (isAddButton) {
			this.isOpenOverTheAddButton = true
			if (moment(CalendarStore.selectedDay).isBefore(moment(), "day")) {
				CalendarStore.selectedDay = moment().set("minute", CalendarStore.getRoundMinutes(moment()))
				CalendarStore.inputDateTimeIsValid = moment()
					.set("minute", CalendarStore.getRoundMinutes(moment()))
					.format("DD.MM.YYYY HH:mm")
			} else if (UIStore.calendarTimePeriod === "week") {
				CalendarStore.selectedDay = moment(CalendarStore.activeWeek).startOf("week")
				CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(
					moment(CalendarStore.activeWeek).startOf("week")
				)
			} else if (UIStore.calendarTimePeriod === "month") {
				CalendarStore.selectedDay = moment(CalendarStore.activeMonth).startOf("month")
				CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(
					moment(CalendarStore.activeMonth).startOf("month")
				)
			} else {
				if (moment(CalendarStore.selectedDay).isAfter(moment(), "day")) {
					const dayOfWeek = moment(CalendarStore.selectedDay).day()
					let openHourFrom = 0

					if (
						isSafe(GlobalStore.openingHour) &&
						isSafe(GlobalStore.openingHour[dayOfWeek]) &&
						isSafe(GlobalStore.openingHour[dayOfWeek].from)
					) {
						openHourFrom = GlobalStore.openingHour[dayOfWeek].from
					}
					CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
						.set("minute", 0)
						.set("hour", openHourFrom)
					CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
						.set("minute", 0)
						.set("hour", openHourFrom)
						.format("DD.MM.YYYY HH:mm")
				} else {
					CalendarStore.selectedDay = moment(CalendarStore.selectedDay)
						.set("minute", CalendarStore.getRoundMinutes(moment()))
						.set("hour", moment().hour())
					CalendarStore.inputDateTimeIsValid = moment(CalendarStore.selectedDay)
						.set("minute", CalendarStore.getRoundMinutes(moment()))
						.set("hour", moment().hour())
						.format("DD.MM.YYYY HH:mm")
				}
			}
		} else {
			this.isOpenOverTheAddButton = false
		}

		this.step = 1
	}

	@action planNextEvent(selectedPatient) {
		this.isOpenedNextVisitDialog = true
		this.selectedPatient = selectedPatient
		this.step = 2
		if (isSafe(selectedPatient) && isSafe(selectedPatient.nextVisit) && isNotEmpty(selectedPatient.nextVisit)) {
			CalendarStore.selectedDay = moment(selectedPatient.nextVisit).set(
				"minute",
				CalendarStore.getRoundMinutes(moment(selectedPatient.nextVisit))
			) //moment(datetime).local()
			CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(moment(selectedPatient.nextVisit))
		} else {
			CalendarStore.selectedDay = moment().set("minute", CalendarStore.getRoundMinutes(moment())) //moment(datetime).local()
			CalendarStore.inputDateTimeIsValid = CalendarStore.getRoundTime(moment())
		}

		CalendarStore.handleEventsByDay(CalendarStore.selectedDay)
		this.getServices()
		this.setSlots()

		this.isOpen = true
	}

	@action close() {
		this.isOpen = false
		this.isSelectedPatient = false
		this.isOpenedNextVisitDialog = false
		this.note = ""
		this.unknowPatientName = undefined
		this.patientList = []
		this.isNonPayer = false
		UIStore.isFormSaving = false
		this.showPatientEvents = false
		this.patientEventsData = []
		this.isOpenOverTheAddButton = false

		if (
			UIStore.calendarTimePeriod === "today" ||
			UIStore.calendarTimePeriod === "tomorrow" ||
			UIStore.calendarTimePeriod === "otherDay"
		) {
			if (moment(CalendarStore.selectedDay).isSame(moment(), "day")) {
				UIStore.calendarTimePeriod = "today"
			} else if (moment(CalendarStore.selectedDay).isSame(moment().add(1, "day"), "day")) {
				UIStore.calendarTimePeriod = "tomorrow"
			} else {
				UIStore.calendarTimePeriod = "otherDay"
			}
		}

		CalendarStore.setCalendarRange(UIStore.calendarTimePeriod)

		this.serviceObj = {}
		this.serviceId = ""
	}
}

var singleton = new AddEventDialogStore()
export default singleton
